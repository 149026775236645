
import React, { useState, useEffect, useMemo } from 'react';
import axios from 'axios';
import common from '../../../common';

import { TableList } from "../../../components";
import styles from './Dept.module.scss';
import 'primeicons/primeicons.css';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

function Contact() {
    const { useAlert, useLoading } = common();
    const { pAlert, AlertComponent } = useAlert();
    const { pLoadingOn, pLoadingOff, LoadingComponent } = useLoading();
    // const [userData, setUserData] = useState([]);//
    const [policyTeamData, setpolicyTeamData] = useState([]);//
    const [memberTeamData, setmemberTeamData] = useState([]);//
    const getMember = async () => {
        try {
            pLoadingOn();
            const response = await axios.post(`${API_BASE_URL}/naek_organization/out/get_list`,
                {
                    committee_category: '사무처',
                    committee_name: '전체'
                },
                { withCredentials: true }
            );
            if (response && response.data && response.data.result === 't') {
                // setUserData(response.data.data)
                const allData = response.data.data;
                const policyTeam = allData.filter(member => member.organization.includes("정책"));
                const memberTeam = allData.filter(member => member.organization.includes("회원"));
                setpolicyTeamData(policyTeam);
                setmemberTeamData(memberTeam);
            } else {
                pAlert(response.data.msg);
            }
        } catch (error) {
            console.error('실패:', error);
        } finally {
            setTimeout(() => {
                pLoadingOff();
            }, 300);
        }
    };
    useEffect(() => {
        getMember();
    }, []);

    const columns = useMemo(() => [
        { title: '', dataIndex: 'idx', className: 'display_none' },
        { title: '성명', dataIndex: 'name', className: '' },
        // { title: '직위', dataIndex: 'position', className: '' },
        { title: '소속', dataIndex: 'organization', className: '' },
        { title: '전화번호', dataIndex: 'phone', className: '' },
        { title: '이메일', dataIndex: 'email', className: '' },
    ], []);
    const pageSize = 110;
    const categoryOptions = useMemo(() => [], []);

    return (
        <>
            <AlertComponent />
            <LoadingComponent />
            <div className="components_wrap">
                <div className={styles.contact_flexwrap}>
                    <div className={styles.contact_half}>
                        <div className={styles.contactIconWrap}>
                            <div className={styles.contactI1}></div>
                        </div>
                        <div className={styles.contactText}>
                            <span>전화</span>
                            <p>02-6009-4000</p>
                        </div>
                    </div>
                    <div className={styles.contact_half}>
                        <div className={styles.contactIconWrap}>
                            <div className={styles.contactI2}></div>
                        </div>
                        <div className={styles.contactText}>
                            <span>팩스</span>
                            <p>02-6009-4019</p>
                        </div>
                    </div>
                    <div className={styles.contact_half}>
                        <div className={styles.contactIconWrap}>
                            <div className={styles.contactI3}></div>
                        </div>
                        <div className={styles.contactText}>
                            <span>이메일</span>
                            <p>naek@naek.or.kr</p>
                        </div>
                    </div>
                    <div className={styles.contact_half}>
                        <div className={styles.contactIconWrap}>
                            <div className={styles.contactI4}></div>
                        </div>
                        <div className={styles.contactText}>
                            <span>페이스북 페이지</span>
                            <a href="http://www.facebook.com/naek1995" target="_blank" className={styles.link}>
                                facebook.com/naek1995 <i className={`pi pi-external-link ${styles.link}`}></i>
                            </a>
                        </div>
                    </div>
                </div>
                <div className={styles.member_info_text}>정책·지원실 안내</div>
                <div className={styles.table_list_box}>
                    <TableList data={policyTeamData} columns={columns}
                        pageSize={pageSize}
                        categoryOptions={categoryOptions}
                        type='organization'
                        pageNav='false'
                    />
                </div>
                
                <div className={styles.member_info_text}>회원·사업실 안내</div>
                <div className={styles.table_list_box}>
                    <TableList data={memberTeamData} columns={columns}
                        pageSize={pageSize}
                        categoryOptions={categoryOptions}
                        type='organization'
                        pageNav='false'
                    />
                </div>
            </div>
        </>
    );
}

export default Contact;