import React, { useEffect, useRef, useState, useCallback } from 'react';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import common from '../../common';

import { FaDownload } from 'react-icons/fa';

import Quill from 'quill'; //에디터
import 'quill/dist/quill.snow.css'; //에디터
import 'quill-better-table/dist/quill-better-table.css';//에디터
import registerQuillCustomFormats from '../../quillCustomFormats';

import './Editor.scss';
import Switch from 'react-switch';
import Select from 'react-select';
import 'quill-better-table/dist/quill-better-table.css';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const QuillEditor = ({ }) => {
	const { useAlert, useLoading, useModal, useConfirm, pToast } = common();
	const { pModal, ModalComponent } = useModal(); // 훅 사용
	const { pAlert, AlertComponent } = useAlert();
	const { pConfirm, ConfirmComponent } = useConfirm();
	const { pLoadingOn, pLoadingOff, LoadingComponent } = useLoading();
	
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const menuIdx = 80;
    const cate = queryParams.get('pageid');
    const doc = queryParams.get('doc');
	
	const inputTitleRef = useRef(null);
	
	const [formData, setFormData ] = useState({
		menuIdx:menuIdx,
		cate:cate,
		title:'',
		contents:'',
		fileList:'',
		file:'',
	});
	const [selectedData, setSelectedData ] = useState({});

	useEffect(() => {
		chkChkMan();
	}, []);
	const chkChkMan = async () => {
        try {
            pLoadingOn();
			let tmpUri = '';
			let tmpParam = {};
			if (doc) {
				tmpUri=`${API_BASE_URL}/naek_member_depart/in/chk_edit_possible`;
				tmpParam={ doc:doc };
			}else{
				tmpUri=`${API_BASE_URL}/naek_member_depart/in/chk_write_possible`;
				tmpParam={ pageid:cate };
			}
            const response = await axios.post(tmpUri, tmpParam, { withCredentials: true });
            if (response.data.result === 't' && response.data.msg===true) {
				if (doc) {
					getDataMan();
				}
            } else {
				window.location.href = '/';
            }
        } catch (error) {
            console.error('Fetch failed:', error);
            pAlert('실패함.');
        } finally {
            pLoadingOff();
        }
    };
	
	const getDataMan = async () => {
		try {
			pLoadingOn();
			const response = await axios.post(`${API_BASE_URL}/naek_document/out/get_detail`, {targetIdx:doc}, {
				withCredentials: true
			});
			if (response.data.result === 't') {
				setFormData(prevFormData => ({
					...prevFormData,
					title:response.data.data.title,
					contents:response.data.data.contents,
					fileList:response.data.data.file,
					// file: [],
					// fileDel: [],
					// contents: response.data.data.contents
				}));
				setSelectedData(prevFormData => ({
					...prevFormData,
					fileBefore  :response.data.data.file,
				}));
				if (quillRef.current) {
					const quill = quillRef.current;
					const editor = quill.root;
					editor.innerHTML = response.data.data.contents || '';
				}
			} else {
				pAlert(response.data.msg);
			}
		} catch (error) {
			pAlert('E');
		} finally {
			pLoadingOff();  // 로딩 종료
		}
	};	
	
	const quillRef = useRef(null);
	const editorRef = useRef(null);

	const imageHandler = async (file) => {
		const edImgFormData = new FormData();
		edImgFormData.append('file', file);
		try {
			pLoadingOn();
			const response = await axios.post(`${API_BASE_URL}/naek_document/in/upload_image`, edImgFormData, {
				withCredentials: true
			});
			if (response.data.result === 't') {
				const imageUrl = response.data.url;
				const quill = quillRef.current;
				const range = quill.getSelection(true);
				quill.insertEmbed(range.index, 'image', imageUrl);
				quill.setSelection(range.index + 1);
			} else {
				pAlert(response.data.msg);
			}
		} catch (error) {
			pAlert('이미지 업로드에 실패했습니다. 다시 시도해주세요.');
		} finally {
			pLoadingOff();  // 로딩 종료
		}
	};
	useEffect(() => {
		registerQuillCustomFormats();
		if (editorRef.current) {
			const quill = new Quill(editorRef.current, {
				theme: 'snow',
				modules: {
					toolbar: {
						container: [
							[{ 'font': [] }],
							[{ 'size': ['10px', '12px', '14px', '16px', '18px', '20px', '24px', '28px', '32px'] }],
							['bold', 'underline', 'italic', 'strike'],
							[{ 'background': [] }, 'clean'],
							[{ 'script': 'sub' }, { 'script': 'super' }],
							[{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'align': [] }],
							['link', 'image', 'video'],
							['blockquote', 'code-block'],
							['table'], // 테이블 버튼 추가
						],
						handlers: {
							image: () => {
								const input = document.createElement('input');
								input.setAttribute('type', 'file');
								input.setAttribute('accept', 'image/*');
								input.click();
								input.onchange = () => {
									const file = input.files[0];
									imageHandler(file);
								};
							},
							table: () => openTableConfirm(), // 테이블 핸들러 등록
						}
					},
					imageResize: {
						parchment: Quill.import('parchment'),
						modules: ['Resize', 'DisplaySize'],
					},
				},
				formats: [
					'font', 'size', 'bold', 'underline', 'italic', 'strike', 'image', 'video', 'header',
					'list', 'align', 'background', 'color', 'code-block', 'script', 'blockquote', 'customLink',
					'table', 'td', 'tr', 'customHtml' // 커스텀 html 블롯 추가
				]
			});
			quillRef.current = quill;
			// quill.root.addEventListener('keydown', (event) => {
			// 	if (event.key === 'Backspace') {
			// 		const range = quill.getSelection();
			// 		if (range) {
			// 			const [leaf] = quill.getLeaf(range.index);
			// 			if (leaf && leaf.parent && leaf.parent.domNode.tagName === 'A' && leaf.parent.domNode.classList.contains('pl_blog_link')) {
			// 				const linkNode = leaf.parent.domNode;
			// 				linkNode.remove();  // a 태그 전체 삭제
			// 				event.preventDefault();  // 기본 Backspace 동작 방지
			// 			}
			// 		}
			// 	}
			// });

			const toolbar = quill.getModule('toolbar');
			const customButtonClass = 'ql-customLink';

			const handleDrop = async (event) => {
				event.preventDefault();
				event.stopPropagation();
				const files = event.dataTransfer.files;
				if (files && files.length > 0) {
					const file = files[0];
					await imageHandler(file);
				}
			};

			const handleDragOver = (event) => {
				event.preventDefault();
				event.stopPropagation();
			};

			quill.root.addEventListener('drop', handleDrop, true);
			quill.root.addEventListener('dragover', handleDragOver, true);

			const editorContainer = editorRef.current;
			editorContainer.addEventListener('drop', handleDrop);
			editorContainer.addEventListener('dragover', handleDragOver);

			setTimeout(() => {
				const editorRoot = quill.root;
				const unwantedTags = editorRoot.querySelectorAll('.ql-preview, .ql-action, .ql-remove, [data-formula], [data-link], [data-video]');
				unwantedTags.forEach(tag => {
					tag.remove();
				});
				const emptyTags = editorRoot.querySelectorAll('div:empty, p:empty');
				emptyTags.forEach(tag => {
					tag.remove();
				});
				const emptyLineTags = editorRoot.querySelectorAll('p');
				emptyLineTags.forEach(tag => {
					if (tag.innerHTML.trim() === '' || tag.innerHTML === '<br>') {
						tag.remove();
					}
				});
			}, 500);
			return () => {
				quill.root.removeEventListener('drop', handleDrop, true);
				quill.root.removeEventListener('dragover', handleDragOver, true);
				editorContainer.removeEventListener('drop', handleDrop);
				editorContainer.removeEventListener('dragover', handleDragOver);

				const buttonToRemove = toolbar.container.querySelector(`.${customButtonClass}`);
				if (buttonToRemove) {
					buttonToRemove.remove();
				}
			};
		}
	}, []);

	// useEffect(() => {
	// 	if (quillRef.current) {
	// 		const quill = quillRef.current;
	// 		const editor = quill.root;
	// 		editor.innerHTML = formData.contents || '';
	// 	}
	// }, [formData]);

	// const [showModal, setShowModal] = useState(false);

	// const fieldNames = {
	// 	cate: '카테고리',
	// 	title: '제목',//제목은 따로관리
	// 	contents: '내용',
	// 	file: '첨부파일',
	// 	fileDel: '첨부파일',
	// };


	// const normalizeHtml = (htmlString) => {
	// 	const parser = new DOMParser();
	// 	const doc = parser.parseFromString(htmlString, 'text/html');
	// 	const removeWhitespace = (node) => {
	// 		if (node.nodeType === Node.TEXT_NODE) {
	// 			node.nodeValue = node.nodeValue.trim();
	// 		} else if (node.nodeType === Node.ELEMENT_NODE) {
	// 			Array.from(node.childNodes).forEach(removeWhitespace);
	// 		}
	// 	};
	// 	removeWhitespace(doc.body);
	// 	const normalizeElement = (element) => {
	// 		if (element.tagName.toLowerCase() === 'iframe') {
	// 			const src = element.getAttribute('src');
	// 			element.innerHTML = ''; // 내용 제거
	// 			Array.from(element.attributes).forEach(attr => {
	// 				if (attr.name !== 'src') {
	// 					element.removeAttribute(attr.name);
	// 				}
	// 			});
	// 			if (src) {
	// 				element.setAttribute('src', src);
	// 			}
	// 		} else {
	// 			if (element.hasAttribute('style')) {
	// 				const styleValue = element.getAttribute('style');
	// 				const cleanedStyle = styleValue
	// 					.split(';')
	// 					.map(style => style.trim())
	// 					.filter(style => {
	// 						const [property, value] = style.split(':').map(s => s.trim());
	// 						// 잘못된 속성 값(예: '13px;')을 필터링
	// 						return property && value && /^[a-z-]+$/i.test(property) && !property.includes('13px');
	// 					})
	// 					.join('; ');
	// 				if (cleanedStyle) {
	// 					element.setAttribute('style', cleanedStyle);
	// 				} else {
	// 					element.removeAttribute('style');
	// 				}
	// 			}
	// 			Array.from(element.children).forEach(normalizeElement);
	// 		}
	// 	};
	// 	Array.from(doc.body.children).forEach(normalizeElement);
	// 	return doc.body.innerHTML.trim();
	// };







































	
	

	const handleUpdate = async (e) => {
		
		e.preventDefault();
		if (!inputTitleRef.current.value) {
			pAlert('제목을 입력해 주세요.');
			pLoadingOff();
			return;
		}
		const quill = quillRef.current;
		const contents = quill.root.innerHTML; // quill의 컨텐츠 가져오기
		if (!contents || contents.trim() === '<p><br></p>') {
			pAlert('내용을 입력해 주세요.');
			pLoadingOff();
			return;
		}
		const newFormData = {}; // 
		let newUpdateFormData = new FormData();
		Object.keys(formData).forEach(key => {
			if (key === 'contents') {
				newFormData[key] = contents;
			} else {
				newFormData[key] = formData[key];
			}
		});
		newUpdateFormData.append('targetIdx', doc);
		newUpdateFormData.append('title', inputTitleRef.current.value);
		Object.entries(newFormData).forEach(([key, value]) => {
			if ((key === 'file' || key === 'fileDelvv') && Array.isArray(value)) {
				value.forEach((file) => {
					newUpdateFormData.append(key, file);
				});
			} else if (key === 'title') {
			} else if (key === 'fileList') {
			} else if (Array.isArray(value)) {
				newUpdateFormData.append(key, JSON.stringify(value));
			} else {
				newUpdateFormData.append(key, value);
			}
		});
		pConfirm(
			'수정',
			<>
				<div>해당 게시물을 수정 하시겠습니까?</div>
			</>,
			'400px',
			'auto',
			async () => {
				try {
					pLoadingOn();
					const response = await axios.post(`${API_BASE_URL}/naek_document/in/data_update`, newUpdateFormData, {
						withCredentials: true,
						headers: {
							'Content-Type': 'multipart/form-data'
						}
					});
					if (response.data.result === 't') {
						setFormData(prevFormData => ({
							...prevFormData,
							file: [],
							fileDel: [],
							contents: response.data.data.contents
						}));
						if (quillRef.current) {
							const quill = quillRef.current;
							const editor = quill.root;
							editor.innerHTML = response.data.data.contents || '';
						}
						pAlert('수정이 완료되었습니다.');
					} else {
						pAlert(response.data.msg);
					}
				} catch (error) {
					console.error('등록 실패:', error);
					pAlert('등록에 실패했습니다. 다시 시도해 주세요.');
				} finally {
					pLoadingOff();
				}
			}
		);
	};







	
	

	const handleDelete = async () => {
		pConfirm(
			'삭제',
			<>
				<div>삭제가 완료되면 복원할 수 없습니다. 해당 게시물을 삭제 하시겠습니까?</div>
			</>,
			'400px',
			'auto',
			async () => {
				try {
					pLoadingOn();
					const response = await axios.post(`${API_BASE_URL}/naek_document/in/data_delete`, { targetIdx: doc }, { withCredentials: true });
					if (response.data.result === 't') {
						// dataTableRef.current.refreshTable();
						// handleBack();
						// inputTitleRef.current.value = '';
						pAlert('게시물이 삭제되었습니다. 목록으로 이동합니다.');
						setTimeout(() => {
							window.location.href = `/memberDepart?pageid=${cate}`;
						}, 2500);
					} else {
						pAlert(response.data.msg);
						pLoadingOff();
					}
				} catch (error) {
					pAlert('삭제에 실패했습니다. 다시 시도해 주세요.');
					pLoadingOff();
				} finally {
					
				}
			}
		);
	};



	// const imageInputRef = useRef(null);
	// const handleImageDelete = () => {
	// 	// 같은 파일을 다시 업로드할 수 있도록 input 초기화
	// 	if (imageInputRef.current) {
	// 		imageInputRef.current.value = '';
	// 	}
	// };

	
	// useEffect(() => {
	// 	if (inputTitleRef.current) {
	// 		inputTitleRef.current.value = formData.title;
	// 	}
	// }, [formData.title]);

	const [inputKey, setInputKey] = useState(Date.now());  // 파일 입력 요소의 키를 설정하여 고유하게 만듦
	const handleFileChange = (event) => {
		const files = Array.from(event.target.files);
		const newFileList = files.map((file, index) => ({
			idx: `${Date.now()}-${index}`,  // 고유 ID 생성
			name: file.name,
			serverName: URL.createObjectURL(file),  // 로컬 파일 URL 생성
			isLocal: true  // 로컬 파일 표시
		}));
		setFormData(prevFormData => ({
			...prevFormData,
			fileList: [...(prevFormData.fileList || []), ...newFileList],  // 기존 파일 리스트에 새로운 파일을 추가
			file: [...(prevFormData.file || []), ...files]  // 기존 파일 리스트에 새로운 파일을 추가
		}));
		setInputKey(Date.now());
	};
	const [selectedFiles, setSelectedFiles] = useState([]);

	const handleFileSelect = (file) => {
		setSelectedFiles((prevSelected) => {
			if (prevSelected.includes(file)) {
				return prevSelected.filter((f) => f !== file);
			} else {
				return [...prevSelected, file];
			}
		});
	};
	// useEffect(() => {
	// }, [selectedFiles]);
	// useEffect(() => {
	// }, [formData]);
	const handleFileDeleteSelected = () => {
		const newFileList = formData.fileList.filter(file => !selectedFiles.includes(file));
		const deletedFiles = formData.fileList.filter(file => selectedFiles.includes(file));
		const newFileDel = deletedFiles.filter(file =>
			(selectedData.fileBefore || []).some(beforeFile => beforeFile.idx === file.idx)
		);
		const remainingFiles = (formData.file || []).filter(file => !selectedFiles.some(selected => selected.name === file.name));
		setFormData(prevFormData => ({
			...prevFormData,
			fileList: newFileList,
			fileDel: [...(prevFormData.fileDel || []), ...newFileDel], // 기존 fileDel 유지하면서 삭제된 파일 추가
			file: remainingFiles  // 새로운 파일 리스트에서 삭제된 파일 제거
		}));
		setSelectedFiles([]);
		setInputKey(Date.now());
	};
	const handleFileDownload = async (file) => {
		if (file.isLocal) {
			const link = document.createElement('a');
			link.href = file.serverName;
			link.download = file.name;
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
		} else {
			try {
				pLoadingOn();
				const response = await axios.post(`${API_BASE_URL}/naek_data_table/in/get_file`, {
					menuIdx: formData.menuIdx,
					idx: [file.idx],
					serverName: file.serverName,
					name: file.name
				}, {
					withCredentials: true,
					responseType: 'blob'  // 응답 타입을 blob으로 설정
				});

				if (response.status === 200) { // HTTP 응답 상태 코드가 200인 경우
					const blob = response.data;
					const downloadUrl = window.URL.createObjectURL(blob);

					// Blob을 사용하여 파일 다운로드
					const link = document.createElement('a');
					link.href = downloadUrl;
					link.download = file.name;
					document.body.appendChild(link);
					link.click();
					document.body.removeChild(link);

					// Blob URL을 해제
					window.URL.revokeObjectURL(downloadUrl);
				} else {
					pAlert('파일 다운로드에 실패했습니다.');
				}
			} catch (error) {
				pAlert(`파일다운에 실패했습니다. 다시 시도해 주세요.`);
			} finally {
				pLoadingOff();
			}
		}
	};

	const handleSave = async (e) => {
		e.preventDefault();
		if (!inputTitleRef.current.value) {
			pAlert('제목을 입력해 주세요.');
			return;
		}
		const quill = quillRef.current;
		const contents = quill.root.innerHTML; // quill의 컨텐츠 가져오기
		if (!contents || contents.trim() === '<p><br></p>') {
			pAlert('내용을 입력해 주세요.');
			return;
		}
		const newFormData = {}; // 
		let newUpdateFormData = new FormData();
		Object.keys(formData).forEach(key => {
			if (key === 'contents') {
				newFormData[key] = contents;
			} else {
				newFormData[key] = formData[key];
			}
		});
		newUpdateFormData.append('menuIdx', formData.menuIdx);
		newUpdateFormData.append('title', inputTitleRef.current.value);
		Object.entries(newFormData).forEach(([key, value]) => {
			if ((key === 'file' || key === 'thumbnailFile') && Array.isArray(value)) {
				value.forEach((file) => {
					newUpdateFormData.append(key, file);
				});
			} else if (Array.isArray(value)) {
				newUpdateFormData.append(key, JSON.stringify(value));
			} else {
				newUpdateFormData.append(key, value);
			}
		});
		pConfirm(
			'게시글 등록',
			<>
				<div>해당 게시글을 등록하시겠습니까?</div>
			</>,
			'400px',
			'auto',
			async () => {
				try {
					pLoadingOn();
					const response = await axios.post(`${API_BASE_URL}/naek_document/in/data_insert`,
						newUpdateFormData,
						{ withCredentials: true }
					);
					if (response.data.result === 't') {
						setTimeout(() => {
							window.location.href = `/noticedetail?id=${response.data.data}`;
							pAlert('등록이 완료되었습니다.');
						}, 500);
					} else {
						pAlert(response.data.msg);
						pLoadingOff();
					}
				} catch (error) {
					console.error('등록 실패:', error);
					pAlert('등록에 실패했습니다. 다시 시도해 주세요.');
					pLoadingOff();
				} finally {
					
				}
			}
		);
	};

	const rowsRef = useRef(null);
	const columnsRef = useRef(null);
	const openTableConfirm = () => {
		const tbConfirmTxt = (
			<div>
				<label>
					행:
					<input
						type="number"
						min="1"
						ref={rowsRef}  // useRef를 통해 입력 필드 참조
						defaultValue={2}  // 기본값 설정
					/>
				</label>
				<label>
					열:
					<input
						type="number"
						min="1"
						ref={columnsRef}  // useRef를 통해 입력 필드 참조
						defaultValue={2}  // 기본값 설정
					/>
				</label>
			</div>
		);
		pConfirm(
			'테이블 생성',  // 모달의 제목
			tbConfirmTxt,
			'300px',  // 모달의 너비
			'200px',  // 모달의 높이
			createTable  // 확인 버튼을 눌렀을 때 실행할 함수
		);
	};
	const createTable = () => {
		const quill = quillRef.current;
		if (!quill) {
			return;
		}
		const range = quill.getSelection();
		const position = range ? range.index : quill.getLength();
		const rows = Number(rowsRef.current.value);
		const columns = Number(columnsRef.current.value);
		const table = document.createElement('table');
		table.classList.add('naek_editor_pyo');
		const tbody = document.createElement('tbody');
		table.appendChild(tbody);
		for (let i = 0; i < rows; i++) {
			const row = document.createElement('tr');
			for (let j = 0; j < columns; j++) {
				const cell = document.createElement('td');
				// cell.innerHTML = '<br>'; // 빈 셀로 설정
				cell.innerHTML = '<p><br></p>';
				row.appendChild(cell);
			}
			tbody.appendChild(row);
		}
		const tableHtml = table.outerHTML;
		quill.insertEmbed(position, 'customHtml', tableHtml);
		quill.setSelection(position + 1); // 테이블 삽입 후 커서 위치 설정
	};
	return (
		<>
			<AlertComponent />
			<LoadingComponent />
			<ModalComponent />
			<ConfirmComponent />
			<div className="components_wrap">
				<div className="editor_wrap">
					<div className="input_wrap">
						<div className="input_box">
							<div className="input_tit_box">
								<input
									type="text"
									placeholder="제목"
									className="p_ip"
									defaultValue={formData.title || ''}
									name="title"
									ref={inputTitleRef}
								/>
							</div>
							<div className="input_file_box">
								<div className="input_file_txt_box flex_between">
									<span className='f_small f_500'>첨부파일</span>
									<div className="input_file_btn_box display_flex">
										<button
											className="input_file_button_del"
											onClick={handleFileDeleteSelected}
											disabled={selectedFiles.length === 0}
										>선택 삭제</button>
										<label className="input_file_button" htmlFor="input-file">파일 업로드</label>
										<input
											key={inputKey}
											type="file"
											id="input-file"
											multiple
											onChange={handleFileChange}
											style={{ display: 'none' }}
										/>
									</div>
								</div>
								<div className="file_list">
									{formData?.fileList && formData?.fileList.length > 0 && formData?.fileList.map((file, index) => (
										<div key={index}>
											<>
												<label className="p_chkbox">
													<input
														type="checkbox"
														id={`file-${index}`}
														checked={selectedFiles.includes(file)}
														onChange={() => handleFileSelect(file)}
													/>
													<span>{file.name}</span>
													<button onClick={() => handleFileDownload(file)}>
														<FaDownload />
													</button>
												</label>
											</>
										</div>
									))}
								</div>
							</div>
						</div>
					</div>
					<div className="ed_wrap">
						<div className="ed_box">
							<div ref={editorRef} />
						</div>
					</div>
				</div>
				<div className="edit_btn_wrap">
					{doc ? (
						<>
							<div className="edit_btn_box_isdata flex_end">
								<button className='p_btn p_btn_normal p_btn_grey' onClick={handleDelete}>삭제</button>
								<button className='p_btn p_btn_normal p_btn_blue ' onClick={handleUpdate}>수정</button>
							</div>
						</>
					) : (
						<>
							<div className="edit_btn_box_nondata flex_end">
								<button className='p_btn p_btn_blue ' onClick={handleSave}>등록</button>
							</div>
						</>
					)}
				</div>
			</div>
		</>
	);
};

export default QuillEditor;
