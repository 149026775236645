
import React, { useState, useEffect, useMemo, useRef } from 'react';
import styles from './RelatedOrganization.module.scss';
import {TableCard} from "../../../../components";

function RelatedOrganization() {

    const data = useMemo(() => [
        { idx: '1', title: 'Council of Academies of Engineering and Technological Sciences(CAETS)', date: '-', thumbnail: '/img/img_003.gif', views: 0 },
    ], []);
    
    const pageSize = 10;
    const categoryOptions = useMemo(() => [
        { label: 'Entire', value: '전체' },
        { label: 'Title', value: 'title' },
        { label: 'Content', value: '' },
    ], []);

    return (
        <>
            <div className="components_wrap">
                <div className={styles.top_content_wrap}>
                    <TableCard data={data} pageSize={pageSize} categoryOptions={categoryOptions} type="relatedOrganization" />
                </div>
            </div>
        </>
    );
}

export default RelatedOrganization;