
import styles from './Greeting.module.scss';

function Greeting() {
    return (
        <>
            <div className="components_wrap">
                <div className="cont_img_wrap">
                    <img src="/img/greeting7th2.jpg" className='cont_img_de' alt="" style={{ width: '100%', }} />
                    <img src="/img/greeting7thM2.jpg" className='cont_img_m' alt="" style={{ width: '100%', }} />
                </div>
                <div className="cont_wrap">
                    <div className="cont_tit_box">
                        <span>인사말</span>
                    </div>
                    <div className="cont_box">
                        <p>한국공학한림원은 대학, 기업 및 연구소 등에서 기술 발전에 현저한 공을 세운 공학기술인을 발굴하여 우대하고, 공학기술과 관련된 학술연구와 지원사업을 통해 국가의 창조적인 공학 기술 개발과 지속적인 발전에 기여하고자 설립되었습니다.</p>
                        <p>한국공학한림원은 대한민국 산업과 공학기술 발전을 위해 회원들의 지혜를 모으고, 공학기술계 리더그룹으로서 정부와 사회를 잇는 가교 역할에 충실할 것 입니다.</p>
                        <p>여러 토론회와 정책연구를 통해 산업, 공학기술과 관련된 사회적 이슈에 대해 바람직한 여론을 형성하고 건전한 정책을 발굴하는데 앞장설 것입니다.</p>
                        <p>대한민국의 미래는 훌륭한 과학기술 인재에 달려 있습니다.</p>
                        <p>한국공학한림원에서 추진하고 있는 주니어공학기술교실, 캠퍼스특허전략유니버시아드, 차세대 공대리더, 차세대 지식재산리더 등 인재 양성 사업을 활성화해 나가겠습니다. 학생들과 미래에 대한 비전을 공유하고, 공학기술인들이 여러 분야에서 자긍심을 가진 리더로서 국가에 기여할 수 있는 풍토를 만들어 가겠습니다.</p>
                        <p>한국공학한림원은 ‘국가와 인류의 지속적인 발전을 선도하는 글로벌 공학기술 플랫폼’을 비전으로 지속 가능한 성장사회, 스마트한 창조사회, 건강하고 안전한 행복사회를 만들어 가는데 선도적인 역할을 해 나갈 것입니다.</p>
                        <p>많은 관심과 지원을 부탁드립니다.</p>
                    </div>
                </div>
                <div className="cont_wrap">
                    <div className="cont_tit_box">
                        <span>비전</span>
                    </div>
                    <div className="cont_box">
                        <div className="cont_img_box">
                            <div className={`${styles.vi_txt} flex_center`}>
                                <img src="/img/vision_txt.gif" className='' alt="" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="cont_wrap">
                    <div className="cont_tit_box">
                        <span>목표</span>
                    </div>
                    <div className="cont_box">
                        <ul className={`${styles.goal_list}`}>
                            <li className={`${styles.goal01}`}>산업혁신과<br />국가 미래전략 정책 제안</li>
                            <li className={`${styles.goal02}`}>미래지향적<br />공학인재 육성 방향 제시</li>
                            <li className={`${styles.goal03}`}>범국민적<br />기술문화 저변 확산</li>
                        </ul>
                    </div>
                </div>
                <div className="cont_wrap">
                    <div className="cont_tit_box">
                        <span>10대 전략과제</span>
                    </div>
                    <div className="cont_box">
                        <ul className={`${styles.goal_list02}`}>
                            <li className={`${styles.goal0201}`}>
                                <ul>
                                    <li className={`${styles.goal_st}`}>
                                        <strong>01</strong>
                                        <p>국가 R&D 활성화를 위한<br />융복합 네트워크 제안</p>
                                    </li>
                                    <li className={`${styles.goal_st}`}>
                                        <strong>02</strong>
                                        <p>급변하는 환경에 대응하는<br />미래 도전기술 발굴</p>
                                    </li>
                                    <li className={`${styles.goal_st}`}>
                                        <strong>03</strong>
                                        <p>신산업/신기술 규제개혁 제안</p>
                                    </li>
                                    <li className={`${styles.goal_st}`}>
                                        <strong>04</strong>
                                        <p>산업의 글로벌 리더십 확보를 위한<br />국제협력 강화</p>
                                    </li>
                                </ul>
                            </li>
                            <li className={`${styles.goal0202}`}>
                                <ul>
                                    <li className={`${styles.goal_st}`}>
                                        <strong>01</strong>
                                        <p>국가 발전을 위한 미래 공학인 모델 제시</p>
                                    </li>
                                    <li className={`${styles.goal_st}`}>
                                        <strong>02</strong>
                                        <p>차세대 창조적 융합인재 육성 방안 제시</p>
                                    </li>
                                    <li className={`${styles.goal_st}`}>
                                        <strong>03</strong>
                                        <p>공학인재를 국가적 리더로 키우는<br />육성체계 강화</p>
                                    </li>
                                </ul>
                            </li>
                            <li className={`${styles.goal0203}`}>
                                <ul>
                                    <li className={`${styles.goal_st}`}>
                                        <strong>01</strong>
                                        <p>공학기술 중심사회 구현을 위한<br />우수공학인 발굴 및 활용 확대</p>
                                    </li>
                                    <li className={`${styles.goal_st}`}>
                                        <strong>02</strong>
                                        <p>예비공학인 발굴과 육성을 위한<br />대국민 소통 채널 다각화</p>
                                    </li>
                                    <li className={`${styles.goal_st}`}>
                                        <strong>03</strong>
                                        <p>공학인 재능기부를 통한<br />사회적 공헌 확대</p>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="cont_wrap">
                    <div className="cont_tit_box">
                        <span>사회적 가치</span>
                    </div>
                    <div className="cont_box">
                        <div className="cont_img_box">
                            <img src="/img/vision.jpg" className='cont_img_de' alt="" style={{ width: '100%' }} />
                            <img src="/img/vision_m.jpg" className='cont_img_m' alt="" style={{ width: '100%', }} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Greeting;