//사업안내>시상 대회

import React, { useState, useEffect, useRef } from 'react';
import styles from './Engineer.module.scss';

function Engineer() {
    

    return (
        <>
            <div className="components_wrap">

                <div className={styles.cont_wrap}>
                    <div className={styles.cont_tit_box}>
                        <span>Campus Patent Universiade</span>
                    </div>
                    <div className={styles.cont_box}>
                        <ul className={styles.bul_list_none}>
                            <li>This contest is designed to foster entrepreneurship at universities by educating students on industrial practices related to Intellectual Property (IP). It stimulates students’ creativity and raises awareness of the need for IP management. Student teams are asked to search for and analyze patents and technologies related to a topic presented by industry, and create R&D strategies to acquire patentable new technologies.
                            The Universiade is well-received by academia (both students and faculty) and industry, and is seen as challenging, practical, educational, and a great way to get smart ideas out of students. Some of their suggestions are being picked up by companies for immediate implementation. The 2022 competition attracted more than 2000 teams, 4700 students, and 30 leading companies. This contest is co-organized by NAEK and the Korea Intellectual Property Office.</li>
                        </ul>
                    </div>
                </div>

                <div className={styles.cont_wrap}>
                    <div className={styles.cont_tit_box}>
                        <span>Young Engineers Honor Society</span>
                    </div>
                    <div className={styles.cont_box}>
                        <ul className={styles.bul_list_none}>
                            <li>Young Engineers Honor Society(YEHS) is a student community majoring in engineering and technology that are selected by engineering school deans or university presidents. YEHS was founded in 2005 and has approximately 1500 members to date.
                            Exchanges among students with diverse backgrounds help them grow into next-generation science and technology leaders who can contribute to society as members of industry, academia, or research institutions. YEHS is a place where engineers with different majors and career aspirations share their knowledge and experiences, creating a starting point for future convergence. The Society also focuses on various social contribution activities to foster social responsibility and ethical leadership required of future leaders.</li>
                        </ul>
                    </div>
                </div>

                <div className={styles.cont_wrap}>
                    <div className={styles.cont_tit_box}>
                        <span>Young Intellectual Property Leader</span>
                    </div>
                    <div className={styles.cont_box}>
                        <ul className={styles.bul_list_none}>
                            <li>Young Intellectual Property Leaders (YIPL) is a network organized to develop the winners of the annual Campus Patent Universiade (CPU) into tomorrow's world-class intellectual property professionals. We are building a system to train IP professionals by leveraging our network of NAEK members who are recognized leaders in their respective technical fields.
                            Winners of CPU are invited to IP workshops, CEO lecture series, technology tours, group activities, and more to gain access to IP-related information that can help them achieve their career goals.</li>
                        </ul>
                    </div>
                </div>

                <div className={styles.cont_wrap}>
                    <div className={styles.cont_tit_box}>
                        <span>Junior Engineering Achievement</span>
                    </div>
                    <div className={styles.cont_box}>
                        <ul className={styles.bul_list_none}>
                            <li>NAEK offers Junior Engineering Achievement classes in partnership with companies to instill a positive perception of science and technology. This unique science education is highly innovative in that it covers advanced industrial technologies rather than basic science. Company employees visit elementary schools to teach classes, and more than 40,000 children benefit from this program each year. Through this program, we hope to inspire Korean children to take an interest in science and technology, foster entrepreneurship, and nurture their dreams of becoming future engineers.</li>
                        </ul>
                    </div>
                </div>

                <div className={styles.cont_wrap}>
                    <div className={styles.cont_tit_box}>
                        <span>Publications</span>
                    </div>
                    <div className={styles.cont_box}>
                        <ul className={styles.bul_list_none}>
                            <li>NAEK publishes a series of books on engineering and technology for the general public. The project was launched in 2002 with support from the Haedong Science Foundation and Ministry of Knowledge Economy. This initiative resulted in a number of widely acclaimed series, including "World’s Best", "Science Textbook of the Future", "Engineering Comics", and "New Encounters with Engineering". NAEK members serve as experts to write the books and review technical terms.
                            An annual book review contest is also organized to encourage young people to read about science and engineering technology.</li>
                        </ul>
                    </div>
                </div>



            </div>
        </>
    );
}

export default Engineer;