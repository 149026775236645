import React, { useRef,useState,useEffect  } from 'react';
import { BrowserRouter as Router, Route, Routes, useNavigate, useLocation } from 'react-router-dom';

import axios from 'axios';
import common from '../../../common';
import { Checkbox } from 'primereact/checkbox';
import { InputText } from 'primereact/inputtext';
import { InputNumber } from 'primereact/inputnumber';
import { Dropdown } from 'primereact/dropdown';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

import './primereact__saga-blue_theme.css';
import 'primereact/resources/primereact.min.css'; // 기본 스타일
import 'primeicons/primeicons.css'; // 아이콘
import 'primeflex/primeflex.css'; // 레이아웃 유틸리티

import styles from './CandidateList.module.scss';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

function CandidateDetail({ onList, totalMem, beforeMem, viewType, isCode, isEditIdx }) {
	const navigate = useNavigate();

	const { useAlert, useLoading, useConfirm, pToast } = common();
	const { pAlert, AlertComponent } = useAlert();
	const { pLoadingOn, pLoadingOff,LoadingComponent} = useLoading();
    const { pConfirm, ConfirmComponent } = useConfirm();
	const [ formData, setFormData ] = useState({//기본 설정
		grade: null,//회원구분 1정회원 2일반회원 3원로회원 4외국회원
		gradeTxt:'',
		depart: null,//추천분과 1 2 3 4
		departTxt:'',
		gender: null,
		genderTxt:'',
		name: '',
		birth: '',
		organization: '',//소속
		position: '',//직위
		education: '',//최종학력/학교명
		nation: '대한민국',
		call: '',
		phone: '',
		email: '',
		recoSeq: '1',//추천순위 아직 안했음
		letterRecoAchievement: Array(4).fill(''),//주요 업적
		charCounts: Array(4).fill(0),//주요 업적 글자 수
		data_status_idx:0,
	});

	
	const gradeRef = useRef(null);
	const emailRef = useRef(null);
	const departRef = useRef(null);
	const nameRef = useRef(null);
	// const birthRef = useRef(null);
	const genderRef = useRef(null);
	const organizationRef = useRef(null);
	const positionRef = useRef(null);
	const educationRef = useRef(null);
	const nationRef = useRef(null);
	// const nationOtherValueRef = useRef(null);
	const letterRecoAchievementRef = useRef(null);
	//회원구분
	const Grade = [
		{ key: 'Grade1', value: '1', name: '정회원' },
		{ key: 'Grade2', value: '2', name: '일반회원' },
		{ key: 'Grade4', value: '4', name: '외국회원' },
	]
	const [selectedGrade, setSelectedGrade] = useState([]);
	const onChangeGrade = (e) => {
		let selected = [];
		if (e.checked) {
			selected.push(e.value);
			setFormData((prevData) => ({ ...prevData, grade: e.value.value, gradeTxt: e.value.name }));
		} else {
			selected = selected.filter(val => val !== e.value);
			setFormData((prevData) => ({ ...prevData, grade: null, gradeTxt: '' }));
		}
		setSelectedGrade(selected);
	};

	//추천분과
	const Depart = [
		{ key: 'Depart1', value: '1', name: '전기전자공학' },
		{ key: 'Depart2', value: '2', name: '기계공학' },
		{ key: 'Depart3', value: '3', name: '건설환경공학' },
		{ key: 'Depart4', value: '4', name: '화학생물공학' },
		{ key: 'Depart5', value: '5', name: '재료자원공학' },
		{ key: 'Depart6', value: '6', name: '기술경영정책' },
		{ key: 'Depart9', value: '9', name: '컴퓨팅' },
		{ key: 'Depart10', value: '10', name: '바이오메디컬' },
	]
	const [selectedDepart, setSelectedDepart] = useState([]);
	const onChangeDepart = (e) => {
		let selected = [];
		if (e.checked) {
			selected.push(e.value);
			setFormData((prevData) => ({ ...prevData, depart: e.value.value, departTxt: e.value.name }));
		} else {
			selected = selected.filter(val => val !== e.value);
			setFormData((prevData) => ({ ...prevData, depart: null,departTxt: '' }));
		}
		setSelectedDepart(selected);
	};

	//성별
	const Gender = [
		{ key: 'GenderF', value: 'F', name: '여성', label: '여' },
		{ key: 'GenderM', value: 'M', name: '남성', label: '남' },
	]
	const [selectedGender, setSelectedGender] = useState([]);
	const onChangeGender = (e) => {
		let selected = [];
		if (e.checked) {
			selected.push(e.value);
			setFormData((prevData) => ({ ...prevData, gender: e.value.value, genderTxt: e.value.name }));
		} else {
			selected = selected.filter(val => val !== e.value);
			setFormData((prevData) => ({ ...prevData, gender: null,genderTxt: '' }));
		}
		setSelectedGender(selected);
	};

	const onChangeBirth = (e) => {
		//
	};

	const onBlurBirth = (e) => {
		let value = e.target.value;
		if(value === 0 || value == ''){
			value='';
		}else{
			if (value < 1910) {
				value = 1910;
			} else if (value > 2024) {
				value = 2024;
			}
		}
		e.target.value=value;
		setFormData((prevData) => ({ ...prevData, birth: value }));
	};
	
	const [nationSelectedOption, setNationSelectedOption] = useState('대한민국');
	const [nationOtherValue, setNationOtherValue] = useState('');
	const nationOptions = [
		{ label: '대한민국', value: '대한민국' },
		{ label: '그외', value: 'other' }
	];
	const onChangeNation = (e) => {
		setNationSelectedOption(e.value);
		if (e.value !== 'other') {
			setNationOtherValue('');
		}
		setFormData((prevData) => ({ ...prevData, nation: e.value }));
	};
	const onChangeNationOther = (e) => {
		setNationOtherValue(e.target.value);
		setFormData((prevData) => ({ ...prevData, nation: e.target.value }));
	};
	
	const phoneSet = (eve = null, len = null, ref = null) => {
		if(eve && len){
			let maxLen=parseInt(len);
			let ipValue=eve.target.value;
			ipValue = ipValue.replace(/[^\d]/g, '')
			if(ref!==null && ipValue.length === maxLen){
				ref.current.focus();
			}
			if (ipValue.length > maxLen) {
				ipValue = ipValue.slice(0, maxLen);
			}
			eve.target.value=ipValue;
			return ipValue;
		}
	};

	//연락처 회사
	const [Call1, setCall1] = useState('');
	const [Call2, setCall2] = useState('');
	const [Call3, setCall3] = useState('');
	const Call2Ref = useRef(null);
	const Call3Ref = useRef(null);
	const onChangeCall1 = (e) => {
		const value = phoneSet(e,'3',Call2Ref);
		setCall1(value);
		setFormData((prevData) => ({ ...prevData, call: `${value}-${Call2}-${Call3}` }));
	};
	const onChangeCall2 = (e) => {
		const value = phoneSet(e,'4',Call3Ref);
		setCall2(value);
		setFormData((prevData) => ({ ...prevData, call: `${Call1}-${value}-${Call3}` }));
	};
	const onChangeCall3 = (e) => {
		const value = phoneSet(e,'4');
		setCall3(value);
		setFormData((prevData) => ({ ...prevData, call: `${Call1}-${Call2}-${value}` }));
	};

	//연락처 휴대폰
	const [Phone1, setPhone1] = useState('');
	const [Phone2, setPhone2] = useState('');
	const [Phone3, setPhone3] = useState('');
	const Phone2Ref = useRef(null);
	const Phone3Ref = useRef(null);
	const onChangePhone1 = (e) => {
		const value = phoneSet(e,'3',Phone2Ref);
		setPhone1(value);
		setFormData((prevData) => ({ ...prevData, phone: `${value}-${Phone2}-${Phone3}` }));
	};
	const onChangePhone2 = (e) => {
		const value = phoneSet(e,'4',Phone3Ref);
		setPhone2(value);
		setFormData((prevData) => ({ ...prevData, phone: `${Phone1}-${value}-${Phone3}` }));
	};
	const onChangePhone3 = (e) => {
		const value = phoneSet(e,'4');
		setPhone3(value);
		setFormData((prevData) => ({ ...prevData, phone: `${Phone1}-${Phone2}-${value}` }));
	};

	const onChangeAchievements = (index, value) => {
		setFormData(prevData => {
			const updatedAchievements = [...prevData.letterRecoAchievement];
			updatedAchievements[index] = value;

			const updatedCharCounts = [...prevData.charCounts];
			updatedCharCounts[index] = value.length;

			return {
				...prevData,
				letterRecoAchievement: updatedAchievements,
				charCounts: updatedCharCounts,
			};
		});
	};

	useEffect(() => {
		if(viewType=='edit' || viewType=='nominee'){
			//
		}
		if(viewType=='recommendation'){
			//
		}
		if(viewType=='edit' && isEditIdx){
			getDetail('e',isEditIdx);
		}
		if(viewType=='nominee' && isCode){
			getDetail('n',isCode);
		}
	}, [viewType, isEditIdx, isCode]);

	const [ tempFormData, setTempFormData ] = useState([]);
	const getDetail = async (type,idx) => {
        try {
            pLoadingOn();
			let postFunPath = type === 'e' ? 'in/get_detail' : 'out/get_detail';
			const postFunRequestData = type === 'e' ? { idx } : { code: idx };
			const response = await axios.post(`${API_BASE_URL}/naek_letter_reco/${postFunPath}`, postFunRequestData, { withCredentials: true });
            if (response && response.data && response.data.result === 't') {
                setDetailFormData(response.data.data);
            } else {
                console.error(response.data);
				pAlert(response.data.msg);
				if (type === 'e') {
				} else if (type === 'n') {
				}
				navigate('');
            }
        } catch (error) {
            console.error('실패:', error);
        } finally {
            setTimeout(() => {
                pLoadingOff();
            }, 300);
        }
    };

    const setDetailFormData = (data) => {
		const achievements = Array.isArray(data.letterRecoAchievement) ? data.letterRecoAchievement : Array(4).fill('');

		setNationSelectedOption(data.nation === '대한민국' ? '대한민국' : 'other');
		setNationOtherValue(data.nation === '대한민국' ? '' : data.nation);

        // 등급 설정
        // const tempGrade = Grade.find(category => category.value === data.grade.toString());
		const gradeValue = isNaN(data.grade) ? (Grade.find(category => category.name === data.grade)?.value || null) : data.grade;
		const gradeText = isNaN(data.grade) ? data.grade : (Grade.find(category => category.value.toString() === data.grade.toString())?.name || '');
		const tempGrade = Grade.find(category => category.value.toString() === gradeValue.toString());
		setSelectedGrade(tempGrade ? [tempGrade] : []);

        // 부서 설정
        // const tempDepart = Depart.find(category => category.value === data.depart.toString());
		const departValue = isNaN(data.depart) ? (Depart.find(category => category.name === data.depart)?.value || null) : data.depart;
		const departText = isNaN(data.depart) ? data.depart : (Depart.find(category => category.value.toString() === data.depart.toString())?.name || '');
		const tempDepart = Depart.find(category => category.value.toString() === departValue.toString());
		setSelectedDepart(tempDepart ? [tempDepart] : []);

        // 성별 설정
        const tempGender = Gender.find(category => category.value === data.gender.toString());
		const genderText = tempGender ? tempGender.name : '';
        setSelectedGender(tempGender ? [tempGender] : []);

        // 전화번호 설정
        const callParts = data.call.split('-');
        setCall1(callParts[0] || '');
        setCall2(callParts[1] || '');
        setCall3(callParts[2] || '');

        const phoneParts = data.phone.split('-');
        setPhone1(phoneParts[0] || '');
        setPhone2(phoneParts[1] || '');
        setPhone3(phoneParts[2] || '');

		const putFormData = {
			// grade: data.grade || null,
			grade: gradeValue || null,
			gradeTxt: gradeText || null,
			// depart: data.depart || null,
			depart: departValue || null,
			departTxt: departText || null,
			name: data.name || '',
			birth: data.birth || '',
			gender: data.gender || '',
			genderTxt: genderText || null,
			organization: data.organization || '',
			position: data.position || '',
			education: data.education || '',
			nation: data.nation || '',
			call: data.call || '',
			phone: data.phone || '',
			email: data.email || '',
			recoSeq: data.recoSeq || '0',
			letterRecoAchievement: achievements,
			charCounts: achievements.map(achievement => achievement.length),
			data_status_idx: data.data_status_idx || '0',
		};

		setFormData(putFormData);
		setTempFormData(putFormData);
    };
	
	const [isSubmitButton, setIsSubmitButton] = useState(false);
    const candidateSubmit = async (e) => {
        // e.preventDefault();
        if (!formData.grade) {
            pAlert('회원구분을 선택해 주세요');
			gradeRef.current.focus();
            return;
        }
        if (!formData.depart) {
            pAlert('추천분과를 선택해 주세요');
			departRef.current.focus();
            return;
        }
        if (!formData.name) {
            pAlert('성명을 입력해 주세요');
			nameRef.current.focus();
            return;
        }
        // if (!formData.birth || formData.birth === 0 || formData.birth === '0') {
        //     pAlert('생년을 입력해 주세요');
		// 	birthRef.current.focus();
        //     return;
        // }
        if (!formData.gender) {
            pAlert('성별을 선택해 주세요');
			genderRef.current.focus();
            return;
        }
        if (!formData.organization) {
            pAlert('소속을 입력해 주세요');
			organizationRef.current.focus();
            return;
        }
        if (!formData.position) {
            pAlert('직위를 입력해 주세요');
			positionRef.current.focus();
            return;
        }
        if (!formData.education) {
            pAlert('최종학력/학교명을 입력해 주세요');
			educationRef.current.focus();
            return;
        }
        if (!formData.nation) {
            pAlert('국적을 선택해 주세요');
			nationRef.current.focus();
            return;
        }
        if (!formData.call) {
            pAlert('연락처 회사를 입력해 주세요');
			Call2Ref.current.focus();
            return;
        }
        if (!formData.phone) {
            pAlert('연락처 휴대폰을 입력해 주세요');
			Phone2Ref.current.focus();
            return;
        }
        if (!formData.email) {
            pAlert('이메일을 입력해 주세요');
			emailRef.current.focus();
            return;
        }
		if (formData.letterRecoAchievement.every(item => item === '')) {
            pAlert('주요 업적을 입력해 주세요');
			letterRecoAchievementRef.current.focus();
            return;
		}

        pConfirm(
            '추천인 제출 확인',
            <>
                <div>해당 추천인을 제출 완료 하시겠습니까?</div>
                <div>제출 완료 시 추천인에게 코드 메일이 발송됩니다.</div>
            </>,
            '400px',
            'auto',
            async () => {
                const postData = {};
                if (formData.grade && formData.grade) postData.grade = formData.grade;
                if (formData.depart && formData.depart) postData.depart = formData.depart;
                if (formData.name) postData.name = formData.name;
                if (formData.birth) postData.birth = formData.birth;
                if (formData.gender && formData.gender) postData.gender = formData.gender;
                if (formData.organization) postData.organization = formData.organization;
                if (formData.position) postData.position = formData.position;
                if (formData.education) postData.education = formData.education;
                if (formData.nation) postData.nation = formData.nation;
                if (formData.call) postData.call = formData.call;
                if (formData.phone) postData.phone = formData.phone;
                if (formData.email) postData.email = formData.email;
                if (formData.recoSeq && formData.recoSeq) postData.recoSeq = formData.recoSeq;
                if (formData.letterRecoAchievement) postData.letterRecoAchievement = formData.letterRecoAchievement;
                try {
                    pLoadingOn();
                    const response = await axios.post(`${API_BASE_URL}/naek_letter_reco/in/data_insert`, postData, { withCredentials: true });
                    if (response.data.result === 't') {
						setIsSubmitButton(true);
                        pAlert('기본 추천서 제출 완료되었습니다!');
                    } else {
                        pAlert(response.data.msg);
                    }
                } catch (error) {
                    console.error('제출 실패:', error);
                } finally {
					setTimeout(() => {
						pLoadingOff();
					}, 500);
                }
            }
        );
    };
	
    const fieldNames = {
        grade: '회원구분',
        gradeTxt: '회원구분',
        depart: '추천분과',
        departTxt: '추천분과',
        name: '이름',
        birth: '생년',
        gender: '성별',
        genderTxt: '성별',
        organization: '소속',
        position: '직위',
        education: '최종학력/학교명',
        nation: '국적',
        call: '연락처(회사)',
        phone: '연락처(휴대폰)',
        email: '이메일',
        recoSeq: '추천 순위',
        letterRecoAchievement: '업적'
    };
	
	const updateFieldValues = (formData, tempFormData, fieldNames) => {
		const updateFormData = {};
		let changes = {};
	
		Object.keys(formData).forEach(key => {
			if (fieldNames[key]) {
				if (key === 'letterRecoAchievement') {
					if (Array.isArray(formData[key])) {
						const originalArray = Array.isArray(tempFormData[key]) ? tempFormData[key] : [];
						const updatedArray = formData[key];
						const newFormArray = [];
						let hasChanges = false;
	
						originalArray.forEach((item, index) => {
							const updatedItem = updatedArray[index] || '';
							newFormArray.push(updatedItem);
	
							if (item !== updatedItem) {
								hasChanges = true;
								if (item && updatedItem) {
									changes[`업적${index + 1}`] = `수정 (${item} -> ${updatedItem})`;
								} else if (item && !updatedItem) {
									changes[`업적${index + 1}`] = `삭제 (${item})`;
								} else if (!item && updatedItem) {
									changes[`업적${index + 1}`] = `추가 (${updatedItem})`;
								}
							}
						});
	
						if (updatedArray.length > originalArray.length) {
							hasChanges = true;
							for (let i = originalArray.length; i < updatedArray.length; i++) {
								newFormArray.push(updatedArray[i]);
								changes[`업적${i + 1}`] = `추가 (${updatedArray[i]})`;
							}
						}
	
						if (hasChanges) {
							const newFormString = newFormArray.join('|||');
							updateFormData[key] = newFormString;
						}
					}
				} else if (key === 'grade' || key === 'depart' || key === 'gender') {
					if (formData[key] !== tempFormData[key]) {
						if (formData[key] || formData[key] === '' || formData[key] === null) {
							updateFormData[key] = formData[key];
						}
					}
				} else if (key === 'gradeTxt' || key === 'departTxt' || key === 'genderTxt') {
					if (formData[key] !== tempFormData[key]) {
						if (formData[key] === '' || formData[key] === null) {
							changes[fieldNames[key]] = `${tempFormData[key]} -> 선택 취소`;
						} else {
							changes[fieldNames[key]] = tempFormData[key]
								? `${tempFormData[key]} -> ${formData[key]}`
								: `${formData[key]}`;
						}
					}
				} else if (key !== 'charCounts' && formData[key] !== tempFormData[key]) {
					updateFormData[key] = formData[key];
					if (formData[key] === '' || formData[key] === null) {
						changes[fieldNames[key]] = `${tempFormData[key]} -> 내용 삭제`;
					} else {
						changes[fieldNames[key]] = tempFormData[key]
							? `${tempFormData[key]} -> ${formData[key]}`
							: `${formData[key]}`;
					}
				}
			}
		});
	
		return { updateFormData, changes };
	};


	// 20240731 피추를 제외한 나머지 사람들은 수정을 못해!
	// const candidateEdit = async (e) => {
	// 	const { updateFormData, changes } = updateFieldValues(formData, tempFormData, fieldNames);
	
	// 	if (Object.keys(updateFormData).length === 0) {
	// 		pAlert('수정된 내용이 없습니다.');
	// 		return;
	// 	}
	
	// 	updateFormData['idx'] = isEditIdx;
	// 	updateFormData['ex'] = changes;
	
	// 	const changesList = Object.entries(changes).map(([field, change], index) => (
	// 		<div key={index}>{field}: {change}</div>
	// 	));
	
	// 	pConfirm(
	// 		'기본 추천서 수정 확인',
	// 		<>
	// 			<div>수정된 정보</div>
	// 			<div>{changesList}</div>
	// 			<div>수정하시겠습니까?</div>
	// 		</>,
	// 		'400px',
	// 		'auto',
	// 		async () => {
	// 			try {
	// 				pLoadingOn();
	// 				const response = await axios.post(`${API_BASE_URL}/naek_letter_reco/in/data_update`
	// 					// ,{ data: updateFormData }
	// 					, updateFormData
	// 					, { withCredentials: true });
	// 				if (response.data.result === 't') {
	// 					getDetail('e',isEditIdx);
	// 					pAlert('기본 추천서 수정이 완료되었습니다.');
	// 				} else {
	// 					pAlert(response.data.msg);
	// 				}
	// 			} catch (error) {
	// 				console.error('등록 실패:', error);
	// 			} finally {
	// 				setTimeout(() => {
	// 					pLoadingOff();
	// 				}, 500);
	// 			}
	// 		}
	// 	);
	// };

	const nomineeEdit = async (e) => {
		const { updateFormData, changes } = updateFieldValues(formData, tempFormData, fieldNames);
	
		if (Object.keys(updateFormData).length === 0) {
			pAlert('수정된 내용이 없습니다.');
			return;
		}
	
		updateFormData['code'] = isCode;
		updateFormData['ex'] = changes;
	
		const changesList = Object.entries(changes).map(([field, change], index) => (
			<div key={index}>{field}: {change}</div>
		));
	
		pConfirm(
			'기본 추천서 수정 확인',
			<>
				<div>수정된 정보</div>
				<div>{changesList}</div>
				<div>수정하시겠습니까?</div>
			</>,
			'400px',
			'auto',
			async () => {
				try {
					pLoadingOn();
					const response = await axios.post(`${API_BASE_URL}/naek_letter_reco/out/data_update`
						// , { data: updateFormData }
						, updateFormData
						, { withCredentials: true });
					if (response.data.result === 't') {
						getDetail('n',isCode);
						pAlert('기본 추천서 수정이 완료되었습니다.');
					} else {
						pAlert(response.data.msg);
					}
				} catch (error) {
					console.error('등록 실패:', error);
				} finally {
					setTimeout(() => {
						pLoadingOff();
					}, 500);
				}
			}
		);
	};

    const nomineeSubmit = async (e) => {
        // e.preventDefault();
		const { updateFormData, changes } = updateFieldValues(formData, tempFormData, fieldNames);
	
		if (Object.keys(updateFormData).length !== 0) {
			pAlert('수정된 내용이 있습니다. 수정버튼을 눌러 수정을 한 뒤 승인해주시기 바랍니다.');
			return;
		}

		pConfirm(
			'기본 추천서 승인 확인',
			<>
				<div>승인하시겠습니까?</div>
			</>,
			'400px',
			'auto',
			async () => {
				try {
					pLoadingOn();
					const response = await axios.post(`${API_BASE_URL}/naek_letter_reco/out/data_approval`
						, { code: isCode }
						, { withCredentials: true });
					if (response.data.result === 't') {
						getDetail('n',isCode);
						pAlert('기본 추천서 승인이 완료되었습니다. 관리자의 승인을 기다려주세요.');
					} else {
						pAlert(response.data.msg);
					}
				} catch (error) {
					console.error('등록 실패:', error);
				} finally {
					setTimeout(() => {
						pLoadingOff();
					}, 500);
				}
			}
		);
	};










































	return (
		<>
		<AlertComponent />
		<LoadingComponent />
		<ConfirmComponent />
		<div className='components_wrap'>
			{isCode && viewType=='nominee' &&
				<>
				<div className={`${styles.info_box}`}>
					{/* <div className='p_i'>
						<div className='p_i_con_box'>
							<span className='f_normal before_required f_bold'>안내사항 영역. 안내사항 문구 필요. 없을 시 영역 없애기.</span>
						</div>
					</div> */}
					<div className='p_i'>
						<div className='p_i_con_box'>
							<span className='f_normal before_required'>안녕하세요. {formData.name} 님 아래 기본추천서 내용을 확인하시고 승인버튼을 눌러주세요.</span>
						</div>
					</div>
					<div className='p_i'>
						<div className='p_i_con_box'>
							<span className='f_normal before_required'>하단 승인버튼을 누르면 관리자 심사 후 지명추천서 입력 단계로 진행됩니다.</span>
						</div>
					</div>
				</div>
				</>
			}

			{isEditIdx && viewType=='edit' &&
				<>
				<div className={`${styles.info_box}`}>
					<div className='p_i'>
						<div className='p_i_con_box'>
							<span className='f_normal before_required f_bold'>idx: {isEditIdx} 수정중</span>
						</div>
					</div>
				</div>
				</>
			}



			<div className={styles.recommend_candidate_wrap}>

				<div className='p_i_sec'>
					<div className='p_i'>
						<span className='p_i_b_tit f_big f_bold required' >1. 회원구분</span>
						<div className='p_i_con_box'>
							{Grade.map((category) => {
								return (
									<div key={category.key} className='p_i_c_3'>
										<Checkbox
											inputId={category.key}
											name="Grade"
											value={category}
											onChange={onChangeGrade}
											checked={selectedGrade.some((item) => item.value === category.value)}
											ref={gradeRef}
										/>
										<label htmlFor={category.key} className="ml-2 f_normal">{category.name}</label>
									</div>
								);
							})}
						</div>
					</div>
				</div>
				<div className='p_i_sec'>
					<div className='p_i'>
						<span className='p_i_b_tit f_big f_bold required'>2. 추천분과</span>
						<span className='p_i_b_tit_mini f_normal '> 1개 분과만 선택</span>
						<div className='p_i_con_box'>
							{Depart.map((category) => {
								return (
									<div key={category.key} className='p_i_c_3'>
										<Checkbox
											inputId={category.key}
											name="Depart"
											value={category}
											onChange={onChangeDepart}
											checked={selectedDepart.some((item) => item.value === category.value)}
											ref={departRef}
										/>
										<label htmlFor={category.key} className="ml-2 f_normal">{category.name}</label>
									</div>
								);
							})}
						</div>
					</div>
				</div>
				<div className='p_i_sec'>
					<div className=''>
						<span className='p_i_b_tit f_big f_bold'>3. 인적사항</span>
						<span className='p_i_b_tit_mini f_normal '>(업적 요약 자료)</span>
					</div>
					<div className='p_i_row'>
						<div className={`p_i ${styles.p_i_name}`}>
							<span className='p_i_tit f_small required'>성명</span>
							<div className='p_i_con_box'>
								<InputText
									className='p_ip'
									placeholder='홍길동'
									keyfilter="alpha"
									value={formData.name}
									onChange={(e) => setFormData((prevData) => ({ ...prevData, name: e.target.value }))}
									ref={nameRef}
								/>
							</div>
						</div>
						<div className={`p_i ${styles.p_i_birth}`}>
							<span className='p_i_tit f_small required'>생년</span>
							<div className='p_i_con_box'>
								<InputNumber
									// min={1900}
									max={2024}
									useGrouping={false}
									inputClassName='p_ip'
									placeholder='1970' 
									// value={formData.birth}
									value={formData.birth === '' || formData.birth === 0 || formData.birth === '0' ? null : formData.birth}
									onChange={onChangeBirth}
									onBlur={onBlurBirth} 
									// ref={birthRef}
								/>
							</div>
						</div>
						<div className={`p_i ${styles.p_i_gender}`}>
							<span className='p_i_tit f_small required'>성별</span>
							<div className='p_i_con_box'>
								{Gender.map((category) => {
									return (
										<div key={category.key} className='p_i_c_2 display_flex h45'>
											<Checkbox
												inputId={category.key}
												name="Gender"
												value={category}
												onChange={onChangeGender}
												checked={selectedGender.some((item) => item.value === category.value)}
												ref={genderRef}
											/>
											<label htmlFor={category.key} className="ml-2 f_normal">{category.name}</label>
										</div>
									);
								})}
							</div>
						</div>
					</div>
					<div className='p_i_row'>
						<div className='p_i p_i_3'>
							<span className='p_i_tit f_small required'>소속</span>
							<div className='p_i_con_box'>
								<input type="text" className='p_ip'
									value={formData.organization}
									onChange={(e) => setFormData((prevData) => ({ ...prevData, organization: e.target.value }))}
									ref={organizationRef}
								/>
							</div>
						</div>
						<div className='p_i p_i_3'>
							<span className='p_i_tit f_small required'>직위</span>
							<div className='p_i_con_box'>
								<input type="text" className='p_ip'
									value={formData.position}
									onChange={(e) => setFormData((prevData) => ({ ...prevData, position: e.target.value }))}
									ref={positionRef}
								/>
							</div>
						</div>
					</div>
					<div className=''>
						<div className='p_i '>
							<span className='p_i_tit f_small required'>최종학력/학교명</span>
							<div className='p_i_con_box'>
								<input type="text" className='p_ip'
									value={formData.education}
									onChange={(e) => setFormData((prevData) => ({ ...prevData, education: e.target.value }))}
									ref={educationRef}
								/>
							</div>
						</div>
					</div>
					<div className='p_i'>
						<span className='p_i_tit f_small required'>국적</span>
						<div className='p_i_con_box'>
							<Dropdown
								options={nationOptions}
								value={nationSelectedOption}
								onChange={onChangeNation}
								placeholder="Select an option"
								className={styles.dropdown_nation}
								ref={nationRef}
							/>
							{nationSelectedOption === 'other' && (
								<InputText
									value={nationOtherValue}
									onChange={onChangeNationOther}
									placeholder="국적을 입력해주세요."
									className={`p_ip ${styles.nation_other_ip}`}
									// ref={nationOtherValueRef}
								/>
							)}
						</div>
					</div>
				</div>

				<div className='p_i_sec'>
					<div className=''>
						<span className='p_i_b_tit f_big f_bold'>연락처</span>
					</div>
					<div className='p_i_row'>
						<div className='p_i p_i_3'>
							<span className='p_i_tit f_small required'>회사</span>
							<div className='p_i_con_box p_i_c_b_phone'>
								<InputText
									keyfilter='num'
									className='p_ip'
									maxLength={3}
									value={Call1}
									onInput={onChangeCall1}
								/>
								<span>-</span>
								<InputText
									keyfilter='num'
									className='p_ip'
									maxLength={4}
									value={Call2}
									onInput={onChangeCall2}
									ref={Call2Ref}
								/>
								<span>-</span>
								<InputText
									keyfilter='num'
									className='p_ip'
									maxLength={4}
									value={Call3}
									onInput={onChangeCall3}
									ref={Call3Ref}
								/>
							</div>
						</div>
						<div className='p_i p_i_3'>
							<span className='p_i_tit f_small required'>휴대폰</span>
							<div className='p_i_con_box p_i_c_b_phone'>
								<InputText
									keyfilter='num'
									className='p_ip'
									maxLength={3}
									value={Phone1}
									onInput={onChangePhone1}
								/>
								<span>-</span>
								<InputText
									keyfilter='num'
									className='p_ip'
									maxLength={4}
									value={Phone2}
									onInput={onChangePhone2}
									ref={Phone2Ref}
								/>
								<span>-</span>
								<InputText
									keyfilter='num'
									className='p_ip'
									maxLength={4}
									value={Phone3}
									onInput={onChangePhone3}
									ref={Phone3Ref}
								/>
							</div>
						</div>
						<div className='p_i p_i_3'>
							<span className='p_i_tit f_small required'>E-mail</span>
							<div className='p_i_con_box'>
								<InputText
									keyfilter='email'
									className='p_ip'
									value={formData.email}
									onChange={(e) => setFormData((prevData) => ({ ...prevData, email: e.target.value }))}
									ref={emailRef}
								/>
							</div>
						</div>
					</div>
				</div>
				<div className='p_i_sec'>
					<div className=''>
						<span className='p_i_b_tit f_big f_bold required'>4. 주요 업적</span>
						<span className='p_i_b_tit_mini f_normal '>(간략하게 작성)</span>
					</div>
					{formData.letterRecoAchievement.map((value, index) => (
						<div key={index} className='p_i'>
							<div className='p_i_con_box'>
								<InputText
									className='p_ip'
									maxLength={45}
									value={value}
									onChange={(e) => onChangeAchievements(index, e.target.value)}
									ref={letterRecoAchievementRef}
								/>
							</div>
						</div>
					))}
				</div>
			</div>
			<div className={styles.recommend_candidate_foot_wrap}>




				{viewType=='recommendation' &&
					<>
					<div className='p_i_sec'>
						{/* <div className='p_i'>
							<div className='p_i_con_box'>
								<span className='f_normal before_required'>안내사항 영역. 안내사항 문구 필요. 없을 시 영역 없애기.</span>
							</div>
						</div> */}
						<div className='p_i'>
							<div className='p_i_con_box'>
								<span className='f_normal before_required'>접수를 완료하면 추천인에게 내용이 전달되며, 내용을 수정할 수 없습니다.</span>
							</div>
						</div>
						<div className='p_i'>
							<div className='p_i_con_box'>
								<span className='f_normal before_required'>한국공학한림원 신입회원 심사를 위한 기본추천서를 작성함에 있어 있는 사실을 그대로 거짓 없이 작성하며, 차후 허위 사실이나 결격사유가 있을 경우, 후보자 추천을 취하할 것을 서약합니다.</span>
							</div>
						</div>
					</div>
					<div className='p_i_sec'>
						<div className='p_i'>
							<div className='p_i_con_box center'>
								<button onClick={onList} className='p_btn p_btn_normal'>뒤로가기</button>
								{!isSubmitButton && (
									<button onClick={candidateSubmit} className='p_btn p_btn_normal p_btn_blue mgl10'>제출</button>
								)}
							</div>
						</div>
					</div>
					</>
				}

				{viewType=='edit' &&
					<>
					<div className='p_i_sec'>
						{/* <div className='p_i'>
							<div className='p_i_con_box'>
								<span className='f_normal before_required'>안내사항 영역. 안내사항 문구 필요. 없을 시 영역 없애기.</span>
							</div>
						</div> */}
						<div className='p_i'>
							<div className='p_i_con_box'>
								<span className='f_normal before_required'>피추천인만 내용을 수정할 수 있습니다.</span>
							</div>
						</div>
						<div className='p_i'>
							<div className='p_i_con_box'>
								<span className='f_normal before_required'>한국공학한림원 신입회원 심사를 위한 기본추천서를 작성함에 있어 있는 사실을 그대로 거짓 없이 작성하며, 차후 허위 사실이나 결격사유가 있을 경우, 후보자 추천을 취하할 것을 서약합니다.</span>
							</div>
						</div>
					</div>
					<div className='p_i_sec'>
						<div className='p_i'>
							<div className='p_i_con_box center'>
								<button onClick={onList} className='p_btn p_btn_normal'>뒤로가기</button>
								{/* 피추를 제외한 나머지 사람들은 수정을 못함! 20240731 jm  */}
								{/* <button onClick={candidateEdit} className='p_btn p_btn_lg p_btn_blue mgl10'>수정하기</button> */}
							</div>
						</div>
					</div>
					</>
				}

				{viewType=='nominee' && isCode &&
					<>


					{(formData.data_status_idx === 230101 || formData.data_status_idx === 330101) ? (
						<>
							{/* 20240731 버튼이나 문구 노출을 어떻게 조절해야하나 일단 하드코딩! */}
							{/* 230101-정회원이 등록 */}
							{/* 230201-피추천인이 승인 */}
							{/* 330101-관리자에 의한 기본추천서 등록 */}
							{/* 330201-관리자가 기본추천서 승인 */}

							<div className='p_i_sec'>
								{/* <div className='p_i'>
									<div className='p_i_con_box'>
										<span className='f_normal before_required'>안내사항 영역. 안내사항 문구 필요. 없을 시 영역 없애기.</span>
									</div>
								</div> */}
								<div className='p_i'>
									<div className='p_i_con_box'>
										<span className='f_normal before_required'>뒤로가기나 화면을 종료하면 저장되지 않습니다. 수정버튼을 눌러 데이터를 저장해주세요.</span>
									</div>
								</div>
								<div className='p_i'>
									<div className='p_i_con_box'>
										<span className='f_normal before_required'>승인버튼을 누르면 관리자 심사 후 지명추천서 입력 단계로 진행됩니다.</span>
									</div>
								</div>
								<div className='p_i'>
									<div className='p_i_con_box'>
										<span className='f_normal before_required'>한국공학한림원 신입회원 심사를 위한 기본추천서를 작성함에 있어 있는 사실을 그대로 거짓 없이 작성하며, 차후 허위 사실이나 결격사유가 있을 경우, 후보자 추천을 취하할 것을 서약합니다.</span>
									</div>
								</div>
							</div>
							<div className='p_i_sec'>
								<div className='p_i'>
									<div className='p_i_con_box center'>
										<button onClick={nomineeEdit} className='p_btn p_btn_normal '>수정</button>
										<button onClick={nomineeSubmit} className='p_btn p_btn_normal p_btn_blue mgl10'>승인</button>
									</div>
								</div>
							</div>

						</>
					) : (
						
						<div className='p_i_sec'>
							{/* <div className='p_i'>
								<div className='p_i_con_box'>
									<span className='f_normal before_required'>현재 상태 : {formData.data_status_idx}</span>
								</div>
							</div> */}
							{/* <div className='p_i'>
								<div className='p_i_con_box'>
									<span className='f_normal before_required'>상황에 따른 화면 노출 유무와 안내문구 필요</span>
								</div>
							</div> */}
						</div>
					)}

					</>
				}
			</div>
		</div>
		</>
	);
}






















































function CandidateList({ onRecommendation, onEdit }) {
	const { useAlert, useLoading } = common();
	const { pAlert, AlertComponent } = useAlert();
	const { pLoadingOn, pLoadingOff,LoadingComponent} = useLoading();

	const [getData, setGetData] = useState([]);//
	const [getDataLen, setGetDataLen] = useState(0);//


    useEffect(() => {
        const fetchData = async () => {
            try {
                pLoadingOn();
                const response = await axios.post(`${API_BASE_URL}/naek_letter_reco/in/get_table`,{  }, { withCredentials: true });
                if (response.data && response.data.result === 't') {
					const tmpData = response.data.data;
					setGetDataLen(tmpData.length);
					setGetData(tmpData);
                } else {
					
                }
            } catch (error) {
                console.error('실패:', error);
            } finally {
                setTimeout(() => {
                    pLoadingOff();
                },300);
            }
        };
        fetchData();
    }, []);


    const onRowSelect = (e) => {
        onEdit(e.value.idx);
    };

	return (
		<>
		<LoadingComponent />
		<div className='components_wrap'>
			<div className={styles.code_wrap}>
				<div className='p_i_sec'>
					<div className='p_i'>
						<div className='p_i_con_box center'>
							<button onClick={onRecommendation} className='p_btn p_btn_normal p_btn_blue '>등록하기</button>
						</div>
					</div>
				</div>
				<div className='p_i_sec'>
					{/* <div className='p_i'>
						<div className='p_i_con_box'>
							<span className='f_normal before_required f_bold'>안내사항 영역. 안내사항 문구 필요. 없을 시 영역 없애기.</span>
						</div>
					</div>
					<div className='p_i'>
						<div className='p_i_con_box'>
							<span className='f_normal before_required'>두번째줄 안내사항 안내사항 안내사항 안내사항 안내사항입니다.</span>
						</div>
					</div> */}
				</div>
			</div>
			{getDataLen !== 0 && (
				<div className='naek_table'>
					<DataTable value={getData} selectionMode="single" onSelectionChange={onRowSelect} dataKey="idx">
						<Column field="idx" header="Num" bodyClassName="text-center"></Column>
						<Column field="createTime" header="등록일" bodyClassName="text-center"></Column>
						<Column field="status" header="상태" bodyClassName="text-center"></Column>
						<Column field="name" header="피추천인" bodyClassName="text-center"></Column>
						<Column field="birth" header="생년" bodyClassName="text-center"></Column>
						<Column field="email" header="이메일"></Column>
					</DataTable>
				</div>
			)}
		</div>
		</>
	);
}







































































function CodeInput({ onSubmitCode }) {
	const { useAlert, useLoading } = common();
	const { pAlert, AlertComponent } = useAlert();
	const { pLoadingOn, pLoadingOff,LoadingComponent} = useLoading();

	const [code, setCode] = useState('');//입력하는 코오드

	const CodeSubmit = async () => {
        try {
            pLoadingOn();
			const response = await axios.post(`${API_BASE_URL}/naek_letter_reco/out/get_detail`, { code: code }, { withCredentials: true });
            if (response && response.data && response.data.result === 't') {
				onSubmitCode(code);
            } else {
                console.error(response.data);
				pAlert(response.data.msg);
            }
        } catch (error) {
            console.error('실패:', error);
        } finally {
            setTimeout(() => {
                pLoadingOff();
            }, 300);
        }
    };

	//여러번 계속 시도하고 있다면?

	return (
		<>
		<AlertComponent />
		<LoadingComponent />

		<div className='components_wrap'>
			<div className={styles.code_wrap}>

				<div className='p_i_sec'>
					<div className='p_i'>
						<div className='p_i_con_box center'>
							<span className='f_bigger f_bold'>코드를 입력해주세요.</span>
						</div>
					</div>
				</div>
				<div className='p_i_sec'>
					<div className='p_i'>
						<div className='p_i_con_box center'>
							<input type="text" className='p_ip' onChange={(e) => setCode(e.target.value)} />
						</div>
					</div>
					<div className='p_i'>
						<div className='p_i_con_box center'>
							<button onClick={CodeSubmit} className='p_btn p_btn_normal p_btn_blue '>제출</button>
						</div>
					</div>
				</div>
				<div className='p_i_sec'>
					{/* <div className='p_i'>
						<div className='p_i_con_box'>
							<span className='f_normal before_required f_bold'>안내사항 영역. 안내사항 문구 필요. 없을 시 영역 없애기.</span>
						</div>
					</div> */}
					<div className='p_i'>
						<div className='p_i_con_box'>
							<span className='f_normal before_required'>이메일로 전달받은 코드를 입력해주세요.</span>
						</div>
					</div>
					{/* <div className='p_i'>
						<div className='p_i_con_box'>
							<span className='f_normal before_required'>두번째줄 안내사항 안내사항 안내사항 안내사항 안내사항입니다.</span>
						</div>
					</div> */}
					{/* <div className='p_i'>
						<div className='p_i_con_box'>
							<span className='f_normal before_required'>개인정보 수집 및 이용에 관한 동의 있어야 하는지 확인필요.</span>
						</div>
					</div> */}
				</div>
			</div>
		</div>
		</>
	);
}





































































function App({isLogin}) {
	const [currentScreen, setCurrentScreen] = useState('');
	const [tempCode, setTempCode] = useState('');
	const [tempIdx, setTempIdx] = useState('');
	const navigate = useNavigate();
    const location = useLocation();
	
	const totalMem=1;//정회원이 추천한 전체 인간들 명수 < 0이면 바로 등록하기 화면으로 갈려고
	const beforeMem=0;//정회원이 등록한 인간들 명수 < 추천순위때문에


    useEffect(() => {
        const query = new URLSearchParams(location.search);
        const view = query.get('view');
        const code = query.get('code');
        const idx = query.get('idx');
        
        setCurrentScreen(view || '');
        setTempCode(code || '');
        setTempIdx(idx || '');
    }, [location.search]);

	const moveList = () => {
		setCurrentScreen('list');
		navigate('?view=list');
	};

	const moveRecommendation = () => {
		setCurrentScreen('recommendation');
		navigate('?view=recommendation');
	};

	const moveEdit = (idx) => {
		setTempIdx(idx);
		setCurrentScreen('edit');
		navigate('?view=edit');
	};

	const moveCodeSubmit = (code) => {
		setTempCode(code);
		setCurrentScreen('nominee');
		navigate(`?view=nominee&code=${code}`);
	};

	if(isLogin){
		if(currentScreen==='recommendation' || totalMem===0){
			return <CandidateDetail viewType='recommendation' onList={moveList} totalMem={totalMem} beforeMem={beforeMem} />;
		}
		if(currentScreen==='edit'){
			// return <CandidateEdit onList={moveList} />;
			return <CandidateDetail viewType='edit' onList={moveList} totalMem={totalMem} beforeMem={beforeMem} isEditIdx={tempIdx} />;
		}
		if(currentScreen==='list'){
			return <CandidateList onRecommendation={moveRecommendation} onEdit={moveEdit} />;
		}
		return <CandidateList onRecommendation={moveRecommendation} onEdit={moveEdit} />;
	}
	if(currentScreen==='nominee'){
		return <CandidateDetail viewType='nominee' isCode={tempCode} />;
	}
	return <CodeInput onSubmitCode={moveCodeSubmit} />

		// if(currentScreen==='recommendation'){
		// 	return <CandidateDetail viewType='recommendation' onList={moveList} totalMem={totalMem} beforeMem={beforeMem} />;
		// }
		// if(currentScreen==='edit'){
		// 	// return <CandidateEdit onList={moveList} />;
		// 	return <CandidateDetail viewType='edit' onList={moveList} totalMem={totalMem} beforeMem={beforeMem} isEditIdx={tempIdx} />;
		// }
		// if(currentScreen==='list'){
		// 	return <CandidateList onRecommendation={moveRecommendation} onEdit={moveEdit} />;
		// }
		// if(currentScreen==='nominee'){
		// 	return <CandidateDetail viewType='nominee' isCode={tempCode} />;
		// }
		// return <CodeInput onSubmitCode={moveCodeSubmit} />
}

export default App;