// quillCustomFormats.js
import ImageResize from 'quill-image-resize-module-react';
import Quill from 'quill';

window.Quill = Quill
Quill.register('modules/imageResize', ImageResize);
export const registerQuillCustomFormats = () => {

    // customLink 블롯 등록
    if (!Quill.imports['formats/customLink']) {
        const Link = Quill.import('formats/link');
        class CustomLink extends Link {
            static create(value) {
                const node = super.create(value);
                node.setAttribute('class', 'pl_blog_link');
                return node;
            }
        }
        CustomLink.blotName = 'customLink';
        CustomLink.tagName = 'a';
        CustomLink.className = 'pl_blog_link';
        Quill.register(CustomLink, true);
    }


    // customHtml 블롯 등록
    if (!Quill.imports['formats/customHtml']) {
        const BlockEmbed = Quill.import('blots/block/embed');
        class HtmlBlock extends BlockEmbed {
            static create(value) {
                const node = super.create();
                node.innerHTML = value;
                return node;
            }

            static value(node) {
                return node.innerHTML;
            }
        }
        HtmlBlock.blotName = 'customHtml';
        HtmlBlock.tagName = 'div';
        Quill.register(HtmlBlock);
    }

    // TableCell (td) 등록
    if (!Quill.imports['formats/td']) {
        const TableCell = Quill.import('blots/block/embed');
        class CustomTableCell extends TableCell {
            static create(value) {
                const node = super.create();
                node.setAttribute('contenteditable', 'true');
                node.innerHTML = value || '';
                return node;
            }

            static formats(node) {
                return node.tagName === 'TD';
            }
        }
        CustomTableCell.blotName = 'td';
        CustomTableCell.tagName = 'td';
        Quill.register(CustomTableCell);
    }

    // TableRow (tr) 등록
    if (!Quill.imports['formats/tr']) {
        const TableRow = Quill.import('blots/block/embed');
        class CustomTableRow extends TableRow {
            static create(value) {
                const node = super.create();
                return node;
            }

            static formats(node) {
                return node.tagName === 'TR';
            }
        }
        CustomTableRow.blotName = 'tr';
        CustomTableRow.tagName = 'tr';
        Quill.register(CustomTableRow);
    }

    // Table 등록
    if (!Quill.imports['formats/table']) {
        const Table = Quill.import('blots/block/embed');
        class CustomTable extends Table {
            static create(value) {
                const node = super.create();
                return node;
            }

            static formats(node) {
                return node.tagName === 'TABLE';
            }
        }
        CustomTable.blotName = 'table';
        CustomTable.tagName = 'table';
        Quill.register(CustomTable);
    }

    // Quill의 기본 Size 포맷을 가져옵니다.
    const Size = Quill.import('attributors/style/size');

    Size.whitelist = ['10px', '12px', '14px', '16px', '18px', '20px', '24px', '28px', '32px'];
    Quill.register(Size, true);


};

export default registerQuillCustomFormats;