import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import common from '../../common';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import './Calendar.scss';
import moment from 'moment';
import 'moment/locale/ko'; // 한국어 로케일을 임포트합니다
import { useNavigate } from 'react-router-dom';

moment.locale('ko'); // 한국어 로케일을 설정합니다

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const App = () => {
    const navigate = useNavigate();
    const [date, setDate] = useState(new Date()); // 현재 선택된 날짜 상태
    const [activeStartDate, setActiveStartDate] = useState(new Date()); // 현재 표시된 월의 시작 날짜 상태
    const calendarRef = useRef(); // Calendar 컴포넌트에 접근하기 위한 ref 생성
    const [events, setEvents] = useState([]);

    const { useAlert, useLoading } = common();
    const { pAlert, AlertComponent } = useAlert();
    const { pLoadingOn, pLoadingOff, LoadingComponent } = useLoading();

    const [holidays, setHolidays] = useState([]);


    useEffect(() => {
        const fetchOptions = async () => {
            try {
                pLoadingOn();
                const response = await axios.post(`${API_BASE_URL}/naek_event/out/event_calendar_holiday_list`, {}, {
                    withCredentials: true
                });
                if (response.data.result === 't') {
                    // 공휴일 배열을 'YYYY-MM-DD' 형식의 키로 변환
                    const holidayMap = response.data.data.reduce((acc, holiday) => {
                        const formattedDate = moment(holiday.date).format('YYYY-MM-DD');
                        acc[formattedDate] = holiday.name;
                        return acc;
                    }, {});
                    setHolidays(holidayMap);
                } else {
                    pAlert(response.data.msg);
                }
            } catch (error) {
                console.error('Error fetching options:', error);
                pAlert('데이터 가져오기에 실패했습니다. 다시 시도해주세요.');
            } finally {
                pLoadingOff();
            }
        };

        fetchOptions();
    }, []);

    // 날짜가 변경될 때 호출되는 함수 >> 달력 일자를 클릭했을때임
    const onChange = date => {
        setDate(date);
    };

    // 활성 시작 날짜가 변경될 때 호출되는 함수 >> 월 바꿨을때
    const onActiveStartDateChange = ({ activeStartDate }) => {
        setActiveStartDate(activeStartDate);
    };

    // 날짜를 'YYYY-MM-DD' 형식으로 포맷하는 함수
    const formatDate = date => {
        return moment(date).format('YYYY-MM-DD');
    };

    useEffect(() => {
        const year = activeStartDate.getFullYear();
        const month = activeStartDate.getMonth() + 1;
        // console.log('onActiveStartDateChange - ' + year + '-' + month);
        setCalData(year, month);


    }, [activeStartDate]);

    const setCalData = async (year, month) => {
        try {
            pLoadingOn();
            const response = await axios.post(`${API_BASE_URL}/naek_event/out/get_table`, { y: year, m: month }, { withCredentials: true });

            if (response && response.data && response.data.result === 't') {
                setEvents(response.data.data);
            } else {
                console.error(response.data);
                setEvents([]);
                pAlert(response.data.msg);
            }
        } catch (error) {
            console.error('실패:', error);
            setEvents([]);
        } finally {
            setTimeout(() => {
                pLoadingOff();
            }, 100);
        }
    };


    // 각 타일에 추가적인 콘텐츠를 렌더링하는 함수
    const tileContent = ({ date, view }) => {
        const eventDate = formatDate(date);  // 날짜를 'YYYY-MM-DD' 형식으로 포맷
        const selectedEvents = events.filter(event => event.date === eventDate);  // 해당 날짜의 이벤트 필터링
        const selectedHoliday = holidays[eventDate];  // holidays 객체에서 해당 날짜의 공휴일 조회

        // 연도나 10년 보기에 공휴일을 표시하지 않음
        if (view === 'year' || view === 'decade') {
            return null;
        }

        return (
            <>
                {/* 이벤트가 있으면 해당 이벤트를 출력 */}
                {selectedEvents.length > 0 && (
                    <div className="events">
                        {selectedEvents.map((event, index) => (
                            <a
                                key={index}
                                className={`event ${event.calendarType === 1 ? 'event_forum' : event.calendarType === 2 ? 'event_meet' : 'event_etc'}`}
                                href={`/eventdetail?id=${event.idx}`}
                            >
                                <span>{event.name}</span>
                            </a>
                        ))}
                    </div>
                )}

                {/* 공휴일이 있으면 공휴일 이름을 출력 */}
                {selectedHoliday && (
                    <span className="holiday-name">{selectedHoliday}</span>
                )}
            </>
        );
    };
    // 타일을 비활성화할 조건을 정의하는 함수
    const tileDisabled = ({ date, view }) => {
        if (view === 'month') {
            const selectedMonth = moment(activeStartDate).month();  // 현재 표시된 월
            return date.getMonth() !== selectedMonth;
        }
        return false;
    };

    // 타일에 클래스 이름을 부여하는 함수
    const tileClassName = ({ date, view }) => {
        if (view === 'month') {
            const dateStr = moment(date).format('YYYY-MM-DD');
            if (holidays[dateStr]) {
                return 'holiday';
            }
            if (date.getDay() === 0) {
                return 'sunday';
            }
            if (date.getDay() === 6) {
                return 'saturday';
            }
        }
        return null;
    };

    // const clickFunc = (value, event) => {
    //     const clickDate = formatDate(value);
    //     console.log('Clicked day: '+ clickDate);
    // };


    // 버튼 클릭 시 캘린더의 월을 현재 월로 설정
    const handleToMonthClick = () => {
        const calendar = calendarRef.current; // Calendar 컴포넌트의 ref 가져오기
        const firstDayOfTodaysMonth = moment().date(1).toDate(); // 현재 월의 첫 번째 날 계산
        calendar.setActiveStartDate(firstDayOfTodaysMonth); // 캘린더의 활성 시작 날짜를 현재 월의 첫 번째 날로 설정
    };

    const isSameMonth = moment(date).isSame(activeStartDate, 'month'); // 날짜가 변경될 때 호출되는 함수
    const toMonthBtnClass = isSameMonth ? 'toMonth_btn' : 'toMonth_btn btn_active'; // 활성 시작 날짜가 변경될 때 호출되는 함수


    return (
        <>
            <AlertComponent />
            <LoadingComponent />
            <div className="p_calendar_wrap">
                <div className="display_flex">
                    <button
                        className={toMonthBtnClass}
                        onClick={handleToMonthClick}
                        disabled={isSameMonth}
                    >
                        이번 달
                    </button>
                    <div className="eventList">
                        <span className="list_txt forum">포럼</span>
                        <span className="list_txt meet">회의</span>
                        <span className="list_txt etc">기타</span>
                    </div>
                </div>
                <Calendar
                    onChange={onChange} // 날짜가 변경될 때 호출되는 함수
                    onActiveStartDateChange={onActiveStartDateChange} // 활성 시작 날짜가 변경될 때 호출되는 함수
                    showFixedNumberOfWeeks={true} // 고정된 주 수를 표시
                    value={date} // 현재 선택된 날짜 상태
                    tileContent={tileContent} // 각 타일에 추가 콘텐츠를 렌더링하는 함수
                    tileClassName={tileClassName} // 타일에 클래스 이름을 부여하는 함수
                    tileDisabled={tileDisabled} // 타일을 비활성화할 조건을 정의하는 함수
                    className="react-calendar" // 사용자 정의 CSS 클래스
                    calendarType="gregory" // 그레고리력 사용
                    locale="ko-KR" // 한국어 로케일 설정
                    next2Label={null} // 두 번째 다음 버튼 숨기기
                    prev2Label={null} // 두 번째 이전 버튼 숨기기
                    showNeighboringMonth={false} // 이웃한 달의 날짜 숨기기
                    minDetail="month" // 10년 단위 년도 숨기기 (month , year , decade , century)
                    ref={calendarRef}
                // onClickDay={clickFunc} // 날짜를 클릭했을 때 호출되는 함수
                />
            </div>
        </>
    );
};

export default App;
