import styles from './MembersIntro.module.scss';
import React, { useEffect, useState } from 'react';
import Highcharts from 'highcharts';
import Highcharts3d from 'highcharts/highcharts-3d';
import HighchartsReact from 'highcharts-react-official';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import common from '../../../common';

import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;


Highcharts3d(Highcharts);

// const regularDepartmentData = [
//     { department: '전기전자공학분과', academic: 19, industry: 27, total: 46 },
//     { department: '기계공학분과', academic: 30, industry: 19, total: 49 },
//     { department: '건설환경공학분과', academic: 23, industry: 14, total: 37 },
//     { department: '화학생물공학분과', academic: 23, industry: 18, total: 41 },
//     { department: '재료자원공학분과', academic: 20, industry: 22, total: 42 },
//     { department: '기술경영정책분과', academic: 15, industry: 19, total: 34 },
//     { department: '컴퓨팅분과', academic: 13, industry: 9, total: 22 },
//     { department: '바이오메디컬분과', academic: 11, industry: 7, total: 18 },
//     { department: '합계', academic: 154, industry: 135, total: 289 }
// ];

// const normalDepartmentData = [
//     { department: '전기전자공학분과', academic: 26, industry: 25, total: 51 },
//     { department: '기계공학분과', academic: 31, industry: 31, total: 62 },
//     { department: '건설환경공학분과', academic: 36, industry: 24, total: 60 },
//     { department: '화학생물공학분과', academic: 24, industry: 21, total: 45 },
//     { department: '재료자원공학분과', academic: 35, industry: 26, total: 61 },
//     { department: '기술경영정책분과', academic: 16, industry: 25, total: 41 },
//     { department: '컴퓨팅분과', academic: 13, industry: 12, total: 25 },
//     { department: '바이오메디컬분과', academic: 16, industry: 7, total: 23 },
//     { department: '합계', academic: 197, industry: 171, total: 368 }
// ];

// const oldDepartmentData = [
//     { department: '합계', total: 651 }
// ];

// const forignerDepartmentData = [
//     { department: '합계', total: 82 }
// ];




// 데이터 정의
// const regularMemberData = [
//     { name: '컴퓨팅', y: 15, color: '#e8c162' },
//     { name: '바이오메디컬', y: 13, color: '#c2d47e' },
//     { name: '전기전자공학', y: 50, color: '#874221' },
//     { name: '기계공학', y: 52, color: '#e2e2e0' },
//     { name: '건설환경공학', y: 39, color: '#eddca6' },
//     { name: '화학생물공학', y: 42, color: '#e2e2e0' },
//     { name: '재료자원공학', y: 43, color: '#dce1bd' },
//     { name: '기술경영정책', y: 33, color: '#c2d47e' }
// ];

// const normalMemberData = [
//     { name: '컴퓨팅', y: 26, color: '#eedad3' },
//     { name: '바이오메디컬', y: 22, color: '#f8c8c6' },
//     { name: '전기전자공학', y: 53, color: '#95483e' },
//     { name: '기계공학', y: 64, color: '#f7c2b0' },
//     { name: '건설환경공학', y: 63, color: '#f2988f' },
//     { name: '화학생물공학', y: 47, color: '#b57c75' },
//     { name: '재료자원공학', y: 61, color: '#c62f1e' },
//     { name: '기술경영정책', y: 42, color: '#6a1b17' }
// ];


function MembersIntro() {
    const [activeTab, setActiveTab] = useState('intro');

    const { useAlert,useConfirm, useLoading } = common();
    const { pAlert, AlertComponent } = useAlert();
    const { pConfirm, ConfirmComponent} = useConfirm();
    const { pLoadingOn, pLoadingOff, LoadingComponent } = useLoading();

    const [regularDepartmentData, setRegularDepartmentData] = useState([]);
    const [normalDepartmentData, setNormalDepartmentData] = useState([]);
    const [oldDepartmentData, setOldDepartmentData] = useState([]);
    const [forignerDepartmentData, setForignerDepartmentData] = useState([]);
    const [totalData, setTotalData] = useState([]);



    const [regularMemberData, setRegularMemberData] = useState([]);
    const [normalMemberData, setNormalMemberData] = useState([]);

    const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 768);
    const [isMobileView2, setIsMobileView2] = useState(window.innerWidth <= 500);

    const handleResize = () => {
        setIsMobileView(window.innerWidth <= 768);
        setIsMobileView2(window.innerWidth <= 500);
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const colorArray = ['#e8c162', '#c2d47e', '#874221', '#e2e2e0', '#eddca6', '#e2e2e0', '#dce1bd', '#c2d47e'];
    const colorArray2 = ['#eedad3', '#f8c8c6', '#95483e', '#f7c2b0', '#f2988f', '#b57c75', '#c62f1e', '#6a1b17'];

    const fetchData = async () => {
        try {
            pLoadingOn();
            const response = await axios.post(`${API_BASE_URL}/naek_member_status/out/get_data`, {lang : "KR"}, {
                withCredentials: true
            });

            if (response.data.result === 't') {
                const data = response.data.data;
                setRegularDepartmentData(data.get_regular_member_data);
                setNormalDepartmentData(data.get_normal_member_data);
                setOldDepartmentData(data.get_old_member_data);
                setForignerDepartmentData(data.get_forigner_member_data);
                setTotalData(data.get_total_member_data);

                // 정회원 그래프 데이터
                const transformedData = data.get_regular_member_data
                .filter(item => item.letterDepartName !== '합계') // '합계' 항목 필터링
                .map((item, index) => ({
                    name: item.letterDepartName.replace('분과', ''),  // '분과' 제거
                    y: item.total,  // 'total' 값을 'y'로 설정
                    color: colorArray[index % colorArray.length]  // 색상 순환
                }));
                setRegularMemberData(transformedData);

                // 정회원 그래프 데이터
                const transformedNormalData = data.get_normal_member_data
                .filter(item => item.letterDepartName !== '합계') // '합계' 항목 필터링
                .map((item, index) => ({
                    name: item.letterDepartName.replace('분과', ''),  // '분과' 제거
                    y: item.total,  // 'total' 값을 'y'로 설정
                    color: colorArray2[index % colorArray2.length]  // 색상 순환
                }));
                setNormalMemberData(transformedNormalData);

                
            } else {
                pAlert(response.data.msg);;
            }
        } catch (error) {
            console.error('Fetch failed:', error);
            pAlert('실패함.');
        } finally {
            pLoadingOff();
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const createChartOptions = (titleText, totleColor, memberCount, data) => {
        return {
            chart: {
                type: 'pie',
                height: isMobileView ? 100 + '%' : 700 + 'px',
                margin: [0, 0, 0, 0],
                options3d: {
                    enabled: true,
                    alpha: 50,
                    beta: 0
                },
            },
            title: {
                text: `
                <div className="${styles.chart_middle_box}">
                    <span className="${styles.chart_middle_title}">${titleText}</span>
                    <span className="${styles.chart_middle_cont}" style="color:${totleColor};">${memberCount}<span className="${styles.chart_middle_end}">명</span></span>
                </div>
                 `,
                align: 'center',
                verticalAlign: 'middle',
                y: 35,
                l: 50,
                useHTML: true // HTML 사용을 명시
            },
            plotOptions: {
                pie: {
                    innerSize: isMobileView2 ? 150 : 200,
                    depth: 65,
                    dataLabels: {
                        enabled: true,
                        format: '{point.name} {point.y}명',
                        distance: 1, // 글씨를 안쪽으로 배치
                        style: {
                            fontSize: isMobileView ? '18px' : '22px',
                            color: '#000000',
                            fontWeight: 400,
                        },
                        backgroundColor: 'rgba(255, 255, 255, 0.7)',
                        alignTo: 'plotEdges', // 레이블을 차트 모서리에 맞춤
                        connectorPadding: 0
                    },
                    states: {
                        hover: {
                            enabled: false // 마우스 호버 시 효과 제거
                        },
                        inactive: {
                            enabled: false // 비활성화 상태 제거
                        }
                    },
                    allowPointSelect: false // 점 선택 비활성화 (선택 애니메이션 제거)
                }
            },
            tooltip: {
                enabled: false // 툴팁 비활성화
            },
            series: [{
                data: data
            }]
        };
    };

    // 전체합계
    const totalValue = totalData.length > 0 ? totalData[0].total : 0;

    // 정회원 차트 옵션 생성
    const regularMemberOptions = createChartOptions(
        '정회원',
        '#e79911',
        regularMemberData.reduce((acc, item) => acc + item.y, 0), // 정회원 총합계 계산
        regularMemberData
    );

    // 일반회원 차트 옵션 생성
    const normalMemberOptions = createChartOptions(
        '일반회원',
        '#c04617',
        normalMemberData.reduce((acc, item) => acc + item.y, 0), // 일반회원 총합계 계산
        normalMemberData
    );


    const rowClassName = (rowData) => {
        return rowData.letterDepartName === '합계' ? 'table_last_row' : '';
    };



    return (
        <>
            <div className="components_wrap">

                <div className={`${styles.dept_btn_box}`}>
                    <button
                        className={activeTab === 'intro' ? styles.active : ''}
                        onClick={() => setActiveTab('intro')}
                    >
                        회원 소개
                    </button>
                    <button
                        className={activeTab === 'status' ? styles.active : ''}
                        onClick={() => setActiveTab('status')}
                    >
                        회원 현황
                    </button>
                </div>
                <div className="cont_wrap">
                    {activeTab === 'intro' && (
                        <>
                            <div className="cont_tit_box">
                                <span>회원 관련 정관 조항</span>
                            </div>
                            <div className={styles.cont_box}>
                                <h5>제 5조 (회원)</h5>
                                <ul className={styles.m_i_ul}>
                                    <li>① 공학한림원은 공학한림원 회원(이하 "회원"이라 한다)으로 구성한다.</li>
                                    <li>② 회원은 정회원, 일반회원, 원로회원, 외국회원, 명예회원 및 단체회원으로 한다.</li>
                                    <li>③ 정회원의 정수는 300명으로 하며 정회원 이외의 회원의 정수는 회원에 관한 규정에 정한다.</li>
                                </ul>
                                <h5>제 6조 (회원의 자격)</h5>
                                <ul className={styles.m_i_ul}>
                                    <li>① 회원의 후보자 자격은 공학산업기술 및 관련분야에서 15년 이상 활동한 자로서 다음 각호 1에 해당한다.</li>
                                    <li className={styles.pl10}>- 공학학술 연구 및 그 결과의 실용화에 현저하게 공헌을 한 자</li>
                                    <li className={styles.pl10}>- 새로운 산업기술 분야에서 선구적인 연구개발을 통하여 현저하게 성과를 올린 자</li>
                                    <li className={styles.pl10}>- 산업관련분야에서 선구적 또는 극히 어려운 사업을 수행함에 있어 지대한 성과를 올린 지도적 입장에 있는 자</li>
                                    <li className={styles.pl10}>- 공학 및 기술 교육에서 괄목할 만한 공헌을 한 자</li>
                                    <li>② 정회원은 일반회원을 지낸 자로서, ①항의 자격을 가진 65세 미만의 대한민국 국민으로서, 총회에서 선출된 자로 한다.</li>
                                    <li>③ 일반회원은 ①항의 자격을 가진 65세 미만의 대한민국 국민으로서, 이사회에서 선출된 자로 한다.</li>
                                    <li>④ 원로회원은 정회원 또는 일반회원을 지낸 자로서 ①항의 자격을 가진 65세 이상의 자로서, 총회에서 선출된 자로 한다.</li>
                                    <li>⑤ 외국회원은 대한민국 국민 이외의 사람으로 ①항의 자격을 가진 자로서, 총회에서 선출된 자로 한다.</li>
                                    <li>⑥ 명예회원은 공학한림원 발전 및 재정에 큰 기여를 한 자로서, 총회에서 선출된 자로 한다.</li>
                                    <li>⑦ 단체회원에 관한 사항은 회원에 관한 규정에 정한다.</li>
                                </ul>
                                <h5>제 7조 (회원의 선출 및 탈퇴)</h5>
                                <ul className={styles.m_i_ul}>
                                    <li>① 정회원은 정회원 및 이사회에서 추천한 자 중에서 분과위원회와 회원위원회의 심사를 거쳐 이사회에서 의결하고 총회의 승인을 얻은 자로 한다. 다만, 총회승인은 회원 신임에 관한 정회원의 서면결의로 대체할 수 있다.</li>
                                    <li>② 정회원 이외의 회원선출, 회원위원회의 구성 등에 관하여 필요한 사항은 회원에 관한 규정에 정한다.</li>
                                    <li>③ 회원이 탈퇴하고자 할 때에는 공학한림원에 탈퇴신청서를 제출하고 임의로 탈퇴할 수 있다.</li>
                                </ul>
                                <h5>제 8조 (회원의 임기 등)</h5>
                                <ul className={styles.m_i_ul}>
                                    <li>① 정회원의 임기는 5년으로 하되, 연임할 수 있다. 단, 임기 중 65세가 지나면 임기가 만료된 것으로 한다.</li>
                                    <li>② 정회원 이외의 회원의 임기는 회원에 관한 규정에 정한다.</li>
                                    <li>③ 회원은 회원에 관한 규정에서 정한 입회비 및 연회비를 납부한다.</li>
                                    <li>④ 모든 회원은 비상근으로 한다.</li>
                                </ul>
                                <h5>제 9조 (회원의 포상 및 징계)</h5>
                                <ul className={styles.m_i_ul}>
                                    <li>① 공학한림원 발전에 크게 기여한 회원에 대하여서는 이사회 의결로써 포상할 수 있다.</li>
                                    <li>② 공학한림원의 명예를 훼손 또는 공학한림원의 목적에 위배되는 행위를 한 회원에 대하여는 이사회의 의결로서 징계할 수 있으며, 이에 필요사항은 회원에 관한 규정에 정한다.</li>
                                </ul>
                                </div>
                                <div className={`cont_tit_box ${styles.mt100}`}>
                                <span>회원 활동</span>
                                </div>
                                <div className={styles.cont_box}>
                                <h5>전문분과별 세미나</h5>
                                <ul className={styles.m_i_ul_2}>
                                    <li>8개 전문분과별 4~6회 세미나를 개최하여 명사의 강연과 함께 회원간 자유토론 및 지식공유를 통해 최신 기술 및 사회 트렌드를 엿볼 수 있는 기회 마련</li>
                                </ul>
                                <h5>사업장방문</h5>
                                <ul className={styles.m_i_ul_2}>
                                    <li>전체 회원대상으로 국내 최고기업의 산업현장 방문 및 기술체험을 통해 최신 산업트렌드를 익히고, 최신 기술에 대한 정보 공유와 발전 방향 모색</li>
                                </ul>
                                <h5>전문분과위원회</h5>
                                <ul className={styles.m_i_ul_2}>
                                    <li>매년 새롭게 선출된 정회원 및 일반회원에 회원증서 및 인증패 수여를 통해 대한민국 최고 공학기술인 그룹의 일원으로서의 영예를 기리는 축하행사를 하고, 소속분과위원회를 구성하여 분과 활성화 추진</li>
                                </ul>
                            </div>
                        </>
                    )}




                    {activeTab === 'status' && (
                        <>
                            <div className={styles.chart_wrap}>
                                <div className={styles.cont_tit_box}>
                                    <span>정회원</span>
                                </div>
                                <div className={styles.chart_box}>
                                    <HighchartsReact
                                        highcharts={Highcharts}
                                        options={regularMemberOptions}
                                    />
                                </div>
                            </div>
                            <div className={styles.table_wrap}>
                                <DataTable value={regularDepartmentData} rowClassName={rowClassName}>
                                    <Column field="letterDepartName" header="구 분" />
                                    <Column field="academic" header="학계" />
                                    <Column field="industry" header="산업계" />
                                    <Column field="total" header="합계" />
                                </DataTable>
                            </div>
                            <div className={`${styles.chart_wrap} ${styles.p_t_100}`}>
                                <div className={styles.cont_tit_box}>
                                    <span>일반회원</span>
                                </div>
                                <div className={styles.chart_box}>
                                    <HighchartsReact
                                        highcharts={Highcharts}
                                        options={normalMemberOptions}
                                    />
                                </div>
                            </div>
                            <div className={styles.table_wrap}>
                                <DataTable value={normalDepartmentData} rowClassName={rowClassName}>
                                    <Column field="letterDepartName" header="구 분" />
                                    <Column field="academic" header="학계" />
                                    <Column field="industry" header="산업계" />
                                    <Column field="total" header="합계" />
                                </DataTable>
                            </div>
                            <div className="flex_between">
                                <div className={styles.status_table_wrap_2}>
                                    <div className={styles.p_r_5}>
                                        <div className={styles.cont_tit_box}>
                                            <span>원로회원</span>
                                        </div>
                                        <DataTable value={oldDepartmentData} rowClassName={rowClassName}>
                                            <Column className={styles.t_a_c} field="total" header="합계" />
                                        </DataTable>
                                    </div>
                                </div>
                                <div className={styles.status_table_wrap_2}>
                                    <div className={styles.p_l_5}>
                                        <div className={styles.cont_tit_box}>
                                            <span>외국회원</span>
                                        </div>
                                        <DataTable value={forignerDepartmentData} rowClassName={rowClassName}>
                                            <Column className={styles.t_a_c} field="total" header="합계" />
                                        </DataTable>
                                    </div>
                                </div>
                            </div>
                            <div className={styles.total_box}>
                                <span>전체합계 <b>{totalValue.toLocaleString()}</b></span>
                            </div>
                        </>
                    )}
                    
                </div>
                
            </div>
        </>
    );
}

export default MembersIntro;