import React, { useState, useEffect } from 'react';
import { Table, Input, Select, Pagination } from 'antd';
import { useNavigate } from 'react-router-dom';
import './TableList.scss';

const { Option } = Select;

function TableList({ data = [], columns = [], pageSize = 10, categoryOptions = [] , type="Editor", pageNav="true", dtSearch="true" }) {
    const navigate = useNavigate();
    // 검색 카테고리 상태
    const [searchCategory, setSearchCategory] = useState('전체');
    // 검색 텍스트 상태
    const [searchText, setSearchText] = useState('');
    // 필터된 데이터 상태
    const [filteredData, setFilteredData] = useState(data);
    // 현재 페이지 상태
    const [currentPage, setCurrentPage] = useState(1);

    useEffect(() => {
        setFilteredData(data);
    }, [data]);

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth <= 768) {
                document.querySelectorAll('.ant-table-cell').forEach(cell => {
                    const columnIndex = cell.cellIndex;
                    const columnName = columns[columnIndex]?.title;
                    if (columnName) {
                        cell.setAttribute('data-label', columnName);
                    }
                });
            } else {
                document.querySelectorAll('.ant-table-cell').forEach(cell => {
                    cell.removeAttribute('data-label');
                });
            }
        };

        window.addEventListener('resize', handleResize);
        handleResize(); // 초기 로드 시 실행

        return () => window.removeEventListener('resize', handleResize);
    }, [columns]);



    // 검색 카테고리 변경 핸들러
    const handleSearchCategoryChange = (value) => {
        setSearchCategory(value);
        handleSearch(searchText, value);
    };

    // 검색 텍스트 변경 핸들러
    const handleSearchTextChange = (e) => {
        const value = e.target.value;
        setSearchText(value);
        handleSearch(value, searchCategory);
    };

    // 검색 기능 핸들러
    const handleSearch = (text, category) => {
        if (text === '') {
            setFilteredData(data);
            return;
        }
    
        const filtered = data.filter(item => {
            if (category === '전체') {
                // 아이템 객체의 모든 필드를 확인합니다.
                return Object.values(item).some(value => 
                    typeof value === 'string' && value.includes(text)
                );
            } else {
                // 특정 카테고리만 확인합니다.
                const value = item[category];
                return value && typeof value === 'string' && value.includes(text);
            }
        });
    
        setFilteredData(filtered);
    };

    // 페이지 변경 핸들러
    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    // columns를 Link로 업데이트
    const handleRowClick = (record) => {
        if (type === "Editor") {
            window.location.href = `/noticedetail?id=${record.idx}`;
        } else if(type === "User") {
            window.location.href = `/userdetail?id=${record.idx}`;
        } else if(type === "Event") {
            window.location.href = `/eventdetail?id=${record.idx}`;
        } else if(type === "Project") {
            window.location.href = `/projectdetail?id=${record.idx}`;
        } else if(type === "Caets") {
            window.location.href = `/engdetail?id=${record.idx}`;
        } else if(type === "organization") {
            // 
        } else if(type === "contact") {
            // 
        }
    };
    
    return (
        <div className='p_tableList_box'>
            {/* 테이블 컴포넌트 */}
            <Table
                columns={columns}
                dataSource={filteredData.slice((currentPage - 1) * pageSize, currentPage * pageSize)}
                pagination={false}
                rowKey="idx"
                locale={{ emptyText: type === 'Caets' || type === 'contact' ? 'No data available' : '내역이 없습니다' }}
                onRow={(record) => ({
                    onClick: () => handleRowClick(record),
                })}
            />
            {/* 페이지 네비게이션 20240925 네비게이션 상태 추가 */}
            {pageNav !== 'false' && (
                <Pagination
                    current={currentPage}
                    total={filteredData.length}
                    pageSize={pageSize}
                    onChange={handlePageChange}
                />
            )}
            {/* 검색창 */}
            {/* 검색창 20240926 상태 추가 */}
            {dtSearch !== 'false' && (
                <div className="search_container_wrap">
                    <div className="search_container">
                        <Select className='p_select p_table_select' value={searchCategory} onChange={handleSearchCategoryChange}>
                            {categoryOptions.map(option => (
                                <Option key={option.value} value={option.value} className="p_table_option">{option.label}</Option>
                            ))}
                        </Select>
                        <Input
                            className='p_input p_table_search_input'
                            placeholder={type === "Caets" || type === "contact" ? "Please enter your search term" : "검색어를 입력해주세요."}
                            value={searchText}
                            onChange={handleSearchTextChange}
                        />
                        <button className='p_search_icon'></button>
                    </div>
                </div>
            )}
        </div>
    );
}

export default TableList;
