import React, { useEffect, useState } from 'react';
import Highcharts from 'highcharts';
import Highcharts3d from 'highcharts/highcharts-3d';
import HighchartsReact from 'highcharts-react-official';
import styles from './Status.module.scss'; // 스타일 파일을 임포트합니다.
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import common from '../../../common';

import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

Highcharts3d(Highcharts);

const regularDepartmentData = [
    { department: '전기전자공학분과', academic: 19, industry: 27, total: 46 },
    { department: '기계공학분과', academic: 30, industry: 19, total: 49 },
    { department: '건설환경공학분과', academic: 23, industry: 14, total: 37 },
    { department: '화학생물공학분과', academic: 23, industry: 18, total: 41 },
    { department: '재료자원공학분과', academic: 20, industry: 22, total: 42 },
    { department: '기술경영정책분과', academic: 15, industry: 19, total: 34 },
    { department: '컴퓨팅분과', academic: 13, industry: 9, total: 22 },
    { department: '바이오메디컬분과', academic: 11, industry: 7, total: 18 },
    { department: '합계', academic: 154, industry: 135, total: 289 }
];

const normalDepartmentData = [
    { department: '전기전자공학분과', academic: 26, industry: 25, total: 51 },
    { department: '기계공학분과', academic: 31, industry: 31, total: 62 },
    { department: '건설환경공학분과', academic: 36, industry: 24, total: 60 },
    { department: '화학생물공학분과', academic: 24, industry: 21, total: 45 },
    { department: '재료자원공학분과', academic: 35, industry: 26, total: 61 },
    { department: '기술경영정책분과', academic: 16, industry: 25, total: 41 },
    { department: '컴퓨팅분과', academic: 13, industry: 12, total: 25 },
    { department: '바이오메디컬분과', academic: 16, industry: 7, total: 23 },
    { department: '합계', academic: 197, industry: 171, total: 368 }
];

const oldDepartmentData = [
    { department: '합계', total: 651 }
];

const forignerDepartmentData = [
    { department: '합계', total: 82 }
];

const DonutChart = () => {


    const { useAlert,useConfirm, useLoading } = common();
    const { pAlert, AlertComponent } = useAlert();
    const { pConfirm, ConfirmComponent} = useConfirm();
    const { pLoadingOn, pLoadingOff, LoadingComponent } = useLoading();

    const [selectedData, setSelectedData] = useState([]);
    const [data , setData] = useState([]);


    const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 768);
    const [isMobileView2, setIsMobileView2] = useState(window.innerWidth <= 500);

    const handleResize = () => {
        setIsMobileView(window.innerWidth <= 768);
        setIsMobileView2(window.innerWidth <= 500);
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const createChartOptions = (titleText, totleColor, memberCount, data) => {
        return {
            chart: {
                type: 'pie',
                height: isMobileView ? 100 + '%' : 700 + 'px',
                margin: [0, 0, 0, 0],
                options3d: {
                    enabled: true,
                    alpha: 50,
                    beta: 0
                },
            },
            title: {
                text: `
                <div class=chart_middle_box>
                    <span class=chart_middle_title>${titleText}</span>
                    <span class=chart_middle_cont style=color:${totleColor};>${memberCount}<span class=chart_middle_end>명</span></span>
                </div>
                 `,
                align: 'center',
                verticalAlign: 'middle',
                y: 35,
                l: 50,
                useHTML: true // HTML 사용을 명시
            },
            plotOptions: {
                pie: {
                    innerSize: isMobileView2 ? 150 : 200,
                    depth: 65,
                    dataLabels: {
                        enabled: true,
                        format: '{point.name} {point.y}명',
                        distance: 1, // 글씨를 안쪽으로 배치
                        style: {
                            fontSize: isMobileView ? '10px' : '14px',
                            color: '#000000'
                        },
                        alignTo: 'plotEdges', // 레이블을 차트 모서리에 맞춤
                        connectorPadding: 0
                    },
                }
            },
            series: [{
                data: data
            }]
        };
    };


    const fetchData = async () => {
        try {
            pLoadingOn();
            const response = await axios.post(`${API_BASE_URL}/naek_member_status/out/get_data`, {}, {
                withCredentials: true
            });

            if (response.data.result === 't') {
                // const data = response.data.data;
                // setData(data);
            } else {
                pAlert(response.data.msg);;
            }
        } catch (error) {
            console.error('Fetch failed:', error);
            pAlert('실패함.');
        } finally {
            pLoadingOff();
        }
    };

    // useEffect(() => {
    //     fetchData();
    // }, []);




    // 데이터 정의
    const regularMemberData = [
        { name: '컴퓨팅', y: 15, color: '#e8c162' },
        { name: '바이오메디컬', y: 13, color: '#c2d47e' },
        { name: '전기전자공학', y: 50, color: '#874221' },
        { name: '기계공학', y: 52, color: '#e2e2e0' },
        { name: '건설환경공학', y: 39, color: '#eddca6' },
        { name: '화학생물공학', y: 42, color: '#e2e2e0' },
        { name: '재료자원공학', y: 43, color: '#dce1bd' },
        { name: '기술경영정책', y: 33, color: '#c2d47e' }
    ];

    const normalMemberData = [
        { name: '컴퓨팅', y: 26, color: '#eedad3' },
        { name: '바이오메디컬', y: 22, color: '#f8c8c6' },
        { name: '전기전자공학', y: 53, color: '#95483e' },
        { name: '기계공학', y: 64, color: '#f7c2b0' },
        { name: '건설환경공학', y: 63, color: '#f2988f' },
        { name: '화학생물공학', y: 47, color: '#b57c75' },
        { name: '재료자원공학', y: 61, color: '#c62f1e' },
        { name: '기술경영정책', y: 42, color: '#6a1b17' }
    ];

    // 옵션 생성
    const regularMemberOptions = createChartOptions('정회원','#e79911', 287, regularMemberData);
    const normalMemberOptions = createChartOptions('일반회원','#c04617', 378, normalMemberData);

    

    const rowClassName = (rowData) => {
        return rowData.department === '합계' ? 'table_last_row' : '';
    };

    return (
        <div className='components_wrap'>
            <div className={styles.tab_wrap}>
                <button>회원 현황</button>
            </div>
            <div className={styles.chart_wrap}>
                <div className={styles.cont_tit_box}>
                    <span>정회원</span>
                </div>
                <div className={styles.chart_box}>
                    <HighchartsReact
                        highcharts={Highcharts}
                        options={regularMemberOptions}
                    />
                </div>
            </div>
            <div className={styles.table_wrap}>
                <DataTable value={regularDepartmentData} rowClassName={rowClassName}>
                    <Column field="department" header="구 분" />
                    <Column field="academic" header="학계" />
                    <Column field="industry" header="산업계" />
                    <Column field="total" header="합계" />
                </DataTable>
            </div>
            <div className={`${styles.chart_wrap} ${styles.p_t_100}`}>
                <div className={styles.cont_tit_box}>
                    <span>일반회원</span>
                </div>
                <div className={styles.chart_box}>
                    <HighchartsReact
                        highcharts={Highcharts}
                        options={normalMemberOptions}
                    />
                </div>
            </div>
            <div className={styles.table_wrap}>
                <DataTable value={normalDepartmentData} rowClassName={rowClassName}>
                    <Column field="department" header="구 분" />
                    <Column field="academic" header="학계" />
                    <Column field="industry" header="산업계" />
                    <Column field="total" header="합계" />
                </DataTable>
            </div>
            <div className="flex_between">
                <div className={styles.status_table_wrap_2}>
                    <div className={styles.p_r_5}>
                        <div className={styles.cont_tit_box}>
                            <span>원로회원</span>
                        </div>
                        <DataTable value={oldDepartmentData} rowClassName={rowClassName}>
                            <Column className={styles.t_a_c} field="total" header="합계" />
                        </DataTable>
                    </div>
                </div>
                <div className={styles.status_table_wrap_2}>
                    <div className={styles.p_l_5}>
                        <div className={styles.cont_tit_box}>
                            <span>외국회원</span>
                        </div>
                        <DataTable value={forignerDepartmentData} rowClassName={rowClassName}>
                            <Column className={styles.t_a_c} field="total" header="합계" />
                        </DataTable>
                    </div>
                </div>
            </div>
            <div className={styles.total_box}>
                <span>전체합계 <b>1,390</b></span>
            </div>
        </div>
    );
};

export default DonutChart;
