
import React, { useState, useEffect } from 'react';
import styles from './Notice.module.scss';
import { useNavigate } from 'react-router-dom';
import {TableList} from "../../../components";
import common from '../../../common';

import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

// 입찰공고

function Bidding() {

    const { useAlert,useConfirm, useLoading } = common();
    const { pAlert, AlertComponent } = useAlert();
    const { pConfirm, ConfirmComponent} = useConfirm();
    const { pLoadingOn, pLoadingOff, LoadingComponent } = useLoading();

    const [selectedData, setSelectedData] = useState([]);
    const [data , setData] = useState([]);

    const categoryData = ['266'];

    const fetchData = async () => {
        try {
            pLoadingOn();
            const response = await axios.post(`${API_BASE_URL}/naek_document/out/get_table`, {categoryData:categoryData}, {
                withCredentials: true
            });

            if (response.data.result === 't') {
                const data = response.data.data;
                setData(data);
            } else {
                pAlert(response.data.msg);;
            }
        } catch (error) {
            console.error('Fetch failed:', error);
            pAlert('실패함.');
        } finally {
            pLoadingOff();
        }
    };

    useEffect(() => {
        fetchData();
    }, []);



    const navigate = useNavigate();
    const handleTabClick = (path) => {
        navigate(path);
    };

    const columns = [
        { title: '제목', dataIndex: 'title', className: 'p_td_title p_td_w_80' },
        { title: '날짜', dataIndex: 'createTime', className: 'p_td_date' },
    ];

    const pageSize = 10;

    const categoryOptions = [
        { label: '전체', value: '전체' },
        { label: '제목', value: 'title' },
        { label: '내용', value: 'contents' },
    ];

    return (
        <>
            <div className="components_wrap">
                <div className={styles.top_content_wrap}>
                    <div className={styles.tab_box}>
                        <a
                            href="/#"
                            className={`${styles.tab_btn}`}
                            onClick={(e) => { e.preventDefault(); handleTabClick('/notice'); }}
                        >
                            공지사항
                        </a>
                        <a
                            href="/#"
                            className={`${styles.tab_btn}`}
                            onClick={(e) => {
                                e.preventDefault();
                                handleTabClick('/recruitment');
                            }}
                        >
                            채용공고
                        </a>
                        <a
                            href="/#"
                            className={`${styles.tab_btn} ${styles.tab_btn_active}`}
                            onClick={(e) => {
                                e.preventDefault();
                                handleTabClick('/bidding');
                            }}
                        >
                            입찰공고
                        </a>
                    </div>
                    <TableList data={data} columns={columns} pageSize={pageSize} categoryOptions={categoryOptions} type="Editor" />
                </div>
            </div>
        </>
    );
}

export default Bidding;