import { useState } from 'react';
import styles from './Dept.module.scss';
const data = [
    {
        label: '총회',
        className: 'dept_main_line',
        link : '#'
    },
    {
        label: '이사회',
        className: 'dept_node_bg_blue dept_main_line',
        link : '/committee?category=이사회',
        children: [
            {
                label: '감사',
                className: 'dept_node_bd_blue dept_left_bottom_line',
                link : '/committee?category=감사',
                position: 'right_bottom'
            },
        ],
    },
    {
        label: '회장',
        className: 'dept_node_bg_blue_2 dept_main_line',
        link : '/committee?category=회장',
        children: [
            {
                label: '회장실',
                className: 'dept_node_bd_blue dept_left_middle_line',
                link : '/chairmanContact',
                position: 'right_middle'
            },
            {
                label: '집행위원회',
                className: 'dept_node_bd_blue_2 dept_left_bottom_line',
                link : '/committee?category=집행위원회',
                position: 'right_bottom'
            },
            {
                label: '최고경영인평의회',
                className: 'dept_node_bd_blue_2 dept_right_bottom_line',
                link : '/committee?category=최고경영인평의회',
                position: 'left_bottom'
            },
        ],
    },
    {
        label: '부회장',
        className: 'dept_node_bg_blue_3 dept_main_line',
        link : '/committee?category=부회장',
        children: [
            {
                label: '사무처',
                className: 'dept_node_bd_blue_3 dept_left_middle_line',
                link : '/contact',
                position: 'right_middle'
            },
            {
                label: '정책위원회',
                className: 'dept_node_bd_blue_3 dept_right_middle_line',
                link : '/committee?category=정책위원회',
                position: 'left_middle'
            },
            {
                label: '정책연구센터',
                className: 'dept_node_bd_blue_3 dept_top_middle_line',
                link : '#',
                position: 'left_bottom'
            },
        ],
    }
];



function Dept() {
    const [activeTab, setActiveTab] = useState('chart');

    const cm1data = [
        {
            img: "/img/집행위원회/김기남.jpg",
            title: "김기남",
            description1: "회장",
            description2: "삼성전자 상임고문"
        }
    ];

    const cm2data = [
        {
            img: "/img/집행위원회/송정희.jpg",
            title: "송정희",
            description1: "상임부회장",
            description2: "(주)일마그나 기술자문"
        },
        {
            img: "/img/집행위원회/구현모.jpg",
            title: "구현모",
            description1: "부회장",
            description2: "KT 前 대표이사 사장"
        },
        {
            img: "/img/집행위원회/윤의준.jpg",
            title: "윤의준",
            description1: "부회장",
            description2: "서울대 특임교수"
        },
        {
            img: "/img/집행위원회/이상엽.jpg",
            title: "이상엽",
            description1: "부회장",
            description2: "KAIST 연구부총장/특훈교수"
        },
        {
            img: "/img/집행위원회/황성우.jpg",
            title: "황성우",
            description1: "부회장",
            description2: "삼성SDS(주) 대표이사 사장"
        }
    ];
    const cm3data = [
        {
            img: "/img/집행위원회/전영현.jpg",
            title: "전영현",
            description1: "최고경영인평의회 의장",
            description2: "삼성전자 부회장"
        }
    ];
    const cm4data = [
        {
            img: "/img/집행위원회/임혜숙.jpg",
            title: "임혜숙",
            description1: "전기전자공학분과위원장",
            description2: "과학기술정보통신부/이화여자대학교 前 장관/교수"
        },
        {
            img: "/img/집행위원회/구자겸.jpg",
            title: "구자겸",
            description1: "기계공학분과위원장",
            description2: "엔브이에이치코리아(주) 대표이사 회장"
        },
        {
            img: "/img/집행위원회/홍성걸.jpg",
            title: "홍성걸",
            description1: "건설환경공학분과위원장",
            description2: "서울대학교 교수"
        },
        {
            img: "/img/집행위원회/최정우.jpg",
            title: "최정우",
            description1: "화학생물공학분과위원장",
            description2: "서강대학교 석학교수/정보통신대학원장"
        },
        {
            img: "/img/집행위원회/홍순국.jpg",
            title: "홍순국",
            description1: "재료자원공학분과위원장",
            description2: "나노융합산업연구조합 고문/이사장"
        },
        {
            img: "/img/집행위원회/안현실.png",
            title: "안현실",
            description1: "기술경영정책분과위원장",
            description2: "서울대학교 객원교수"
        },
        {
            img: "/img/집행위원회/백은옥.jpg",
            title: "백은옥",
            description1: "컴퓨팅분과위원장",
            description2: "한양대학교 교수"
        },
        {
            img: "/img/집행위원회/윤채옥.jpg",
            title: "윤채옥",
            description1: "바이오메디컬분과위원장",
            description2: "한양대학교/진메디신(주) 교수/대표이사"
        },
    ];
    const cm5data = [
        {
            img: "/img/집행위원회/유지범.png",
            title: "유지범",
            description1: "포상위원장",
            description2: "성균관대학교 총장"
        },
        {
            img: "/img/집행위원회/현택환.jpg",
            title: "현택환",
            description1: "회원위원장",
            description2: "서울대학교 석좌교수"
        },
        {
            img: "/img/집행위원회/주오심.jpg",
            title: "주오심",
            description1: "공학문화확산위원장",
            description2: "KIST 책임연구원"
        },
        {
            img: "/img/집행위원회/조형희.png",
            title: "조형희",
            description1: "인재양성위원장",
            description2: "연세대학교 교수"
        },
        {
            img: "/img/집행위원회/이인규.jpg",
            title: "이인규",
            description1: "국제협력위원장",
            description2: "고려대학교 교수"
        },
        {
            img: "/img/집행위원회/류석현.jpg",
            title: "류석현",
            description1: "기획위원장",
            description2: "한국기계연구원 원장"
        },
    ];
    const cm6data = [
        {
            img: "/img/집행위원회/장웅성.jpg",
            title: "장웅성",
            description1: "포럼운영위원장",
            description2: "산업통상자원 R&D 전략기획단 단장"
        },
        {
            img: "/img/집행위원회/장석권.png",
            title: "장석권",
            description1: "산업미래전략위원장",
            description2: "한양대학교 명예교수"
        },
        {
            img: "/img/집행위원회/김영기.png",
            title: "김영기",
            description1: "자율주행위원장",
            description2: "삼성전자(주) 고문"
        }
    ];

    return (
        <div className="components_wrap">
            <div className={`${styles.dept_btn_box}`}>
                <button
                    className={activeTab === 'chart' ? styles.active : ''}
                    onClick={() => setActiveTab('chart')}
                >
                    조직도
                </button>
                <button
                    className={activeTab === 'committee' ? styles.active : ''}
                    onClick={() => setActiveTab('committee')}
                >
                    집행위원회
                </button>
            </div>
            {activeTab === 'chart' && (
                <div className={styles.chart_wrap}>
                    <div className={styles.organization_chart}>
                        {data.map((dept, index) => {
                            const leftChildrenBottom = dept.children?.filter(child => child.position === 'left_bottom') || [];
                            const leftChildrenMiddle = dept.children?.filter(child => child.position === 'left_middle') || [];
                            const rightChildrenBottom = dept.children?.filter(child => child.position === 'right_bottom') || [];
                            const rightChildrenMiddle = dept.children?.filter(child => child.position === 'right_middle') || [];

                            return (
                                <div key={index} className={`${styles.dept_container}`}>
                                    <a href={dept.link} className={`${styles.node} ${dept.className}`}>{dept.label}</a>
                                    {leftChildrenBottom.length > 0 && (
                                        <div className={styles.left_children_bottom}>
                                            {leftChildrenBottom.map((child, childIndex) => (
                                                <div key={childIndex} className={styles.left_child_bottom}>
                                                    <a href={child.link} className={`${styles.node} ${styles.left_node_bottom} ${child.className}`}>{child.label}</a>
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                    {leftChildrenMiddle.length > 0 && (
                                        <div className={styles.left_children_middle}>
                                            {leftChildrenMiddle.map((child, childIndex) => (
                                                <div key={childIndex} className={styles.left_child_middle}>
                                                    <a href={child.link} className={`${styles.node} ${styles.left_node_middle} ${child.className}`}>{child.label}</a>
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                    {rightChildrenBottom.length > 0 && (
                                        <div className={styles.right_children_bottom}>
                                            {rightChildrenBottom.map((child, childIndex) => (
                                                <div key={childIndex} className={styles.right_child_bottom}>
                                                    <a href={child.link} className={`${styles.node} ${styles.right_node_bottom} ${child.className}`}>{child.label}</a>
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                    {rightChildrenMiddle.length > 0 && (
                                        <div className={styles.right_children_middle}>
                                            {rightChildrenMiddle.map((child, childIndex) => (
                                                <div key={childIndex} className={styles.right_child_middle}>
                                                    <a href={child.link} className={`${styles.node} ${styles.right_node_middle} ${child.className}`}>{child.label}</a>
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                </div>
                            );
                        })}
                    </div>
                    <div className={styles.depth_group}>
                        <div className={styles.depth_group_line}></div>
                        <div className={styles.group_m01}>
                            <a href="/committee?category=상설위원회">
                                <div className={styles.txt}>
                                    <span className={styles.str}>상설위원회</span>
                                </div>
                            </a>
                            <ul className={styles.bul}>
                                <a href="/committee?category=상설위원회&name=회원위원회" className={styles.child_bul}><span>회원위원회</span></a>
                                <a href="/committee?category=상설위원회&name=포상위원회" className={styles.child_bul}><span>포상위원회</span></a>
                                <a href="/committee?category=상설위원회&name=기획위원회" className={styles.child_bul}><span>기획위원회</span></a>
                                <a href="/committee?category=상설위원회&name=국제협력위원회" className={styles.child_bul}><span>국제협력위원회</span></a>
                                <a href="/committee?category=상설위원회&name=공학문화확산위원회" className={styles.child_bul}><span>공학문화확산위원회</span></a>
                                <a href="/committee?category=상설위원회&name=인재양성위원회" className={styles.child_bul}><span>인재양성위원회</span></a>
                            </ul>
                        </div>
                        <div className={styles.group_m02}>
                            <a href="/committee?category=전문분과위원회">
                                <div className={styles.txt}>
                                    <span className={styles.str}>전문분과위원회</span>
                                </div>
                            </a>
                            <ul className={styles.bul}>
                                <a href="/committee?category=전문분과위원회&name=전기전자공학분과" className={styles.child_bul}><span>전기전자공학분과위원회</span></a>
                                <a href="/committee?category=전문분과위원회&name=기계공학분과" className={styles.child_bul}><span>기계공학분과위원회</span></a>
                                <a href="/committee?category=전문분과위원회&name=건설환경공학분과" className={styles.child_bul}><span>건설환경공학분과위원회</span></a>
                                <a href="/committee?category=전문분과위원회&name=화학생물공학분과" className={styles.child_bul}><span>화학생물공학분과위원회</span></a>
                                <a href="/committee?category=전문분과위원회&name=재료자원공학분과" className={styles.child_bul}><span>재료자원공학분과위원회</span></a>
                                <a href="/committee?category=전문분과위원회&name=기술경영정책분과" className={styles.child_bul}><span>기술경영정책분과위원회</span></a>
                                <a href="/committee?category=전문분과위원회&name=컴퓨팅분과" className={styles.child_bul}><span>컴퓨팅분과위원회</span></a>
                                <a href="/committee?category=전문분과위원회&name=바이오메디컬분과" className={styles.child_bul}><span>바이오메디컬분과위원회</span></a>
                            </ul>
                        </div>
                        <div className={styles.group_m03}>
                            <a href="/committee?category=포럼운영위원회">
                                <div className={styles.txt}>
                                    <span className={styles.str}>포럼운영위원회</span>
                                </div>
                            </a>
                        </div>
                        <div className={`${styles.group_m04} ${styles.last}`}>
                            <a href="/committee?category=특별위원회">
                                <div className={styles.txt}>
                                    <span className={styles.str}>특별위원회</span>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            )}
            {activeTab === 'committee' && (
                // 집행위원회 탭
                <div className={styles.cm_wrap}>
                    <div className="cont_wrap">
                        <div className="cont_tit_box">
                            <span>회장</span>
                        </div>
                        <div className="cm_cont_box">
                            <div className="cm_cont_img_wrap">
                                {cm1data.map((item, index) => (
                                    <div className="cont_img_box" key={index}>
                                        <img src={item.img} className='' alt="" style={{ width: '100%', height: 'auto' }} />
                                        <p className='cm_txt_1'>{item.title}</p>
                                        <p className='cm_txt_2'>{item.description1}</p>
                                        <p className='cm_txt_3'>{item.description2}</p>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    <div className="cont_wrap">
                        <div className="cont_tit_box">
                            <span>부회장</span>
                        </div>
                        <div className="cm_cont_box">
                            <div className="cm_cont_img_wrap">
                                {cm2data.map((item, index) => (
                                    <div className="cont_img_box" key={index}>
                                        <img src={item.img} className='' alt="" style={{ width: '100%', height: 'auto' }} />
                                        <p className='cm_txt_1'>{item.title}</p>
                                        <p className='cm_txt_2'>{item.description1}</p>
                                        <p className='cm_txt_3'>{item.description2}</p>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    <div className="cont_wrap">
                        <div className="cont_tit_box">
                            <span>최고경영인평의회 의장</span>
                        </div>
                        <div className="cm_cont_box">
                            <div className="cm_cont_img_wrap">
                                {cm3data.map((item, index) => (
                                    <div className="cont_img_box" key={index}>
                                        <img src={item.img} className='' alt="" style={{ width: '100%', height: 'auto' }} />
                                        <p className='cm_txt_1'>{item.title}</p>
                                        <p className='cm_txt_2'>{item.description1}</p>
                                        <p className='cm_txt_3'>{item.description2}</p>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    <div className="cont_wrap">
                        <div className="cont_tit_box">
                            <span>전문분과위원장</span>
                        </div>
                        <div className="cm_cont_box">
                            <div className="cm_cont_img_wrap">
                                {cm4data.map((item, index) => (
                                    <div className="cont_img_box" key={index}>
                                        <img src={item.img} className='' alt="" style={{ width: '100%', height: 'auto' }} />
                                        <p className='cm_txt_1'>{item.title}</p>
                                        <p className='cm_txt_2'>{item.description1}</p>
                                        <p className='cm_txt_3'>{item.description2}</p>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    <div className="cont_wrap">
                        <div className="cont_tit_box">
                            <span>상설위원장</span>
                        </div>
                        <div className="cm_cont_box">
                            <div className="cm_cont_img_wrap">
                                {cm5data.map((item, index) => (
                                    <div className="cont_img_box" key={index}>
                                        <img src={item.img} className='' alt="" style={{ width: '100%', height: 'auto' }} />
                                        <p className='cm_txt_1'>{item.title}</p>
                                        <p className='cm_txt_2'>{item.description1}</p>
                                        <p className='cm_txt_3'>{item.description2}</p>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    <div className="cont_wrap">
                        <div className="cont_tit_box">
                            <span>포럼운영위원장 및 특별위원장</span>
                        </div>
                        <div className="cm_cont_box">
                            <div className="cm_cont_img_wrap">
                                {cm6data.map((item, index) => (
                                    <div className="cont_img_box" key={index}>
                                        <img src={item.img} className='' alt="" style={{ width: '100%', height: 'auto' }} />
                                        <p className='cm_txt_1'>{item.title}</p>
                                        <p className='cm_txt_2'>{item.description1}</p>
                                        <p className='cm_txt_3'>{item.description2}</p>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default Dept;