import React, { useState } from 'react';
import styles from './Privacy.module.scss';
import common from '../../common';
import axios from 'axios';
import { useLocation } from 'react-router-dom';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

function Privacy() {

    const { useAlert, useLoading } = common();
    const { pAlert, AlertComponent } = useAlert();
    const { pLoadingOn, pLoadingOff, LoadingComponent } = useLoading();

    const [openSections, setOpenSections] = useState({});

    // 특정 섹션을 토글하는 함수
    const toggleSection = (index) => {
        setOpenSections((prevState) => ({
            ...prevState,
            [index]: !prevState[index], // 해당 인덱스의 상태를 토글
        }));
    };

    return (
        <>
            <AlertComponent />
            <LoadingComponent />
            <div className="components_wrap">
                <div className={styles.cont_wrap}>
                    <div className={styles.cont_tit_box}>
                        <span>개인정보취급방침</span>
                    </div>
                    <div className={styles.cont_box}>
                        <ul className={styles.bul_list_none}>
                            <li>&lt;한국공학한림원&gt;('www.naek.or.kr'이하 '한국공학한림원')은 개인정보보호법에 따라 이용자의 개인정보 보호 및 권익을 보호하고 개인정보와 관련한 이용자의 고충을 원활하게 처리할 수 있도록 다음과 같은 처리방침을 두고 있습니다.</li>
                            <li>한국공학한림원은 개인정보처리방침을 개정하는 경우 웹사이트 공지사항(또는 개별공지)을 통하여 공지할 것입니다.</li>
                            <li><b>본 방침은 2015년 12월 10일부터 시행됩니다.</b></li>
                        </ul>
                    </div>
                </div>


                <div className={styles.privacy_wrap}>
                    <div className={styles.privacy_box}>
                        <div
                            className={`${styles.section_title} ${openSections[1] ? styles.active : ''}`}
                            onClick={() => toggleSection(1)}
                            >
                                1. 개인정보의 처리 목적
                        </div>
                        <div className={styles.section_contents_box}>
                            <ul className={`${styles.section_contents} ${openSections[1] ? styles.active : ''}`}>
                                <span>
                                    한국공학한림원은 개인정보를 다음의 목적을 위해 처리합니다. 처리한 개인정보는 다음의 목적 이외의 용도로는 사용되지 않으며 이용 목적이 변경될 시에는 사전동의를 구할 예정입니다.
                                </span>
                                <span>
                                    가. 홈페이지 회원가입 및 관리<br/>
                                    회원 가입의사 확인, 회원제 서비스 제공에 따른 본인 식별·인증, 회원자격 유지·관리, 제한적 본인확인제 시행에 따른 본인확인, 서비스 부정이용 방지, 만14세 미만 아동 개인정보 수집 시 법정대리인 동의 여부 확인, 각종 고지·통지, 고충처리, 분쟁 조정을 위한 기록 보존 등을 목적으로 개인정보를 처리합니다.
                                </span>
                                <span>
                                    나. 민원사무 처리<br/>
                                    민원인의 신원 확인, 민원사항 확인, 사실조사를 위한 연락·통지, 처리결과 통보 등을 목적으로 개인정보를 처리합니다.
                                </span>
                                <span>
                                    다. 재화 또는 서비스 제공<br/>
                                    서비스 제공, 청구서 발송, 콘텐츠 제공, 맞춤 서비스 제공, 본인인증, 연령인증, 요금결제·정산, 채권추심 등을 목적으로 개인정보를 처리합니다.
                                </span>
                                <span>
                                    라. 마케팅 및 광고에의 활용<br/>
                                    신규 서비스(제품) 개발 및 맞춤 서비스 제공, 이벤트 및 광고성 정보 제공 및 참여기회 제공, 인구통계학적 특성에 따른 서비스 제공 및 광고 게재, 서비스의 유효성 확인, 접속빈도 파악 또는 회원의 서비스 이용에 대한 통계 등을 목적으로 개인정보를 처리합니다.
                                </span>
                                <span>
                                    마. 개인영상정보<br/>
                                    범죄의 예방 및 수사, 시설안전 및 화재예방, 교통단속, 교통정보의 수집·분석 및 제공 등을 목적으로 개인정보를 처리합니다.
                                </span>
                            </ul>
                        </div>
                    </div>






                    <div className={styles.privacy_box}>
                        <div
                            className={`${styles.section_title} ${openSections[2] ? styles.active : ''}`}
                            onClick={() => toggleSection(2)}
                            >
                                2. 개인정보 파일 현황
                        </div>
                        <div className={styles.section_contents_box}>
                            <ul className={`${styles.section_contents} ${openSections[2] ? styles.active : ''}`}>
                                <span>
                                    개인정보 파일명 : 한국공학한림원 회원 개인정보
                                </span>
                                <li>
                                    개인정보 항목 : 비밀번호, 생년월일, 로그인ID, 휴대전화번호, 이름, 이메일, 회사명, 직책, 회사전화번호, 부서
                                </li>
                                <li>
                                    수집방법 : 서면양식
                                </li>
                                <li>
                                    보유근거 : 1.우수 공학·산업기술인의 사회적 지위향상을 위한 지원 사업 2.기업·대학·연구소 및 업종별 단체 간의 유기적 연계체제의 구축사업 3.공학 및 기술분야의 국내외 교류사업
                                </li>
                                <li>
                                    보유기간 : 3년
                                </li>
                                <li>
                                    관련법령 : 신용정보의 수집/처리 및 이용 등에 관한 기록 : 3년
                                </li>
                            </ul>
                        </div>
                    </div>





                    <div className={styles.privacy_box}>
                        <div
                            className={`${styles.section_title} ${openSections[3] ? styles.active : ''}`}
                            onClick={() => toggleSection(3)}
                            >
                                3. 개인정보의 처리 및 보유 기간
                        </div>
                        <div className={styles.section_contents_box}>
                            <ul className={`${styles.section_contents} ${openSections[3] ? styles.active : ''}`}>
                                <span>
                                    ① 한국공학한림원은 법령에 따른 개인정보 보유·이용기간 또는 정보주체로부터 개인정보를 수집시에 동의 받은 개인정보 보유,이용기간 내에서 개인정보를 처리,보유합니다.
                                </span>
                                <span>
                                    ② 각각의 개인정보 처리 및 보유 기간은 다음과 같습니다.
                                </span>
                                <span>
                                    &lt;홈페이지 회원가입 및 관리&gt;
                                </span>
                                <li>
                                    &lt;홈페이지 회원가입 및 관리&gt;와 관련한 개인정보는 수집.이용에 관한 동의일로부터&lt;3년&gt;까지 위 이용목적을 위하여 보유.이용됩니다.
                                </li>
                                <li>
                                    보유근거 : 산업기술촉진법 제40조
                                </li>
                                <li>
                                    관련법령 : 신용정보의 수집/처리 및 이용 등에 관한 기록 : 3년
                                </li>
                                <li>
                                    예외사유 : 없음
                                </li>
                            </ul>
                        </div>
                    </div>




                    <div className={styles.privacy_box}>
                        <div
                            className={`${styles.section_title} ${openSections[4] ? styles.active : ''}`}
                            onClick={() => toggleSection(4)}
                            >
                                4. 개인정보의 제3자 제공에 대한 사항
                        </div>
                        <div className={styles.section_contents_box}>
                            <ul className={`${styles.section_contents} ${openSections[4] ? styles.active : ''}`}>
                                <span>
                                    ① 한국공학한림원은 정보주체의 동의, 법률의 특별한 규정 등 개인정보 보호법 제17조 및 제18조에 해당하는 경우에만 개인정보를 제3자에게 제공합니다.
                                </span>
                                <span>
                                    ② 한국공학한림원은 다음과 같이 개인정보를 제3자에게 제공하고 있습니다.
                                </span>
                                <span>
                                    &lt;한국공학한림원&gt;
                                </span>
                                <li>
                                    개인정보를 제공받는 자 : 한국공학한림원
                                </li>
                                <li>
                                    제공받는 자의 개인정보 이용목적 : 비밀번호, 생년월일, 로그인ID, 휴대전화번호, 이름, 이메일, 회사명, 직책, 회사전화번호, 부서
                                </li>
                                <li>
                                    제공받는 자의 보유.이용기간: 3년
                                </li>
                            </ul>
                        </div>
                    </div>




                    <div className={styles.privacy_box}>
                        <div
                            className={`${styles.section_title} ${openSections[5] ? styles.active : ''}`}
                            onClick={() => toggleSection(5)}
                            >
                                5. 개인정보처리 위탁
                        </div>
                        <div className={styles.section_contents_box}>
                            <ul className={`${styles.section_contents} ${openSections[5] ? styles.active : ''}`}>
                                <span>
                                    ① 한국공학한림원은 원활한 개인정보 업무처리를 위하여 다음과 같이 개인정보 처리업무를 위탁하고 있습니다.
                                </span>
                                <span>
                                    위탁사무명 : 한국공학한림원
                                </span>
                                <li>
                                    위탁받는 자 (수탁자) : 유비코어
                                </li>
                                <li>
                                    위탁하는 업무의 내용 : 회원제 서비스 이용에 따른 본인확인, 불만처리 등 민원처리, 고지사항 전달, 신규 서비스(제품) 개발 및 맞춤 서비스 제공, 이벤트 및 광고성 정보 제공 및 참여기회 제공
                                </li>
                                <li>
                                    위탁기간 : 3년
                                </li>
                                <span>
                                    ② 한국공학한림원은 위탁계약 체결시 개인정보 보호법 제25조에 따라 위탁업무 수행목적 외 개인정보 처리금지, 기술적․관리적 보호조치, 재위탁 제한, 수탁자에 대한 관리.감독, 손해배상 등 책임에 관한 사항을 계약서 등 문서에 명시하고, 수탁자가 개인정보를 안전하게 처리하는지를 감독하고 있습니다.
                                </span>
                                <span>
                                    ③ 위탁업무의 내용이나 수탁자가 변경될 경우에는 지체없이 본 개인정보 처리방침을 통하여 공개하도록 하겠습니다.
                                </span>
                            </ul>
                        </div>
                    </div>






                    <div className={styles.privacy_box}>
                        <div
                            className={`${styles.section_title} ${openSections[6] ? styles.active : ''}`}
                            onClick={() => toggleSection(6)}
                            >
                                6. 정보주체의 권리, 의무 및 그 행사방법
                        </div>
                        <div className={styles.section_contents_box}>
                            <ul className={`${styles.section_contents} ${openSections[6] ? styles.active : ''}`}>
                                <span>
                                    이용자는 개인정보주체로서 다음과 같은 권리를 행사할 수 있습니다.
                                </span>
                                <span>
                                    ① 정보주체는 한국공학한림원에 대해 언제든지 다음 각 호의 개인정보 보호 관련 권리를 행사할 수 있습니다.
                                </span>
                                <span>
                                    1. 개인정보 열람요구
                                </span>
                                <span>
                                    2. 오류 등이 있을 경우 정정 요구
                                </span>
                                <span>
                                    3. 삭제요구
                                </span>
                                <span>
                                    4. 처리정지 요구
                                </span>
                                <span>
                                    ② 제1항에 따른 권리 행사는 한국공학한림원에 대해 개인정보 보호법 시행규칙 별지 제8호 서식에 따라 서면, 전자우편, 모사전송(FAX) 등을 통하여 하실 수 있으며 한국공학한림원은 이에 대해 지체 없이 조치하겠습니다.
                                </span>
                                <span>
                                    ③ 정보주체가 개인정보의 오류 등에 대한 정정 또는 삭제를 요구한 경우에는 한국공학한림원은 정정 또는 삭제를 완료할 때까지 당해 개인정보를 이용하거나 제공하지 않습니다.
                                </span>
                                <span>
                                    ④ 제1항에 따른 권리 행사는 정보주체의 법정대리인이나 위임을 받은 자 등 대리인을 통하여 하실 수 있습니다. 이 경우 개인정보 보호법 시행규칙 별지 제11호 서식에 따른 위임장을 제출하셔야 합니다.
                                </span>
                            </ul>
                        </div>
                    </div>




                    <div className={styles.privacy_box}>
                        <div
                            className={`${styles.section_title} ${openSections[7] ? styles.active : ''}`}
                            onClick={() => toggleSection(7)}
                            >
                                7. 처리하는 개인정보의 항목
                        </div>
                        <div className={styles.section_contents_box}>
                            <ul className={`${styles.section_contents} ${openSections[7] ? styles.active : ''}`}>
                                <span>
                                    한국공학한림원은 다음의 개인정보 항목을 처리하고 있습니다.
                                </span>
                                <span>
                                    &lt;홈페이지 회원가입 및 관리&gt;
                                </span>
                                <li>
                                    필수항목 : 비밀번호, 생년월일, 로그인ID, 휴대전화번호, 이름, 이메일, 회사명, 직책, 부서
                                </li>
                                <li>
                                    선택항목 : 비밀번호, 생년월일, 로그인ID, 휴대전화번호, 이름, 이메일, 회사명, 직책, 부서
                                </li>
                            </ul>
                        </div>
                    </div>





                    <div className={styles.privacy_box}>
                        <div
                            className={`${styles.section_title} ${openSections[8] ? styles.active : ''}`}
                            onClick={() => toggleSection(8)}
                            >
                                8. 개인정보의 파기
                        </div>
                        <div className={styles.section_contents_box}>
                            <ul className={`${styles.section_contents} ${openSections[8] ? styles.active : ''}`}>
                                <span>
                                    한국공학한림원은 원칙적으로 개인정보 처리목적이 달성된 경우에는 지체없이 해당 개인정보를 파기합니다. 파기의 절차, 기한 및 방법은 다음과 같습니다.
                                </span>
                                <li>
                                    파기절차
                                </li>
                                <span>
                                    이용자가 입력한 정보는 목적 달성 후 별도의 DB에 옮겨져(종이의 경우 별도의 서류) 내부 방침 및 기타 관련 법령에 따라 일정기간 저장된 후 혹은 즉시 파기됩니다. 이 때, DB로 옮겨진 개인정보는 법률에 의한 경우가 아니고서는 다른 목적으로 이용되지 않습니다.
                                </span>
                                <li>
                                    파기기한
                                </li>
                                <span>
                                    이용자의 개인정보는 개인정보의 보유기간이 경과된 경우에는 보유기간의 종료일로부터 5일 이내에, 개인정보의 처리 목적 달성, 해당 서비스의 폐지, 사업의 종료 등 그 개인정보가 불필요하게 되었을 때에는 개인정보의 처리가 불필요한 것으로 인정되는 날로부터 5일 이내에 그 개인정보를 파기합니다.
                                </span>
                                <li>
                                    파기방법
                                </li>
                                <span>
                                    전자적 파일 형태의 정보는 기록을 재생할 수 없는 기술적 방법을 사용합니다.
                                </span>
                                <span>
                                    종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을 통하여 파기합니다.
                                </span>
                            </ul>
                        </div>
                    </div>




                    <div className={styles.privacy_box}>
                        <div
                            className={`${styles.section_title} ${openSections[9] ? styles.active : ''}`}
                            onClick={() => toggleSection(9)}
                            >
                                9. 개인정보의 안전성 확보 조치
                        </div>
                        <div className={styles.section_contents_box}>
                            <ul className={`${styles.section_contents} ${openSections[9] ? styles.active : ''}`}>
                                <span>
                                    한국공학한림원은 개인정보보호법 제29조에 따라 다음과 같이 안전성 확보에 필요한 기술적/관리적 및 물리적 조치를 하고 있습니다.
                                </span>
                                <span>
                                    ① 개인정보 취급 직원의 최소화 및 교육<br/>
                                    개인정보를 취급하는 직원을 지정하고 담당자에 한정시켜 최소화 하여 개인정보를 관리하는 대책을 시행하고 있습니다.
                                </span>
                                <span>
                                    ② 정기적인 자체 감사 실시<br/>
                                    개인정보 취급 관련 안정성 확보를 위해 정기적(분기 1회)으로 자체 감사를 실시하고 있습니다.
                                </span>
                                <span>
                                    ③ 내부관리계획의 수립 및 시행<br/>
                                    개인정보의 안전한 처리를 위하여 내부관리계획을 수립하고 시행하고 있습니다.
                                </span>
                                <span>
                                    ④ 개인정보의 암호화<br/>
                                    이용자의 개인정보는 비밀번호는 암호화 되어 저장 및 관리되고 있어, 본인만이 알 수 있으며 중요한 데이터는 파일 및 전송 데이터를 암호화 하거나 파일 잠금 기능을 사용하는 등의 별도 보안기능을 사용하고 있습니다.
                                </span>
                                <span>
                                    ⑤ 해킹 등에 대비한 기술적 대책<br/>
                                    한국공학한림원은 해킹이나 컴퓨터 바이러스 등에 의한 개인정보 유출 및 훼손을 막기 위하여 보안프로그램을 설치하고 주기적인 갱신·점검을 하며 외부로부터 접근이 통제된 구역에 시스템을 설치하고 기술적/물리적으로 감시 및 차단하고 있습니다.
                                </span>
                                <span>
                                    ⑥ 개인정보에 대한 접근 제한<br/>
                                    개인정보를 처리하는 데이터베이스시스템에 대한 접근권한의 부여,변경,말소를 통하여 개인정보에 대한 접근통제를 위하여 필요한 조치를 하고 있으며 침입차단시스템을 이용하여 외부로부터의 무단 접근을 통제하고 있습니다.
                                </span>
                                <span>
                                    ⑦ 접속기록의 보관 및 위변조 방지<br/>
                                    개인정보처리시스템에 접속한 기록을 최소 6개월 이상 보관, 관리하고 있으며, 접속 기록이 위변조 및 도난, 분실되지 않도록 보안기능 사용하고 있습니다.
                                </span>
                                <span>
                                    ⑧ 문서보안을 위한 잠금장치 사용<br/>
                                    개인정보가 포함된 서류, 보조저장매체 등을 잠금장치가 있는 안전한 장소에 보관하고 있습니다.
                                </span>
                                <span>
                                    ⑨ 비인가자에 대한 출입 통제<br/>
                                    개인정보를 보관하고 있는 물리적 보관 장소를 별도로 두고 이에 대해 출입통제 절차를 수립, 운영하고 있습니다.
                                </span>
                            </ul>
                        </div>
                    </div>





                    <div className={styles.privacy_box}>
                        <div
                            className={`${styles.section_title} ${openSections[10] ? styles.active : ''}`}
                            onClick={() => toggleSection(10)}
                            >
                                10. 개인정보 보호책임자 작성
                        </div>
                        <div className={styles.section_contents_box}>
                            <ul className={`${styles.section_contents} ${openSections[10] ? styles.active : ''}`}>
                                <span>
                                    ① 한국공학한림원은 개인정보 처리에 관한 업무를 총괄해서 책임지고, 개인정보 처리와 관련한 정보주체의 불만처리 및 피해구제 등을 위하여 아래와 같이 개인정보 보호책임자를 지정하고 있습니다.
                                </span>
                                <span>
                                    &lt;개인정보 보호책임자&gt;
                                </span>
                                <li>
                                    성명 : 남상욱
                                </li>
                                <li>
                                    직책 : 실장
                                </li>
                                <li>
                                    연락처 :02-6009-4004, nsu@naek.or.kr
                                </li>
                                <li>
                                    ※ 개인정보 보호 담당부서로 연결됩니다.
                                </li>
                                <span>
                                    &lt;개인정보 보호 담당부서&gt;
                                </span>
                                <li>
                                    부서명 :한국공학한림원
                                </li>
                                <li>
                                    담당자 :윤관영
                                </li>
                                <li>
                                    연락처 :02-6009-4011, yky@naek.or.kr
                                </li>
                                <span>
                                    ② 정보주체께서는 한국공학한림원의 서비스(또는 사업)을 이용하시면서 발생한 모든 개인정보 보호 관련 문의, 불만처리, 피해구제 등에 관한 사항을 개인정보 보호책임자 및 담당부서로 문의하실 수 있습니다. 한국공학한림원은 정보주체의 문의에 대해 지체 없이 답변 및 처리해드릴 것입니다.
                                </span>
                            </ul>
                        </div>
                    </div>





                    <div className={styles.privacy_box}>
                        <div
                            className={`${styles.section_title} ${openSections[11] ? styles.active : ''}`}
                            onClick={() => toggleSection(11)}
                            >
                                11. 개인정보 처리방침 변경
                        </div>
                        <div className={styles.section_contents_box}>
                            <ul className={`${styles.section_contents} ${openSections[11] ? styles.active : ''}`}>
                                <span>
                                    이 개인정보처리방침은 시행일로부터 적용되며, 법령 및 방침에 따른 변경내용의 추가, 삭제 및 정정이 있는 경우에는 변경사항의 시행 7일 전부터 공지사항을 통하여 고지할 것입니다.
                                </span>
                            </ul>
                        </div>
                    </div>



                </div>
            </div>
        </>
        
    );
}

export default Privacy;
