
import React, { useState, useEffect, useMemo, useRef } from 'react';
import './ProjectParti.module.scss';
import {TableList} from "../../../components";
import common from '../../../common';

import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

function ProjectParti() {

    const { useAlert,useConfirm, useLoading } = common();
    const { pAlert, AlertComponent } = useAlert();
    const { pConfirm, ConfirmComponent} = useConfirm();
    const { pLoadingOn, pLoadingOff, LoadingComponent } = useLoading();

    const [selectedData, setSelectedData] = useState([]);
    const [data , setData] = useState([]);

    const columns = [
        { title: '사업명', dataIndex: 'title', className: 'p_td_title p_td_w_80' },
        { title: '신청 기간', dataIndex: 'date', className: 'p_td_date' },
    ];

    const pageSize = 10;
    const categoryOptions = [
        { label: '전체', value: '전체' },
        { label: '제목', value: 'title' },
        { label: '내용', value: 'contents' },
    ];

    const categoryData = ['11'];

    const fetchData = async () => {
        try {
            pLoadingOn();
            const response = await axios.post(`${API_BASE_URL}/naek_project/out/get_table`, {categoryData:categoryData}, {
                withCredentials: true
            });
            if (response.data.result === 't') {
                const data = response.data.data;
                setData(data);
            } else {
                pAlert(response.data.msg);;
            }
        } catch (error) {
            console.error('Fetch failed:', error);
            pAlert('실패함.');
        } finally {
            pLoadingOff();
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <>
            <div className="components_wrap">
                <TableList data={data} columns={columns} pageSize={pageSize} categoryOptions={categoryOptions} type="Project" />
            </div>
        </>
    );
}

export default ProjectParti;