
import React, { useState, useEffect, useMemo, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { TableList } from "../../../components";
import styles from './Donation.module.scss';
import common from '../../../common';

import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;



function Donation() {


    const { useAlert,useConfirm, useLoading } = common();
    const { pAlert, AlertComponent } = useAlert();
    const { pConfirm, ConfirmComponent} = useConfirm();
    const { pLoadingOn, pLoadingOff, LoadingComponent } = useLoading();

    const [selectedData, setSelectedData] = useState([]);
    const [data , setData] = useState([]);



    const categoryData = ['200'];

    const fetchData = async () => {
        try {
            pLoadingOn();
            const response = await axios.post(`${API_BASE_URL}/naek_document/out/get_table`, {categoryData:categoryData}, {
                withCredentials: true
            });

            if (response.data.result === 't') {
                const data = response.data.data;
                setData(data);
            } else {
                pAlert(response.data.msg);;
            }
        } catch (error) {
            console.error('Fetch failed:', error);
            pAlert('실패함.');
        } finally {
            pLoadingOff();
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const overviewRef = useRef(null);
    const forumHistoryRef = useRef(null);

    const handleButtonClick = (section) => {
        if (section === 'overview' && overviewRef.current) {
            window.scrollTo({
                top: overviewRef.current.offsetTop - 150,
                behavior: 'smooth',
            });
        } else if (section === 'forumHistory' && forumHistoryRef.current) {
            window.scrollTo({
                top: forumHistoryRef.current.offsetTop - 150,
                behavior: 'smooth',
            });
        }
    };

    const columns = useMemo(() => [
        { title: '제목', dataIndex: 'title', className: 'p_td_title p_td_w_80' },
        { title: '날짜', dataIndex: 'createTime', className: 'p_td_date' },
    ], []);
    const pageSize = 10;
    const categoryOptions = useMemo(() => [
        { label: '전체', value: '전체' },
        { label: '제목', value: 'title' },
        { label: '내용', value: 'contents' },
    ], []);

    const [returnTableList, setReturnTableList] = useState(null);

    useEffect(() => {
        setReturnTableList(
            <TableList data={data} columns={columns} pageSize={pageSize} categoryOptions={categoryOptions} type="Editor" />
        );
    }, [columns, data, pageSize, categoryOptions]);

    return (
        <>
            <div className="components_wrap">
                <div className={styles.tab_btn_box} ref={overviewRef}>
                    <button
                        className={`${styles.tab_btn} ${styles.active}`}
                        onClick={() => handleButtonClick('overview')}
                    >
                        안내
                    </button>
                    <button
                        className={`${styles.tab_btn}`}
                        onClick={() => handleButtonClick('forumHistory')}
                    >
                        기부금 현황
                    </button>
                </div>

                <div className={styles.cont_wrap}>
                    <div className={styles.cont_tit_box}>
                        <span>안내</span>
                    </div>
                    <div className={styles.cont_box}>
                        <ul className={styles.bul_list}>
                            <li>한국공학한림원이 공학기술계를 대표하는 단체로서 정치적 중립을 유지하고 건전한 정책제언을 지속하기 위해서는 독립적인 예산의 확보가 필요합니다. 현재는 전체 예산의 70% 이상을 정부의 지원에 의존하고 있습니다.</li>
                            <li>여러분께서 출연해 주시는 기금은 공학한림원의 각종사업 중에서 선택하여 후원할 수 있으며, 지정기부금 영수증을 발급하여 드립니다.</li>
                        </ul>
                    </div>
                </div>

                <div className={styles.cont_wrap}>
                    <div className={styles.cont_tit_box}>
                        <span>정보</span>
                    </div>
                    <div className={styles.cont_box}>
                        <ul className={styles.bul_list_2}>
                            <li><b>문의처 : </b>한국공학한림원 사무처</li>
                            <li><b>전화 : </b>02-6009-4000</li>
                            <li><b>이메일 : </b>naek@naek.or.kr</li>
                            <li><b>입금계좌 : </b>기업은행 449-000014-04-013 (예금주 : 한국공학한림원)</li>
                        </ul>
                    </div>
                </div>

                <div className={`${styles.tab_btn_box} ${styles.m_t_b_100}`} ref={forumHistoryRef}>
                    <button
                        className={`${styles.tab_btn}`}
                        onClick={() => handleButtonClick('overview')}
                    >
                        안내
                    </button>
                    <button
                        className={`${styles.tab_btn} ${styles.active}`}
                        onClick={() => handleButtonClick('forumHistory')}
                    >
                        기부금 현황
                    </button>
                </div>
                {returnTableList}
            </div>
        </>
    );
}

export default Donation;