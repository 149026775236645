//사업안내>국제교류 및 협력
import React, { useState, useEffect, useMemo, useRef } from 'react';
import styles from './Awards.module.scss';

function Awards() {

    

    return (
        <>
            <div className="components_wrap">

                <div className={styles.cont_wrap}>
                    <div className={styles.cont_box}>
                        <ul className={styles.bul_list_none}>
                            <li>The National Academy of Engineering of Korea holds various forums, seminars, and debates throughout the year on contemporary engineering and technological issues, government policy, economy, education, and other societal concerns. The Fourth Industrial Revolution, digitalization, and corporate strategy are among the most pressing challenges for sustainable growth and future prosperity in Korea.</li>
                        </ul>
                    </div>
                </div>


                <div className={styles.cont_wrap}>
                    <div className={styles.cont_tit_box}>
                        <span>The NAEK Award</span>
                    </div>
                    <div className={styles.cont_box}>
                        <ul className={styles.bul_list_none}>
                            <li>The NAEK Award was instituted to honor and support engineers who have made conspicuous contributions to the development of engineering and technology at Korean universities, companies and government supported institutions. The aim is to promote ground-breaking engineering and technology in Korea. The Award pays tribute to those who have achieved original, world-leading research and development records. Their achievements inspire others in academia and industry to strive for excellence, instilling pride in all Korean engineers.
                            Each year, the NAEK Award is conferred on one recipient, and two others are recognized with the NAEK Young Engineer Award. The Award laureates get a certificate from the NAEK President and an honorarium of KRW 200 million for the NAEK Award and KRW 100 million each for the Young Engineer Award. Nominations for award candidates are accepted every July from the press, academia, R&D centers, companies and reputed institutions. A committee of specialists performs the preliminary screening of candidates, while the General Evaluation Committee makes the final selection. The Awards are formally presented at an awards ceremony at the beginning of the year. The NAEK Award was inaugurated in 1996 and is now recognized as one of Korea’s most prestigious honors related to engineering and technology.</li>
                        </ul>
                    </div>
                </div>

                <div className={styles.cont_wrap}>
                    <div className={styles.cont_tit_box}>
                        <span>The Haedong Award</span>
                    </div>
                    <div className={styles.cont_box}>
                        <ul className={styles.bul_list_none}>
                            <li>The Haedong Award was established in 2005 to promote innovation in engineering education and is sponsored by the Haedong Science Foundation. The award recognizes writers, publicists, educators and others who have contributed to the spread of engineering and technology culture and the advancement of engineering education. Nominations are accepted every July, and the Award Nomination Committee convenes to screen the nominees for the award. Any member or emeritus member of NAEK is authorized to nominate an eligible candidate. The General Evaluation Committee then makes the final selection. The Awards are formally presented in a ceremony at the beginning of the year.</li>
                        </ul>
                    </div>
                </div>

                <div className={styles.cont_wrap}>
                    <div className={styles.cont_tit_box}>
                        <span>The Iljin Award</span>
                    </div>
                    <div className={styles.cont_box}>
                        <ul className={styles.bul_list_none}>
                            <li>The Iljin Award was created in 2004 to honor engineers from various disciplines who have made exceptional contributions to industry-academia collaboration and technology policy development. The Award is clearly differentiated from the NAEK Award. Nominations are accepted every May, and the Award Nomination Committee convenes to screen nominees for the Award. Any member or emeritus member of NAEK is authorized to nominate an eligible candidate. The General Evaluation Committee then makes the final selection. The Awards are formally presented in a ceremony at the beginning of the year, and each laureate receives a certificate from the NAEK President and an honorarium of KRW 25 million. This award is sponsored by HUH Jin Gyu, Chairman of Iljin Group and former Chairman of NAEK.</li>
                        </ul>
                    </div>
                </div>

                <div className={styles.cont_wrap}>
                    <div className={styles.cont_tit_box}>
                        <span>The Wonik Award</span>
                    </div>
                    <div className={styles.cont_box}>
                        <ul className={styles.bul_list_none}>
                            <li>This award was instituted to acknowledge engineers in their 20s or 30s who, as entrepreneurs or researchers, have made technological breakthroughs or outstanding achievements in the field of materials, components, and equipment.</li>
                        </ul>
                    </div>
                </div>


            </div>
        </>
    );
}

export default Awards;