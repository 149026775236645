import React, { useState, useEffect, useMemo, useRef } from 'react';
import styles from './Clean.module.scss';
function Clean() {
    return (
        <>
            <div className="components_wrap">
                <div className={styles.cont_wrap}>
                    <div className={styles.cont_tit_box}>
                        <span>부패행위 신고대상 :「부패방지 및 국민권익위원회의 설치와 운영에 관한 법률」제2조 제4호</span>
                    </div>
                    <div className={styles.cont_box}>
                        <ul className={styles.bul_list}>
                            <li>공직자가 직무와 관련하여 그 지위 또는 권한을 남용하거나 법령을 위반하여 자기 또는 제3자의 이익을 도모하는 행위</li>
                            <li>공공기관의 예산사용, 공공기관 재산의 취득 관리 처분 또는 공공기관을 당사자로 하는 계약의 체결 및 그 이행에 있어서법령에 위반하여 공공기관에 대하여 재산상 손해를 가하는 행위</li>
                            <li>위에서 규정한 행위 및 그 은폐를 강요, 권고, 제의, 유인 하는 행위
                            </li>
                        </ul>
                        <div className={styles.cont_box}>
                            <table className={styles.cont_table}>
                                <tbody>
                                    <tr>
                                        <th>국민권익위원회-부패신고상담 - 부패행위신고상담 - 신고안내</th>
                                        <td>
                                            <div className={styles.homepage_btn_box}>
                                                <a href="https://www.acrc.go.kr/" target='_blank' className={styles.homepage_btn}>
                                                    <span>바로가기</span>
                                                </a>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>국민권익위원회 - 청렴교육센터 - 사이버청렴교육(자율적 신청 및 선착순 마감제 운영)</th>
                                        <td>
                                            <div className={styles.homepage_btn_box}>
                                                <a href="https://acti.nhi.go.kr/" target='_blank' className={styles.homepage_btn}>
                                                    <span>바로가기</span>
                                                </a>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>국민권익위원회 - 부패방지 - 보호보상상담</th>
                                        <td>
                                            <div className={styles.homepage_btn_box}>
                                                <a href="https://www.acrc.go.kr/menu.es?mid=a10204010000" target='_blank' className={styles.homepage_btn}>
                                                    <span>바로가기</span>
                                                </a>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Clean;