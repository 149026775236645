import React, { useState } from 'react';
import styles from './FindIdPw.module.scss';
import common from '../../common';
import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

function FindIdPw() {

    const { useAlert, useLoading } = common();
    const { pAlert, AlertComponent } = useAlert();
    const { pLoadingOn, pLoadingOff, LoadingComponent } = useLoading();


    const [activeTab, setActiveTab] = useState('id');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');

    const findId = async () => {

        try {
            pLoadingOn();
            const response = await axios.post(`${API_BASE_URL}/naek_login/out/find_email`, {phone : phoneNumber , name : name}, {
                withCredentials: true
            });
    
            if (response.data.result === 't') {
                const data = response.data.data;
                pAlert('이메일 주소는 ' + data +' 입니다.');
            } else {
                pAlert(response.data.msg);
            }
        } catch (error) {
            pAlert('실패함.');
        } finally {
            pLoadingOff();
        }

    };

    const findPw = async () => {

        try {
            pLoadingOn();
            const response = await axios.post(`${API_BASE_URL}/naek_login/out/find_password`, {email : email , name : name}, {
                withCredentials: true
            });
    
            if (response.data.result === 't') {
                pAlert('입력하신 이메일로 비밀번호를 전송했습니다. 확인 후 비밀번호를 변경 해주세요.');
            } else {
                pAlert(response.data.msg);
            }
        } catch (error) {
            pAlert('실패함.');
        } finally {
            pLoadingOff();
        }

    };

    const handlePhoneNumberChange = (e) => {
        let input = e.target.value.replace(/\D/g, ''); // 숫자만 남기기

        if (input.length > 3 && input.length <= 7) {
            input = `${input.slice(0, 3)}-${input.slice(3)}`;
        } else if (input.length > 7) {
            input = `${input.slice(0, 3)}-${input.slice(3, 7)}-${input.slice(7, 11)}`;
        }

        setPhoneNumber(input);
    };

    const handleTabClick = (tab) => {
        if (activeTab !== tab) {
            setPhoneNumber('');
            setName('');
            setEmail('');
        }
        setActiveTab(tab);
    };

    return (
        <>
        <AlertComponent />
        <LoadingComponent />
            <div className="components_wrap">
                <div className={styles.components_wrap}>
                    <div className={styles.idpw_title}>아이디 / 비밀번호 찾기</div>
                    <div className={styles.idpw_tab_container}>
                        <div
                            className={`${styles.idpw_tab} ${activeTab === 'id' ? styles.active : ''}`}
                            onClick={() => handleTabClick('id')}
                        >
                            아이디 찾기
                        </div>
                        <div
                            className={`${styles.idpw_tab} ${activeTab === 'pw' ? styles.active : ''}`}
                            onClick={() => handleTabClick('pw')}
                        >
                            비밀번호 찾기
                        </div>
                    </div>
                    {activeTab === 'id' ? (
                        <div className={styles.form_container}>
                            <div className={styles.form_box}>
                                <span>핸드폰번호</span>
                                <input
                                    type="text"
                                    placeholder="핸드폰번호"
                                    className={styles.input}
                                    value={phoneNumber}
                                    onChange={handlePhoneNumberChange}
                                    maxLength={13} 
                                />
                            </div>
                            <div className={styles.form_box}>
                                <span>이름</span>
                                <input
                                    type="text"
                                    placeholder="이름"
                                    className={styles.input}
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                />
                            </div>
                            <button onClick={findId} className={styles.button}>아이디 찾기</button>
                        </div>
                    ) : (
                        <div className={styles.form_container}>
                            <div className={styles.form_box}>
                                <span>이메일</span>
                                <input
                                    type="text"
                                    placeholder="이메일"
                                    className={styles.input}
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                            </div>
                            <div className={styles.form_box}>
                                <span>이름</span>
                                <input
                                    type="text"
                                    placeholder="이름"
                                    className={styles.input}
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                />
                            </div>
                            <button onClick={findPw} className={styles.button}>비밀번호 찾기</button>
                        </div>
                    )}
                </div>
            </div>
        </>
        
    );
}

export default FindIdPw;
