//사업안내>시상 대회

import React, { useState, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import styles from './Competition.module.scss';
import common from '../../../common';
import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

function Competition() {
    const { useAlert,useConfirm, useLoading, useModal } = common();
    const { pModal, ModalComponent } = useModal();
    const { pAlert, AlertComponent } = useAlert();
    const { pConfirm, ConfirmComponent} = useConfirm();
    const { pLoadingOn, pLoadingOff, LoadingComponent } = useLoading();

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const pageid = queryParams.get('pageid');

    const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 768);

    const handleResize = () => {
        setIsMobileView(window.innerWidth <= 768);
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);


    const [awardCategoryIDX, setAwardCategoryIDX] = useState(1); // 초기 상태를 1로 설정

    useEffect(() => {
        if (pageid === '1') {
            setAwardCategoryIDX(1);
        } else if (pageid === '2') {
            setAwardCategoryIDX(2);
        } else if (pageid === '3') {
            setAwardCategoryIDX(3);
        } else if (pageid === '4') {
            setAwardCategoryIDX(4); 
        } else if (pageid === '5') {
            setAwardCategoryIDX(5);
        } else {
            setAwardCategoryIDX(1);
        }
    }, [pageid]);


    const [awardsData, setAwardsData] = useState(null);
    const [yearData, setYearData] = useState(null); 

    // const fetchData = async () => {
    //     try {
    //         pLoadingOn();
    //         const response = await axios.post(`${API_BASE_URL}/naek_award/out/get_table`, {awardCategoryIDX:awardCategoryIDX}, {
    //             withCredentials: true
    //         });

    //         console.log(response.data);

    //         if (response.data.result === 't') {
    //             const data = response.data.data;
    //             setAwardsData(data); // 상태 업데이트
    //         } else {
    //             pAlert(response.data.msg);
    //         }
    //     } catch (error) {
    //         console.error('Fetch failed:', error);
    //         pAlert('실패함.');
    //     } finally {
    //         pLoadingOff();
    //     }
    // };

    // const fetchYearData = async () => {
    //     try {
    //         pLoadingOn();
    //         const response = await axios.post(`${API_BASE_URL}/naek_award/out/get_year_data`, {awardCategoryIDX:awardCategoryIDX}, {
    //             withCredentials: true
    //         });

    //         console.log(response.data);

    //         if (response.data.result === 't') {
    //             const data = response.data.data;
    //             setYearData(data); // 상태 업데이트
    //         } else {
    //             pAlert(response.data.msg);
    //         }
    //     } catch (error) {
    //         console.error('Fetch failed:', error);
    //         pAlert('실패함.');
    //     } finally {
    //         pLoadingOff();
    //     }
    // };

    

    const Awards = ({ data , yearData , awardCategoryIDX  }) => {


        const { useAlert,useConfirm, useLoading, useModal } = common();
        const { pLoadingOn, pLoadingOff, LoadingComponent } = useLoading();

        const [activeYear, setActiveYear] = useState(yearData ? yearData[0].year : null); // 최신 연도로 기본 설정
        const [activeIdx, setActiveIdx] = useState(yearData ? yearData[0].idx : null); // 최신 연도로 기본 설정
        const [activeTab, setActiveTab] = useState('wonik'); // 기본 탭은 'leader'로 설정
        const [filteredYearData, setFilteredYearData] = useState([]); 
        const [awards, setAwards] = useState(data); // 초기 데이터를 awards로 설정
        const yearsBoxRef = useRef(null);

        const fetchAwardsDataForYear = async (idx) => {
            try {
                const response = await axios.post(`${API_BASE_URL}/naek_award/out/get_target_data`, { targetIdx : idx });
                if (response.data.result === 't') {
                    setAwards(response.data.data);
                } else {
                    alert(response.data.msg);
                }
                
            } catch (error) {
                console.error('Failed to fetch year data:', error);
            } finally {
                pLoadingOff();
            }
        };

        const handleYearChange = (year , idx) => {
            setActiveYear(year);
            setActiveIdx(idx)
            pLoadingOn();
            fetchAwardsDataForYear(idx);
        };

        const handleTabClick = (tab) => {
            setActiveTab(tab);

            const filteredData = yearData.filter(item => {
                if (tab === 'leader') {
                    return item.awardCategoryName === '차세대리더상';
                } else if (tab === 'wonik') {
                    return item.awardCategoryName === '원익상' || item.awardCategoryName === '원익 차세대 공학도상';
                }
                return false;
            });

            setFilteredYearData(filteredData); // 필터링된 연도 데이터를 상태로 설정

            if (filteredData.length > 0) {
                setActiveYear(filteredData[0].year);
                setActiveIdx(filteredData[0].idx);
                fetchAwardsDataForYear(filteredData[0].idx);
            }

        };
        
        // 가로 스크롤
        useEffect(() => {
            const handleWheel = (e) => {
                if (yearsBoxRef.current && yearsBoxRef.current.contains(e.target)) {
                    if (e.deltaY !== 0) {
                        yearsBoxRef.current.scrollBy({ left: e.deltaY });
                        e.preventDefault();
                    }
                }
            };
    
            window.addEventListener('wheel', handleWheel, { passive: false });
    
            return () => {
                window.removeEventListener('wheel', handleWheel);
            };
        }, []);

        useEffect(() => {
            if (awardCategoryIDX === 4) {
                // 기본 탭과 필터링된 데이터를 설정
                handleTabClick(activeTab); // 'wonik'을 기본값으로 설정했으므로 초기 로드 시 실행
            }
        }, [awardCategoryIDX, yearData]);

        return (
            <>
            <LoadingComponent />
            <div className={styles.award_modal_wrap}>
                {awardCategoryIDX === 4 && (
                    <>
                        <div className={styles.button_box}>
                            <button
                                onClick={() => handleTabClick('wonik')}
                                className={activeTab === 'wonik' ? styles.tab_active : ''}
                            >
                                원익 차세대 공학도상
                            </button>
                            <button
                                onClick={() => handleTabClick('leader')}
                                className={activeTab === 'leader' ? styles.tab_active : ''}
                            >
                                차세대리더상
                            </button>
                        </div>
                    </>
                )}
                <div className={styles.years_box} tabIndex="0" ref={yearsBoxRef}>
                    {awardCategoryIDX === 4 && (
                        <>
                            {/* 탭이 선택된 경우에만 filteredYearData 버튼을 보여줌 */}
                            {activeTab && filteredYearData.map((item, index) => (
                                <button
                                    key={index}
                                    onClick={() => handleYearChange(item.year, item.idx)}
                                    className={`${styles.year_btn} ${item.year === activeYear ? styles.active : ''}`}
                                >
                                    {item.times}회 ({item.year}년)
                                </button>
                            ))}
                        </>
                    )}

                    {/* awardCategoryIDX가 4가 아닌 경우 모든 yearData를 보여줌 */}
                    {awardCategoryIDX !== 4 && yearData.map((item, index) => (
                        <button
                            key={index}
                            onClick={() => handleYearChange(item.year, item.idx)}
                            className={`${styles.year_btn} ${item.year === activeYear ? styles.active : ''}`}
                        >
                            {item.times}회 ({item.year}년)
                        </button>
                    ))}
                    {/* {yearData.map((item, index) => (
                        <button
                            key={index}
                            onClick={() => handleYearChange(item.year,item.idx)}
                            className={`${styles.year_btn} ${item.year === activeYear ? styles.active : ''}`}
                        >
                            {item.times}회 ({item.year}년)
                        </button>
                    ))} */}
                        
                </div>
                {awards && awards.length > 0 ? (
                    <div key={activeYear} id={`year-${activeYear}`} className={styles.yearSection}>
                        <h2>{activeYear}년 수상자 명단</h2>
                        {awards.map((award, awardIndex) => (
                            <div key={awardIndex} className={styles.award}>
                                <div className={styles.image_wrap}>
                                    <img
                                        src={award.thumbnail || '/img/symbol_1.png'}
                                        alt={`${award.winner} 사진`}
                                        className={styles.thumbnail}
                                    />
                                    <div className={styles.thumbnail_info_box}>
                                        <div className={styles.thumbnail_info}>
                                            <strong>수상부문</strong>
                                            <span>{award.awardCategoryName}</span>
                                        </div>
                                        <div className={styles.thumbnail_info}>
                                            <strong>수상자</strong>
                                            <span>{award.winnerName}</span>
                                        </div>
                                        <div className={styles.thumbnail_info}>
                                            <strong>소속 및 직위</strong>
                                            <span>{award.position}</span>
                                        </div>
                                    </div>
                                </div>
                                <div className={styles.details}>
                                    <ul className={styles.bul_list_3}>
                                        <span>주요업적</span>
                                        {/* {award.career.split('|@|').map((career, achIndex) => ( */}
                                        {award.career.split('\n').map((career, achIndex) => (
                                            <li key={achIndex}>{career}</li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        ))}
                    </div>
                ) : (
                    <p>수상자가 없습니다.</p>
                )}
            </div>
            </>
            
        );
    };

    const handleOpenModal = async () => {
        try {
            pLoadingOn();
    
            // 데이터를 병렬로 로드
            const [awardsResponse, yearResponse] = await Promise.all([
                axios.post(`${API_BASE_URL}/naek_award/out/get_table`, { awardCategoryIDX: awardCategoryIDX }, { withCredentials: true }),
                axios.post(`${API_BASE_URL}/naek_award/out/get_year_data`, { awardCategoryIDX: awardCategoryIDX }, { withCredentials: true })
            ]);
    
            // 수상 데이터 처리
            if (awardsResponse.data.result === 't') {
                // setAwardsData(awardsResponse.data.data);
            } else {
                pAlert(awardsResponse.data.msg);
                return;
            }
    
            // 연도 데이터 처리
            if (yearResponse.data.result === 't') {
                // setYearData(yearResponse.data.data);
            } else {
                pAlert(yearResponse.data.msg);
                return;
            }
    
            setTimeout(() => {
                pModal(
                    '',
                    <Awards data={awardsResponse.data.data} yearData={yearResponse.data.data} awardCategoryIDX={awardCategoryIDX} />,
                    isMobileView ? '90%' : '700px',
                    isMobileView ? '85%' : 'auto',
                );
            }, 0);
    
        } catch (error) {
            console.error('Failed to fetch data:', error);
            pAlert('데이터를 가져오는 중 오류가 발생했습니다.');
        } finally {
            pLoadingOff();
        }
    };

    return (
        <>
            <ModalComponent />
            <LoadingComponent />
            <div className="components_wrap">
                {(pageid === '1' || pageid === '2' || pageid === '3' || pageid === '4') ? (
                    <>
                        {/* pageid 1 , 2 , 3 , 4 */}
                        <div className={styles.award_list_box}>
                            <button className={styles.award_btn} onClick={handleOpenModal}>역대 수상자 명단 보기</button>
                        </div>

                        {/* ============================ 포상대상자격 ============================ */}

                        {(pageid === '1' || pageid === '2' || pageid === '3') && (
                            <>
                                <div className={styles.cont_wrap}>
                                    <div className={styles.cont_tit_box}>
                                        <span>포상대상자격</span>
                                    </div>
                                    <div className={styles.cont_box}>
                                        {pageid === '1' &&
                                            <>
                                                <ul className={styles.bul_list}>
                                                    <li>공학과 관련된 경영, 기술, 교육 및 연구의 부문에서 대한민국의 산업 발전에 크게 기여한 공학인 및 기술인</li>
                                                </ul>
                                                <span className={styles.noteYellow}>수상후보의 단일업적이 아닌 평생동안의 업적을 대상으로 함</span>
                                                <span className={styles.noteYellow}>젊은공학인상은 추천연도 말일 기준 만 50세 미만으로 제한</span>
                                            </>
                                        }
                                        {pageid === '2' &&
                                            <>
                                                <ul className={styles.bul_list}>
                                                    <li>산학협력 증진, 기술정책 개발을 통해 국가 기술 발전에 기여한 자</li>
                                                </ul>
                                            </>
                                        }
                                        {pageid === '3' &&
                                            <>
                                                <ul className={styles.bul_list}>
                                                    <li>공학기술 관련 저술, 출판, 언론, 콘텐츠 개발 등의 공학기술 문화 확산과 공학교육 발전을 위한 공학교육 혁신에 크게 기여한 자</li>
                                                </ul>
                                            </>
                                        }
                                    </div>
                                </div>
                            </>
                        )}

                        {/* ============================ 포상인원 ============================ */}

                        {(pageid === '1' || pageid === '2' || pageid === '3') && (
                            <>
                                <div className={styles.cont_wrap}>
                                    <div className={styles.cont_tit_box}>
                                        <span>포상인원</span>
                                    </div>
                                    <div className={styles.cont_box}>
                                        <ul className={styles.bul_list}>
                                            {pageid === '1' &&
                                                <>
                                                    <li>대상 1인, 젊은공학인상 2인</li>
                                                </>
                                            }
                                            {pageid === '2' &&
                                                <>
                                                    <li>산학협력증진 부문/기술정책개발 부문 각 1인 이내</li>
                                                </>
                                            }
                                            {pageid === '3' &&
                                                <>
                                                    <li>공학기술문화확산 부문/공학교육혁신 부문 각 1인 이내</li>
                                                </>
                                            }
                                        </ul>
                                    </div>
                                </div>
                            </>
                        )}

                        {/* ============================ 포상내용 ============================ */}

                        {(pageid === '1' || pageid === '2' || pageid === '3') && (
                            <>
                                <div className={styles.cont_wrap}>
                                    <div className={styles.cont_tit_box}>
                                        <span>포상내용</span>
                                    </div>
                                    <div className={styles.cont_box}>
                                        <ul className={styles.bul_list}>
                                        {pageid === '1' &&
                                            <>
                                                <li>한국공학한림원 회장 상패 및 부상(상금 대상 2억원, 젊은공학인상 1억원/인)</li>
                                            </>
                                        }
                                        {pageid === '2' &&
                                            <>
                                                <li>한국공학한림원 회장 상패(상금 2천5백만원/1인) 수여</li>
                                            </>
                                        }
                                        {pageid === '3' &&
                                            <>
                                                <li>한국공학한림원 회장 상패(상금 2천5백만원/1인) 수여</li>
                                            </>
                                        }
                                        </ul>
                                    </div>
                                </div>
                            </>
                        )}

                        {/* ============================ 대상자격 ============================ */}

                        {(pageid === '4') && (
                            <>
                                {/* <span className={styles.danger_text}>
                                ※ 명칭 변경 : 차세대 공학리더상(&#x60;16~&#x60;21) -&gt; 원익상(&#x60;22~&#x60;23) -&gt; 원익 차세대 공학도상(&#x60;24~)
                                </span> */}
                                <div className={styles.cont_wrap}>
                                    <div className={styles.cont_tit_box}>
                                        <span>대상자격</span>
                                    </div>
                                    <div className={styles.cont_box}>
                                        <ul className={styles.bul_list}>
                                            <li>국내 공학 관련 전공 대학(원) 재학(휴학)생으로서 소재·부품·장비 및 관련 분야에서 창의성, 전문성, 열정, 리더십을 겸비하고 다음의 분야에서 사회발전에 기여한 자</li>
                                        </ul>
                                        <span className={styles.noteYellow}>추천연도 말일 기준 만 32세 미만으로 제한</span>
                                        <span className={styles.noteYellow}>스마트팩토리 옵티마이제이션 시스템, AI 활용 소재 개발 등 관련 SW 분야 포함
                                        </span>
                                    </div>
                                </div>
                            </>
                        )}

                        {/* ============================ 시상부문 ============================ */}

                        {(pageid === '4') && (
                            <>
                                <div className={styles.cont_wrap}>
                                    <div className={styles.cont_tit_box}>
                                        <span>시상부문</span>
                                    </div>
                                    <div className={styles.cont_box}>
                                        <ul className={styles.bul_list_2}>
                                            <li>연구 부문 : 공학기술 분야에서 학술적 업적이 뛰어난 경우</li>
                                            <li>기업가정신 부문 : 기술 창업, 독창적 발명을 통해 사회발전에 기여한 경우</li>
                                        </ul>
                                    </div>
                                </div>
                            </>
                        )}

                        {/* ============================ 시상내역 ============================ */}

                        {(pageid === '4') && (
                            <>
                                <div className={styles.cont_wrap}>
                                    <div className={styles.cont_tit_box}>
                                        <span>시상내역</span>
                                    </div>
                                    <div className={styles.cont_box}>
                                        <ul className={styles.bul_list_2}>
                                            <li>최우수상 (1인) : 한국공학한림원 회장 상장 및 상금 1천만 원</li>
                                            <li>우수상 (6인) : 한국공학한림원 회장 상장 및 상금 각 5백만 원</li>
                                            <span className={styles.noteYellow}>수상 인원은 심사 결과에 따라 변동될 수 있음</span>
                                        </ul>
                                    </div>
                                </div>
                            </>
                        )}

                        {/* ============================ 추천권자 ============================ */}

                        <div className={styles.cont_wrap}>
                            <div className={styles.cont_tit_box}>
                                <span>추천권자</span>
                            </div>
                            <div className={styles.cont_box}>
                                <ul className={styles.bul_list}>
                                {pageid === '1' &&
                                    <>
                                        <li>한국공학한림원 정회원, 원로회원 및 각 관련기관(대학, 연구소, 기업체, 행정기관, 언론계, 학회 등)의 장, 수상후보추천위원회</li>
                                    </>
                                }
                                {pageid === '2' &&
                                    <>
                                        <li>한국공학한림원 정회원, 원로회원, 추천위원회 또는 관련 기업, 연구소, 대학, 학회, 행정기관의 장</li>
                                    </>
                                }
                                {pageid === '3' &&
                                    <>
                                        <li>한국공학한림원 정회원, 원로회원, 추천위원회 또는 관련 기업, 연구소, 대학, 학회, 행정기관의 장</li>
                                    </>
                                }
                                {pageid === '4' &&
                                    <>
                                        <li>한국공학한림원 회원, 각 대학의 총장 및 공학 관련 전공 교수</li>
                                    </>
                                }
                                </ul>
                            </div>
                        </div>

                        {/* ============================ 심사 및 선정 ============================ */}

                        <div className={styles.cont_wrap}>
                            <div className={styles.cont_tit_box}>
                                <span>심사 및 선정</span>
                            </div>
                            <div className={styles.cont_box}>
                                <ul className={styles.bul_list}>
                                {pageid === '1' &&
                                    <>
                                        <li><b>1차 심사</b> : 전문 분과별 예비심의위원회를 구성하여 예비심사</li>
                                        <li><b>2차 심사</b> : 학계, 산업계, 행정기관, 언론계 등 관련 분야 권위자 30인 이내로 구성된 종합심의위원회 최종심사</li>
                                        <li><b>최종 검증</b> : 최종 수상후보자 전공 관련 전문가로 구성된 검증단이 공적 기여도 등 검증</li>
                                    </>
                                }
                                {pageid === '2' &&
                                    <>
                                        <li><b>1차 심사</b> : 부문별 예비심의위원회 예비심사</li>
                                        <li><b>2차 심사</b> : 관련 분야 전문가 20인 이내로 구성된 종합심의위원회 최종심사</li>
                                    </>
                                }
                                {pageid === '3' &&
                                    <>
                                        <li><b>1차 심사</b> : 부문별 예비심의위원회 예비심사</li>
                                        <li><b>2차 심사</b> : 관련 분야 전문가 20인 이내로 구성된 종합심의위원회 최종심사</li>
                                    </>
                                }
                                {pageid === '4' &&
                                    <>
                                        <li><b>1차 서면 심사</b> : 관련 전문가 10인으로 구성된 심사위원회에서 추천서 및 공적서를 바탕으로 예비심사</li>
                                        <li><b>2차 발표 심사</b> : 예비심사 통과자 대상으로 공적 발표심사</li>
                                    </>
                                }
                                </ul>
                            </div>
                        </div>

                        {/* ============================ 심사기준 ============================ */}

                        <div className={styles.cont_wrap}>
                            <div className={styles.cont_tit_box}>
                                <span>심사기준</span>
                            </div>
                            <div className={styles.cont_box}>
                                <ul className={styles.bul_list}>
                                {pageid === '1' &&
                                    <>
                                        <li>경영, 교육, 기술, 연구의 부문에서 대한민국 산업발전에의 기여도를 중점적으로 고려/반영</li>
                                        
                                    </>
                                }
                                {pageid === '2' &&
                                    <>
                                        <li><b>산학협력증진 부문</b> : 산학협력을 통한 연구실적이 아닌 산학협력을 강화하기 위한 제도, 시스템, 인프라 마련과 관련된 공적을 평가 (다만, 심사 대상이 산업계가 아닌 경우 연구실적도 고려) </li>
                                        <li><b>기술정책개발 부문</b> : 정책개발과 실현을 통한 기술발전의 파급효과를 평가</li>
                                    </>
                                }
                                {pageid === '3' &&
                                    <>
                                        <li><b>공학기술문화확산 부문</b> : 공학기술 문화활동(저술, 출판, 언론, 방송, 콘텐츠 개발 등)을 통해 공학을 널리 전파한 공적을 평가</li>
                                        <li><b>공학교육혁신 부문</b> : 공학교육 혁신을 위한 구체적인 노력과 파급효과를 평가
                                        </li>
                                    </>
                                }
                                {pageid === '4' &&
                                    <>
                                        <li><b>연구 부문</b> : 연구성과 및 전문성, 창의성, 비전 등</li>
                                        <li><b>기업가정신 부문</b> : 기업가정신 및 전문성, 창의성 등
                                        </li>
                                    </>
                                }
                                </ul>
                            </div>
                        </div>

                        {/* ============================ 평가항목 ============================ */}

                        {(pageid === '1') && (
                            <>
                                <div className={styles.cont_wrap}>
                                    <div className={styles.cont_tit_box}>
                                        <span>평가항목</span>
                                    </div>
                                    <div className={styles.cont_box}>
                                        <div className={styles.num_list_box}>
                                            <span>1. 산업발전 기여도</span>
                                            <ul className={styles.num_list}>
                                                <li>공학기술 관련 기관 창설 및 창업으로 인한 국가발전 기여도</li>
                                                <li>실용화, 상용화 및 수출 증대 등의 경제적 효과</li>
                                                <li>관련 해당 분야 및 유사 분야 발전에의 학문적, 기술적 파급 효과</li>
                                                <li>전문 인력 양성을 통한 국가발전 기여도</li>
                                            </ul>
                                        </div>
                                        <div className={styles.num_list_box}>
                                            <span>2. 독창성 및 중요성</span>
                                            <ul className={styles.num_list}>
                                                <li>신기술, 신기능, 신공정, 신경영기법 및 교육개선을 통한 경제/사회적 파급효과</li>
                                                <li>세계 최초 또는 국내 최초 여부</li>
                                                <li>기존의 방법(제품)과의 비교 우위 여부</li>
                                                <li>주요 해외학술지 게재 여부, 특허 및 국제 품질 인증 취득 여부</li>
                                            </ul>
                                        </div>
                                        <div className={styles.num_list_box}>
                                            <span>3. 기타</span>
                                            <ul className={styles.num_list}>
                                                <li>해당 분야 단체(학회, 협회 등) 활동 실적</li>
                                                <li>사회 봉사 업적(공공기관 재직, 공학기술 단체에의 재원 기부 등)</li>
                                                <li>국내외 인지도</li>
                                                <li>훈장 및 포상 경력</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </>
                        )}

                        {/* ============================ 재원 ============================ */}

                        {pageid === '1' &&
                            <>
                                <span className={`${styles.noteYellow} ${styles.m_t_20}`}>재원 <br /> 최진민 귀뚜라미그룹 회장(귀뚜라미문화재단) 출연</span>
                            </>
                        }
                        {pageid === '2' &&
                            <>
                                <span className={`${styles.noteYellow} ${styles.m_t_20}`}>재원 <br /> 허진규 일진그룹 회장 (일진과학기술문화재단) 출연</span>
                            </>
                        }
                        {pageid === '3' &&
                            <>
                                <span className={`${styles.noteYellow} ${styles.m_t_20}`}>재원 <br /> 김영재 대덕전자 사장 (해동과학문화재단) 출연</span>
                            </>
                        }
                        {pageid === '4' &&
                            <>
                                <span className={`${styles.noteYellow} ${styles.m_t_20}`}>재원 <br /> 이용한 원익그룹 회장 출연</span>
                            </>
                        }
                    </>
                ) : (
                    <>
                        {/* pageid 5 */}

                        {/* ============================ 참가자격 ============================ */}

                        <div className={styles.cont_wrap}>
                            <div className={styles.cont_tit_box}>
                                <span>참가자격</span>
                            </div>
                            <div className={styles.cont_box}>
                                <ul className={styles.bul_list}>
                                    <li>국내 대학(원)생으로, 개인 또는 3인 이내 팀*으로 참가 및 지도교수 1인(복수팀 지도 가능) 필요</li>
                                </ul>
                                <span className={styles.noteYellow}>다양한 전공 학생들 간의 팀 구성을 권장</span>
                                <span className={styles.noteYellow}>경쟁 부문 복수 신청 가능</span>
                            </div>
                        </div>

                        {/* ============================ 경쟁 부문 ============================ */}

                        <div className={styles.cont_wrap}>
                            <div className={styles.cont_tit_box}>
                                <span>경쟁 부문</span>
                            </div>
                            <div className={styles.cont_box}>
                                <ul className={styles.bul_list_2}>
                                    <li>발명사업화 부문 : 기업‧연구소 등이 보유한 기술에 관한 특허 빅데이터를 분석하여 신제품 아이디어와 디자인‧경영전략 등 발명사업화 기반의 비즈니스 전략 수립</li>
                                    <li>특허전략수립 부문 : 기업‧연구소 등이 제시한 기술주제에 대해 국내외 특허 빅데이터를 분석하여 향후 R & D 전략 제시 및 특허 획득 방향 수립</li>
                                </ul>
                            </div>
                        </div>

                        {/* ============================ 심사 절차 및 기준 ============================ */}

                        <div className={styles.cont_wrap}>
                            <div className={styles.cont_tit_box}>
                                <span>심사 절차 및 기준</span>
                            </div>
                            <div className={styles.cont_box}>
                                <table className={styles.compertition_table}>
                                    <thead>
                                        <tr>
                                            <th>구분</th>
                                            <th>심사단계</th>
                                            <th colSpan="2">심사내용</th>
                                        </tr>
                                    </thead>
                                    <tbody><tr>
                                        <th>1</th>
                                        <th>기초심사</th>
                                        <td colSpan="2">
                                            신청 자격, 제출 서류 및 답안의 유효성 확인
                                        </td>
                                    </tr>
                                        <tr>
                                            <th rowSpan="2">2</th>
                                            <th rowSpan="2">서면심사</th>
                                            <th>발명사업화</th>
                                            <td>
                                                관련 특허기술 이해도, 비즈니스 전략의 적절성 등
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>특허전략수립</th>
                                            <td>
                                                선행특허 조사·분석, 핵심특허분석, 특허전략 수립의 타당성 등
                                            </td>
                                        </tr>
                                        <tr>
                                            <th rowSpan="2">3</th>
                                            <th rowSpan="2">발표심사</th>
                                            <th>발명사업화</th>
                                            <td>
                                                활용 아이디어·비즈니스 전략의 창의성, 실현가능성, 사업성 등
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>특허전략수립</th>
                                            <td>
                                                이해력, 분석력, 표현력, 발표력 등
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>4</th>
                                            <th>부문별 최종심사</th>
                                            <td colSpan="2">
                                                서면·발표심사 내용의 종합적 판단
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>5</th>
                                            <th>부문통합 최종심사</th>
                                            <td colSpan="2">
                                                <span className={styles.m_b_10}>서면·발표심사 내용의 종합적 판단</span>
                                                (국민참여심사 실시 후 결과에 따라 가점 부여)
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        {/* ============================ 시상내역 ============================ */}

                        <div className={styles.cont_wrap}>
                            <div className={styles.cont_tit_box}>
                                <span>시상내역</span>
                            </div>
                            <div className={styles.cont_box}>
                                <ul className={styles.bul_list_2}>
                                    <li>대통령상, 국무총리상, 과학기술정보통신부장관상, 산업통상자원부장관상, 특허청장상, 한국공학한림원회장상, 한국발명진흥회장상, 후원기관장상, 지도교수상, 최다수상대학상, 최다응모대학상 등 상장 및 상금</li>
                                </ul>
                            </div>
                        </div>

                        {/* ============================ 홈페이지 및 문의처 ============================ */}

                        <div className={styles.cont_wrap}>
                            <div className={styles.cont_tit_box}>
                                <span>홈페이지 및 문의처</span>
                            </div>
                            <div className={styles.cont_box}>
                                <ul className={styles.bul_list_2}>
                                    <li>대회 문의: 한국발명진흥회 지식재산인력양성실(02-3459-2813, cpu@kipa.org)</li>
                                </ul>
                            </div>
                        </div>
                        <div className={`${styles.campus_homepage_btn_box} ${styles.m_b_40}`}>
                            <a href="https://www.kipa.org/cpu/main.jsp" target='_blank' className={styles.campus_homepage_btn} rel="noreferrer">
                                <span>캠퍼스 특허 유니버시아드</span>
                                <span>홈페이지 바로가기</span>
                            </a>
                        </div>

                    </>
                )}


            </div>
        </>
    );
}

export default Competition;