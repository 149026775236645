import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import './Detail.scss';
import common from '../../common'; 

const { useModal } = common();

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

function Detail({isLogin}) {

    const { pModal, ModalComponent} = useModal(); // 훅 사용
	const { useAlert, useLoading } = common();
	const { pAlert, AlertComponent } = useAlert();
	const { pLoadingOn, pLoadingOff,LoadingComponent} = useLoading();

    const [detailData, setDetailData] = useState([]);

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const idx = queryParams.get('id');

    const formatDateString = (dateString) => {
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0'); // 월은 0부터 시작하므로 1을 더함
        const day = date.getDate().toString().padStart(2, '0');
        
        let formattedDate = `${year}년 ${month}월 ${day}일`;

        const hours = date.getHours();
        const minutes = date.getMinutes();
        if (hours || minutes) { // 시:분이 있는 경우 추가
            const formattedHours = hours.toString().padStart(2, '0');
            const formattedMinutes = minutes.toString().padStart(2, '0');
            formattedDate += ` ${formattedHours}시 ${formattedMinutes}분`;
        }
        
        return formattedDate;
    };
    const setData = async () => {
        try {
            pLoadingOn();
            const response = await axios.post(`${API_BASE_URL}/naek_project/out/get_detail`, { target_idx:idx, il:isLogin }, { withCredentials: true });
        
            if (response && response.data && response.data.result === 't') {
                setDetailData(response.data.data);
            } else {
                console.error(response.data);
                setDetailData([]);
                pAlert(response.data.msg);
            }
        } catch (error) {
            console.error('실패:', error);
            setDetailData([]);
        } finally {
            setTimeout(() => {
                pLoadingOff();
            }, 100);
        }
    };
    useEffect(() => {
        setData();
    }, []);

	const pRegister = async () => {
        window.location.href = `/projectregist?id=${idx}`;
    };
    return (
        <>
            <AlertComponent />
            <LoadingComponent />
            <ModalComponent />
            <div className="detail_wrap">
                <div className="components_wrap">
                    <div className="p_event_wrap">
                        <div className="event_info_box">
                            <span className="event_title">{detailData.title}</span>
                            <table className='event_table'>
                                <tbody>
                                    <tr>
                                        <th>사업명</th>
                                        <td>{detailData.title}</td>
                                    </tr>
                                    <tr>
                                        <th>참여기간</th>
                                        <td>{detailData.date}</td>
                                    </tr>
                                </tbody>
                            </table>
                            <div className='p_event_detail_content' dangerouslySetInnerHTML={{ __html: detailData.contents }}></div>
                        </div>

                        <div className='event_register_btn_box'>
                        {
                            detailData.acceptStatus === 2 ? (
                                <button className='p_btn p_btn_normal  event_register_btn' onClick={pRegister}>신청</button>
                            ) : (
                                null
                            )
                        }
                        </div>
                    </div>
                </div>
            </div>
            
        </>
    );
}

export default Detail;