import React, { useState } from 'react';
import styles from './Purpose.module.scss';

function Purpose() {
    const historyData = {
        '현재 ~ 2010’S': [
            { date: '2023. 05', events: ['한반도국토포럼이 미래국토포럼으로 명칭 변경'] },
            { date: '2023. 03', events: ['박성욱 이사장 취임 (2023.03~현재)'] },
            { date: '2023. 01', events: ['제7대 김기남 회장 취임 (2023.01~현재)'] },
            { date: '2020. 10', events: ['세계공학한림원평의회(CAETS) 2020 국제컨퍼런스 개최'] },
            { date: '2019. 06', events: ['김기남 이사장 취임'] },
            { date: '2017. 01', events: ['제6대 권오경 회장 취임 (2017.01~2022.12)'] },
            { date: '2017. 06', events: ['권오준 이사장 취임'] },
            { date: '2017. 01', events: ['CEO Forum과 원탁토론회 통합 "NAEK 포럼"으로 변경'] },
            { date: '2015. 01', events: ['제5대 오영호 회장 취임 (2015.01~2016.12)'] },
            { date: '2014. 11', events: ['한반도국토포럼 출범'] },
            { date: '2011. 06', events: ['제4대 정준양 회장 취임 (2011.06~2014.12)'] },
            { date: '2011. 05', events: ['차세대 지식재산리더 출범'] },
            { date: '2011. 01', events: ['제3대 김도연 회장 취임 (2011.01~2011.03)'] },
            { date: '2010. 08', events: ['NAEK VOICE 발간'] },
        ],
        '2000’S': [
            { date: '2008. 09', events: ['NAEK Lecture 사업 시작'] },
            { date: '2008. 09', events: ['에너지포럼 출범'] },
            { date: '2006. 03', events: ['특별위원회 구성을 통한 정책연구'] },
            { date: '2005. 12', events: ['제1회 해동상 시상'] },
            { date: '2005. 12', events: ['10주년 기념 사업'] },
            { date: '2005. 02', events: ['허진규 이사장 취임'] },
            { date: '2005. 01', events: ['제2대 윤종용 회장 취임 (2005~2010)'] },
            { date: '2004. 06', events: ['제1회 일진상 시상'] },
            { date: '2004. 03', events: ['주니어 공학기술교실 시작'] },
            { date: '2004. 03', events: ['차세대 공대리더 출범'] },
            { date: '2003. 11', events: ['코리아리더스 포럼 출범'] },
            { date: '2003. 06', events: ['한국공학한림원 원탁토론회 출범'] },
            { date: '2002. 11', events: ['한국기술센터 사무실 이전'] },
            { date: '2002. 03', events: ['한국공학기술상이 한국공학한림원 대상으로 명칭 변경'] },
            { date: '2002. 03', events: ['『공학과의 새로운 만남 시리즈』 발간'] },
            { date: '2001. 09', events: ['CEO조찬집담회 출범'] },
            { date: '2000. 10', events: ['세계공학한림원평의회(CAETS) 회원국 가입'] },
        ],
        '1990’S': [
            { date: '1999. 01', events: ['한국공학한림원으로 명칭 변경'] },
            { date: '1998. 09', events: ['윤종용 이사장 취임'] },
            { date: '1998. 09', events: ['The CEO Forum 출범'] },
            { date: '1997. 11', events: ['제1회 한·중·일 라운드테이블미팅(EA-RTM) 출범'] },
            { date: '1997. 03', events: ['제1회 한국공학기술상 시상'] },
            { date: '1996. 06', events: ['창립총회 (이기준 초대회장 취임)'] },
            { date: '1996. 04', events: ['이종훈 이사장 취임'] },
            { date: '1996. 04', events: ['제1차 이사회-창립회원 확정, 사업계획 및 수지예산(안) 의결'] },
            { date: '1995. 10', events: ['산업자원부에서 법인으로 설립 인가'] },
            { date: '1994. 12', events: ['산업기술기반조성에 관한 법률(안) 국회 의결'] },
            { date: '1994. 10', events: ['제1차 이사회-창립회원 확정, 사업계획 및 수지예산(안) 의결'] },
            { date: '1994. 07', events: ['한국공학원 설립을 포함한 산업기술기반조성에 관한 법률(안) 입법 예고'] },
            { date: '1993. 12', events: ['전국공과대학장협의회 및 공학관련 학회에서 한국공학원의 설립 건의'] },
        ],
    };

    const [activePeriod, setActivePeriod] = useState(Object.keys(historyData)[0]);


    const renderHistory = (data) => {
        const groupedData = [];
        let currentGroup = null;

        data.forEach((item) => {
            const year = item.date.split('.')[0].trim();
            if (currentGroup && currentGroup.year === year) {
                currentGroup.items.push(item);
            } else {
                currentGroup = {
                    year: year,
                    items: [item]
                };
                groupedData.push(currentGroup);
            }
        });

        return groupedData.map((group, index) => (
            <div className={styles.pp_box} key={index}>
                <div className={styles.pp_box_le_box}>
                    {group.items.map((item, idx) => (
                        <span key={idx}>{idx === 0 ? item.date : item.date.split('.')[1]}</span>
                    ))}
                </div>
                <div className={styles.pp_box_ri_box}>
                    {group.items.map((item) => (
                        item.events.map((event, eventIdx) => (
                            <span key={eventIdx}>{event}</span>
                        ))
                    ))}
                </div>
            </div>
        ));
    };

    return (
        <>
            <div className="components_wrap">
                <div className="cont_wrap">
                    <div className="cont_tit_box">
                        <span>설립목적</span>
                    </div>
                    <div className="cont_box">
                        <p>학계, 산업계 및 국가의 기관 등에서 공학 및 기술 발전에 현저한 공적을 세운 우수한 공학/기술인을 발굴/우대하고, 공학/기술과 관련된 학술 연구와 지원사업 및 국제교류 협력 사업을 병행함으로써 국가의 창조적인 공학/기술 개발과 지속적인 발전에 이바지하게 함을 목적으로 함.</p>
                    </div>
                </div>
                <div className="cont_wrap">
                    <div className="cont_tit_box">
                        <span>설립근거</span>
                    </div>
                    <div className={`${styles.num_type} cont_box`}>
                        <p>산업기술혁신촉진법</p>
                        <p>제7장 산업기술혁신 유관기관</p>
                        <p>제40조(한국공학한림원의 설립 등)</p>
                        <ol>
                            <li>① 공학 및 산업기술의 발전을 효율적으로 추진하고 우수한 공학·산업기술인을 발굴·활용하기 위하여 한국공학한림원을 설립한다.</li>
                            <li>② 제1항에 따른 한국공학한림원(이하 "한국공학한림원"이라 한다)은 다음 각 호의 사업을 수행한다. &lt;개정 2013.3.23&gt;
                                <ul>
                                    <li>1. 우수 공학·산업기술인의 사회적 지위향상을 위한 지원 사업</li>
                                    <li>2. 기업·대학·연구소 및 업종별 단체 간의 유기적 연계체제의 구축사업</li>
                                    <li>3. 공학 및 기술 분야의 국내외 교류사업</li>
                                    <li>4. 산업통상자원부장관이 산업기술혁신을 위하여 필요하다고 인정하여 위탁한 사업</li>
                                </ul>
                            </li>
                            <li>③ 한국공학한림원에 관하여는 제38조제2항 및 제5항부터 제7항까지의 규정을 준용한다. 이 경우 "기술진흥원"은 "한국공학한림원"으로, "제3항 각 호의 사업"은 "제2항 각 호의 사업"으로 본다.</li>
                        </ol>
                        <p>[전문개정 2009.1.30]</p>
                    </div>
                </div>
                <div className="cont_wrap">
                    <div className="cont_tit_box">
                        <span>연혁</span>
                    </div>

                    {Object.keys(historyData).map((period, idx) => (
                        <div key={idx}>
                            <div className={styles.pp_date_btn}>
                                {Object.keys(historyData).map((innerPeriod, innerIdx) => (
                                    <button
                                        key={innerIdx}
                                        className={innerPeriod === period ? styles.pp_date_btn_active : ''}
                                        onClick={() => {
                                            setActivePeriod(innerPeriod);
                                            const section = document.getElementById(`section-${innerIdx}`);
                                            if (section) {
                                                const yOffset = -250; // 100px 위로 스크롤
                                                const y = section.getBoundingClientRect().top + window.pageYOffset + yOffset;
                                                window.scrollTo({ top: y, behavior: 'smooth' });
                                            }
                                        }}
                                    >
                                        {innerPeriod}
                                    </button>
                                ))}
                            </div>
                            <div className={styles.purpose_wrap} id={`section-${idx}`}>
                                <div className={`${styles.purpose_list} cont_box`}>
                                    <div className={styles.pp_wrap}>
                                        {renderHistory(historyData[period])}
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </>
    );
}

export default Purpose;