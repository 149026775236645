import React from 'react';

function Footer() {


    return (
        <>
            <footer className='footer_wrap'>
                <div className="footer_box">
                    <div className="flex_between f_top_content">
                        <div className="info_box">
                            <a href="/privacy">
                                <span className='f_info_important_txt'>개인정보취급방침</span>
                            </a>
                            <span className='between_txt'>|</span>
                            <a href="/direction">
                                <span className='f_info_txt'>오시는 길</span>
                            </a>
                        </div>
                        <div className="flex_between icon_box">
                            <a href="https://www.facebook.com/naek1995" target='_blank' rel="noopener noreferrer">
                                <div className="f_icon icon_facebook"></div>
                            </a>
                            <a href="https://blog.naver.com/naek1995" target='_blank' rel="noopener noreferrer">
                                <div className="f_icon icon_blog"></div>
                            </a>
                            <a href="https://www.youtube.com/channel/UCgZHNqY0WjPDm4t7NuF5vCg" target='_blank' rel="noopener noreferrer">
                                <div className="f_icon icon_youtube"></div>
                            </a>
                        </div>
                    </div>
                    <div className="f_bottom_content">
                        <div className="b_content_box">
                            <span className='b_content_bold'>상호명</span>
                            <span className='b_content_txt'>(재)한국공학한림원</span>
                        </div>
                        <div className="b_content_box">
                            <span className='b_content_bold'>주소</span>
                            <span className='b_content_txt'>서울특별시 강남구 테헤란로 305 한국기술센터 15층</span>
                        </div>
                        <div className="b_content_box">
                            <span className='b_content_bold'>사업자등록번호</span>
                            <span className='b_content_txt'>220-82-02160</span>
                        </div>
                        <div className="b_content_box">
                            <span className='b_content_bold'>대표자</span>
                            <span className='b_content_txt'>김기남</span>
                        </div>
                        <div className="b_content_box">
                            <span className='b_content_bold'>Tel.</span>
                            <span className='b_content_txt'>02-6009-4000</span>
                        </div>
                        <div className="b_content_box">
                            <span className='b_content_bold'>Email</span>
                            <span className='b_content_txt'>naek@naek.or.kr</span>
                        </div>
                        <div className="b_content_box">
                            <span className='b_content_bold'>통신판매업신고</span>
                            <span className='b_content_txt'>제2019-서울강남-04883호</span>
                        </div>
                        <div className="b_content_copyright">
                            <span>© Copyright - The National Academy of Engineering of Korea</span>
                        </div>
                    </div>
                    <div className="footer_logo_box">
                        <div className="footer_logo"></div>    
                    </div>
                </div>
            </footer>
        </>
    );
}

export default Footer;

