import React, { useState, useEffect } from 'react';

import { useNavigate, useLocation } from 'react-router-dom';
import styles from './PaymentHistory.module.scss';
import 'primeicons/primeicons.css';
import axios from 'axios';
import common from '../../../common';
import Select from 'react-select';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const accountTypes = [
    { value: '004', label: 'KB국민은행' },
    { value: '088', label: '신한은행' },
    { value: '020', label: '우리은행' },
    { value: '081', label: '하나은행' },
    { value: '027', label: '한국씨티은행' },
    { value: '023', label: 'SC제일은행' },
    { value: '039', label: '경남은행' },
    { value: '034', label: '광주은행' },
    { value: '031', label: '대구은행' },
    { value: '032', label: '부산은행' },
    { value: '071', label: '우체국' },
    { value: '037', label: '전북은행' },
    { value: '035', label: '제주은행' },
    { value: '002', label: '산업은행' },
    { value: '045', label: '새마을금고' },
    { value: '007', label: '수협중앙회' },
    { value: '048', label: '신협' },
    { value: '005', label: '외환은행' },
    { value: '011', label: '농협중앙회' },
    { value: '012', label: '단위농협' },
    { value: '008', label: '수출입은행' },
    { value: '003', label: '기업은행' },
    { value: '009', label: '국민은행' },
    { value: '001', label: '한국은행' },
];

const PaymentHistory = () => {
    const { useAlert, useLoading, useConfirm, useModal, pToast, pClipboard } = common();
    const { pAlert, AlertComponent } = useAlert();
    const { pLoadingOn, pLoadingOff, LoadingComponent } = useLoading();
    const { pConfirm, ConfirmComponent } = useConfirm();
    const { pModal, closeModal, ModalComponent } = useModal();
    const navigate = useNavigate();
    const location = useLocation();


    const { state } = location;
    const [data, setData] = useState(null);
    useEffect(() => {
        if (state) {
            // console.log('Received state:', state);
            setData(state); // 받아온 데이터를 state에 저장
        } else {
            // console.log('No data received');
        }
    }, [state]);




    const [refundFormData, setRefundFormData] = useState({
        bank: '',
        holderName: '',
        accountNumber: '',
    });

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const handleBackClick = () => {
        window.scrollTo(0, 0);
        navigate('/payment', { replace: true });
    };
    const handleCancelClick = async () => {
        if (data?.methodName === '가상계좌' && !refundFormData.bank) {
            pAlert('환불 은행을 선택해 주세요.');
            return;
        }
        if (data?.methodName === '가상계좌' && !refundFormData.holderName) {
            pAlert('계좌주를 입력해 주세요.');
            return;
        }
        if (data?.methodName === '가상계좌' && !refundFormData.accountNumber) {
            pAlert('계좌번호를 입력해 주세요.');
            return;
        }
        if (data.statusName !== '결제 성공') {
            pAlert('결제 성공건만 취소요청이 가능합니다.');
            return;
        }
        pConfirm(
            '결제 취소',
            <>
                <div>해당 건을 취소 요청 하시겠습니까?</div>
            </>,
            '400px',
            'auto',
            async () => {
                try {
                    pLoadingOn();
                    const apiData = data?.methodName === '가상계좌'
                        ? { ...refundFormData, orderId: data.orderId }
                        : { orderId: data.orderId };
                    const response = await axios.post(`${API_BASE_URL}/naek_toss_payments/out/cancel_payment`,
                        apiData,
                        { withCredentials: true }
                    );
                    if (response.data.result === 't') {
                        navigate('/paymentrefund', {
                            state: { data: response.data },
                            replace: true
                        });
                    } else {
                        pAlert(response.data.msg);
                    }
                } catch (error) {
                    pAlert('취소 요청에 실패했습니다. 관리자에게 문의하세요.');
                } finally {
                    pLoadingOff();
                }
            }
        );

    };
    const formatNumber = (num) => {
        if (!num) return '';
        return Number(num).toLocaleString();
    };
    return (
        <>
            <AlertComponent />
            <LoadingComponent />
            <ConfirmComponent />
            <ModalComponent />
            <div className={styles.payment_wrap}>
                {/* <div className={styles.cont_tit_box}>
                    <p>회비 결제내역</p>
                </div> */}
                <div className={styles.cont_box}>
                    <table className={styles.info_table}>
                        <tbody>
                            <tr>
                                <td>결제 상태</td>
                                <td>{data?.statusName}</td>
                            </tr>
                            {(data?.statusName === '결제 취소 요청' || data?.statusName === '결제 취소') && (
                                <tr>
                                    <td>취소 요청 일시</td>
                                    <td>{data?.cancelRequestTime}</td>
                                </tr>
                            )}
                            {data?.statusName === '결제 취소' && (
                                <tr>
                                    <td>취소 일시</td>
                                    <td>{data?.cancelTime}</td>
                                </tr>
                            )}

                            <tr>
                                <td>주문 번호</td>
                                <td>{data?.orderId}</td>
                            </tr>
                            <tr>
                                <td>결제 금액</td>
                                <td>{formatNumber(data?.amount)} 원</td>
                            </tr>
                            <tr>
                                <td>결제 방법</td>
                                <td>{data?.methodName}</td>
                            </tr>
                            <tr>
                                <td>결제 일시</td>
                                <td>{data?.createTime}</td>
                            </tr>
                            <tr>
                                <td>회원 종류</td>
                                <td>{data?.memberGradeName}</td>
                            </tr>
                            <tr>
                                <td>회원명</td>
                                <td>{data?.customerName}</td>
                            </tr>
                            <tr>
                                <td>소속기관</td>
                                <td>{data?.organization}</td>
                            </tr>
                            <tr>
                                <td>직위</td>
                                <td>{data?.position}</td>
                            </tr>
                            {/* <tr>
                                <td>분과</td>
                                <td>{data?.letterDepartName}</td>
                            </tr> */}
                            <tr>
                                <td>연락처</td>
                                <td>{data?.customerMobilePhone}</td>
                            </tr>
                            <tr>
                                <td>이메일</td>
                                <td>{data?.customerEmail}</td>
                            </tr>
                            <tr>
                                <td>메모</td>
                                <td>{data?.memo}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                {data?.methodName === '가상계좌' && data?.statusName === '결제 성공' && (
                    <>
                        <div className={styles.cont_tit_box}>
                            <p>결제 취소</p>
                        </div>
                        <div className={styles.cont_box}>
                            <div className={styles.cont_sel_wrap}>
                                <label>환불 은행</label>
                                <div className={styles.cont_sel_box}>
                                    <Select
                                        value={accountTypes.find(option => option.value === refundFormData.bank)}
                                        onChange={(option) => setRefundFormData({ ...refundFormData, bank: option.value })}
                                        options={accountTypes}
                                    />
                                </div>
                            </div>
                            <div className={styles.cont_sel_wrap}>
                                <label>계좌주</label>
                                <div className={styles.cont_sel_box}>
                                    <input
                                        className={styles.p_input}
                                        type="text"
                                        value={refundFormData.holderName}
                                        onChange={(e) => setRefundFormData({ ...refundFormData, holderName: e.target.value })}
                                    />
                                </div>
                            </div>
                            <div className={styles.cont_sel_wrap}>
                                <label>계좌번호 ( - 제외 )</label>
                                <div className={styles.cont_sel_box}>
                                    <input
                                        className={styles.p_input}
                                        type="number"
                                        value={refundFormData.accountNumber}
                                        onChange={(e) => setRefundFormData({ ...refundFormData, accountNumber: e.target.value })}
                                        onKeyPress={(e) => {
                                            if (!/[0-9]/.test(e.key)) {
                                                e.preventDefault();
                                            }
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </>
                )}
                <div className={styles.pm_btn_wrap}>
                    <button className={styles.submit_btn} onClick={handleBackClick}>이전으로</button>
                    {data?.statusName === '결제 성공' && (
                        <button onClick={handleCancelClick}>결제 취소 요청</button>
                    )}
                </div>
            </div>
        </>
    );
}

export default PaymentHistory;