// 리덕스 툴킷의 createSlice를 임포트
import { createSlice } from '@reduxjs/toolkit';

// userSlice를 생성합니다. name은 'user', 초기 상태는 loginInfo 객체를 가집니다.
const userSlice = createSlice({
    name: 'user',
    initialState: {
        loginInfo: {}
    },
    // 리듀서를 정의합니다.
    reducers: {
        // setLoginInfo 리듀서: 상태를 action.payload로 설정합니다.
        setLoginInfo: (state, action) => {
            state.loginInfo = action.payload;
        },
        // clearLoginInfo 리듀서: loginInfo 상태를 빈 객체로 설정합니다.
        clearLoginInfo: (state) => {
            state.loginInfo = {};
        }
    }
});

// 액션 생성자를 익스포트합니다.
export const { setLoginInfo, clearLoginInfo } = userSlice.actions;
// 리듀서를 익스포트하여 스토어에서 사용할 수 있도록 합니다.
export default userSlice.reducer;
