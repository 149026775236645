import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styles from './PaymentResult.module.scss';
import axios from 'axios';
import common from '../../../common';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const PaymentResult = () => {
    const { useAlert, useLoading, useConfirm, useModal, pToast, pClipboard } = common();
    const { pAlert, AlertComponent } = useAlert();
    const { pLoadingOn, pLoadingOff, LoadingComponent } = useLoading();
    const { pConfirm, ConfirmComponent } = useConfirm();
    const { pModal, closeModal, ModalComponent } = useModal();
    const navigate = useNavigate();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const status = queryParams.get('status');
    const orderId = queryParams.get('orderId');
    const amount = queryParams.get('amount');
    const paymentKey = queryParams.get('paymentKey');
    const code = queryParams.get('code');
    const message = queryParams.get('message');


    useEffect(() => {
        if (status === 'success') {
            const fetchOptions = async () => {
                try {
                    pLoadingOn();
                    const response = await axios.post(`${API_BASE_URL}/naek_toss_payments/out/confirm_payment`, {
                        amount: amount,
                        orderId: orderId,
                        paymentKey: paymentKey,
                    }, {
                        withCredentials: true
                    });
                    navigate('/paymentsuccess', {
                        state: { data: response.data },
                        replace: true
                    });
                } catch (error) {
                    console.error('Error fetching options:', error);
                    pAlert('데이터 가져오기에 실패했습니다. 다시 시도해주세요.');
                } finally {
                    pLoadingOff();
                }
            };
            fetchOptions();
        }
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const handleBackClick = () => {
        window.scrollTo(0, 0);
        navigate('/payment', { replace: true });
    };

    return (
        <>
            <AlertComponent />
            <LoadingComponent />
            <ConfirmComponent />
            <ModalComponent />
            <div className={styles.payment_wrap}>
                <div className={styles.cont_tit_box}>
                    {status === 'success' ?
                        <>
                            {/* <i className='pi pi-check-circle'></i>
                            <p>결제가 성공적으로 완료되었습니다.</p> */}
                        </>
                        :
                        <>
                            <i className={`pi pi-exclamation-circle ${styles.check_btn}`}></i>
                            <p>결제가 실패하였습니다.</p>
                        </>
                    }
                </div>
                {status === 'success' ?
                    <>
                        {/* <table className={styles.info_table}>
                                <tbody>
                                    <tr>
                                        <td>주문 번호</td>
                                        <td>{orderId}</td>
                                    </tr>
                                    <tr>
                                        <td>주문 번호</td>
                                        <td>{orderId}</td>
                                    </tr>
                                </tbody>
                            </table>
                            <p>* 결제 이후 주문 번호 확인은 입력하신 이메일을 통해 확인하실 수 있습니다.</p> */}
                    </>
                    :
                    <>
                        <div className={styles.cont_box}>
                            <p>오류 코드: {code}</p>
                            <p>실패 사유: {message}</p>
                        </div>
                        <div className={styles.pm_btn_wrap}>
                            <button className={styles.submit_btn} onClick={handleBackClick}>이전으로</button>
                        </div>

                    </>
                }
            </div>
        </>
    );
}

export default PaymentResult;