
import styles from './Progress.module.scss';

function Progress() {
    return (
        <>
            <div className="components_wrap">
                <div className={styles.progressWrap}>
                    <div className={styles.progressTb}>
                        <img src="/img/stepPc.png" alt="" className={styles.pcOnly}></img>
                        <img src="/img/stepM.png" alt="" className={styles.mOnly}></img>
                        <div className={styles.absWrap}>
                            <div className={styles.step1}>
                                <div>
                                    <h5>접수</h5>
                                    <p></p>
                                </div>
                            </div>
                            <div className={styles.step2}>
                                <div>
                                    <h5>심사위원 평가소견서 접수</h5>
                                </div>
                            </div>
                            <div className={styles.step3}>
                                <div>
                                    <h5>전문분과 위원회 심사</h5>
                                </div>
                            </div>
                        </div>
                        <div className={styles.absWrap}>
                            <div className={styles.step4}>
                                <div>
                                    <h5>회원위원회 심사</h5>
                                </div>
                            </div>
                            <div className={styles.step5}>
                                <div>
                                    <h5>이사회</h5>
                                </div>
                            </div>
                            <div className={styles.step6}>
                                <div>
                                    <h5>총회</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Progress;