import { useState } from 'react';
import styles from './Dept.module.scss';
const data = [
    {
        label: 'General Assembly',
        className: 'dept_main_line',
        // link : '#'
    },
    {
        label: 'Board',
        className: 'dept_node_bg_blue dept_main_line',
        // link : '/committee?code1=7',
        children: [
            {
                label: 'Auditor',
                className: 'dept_node_bd_blue dept_left_bottom_line',
                // link : '/committee?code1=11&code2=1',
                position: 'right_bottom'
            },
        ],
    },
    {
        label: 'President',
        className: 'dept_node_bg_blue_2 dept_main_line',
        // link : '/committee?code1=9&code2=1',
        children: [
            {
                label: 'Presidential Office',
                className: 'dept_node_bd_blue dept_left_middle_line',
                // link : '/chairmanContact',
                position: 'right_middle'
            },
            {
                label: 'Executive Committee',
                className: 'dept_node_bd_blue_2 dept_left_bottom_line',
                // link : '/committee?code1=5',
                position: 'right_bottom'
            },
            {
                label: 'Business Executive Council',
                className: 'dept_node_bd_blue_2 dept_right_bottom_line',
                // link : '/committee?code1=6',
                position: 'left_bottom'
            },
        ],
    },
    {
        label: 'Vice Presidents',
        className: 'dept_node_bg_blue_3 dept_main_line',
        // link : '/committee?code1=10&code2=1',
        children: [
            {
                label: 'Secretariat',
                className: 'dept_node_bd_blue_3 dept_left_middle_line',
                link : '/en/contact',
                position: 'right_middle'
            },
            {
                label: 'Policy Committee',
                className: 'dept_node_bd_blue_3 dept_right_middle_line',
                // link : '/committee?code1=8',
                position: 'left_middle'
            },
            {
                label: 'Policy Research Center',
                className: 'dept_node_bd_blue_3 dept_top_middle_line',
                // link : '#',
                position: 'left_bottom'
            },
        ],
    }
];
function Dept() {
    const [activeTab, setActiveTab] = useState('chart');

    return (
        <div className="components_wrap">
            <div className={`${styles.dept_btn_box}`}>
                <button
                    className={activeTab === 'chart' ? styles.active : ''}
                    onClick={() => setActiveTab('chart')}
                >
                    Organization
                </button>
            </div>
            {activeTab === 'chart' && (
                <div className={styles.chart_wrap}>
                    <div className={styles.organization_chart}>
                        {data.map((dept, index) => {
                            const leftChildrenBottom = dept.children?.filter(child => child.position === 'left_bottom') || [];
                            const leftChildrenMiddle = dept.children?.filter(child => child.position === 'left_middle') || [];
                            const rightChildrenBottom = dept.children?.filter(child => child.position === 'right_bottom') || [];
                            const rightChildrenMiddle = dept.children?.filter(child => child.position === 'right_middle') || [];

                            return (
                                <div key={index} className={`${styles.dept_container}`}>
                                    <button className={`${styles.node} ${dept.className}`}>{dept.label}</button>
                                    {leftChildrenBottom.length > 0 && (
                                        <div className={styles.left_children_bottom}>
                                            {leftChildrenBottom.map((child, childIndex) => (
                                                <div key={childIndex} className={styles.left_child_bottom}>
                                                    <a href={child.link} className={`${styles.node} ${styles.left_node_bottom} ${child.className}`}>{child.label}</a>
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                    {leftChildrenMiddle.length > 0 && (
                                        <div className={styles.left_children_middle}>
                                            {leftChildrenMiddle.map((child, childIndex) => (
                                                <div key={childIndex} className={styles.left_child_middle}>
                                                    <a href={child.link} className={`${styles.node} ${styles.left_node_middle} ${child.className}`}>{child.label}</a>
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                    {rightChildrenBottom.length > 0 && (
                                        <div className={styles.right_children_bottom}>
                                            {rightChildrenBottom.map((child, childIndex) => (
                                                <div key={childIndex} className={styles.right_child_bottom}>
                                                    <a href={child.link} className={`${styles.node} ${styles.right_node_bottom} ${child.className}`}>{child.label}</a>
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                    {rightChildrenMiddle.length > 0 && (
                                        <div className={styles.right_children_middle}>
                                            {rightChildrenMiddle.map((child, childIndex) => (
                                                <div key={childIndex} className={styles.right_child_middle}>
                                                    <a href={child.link} className={`${styles.node} ${styles.right_node_middle} ${child.className}`}>{child.label}</a>
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                </div>
                            );
                        })}
                    </div>
                    <div className={styles.depth_group}>
                        <div className={styles.depth_group_line}></div>
                        <div className={styles.group_m01}>
                            <button>
                                <div className={styles.txt}>
                                    <span className={styles.str}>Steering<br/>
                                    Committee</span>
                                </div>
                            </button>
                            <ul className={styles.bul}>
                                <button className={styles.child_bul}><span>Membership</span></button>
                                <button className={styles.child_bul}><span>Awards</span></button>
                                <button className={styles.child_bul}><span>Planning</span></button>
                                <button className={styles.child_bul}><span>International Relations</span></button>
                                <button className={styles.child_bul}><span>Leadership Development</span></button>
                                <button className={styles.child_bul}><span>Public Reachout</span></button>
                            </ul>
                        </div>
                        <div className={styles.group_m02}>
                            <button href="#">
                                <div className={styles.txt}>
                                    <span className={styles.str}>Engineering<br/>Committee</span>
                                </div>
                            </button>
                            <ul className={styles.bul}>
                                <button className={styles.child_bul}><span>Electrical and Electronic Engineering</span></button>
                                <button className={styles.child_bul}><span>Mechanical Engineering</span></button>
                                <button className={styles.child_bul}><span>Architectural, Civil and Environmental Engineering</span></button>
                                <button className={styles.child_bul}><span>Chemical and Biological Engineering</span></button>
                                <button className={styles.child_bul}><span>Material and Energy Engineering</span></button>
                                <button className={styles.child_bul}><span>Technology, Management and Policy</span></button>
                                <button className={styles.child_bul}><span>Computer Science and Engineering</span></button>
                                <button className={styles.child_bul}><span>Biomedical Engineering</span></button>
                            </ul>
                        </div>
                        <div className={styles.group_m03}>
                            <button>
                                <div className={styles.txt}>
                                    <span className={styles.str}>Forum Operating<br/>Committee</span>
                                </div>
                            </button>
                        </div>
                        <div className={`${styles.group_m04} ${styles.last}`}>
                            <button>
                                <div className={styles.txt}>
                                    <span className={styles.str}>Ad-hoc<br/>Committee</span>
                                </div>
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default Dept;