import React, { useState, useEffect, useMemo, useRef } from 'react';
import styles from './Policy.module.scss';

function Policy() {

    return (
        <div className="components_wrap">


            <div className={styles.cont_wrap}>
                <div className={styles.cont_box}>
                    <ul className={styles.bul_list_none}>
                        <li>The National Academy of Engineering of Korea proposes policy initiatives and strategies to the Korean Government for the advancement of engineering and technology. It aims to become a global engineering and technology platform that supports the sustainable development of industrial innovation.</li>
                        <li>The Policy Committee has become a standing committee led by the executive vice president. The committee is responsible for studying and planning short-term and long-term projects on policy research, establishing ad-hoc committees, and publishing the NAEK VOICE and NAEK Policy Proposal series.</li>
                    </ul>
                </div>
            </div>


            <div className={styles.cont_wrap}>
                <div className={styles.cont_tit_box}>
                    <span>NAEK Voice</span>
                </div>
                <div className={styles.cont_box}>
                    <ul className={styles.bul_list_none}>
                        <li>NAEK Voice covers arguable controversies with respect to industrial and scientific technologies and offers optimal strategies and solutions. This bulletin is published as issues arise, designed in a tabloid format, and written in easy-to-understand language for non-specialists. The presentation gets straight to the matter at hand. The outcomes are submitted to the relevant government departments to support national policymaking and are distributed to the public for feedback. At the same time, public awareness of engineering achievements is enhanced. We hope our views are heard by diverse communities of interest so that we can stimulate constructive debate and elicit valuable opinions. NAEK VOICE is sent to more than 2,000 opinion leaders such as NAEK members, lawmakers, and relevant organizations.</li>
                    </ul>
                </div>
            </div>

            <div className={styles.cont_wrap}>
                <div className={styles.cont_tit_box}>
                    <span>Industrial Future Strategy Committee</span>
                </div>
                <div className={styles.cont_box}>
                    <ul className={styles.bul_list_none}>
                        <li>In 2018, NAEK launched the “Industrial Future Strategy Committee” to propose policy initiatives for new industries and suggest the direction of national policy. The committee's efforts over the first four years culminated in the publication of "Great Transformation” in 2022, followed by an extension project named “the GREAT Project”.</li>
                        <li>In 2022, the second term of a newly formed committee began. In order to establish a new national vision and strategies to address internal and external environmental changes and crises, the committee surveyed all NAEK members on the development and implementation of national future strategies for Korean industries. As a result, “IS4T (Industrial Strategy for Technology, Talent, Trade & security, Transformation)” and “NF3T (Non-Fungible Technology, Talent, and Trust)” were proposed as survival strategies for the country and industry. In 2023, the committee organized a scenario planning workshop titled "Korea Towards 2040: Building an Invaluable Country." In 2024, we plan to form seven subcommittees, each dedicated to a specific domain, to conduct more in-depth research in each field.</li>
                    </ul>
                </div>
            </div>
            
        </div>
    );
}

export default Policy;
