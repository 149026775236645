import React, { useState, useEffect, useMemo, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { TableCard } from "../../../components";
import styles from './PolicySeries.module.scss';
import common from '../../../common';

import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

function PolicySeries() {
    const { useAlert,useConfirm, useLoading } = common();
    const { pAlert, AlertComponent } = useAlert();
    const { pConfirm, ConfirmComponent} = useConfirm();
    const { pLoadingOn, pLoadingOff, LoadingComponent } = useLoading();


    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const pageid = queryParams.get('pageid');


    const [selectedData, setSelectedData] = useState([]);


    const [data1 , setData1] = useState([]);
    const [data2 , setData2] = useState([]);
    const [data3 , setData3] = useState([]);
    const [data4 , setData4] = useState([]);
    const [data5 , setData5] = useState([]);


    const categoryData = ['18','17','16','15','14'];

    const fetchData = async () => {
        try {
            pLoadingOn();
            const response = await axios.post(`${API_BASE_URL}/naek_document/out/get_table`, {categoryData:categoryData}, {
                withCredentials: true
            });


            if (response.data.result === 't') {
                const allData = response.data.data;

                const newData1 = [];
                const newData2 = [];
                const newData3 = [];
                const newData4 = [];
                const newData5 = [];

                allData.forEach(item => {
                    switch (item.documentCategoryIDX) {
                        case 18:
                            newData1.push(item);
                            break;
                        case 17:
                            newData2.push(item);
                            break;
                        case 16:
                            newData3.push(item);
                            break;
                        case 15:
                            newData4.push(item);
                            break;
                        case 14:
                            newData5.push(item);
                            break;
                        default:
                            console.warn(`Unexpected category: ${item.documentCategoryIDX}`);
                    }
                });
    
                setData1(newData1);
                setData2(newData2);
                setData3(newData3);
                setData4(newData4);
                setData5(newData5);

                
            } else {
                pAlert(response.data.msg);;
            }
        } catch (error) {
            console.error('Fetch failed:', error);
            pAlert('실패함.');
        } finally {
            pLoadingOff();
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const pageSize = 10;
    const categoryOptions = useMemo(() => [
        { label: '전체', value: '전체' },
        { label: '제목', value: 'title' },
        { label: '내용', value: 'contents' },
    ], []);

    const [returnTableCard, setReturnTableCard] = useState(null);

    useEffect(() => {
        let selectedData = [];

        switch (pageid) {
            case '1':
                selectedData = data1;
                break;
            case '2':
                selectedData = data2;
                break;
            case '3':
                selectedData = data3;
                break;
            case '4':
                selectedData = data4;
                break;
            case '5':
                selectedData = data5;
                break;
            default:
                selectedData = data1;
        }

        setSelectedData(selectedData);
        setReturnTableCard(
            <TableCard
                key={pageid}
                data={selectedData}
                pageSize={pageSize}
                categoryOptions={categoryOptions}
                type="Editor"
            />
        );


    }, [pageid, data1, data2, data3, data4, data5, pageSize, categoryOptions]);

    // =========================================

    const overviewRef = useRef(null);
    const forumHistoryRef = useRef(null);

    const handleButtonClick = (section) => {
        if (section === 'overview' && overviewRef.current) {
            window.scrollTo({
                top: overviewRef.current.offsetTop - 150,
                behavior: 'smooth',
            });
        } else if (section === 'forumHistory' && forumHistoryRef.current) {
            window.scrollTo({
                top: forumHistoryRef.current.offsetTop - 150,
                behavior: 'smooth',
            });
        }
    };

    return (
        <div className="components_wrap">
            {(pageid === '1' || pageid === '2' || pageid === '3') && (
                <>
                <div className={styles.tab_btn_box} ref={overviewRef}>
                    <button
                        className={`${styles.tab_btn} ${styles.active}`}
                        onClick={() => handleButtonClick('overview')}
                    >
                        개요
                    </button>
                    <button
                        className={`${styles.tab_btn}`}
                        onClick={() => handleButtonClick('forumHistory')}
                    >
                        역대 보고서 소개
                    </button>
                </div>
                </>
            )}
            

            {/* ==================== 역대 정책총서 리스트 ==================== */}

            {(pageid === '1') && (
                <>
                    <div className={styles.cont_wrap}>
                        <div className={styles.cont_tit_box}>
                            <span>역대 정책총서 리스트</span>
                        </div>
                        <div className={styles.cont_box}>
                            <ul className={styles.bul_list_2}>
                                <li>정책총서Ⅰ 「공학기술로 나라 살리자」, 1997년</li>
                                <li>정책총서Ⅱ 「공학기술로 21세기 앞장서자」, 2002년</li>
                                <li>정책총서Ⅲ 「창조적 혁신으로 새 성장판을 열자」, 2007년</li>
                                <li>정책총서Ⅳ 「대한민국의 새로운 50년, 과학기술로 연다」, 2012년</li>
                                <li>정책총서Ⅴ 「기회의 창을 여는 대한민국: 기업가형 개방국가, 학습하는 혁신사회」, 2017년</li>
                                <li>정책총서Ⅵ 「새로운 100년 산업혁명, ‘추월의 시대’로 가자」, 2021년</li>
                            </ul>
                        </div>
                    </div>
                </>
            )}

            {/* ==================== 특별위원회 ==================== */}

            {(pageid === '2') && (
                <>
                    <div className={styles.cont_wrap}>
                        <div className={styles.cont_tit_box}>
                            <span>특별위원회</span>
                        </div>
                        <div className={styles.cont_box}>
                            <ul className={styles.bul_list_2}>
                                <li>정책위원회: 정책연구 주제 발굴 및 각종 토론회와 정책연구의 연계 강화, 정책 관련 이슈페이퍼(NAEK VOICE) 발간</li>
                                <li>인재양성위원회: 공학교육 및 인재양성과 관련된 장단기 연구 및 토론을 통해 미래지향적 인재양성 정책 제시</li>
                                <li>산업미래전략위원회: 정치적 중립을 기반으로 한 장기적 미래연구 추진 및 국가 산업 정책의 방향 제시</li>
                                {/* <li>지식재산전략연구회: IP전략 포럼을 통해 지식재산 이슈를 분석하고 향후 정책 과제를 발굴하여 제시</li> */}
                                <li>자율주행위원회: 자율주행 글로벌 1등이 되기 위한 국가 산업전략 제안</li>
                            </ul>
                        </div>
                    </div>
                </>
            )}

            {/* ==================== 사업진행절차 ==================== */}

            {(pageid === '3') && (
                <>
                    <div className={styles.cont_wrap}>
                        <div className={styles.cont_tit_box}>
                            <span>사업진행절차</span>
                        </div>
                        <div className={styles.cont_box}>
                            <ul className={styles.bul_list_3}>
                                <li>단기정책연구 과제 공모</li>
                                <li>발굴된 과제 검토 및 선정</li>
                                <li>연구 과제 수행 및 중간 모니터링</li>
                                <li>연구결과 발표회 개최 및 보고서 발간</li>
                            </ul>
                        </div>
                    </div>
                </>
            )}

           
            <div className={`${styles.tab_btn_box} ${styles.m_t_b_100}`} ref={forumHistoryRef}>
                {(pageid === '1' || pageid === '2' || pageid === '3' || pageid === '5') && (
                    <>
                     <button
                        className={`${styles.tab_btn}`}
                        onClick={() => handleButtonClick('overview')}
                    >
                        개요
                    </button>
                    <button
                        className={`${styles.tab_btn} ${styles.active}`}
                        onClick={() => handleButtonClick('forumHistory')}
                    >
                        역대 보고서 소개
                    </button>
                    </>
                )}
                {(pageid === '4') && (
                    <>
                    <button
                        className={`${styles.tab_btn} ${styles.active}`}
                        onClick={() => handleButtonClick('forumHistory')}
                    >
                        역대 보고서 소개
                    </button>
                    </>
                )}
               
            </div>
            
            {returnTableCard}
        </div>
    );
}

export default PolicySeries;
