import React, { useState, useRef, useEffect, useMemo } from 'react';
import axios from 'axios';
import common from '../../common'; 
import { useLocation } from 'react-router-dom';

import './Detail.scss';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

function UserDetail({ isLogin }) {

    const { useAlert, useLoading } = common();
    const { pAlert, AlertComponent } = useAlert();
    const { pLoadingOn, pLoadingOff, LoadingComponent } = useLoading();
    
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const id = queryParams.get('id');

    const resetData = ({
        idx:'',
        thumbnail:'',
        nameKR:'',
        nameEN:'',
        nameHanja:'',
        organizationKR:'',
        deptKR:'',
        positionKR:'',
        letterDepart:'',
        gradeName:'',
        achievementKeyword:'',
        schoolHistory:'',
        career:'',
        award:''
    });
    const [formData, setFormData] = useState(resetData);

    const getData = async () => {
        try {
            pLoadingOn();
            const response = await axios.post(`${API_BASE_URL}/naek_member_detail/out/get_member_detail`,
                {
                    id: id,
                    il: isLogin
                },
                { withCredentials: true }
            );
            if (response && response.data && response.data.result === 't') {
                setFormData(response.data.data)
            } else {
                pAlert(response.data.msg);
            }
        } catch (error) {
            console.error('실패:', error);
        } finally {
            setTimeout(() => {
                pLoadingOff();
            }, 300);
        }
    };
    useEffect(() => {
        getData();
    }, []);
    

    return (
        <>
            <AlertComponent />
            <LoadingComponent />
            <div className="userdetail_components_wrap">
                <div className="userdetail_profile_box">
                    {formData.thumbnail && formData.thumbnail.length > 0 ? (
                        <div 
                            className="userdetail_thumbnail_img"
                            style={{ backgroundImage: `url(${formData.thumbnail[0].url})` }}
                        ></div>
                    ) : (
                        <div 
                            className="userdetail_thumbnail_img"
                            style={{ backgroundImage: `url(/img/profile.png)` }}
                        ></div>
                    )}
                    <div className="userdetail_profile_info_box">
                        <div className="userdetail_profile_flex_box">
                            <span className='userdetail_name_kor_txt'>{formData.nameKR}</span>
                            <span className='userdetail_name_eng_txt'>{formData.nameEN}</span>
                            <span className='userdetail_name_han_txt'>{formData.nameHanja}</span>
                        </div>
                        <div className="userdetail_profile_flex_box">
                            {formData.organizationKR && (
                                <span className='userdetail_duty_txt'>{formData.organizationKR}</span>
                            )}
                            {formData.deptKR && (
                                <span className='userdetail_duty_txt'>{formData.deptKR}</span>
                            )}
                            {formData.positionKR && (
                                <span className='userdetail_duty_txt'>{formData.positionKR}</span>
                            )}
                        </div>
                        <div className="userdetail_profile_flex_box">
                            <div className="userdetail_com_type_box">
                                <span className='userdetail_com_type_tit'>전문분과위원회</span>
                                <span className='userdetail_com_type_txt'>{formData.letterDepart}</span>
                            </div>
                            <div className='userdetail_com_type_box_content'></div>
                            <div className="userdetail_com_type_box">
                                <span className='userdetail_com_type_tit'>회원구분</span>
                                <span className='userdetail_com_type_txt'>{formData.gradeName}</span>
                            </div>
                        </div>
                        <div className="keywords">
                            {formData.achievementKeyword && formData.achievementKeyword.split('|@|').map((keyword, index) => (
                                <span key={index} className="userdetail_keyword">{keyword}</span>
                            ))}
                        </div>
                    </div>
                </div>
                {isLogin && (
                    <div className="userdetail_details">
                        <div className="userdetail_details_box">
                            <span className='userdetail_details_tit'><i className='userdetail_details_tit_i userdetail_details_i_1'></i>학력</span>
                            <ul className='userdetail_details_box_ul'>
                                {formData.schoolHistory && formData.schoolHistory.split('\n').map((schoolHistory, index) => (
                                    <li key={index}>{schoolHistory}</li>
                                ))}
                            </ul>
                        </div>
                        <div className="userdetail_details_box">
                            <span className='userdetail_details_tit'><i className='userdetail_details_tit_i userdetail_details_i_2'></i>경력</span>
                            <ul className='userdetail_details_box_ul'>
                                {formData.career && formData.career.split('\n').map((career, index) => (
                                    <li key={index}>{career}</li>
                                ))}
                            </ul>
                        </div>

                        <div className="userdetail_details_box">
                            <span className='userdetail_details_tit'><i className='userdetail_details_tit_i userdetail_details_i_3'></i>상훈</span>
                            <ul className='userdetail_details_box_ul'>
                                {formData.award && formData.award.split('\n').map((award, index) => (
                                    <li key={index}>{award}</li>
                                ))}
                            </ul>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
}

export default UserDetail;
