import React, { useState, useEffect } from 'react';
import styles from './Main.module.scss';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { EffectFade, Navigation, Pagination , Autoplay } from 'swiper/modules';
import 'primeicons/primeicons.css';
import common from '../../common';

import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;


function Main() {
    const { useAlert,useConfirm, useLoading } = common();
    const { pAlert, AlertComponent } = useAlert();
    const { pConfirm, ConfirmComponent} = useConfirm();
    const { pLoadingOn, pLoadingOff, LoadingComponent } = useLoading();



    const [sliderPack, setSliderPack] = useState([]);
    const [sec1_data_pack_1, setSec1_data_pack_1] = useState([]);
    const [sec1_data_pack_2, setSec1_data_pack_2] = useState([]);
    const [eventPack, setEventPack] = useState([]);
    const [noticePack, setNoticePack] = useState([]);
    const [pressReleasesPack, setPressReleasesPack] = useState([]);
    const [publicationPack, setPublicationPack] = useState([]);




    const fetchData = async () => {
        try {
            pLoadingOn();
            const response = await axios.post(`${API_BASE_URL}/naek_main/out/get_render_data`, {}, {
                withCredentials: true
            });

            if (response.data.result === 't') {
                const filteredSlideData = response.data.data_pack.slider_pack.filter(slide => slide.originLang === 'KR');
                
                const sortedSlideData = filteredSlideData.sort((a, b) => a.seq - b.seq);
                setSliderPack(sortedSlideData);

                // ===================================================

                const sortedSec1Data = response.data.data_pack.sec1_data_pack_1.sort((a, b) => {
                    const order = [3, 4, 5];  // 원하는 정렬 순서
                    return order.indexOf(a.documentCategoryIDX) - order.indexOf(b.documentCategoryIDX);
                });
                setSec1_data_pack_1(sortedSec1Data);

                // ===================================================

                const sortedSec2Data = response.data.data_pack.sec1_data_pack_2.sort((a, b) => {
                    const order = [7, 188];  // 원하는 정렬 순서
                    
                    // isYoutube가 'Y'인 항목은 항상 뒤로 보냄
                    if (a.isYoutube === "Y" && b.isYoutube !== "Y") {
                        return 1;
                    }
                    if (a.isYoutube !== "Y" && b.isYoutube === "Y") {
                        return -1;
                    }
                    
                    // 유튜브가 아닌 경우, order 배열에 따라 정렬
                    return order.indexOf(a.documentCategoryIDX) - order.indexOf(b.documentCategoryIDX);
                });
                
                setSec1_data_pack_2(sortedSec2Data);

                setEventPack(response.data.data_pack.event_pack);
                setNoticePack(response.data.data_pack.notice_pack);
                setPressReleasesPack(response.data.data_pack.press_rleases_pack);
                setPublicationPack(response.data.data_pack.publication_pack);
            } else {
                pAlert(response.data.msg);;
            }
        } catch (error) {
            console.error('Fetch failed:', error);
            pAlert('실패함.');
        } finally {
            pLoadingOff();
        }
    };

    useEffect(() => {
        fetchData();
    }, []);


    // 유튜브 링크 어떻게 보여줘야할지
    
    const sec1_data_2 = [
        {
            image: '/img/thumbnail_4.jpg',
            title: '미래국토포럼',
            content: '제38회 - 스마트·넷제로 시티 어떻게 만들 것인가',
            date: '2024. 01. 09',
            link: '/greeting',
            isYoutube: false
        },
        {
            image: '/img/thumbnail_1.jpg',
            title: 'IP전략포럼',
            content: '제19회 - 기술안보와 특허 생태계',
            date: '2023. 12. 11',
            link: '/greeting',
            isYoutube: false
        },
        {
            image: '',
            title: 'YOUTUBE',
            content: '[주니어 공학기술교실] 달탐사 로봇 코리 1부',
            date: '2024. 07. 11',
            link: 'https://youtu.be/iNvYsGKelYs?si=spshuLnKSSx9yb7X', 
            isYoutube: true
        }
    ];


    const [currentSlide, setCurrentSlide] = useState(0);
    const [activeTab, setActiveTab] = useState('notices');
    const tabData = activeTab === 'notices' ? noticePack : pressReleasesPack;

    const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 1024);
    const [isMobileView2, setIsMobileView2] = useState(window.innerWidth <= 768);

    const handleResize = () => {
        setIsMobileView(window.innerWidth <= 1024);
        setIsMobileView2(window.innerWidth <= 768);
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);


    return (
        <>
            <div className={styles.swiper_wrap}>
                <Swiper
                    spaceBetween={50}
                    slidesPerView={1}
                    effect="fade"
                    loop={true}
                    modules={[EffectFade, Navigation, Pagination]}
                    navigation={{
                        nextEl: `.${styles.swiper_button_next}`,
                        prevEl: `.${styles.swiper_button_prev}`,
                    }}
                    pagination={{ clickable: true }}
                    onSlideChange={(swiper) => setCurrentSlide(swiper.realIndex)}
                >
                    {sliderPack.map((slide, index) => {
                        const backgroundImage = isMobileView2 ? slide.mobileServerName : slide.webServerName;

                        return (
                            <SwiperSlide key={index}>
                                <div
                                    className={styles.main_slide_img}
                                    style={{
                                        backgroundImage: `url(${backgroundImage})`,
                                        backgroundRepeat: 'no-repeat',
                                        backgroundSize: 'cover',
                                        backgroundPosition: 'center',
                                        height: '475px',
                                        position: 'relative',
                                    }}
                                >
                                    <div className={styles.slider_text_wrap}>
                                        <div className={styles.slider_text_title}>
                                            {slide.title}
                                        </div>
                                        <div className={styles.slider_text}>
                                            {slide.content}
                                        </div>
                                        {slide.buttonUrl && slide.buttonTitle && (
                                            <a href={slide.buttonUrl} rel="noopener noreferrer" className={styles.slider_button}>
                                                {slide.buttonTitle}
                                            </a>
                                        )}
                                    </div>
                                </div>
                            </SwiperSlide>
                        );
                    })}
                </Swiper>
                <div className={styles.custom_pagination_box}>
                    <div className={styles.swiper_button_prev}>
                        <span className="pi pi-chevron-left"></span>
                    </div>
                    <div className={styles.custom_pagination}>
                        <span>{currentSlide + 1} / {sliderPack.length}</span> {/* 슬라이드 개수 자동 맞춤 */}
                    </div>
                    <div className={styles.swiper_button_next}>
                        <span className="pi pi-chevron-right"></span>
                    </div>
                </div>
            </div>






            <div className={styles.main_sec1}>
                <div className={styles.main_inner}>
                    <div className={styles.sec_title_box}>
                        <span className={styles.sec_title}>NAEK <span className={styles.color_b}>동향</span></span>
                        <span className={styles.sec_sub_title}>NAEK의 최근 동향과 유튜브 채널의 다양한 이야기를 전해 드립니다.</span>
                    </div>
                    <div className={styles.sec1_grid}>
                        {/* 768px 이하면 슬라이드 아니면 박스형식 */}
                        {!isMobileView2 ? (
                            <>
                            {sec1_data_pack_1.map((item, index) => (  
                                <a href={`noticedetail?id=${item.idx}`} className={styles.sec1_box} key={index}>
                                    <div
                                        className={styles.sec1_image}
                                        style={{ backgroundImage: `url(${item.thumbnail || '/img/symbol_1.png'})` }}
                                    ></div>
                                    <div className={styles.sec1_text_wrap}>
                                        <div className={styles.sec1_title}>{item.documentCategoryName}</div>
                                        <div className={styles.sec1_content}>{item.title}</div>
                                        <div className={styles.sec1_date}>{item.createTime}</div>
                                    </div>
                                </a>
                            ))}
                            </>
                        ) : (
                            <>
                                <Swiper
                                    spaceBetween={50}
                                    slidesPerView={1}
                                    loop={true}
                                    modules={[EffectFade, Navigation, Pagination, Autoplay]}
                                    autoplay={{
                                        delay: 3000,
                                        disableOnInteraction: false, // 사용자가 슬라이드를 조작해도 자동 슬라이드가 계속 작동하게 합니다.
                                    }}
                                >
                                    {sec1_data_pack_1.map((item, index) => (  // 여기서도 sec1_data_1을 sec1_data_pack_1으로 변경
                                        <SwiperSlide key={index}>
                                            <a href={`/noticedetail?id=${item.idx}`} className={styles.sec1_box} key={index}>
                                                <div className={styles.sec1_image} style={{ backgroundImage: `url(${item.thumbnail || '/img/symbol_1.png'})` }}></div>
                                                <div className={styles.sec1_text_wrap}>
                                                    <div className={styles.sec1_title}>{item.documentCategoryName}</div>
                                                    <div className={styles.sec1_content}>{item.title}</div>
                                                    <div className={styles.sec1_date}>{item.createTime}</div>
                                                </div>
                                            </a>
                                        </SwiperSlide>
                                    ))}
                                </Swiper>
                            </>
                        )}
                    </div>
                    <div className={styles.main_more_btn_box}>
                        <a href="/regulardebate?pageid=1" className={styles.main_more_btn}>more
                            <div className={styles.moreArrow}></div>
                        </a>
                    </div>

                    <div className={styles.sec1_grid}>
                        {/* 768px 이하면 슬라이드 아니면 박스형식 */}
                        {!isMobileView2 ? (
                            <>
                                {sec1_data_pack_2.map((item, index) => (
                                    item.isYoutube === "Y" ? (
                                        <div className={styles.sec1_box} key={index}>
                                            {item.thumbnail === 'N' ? (
                                                 <a href={`https://www.youtube.com/watch?v=${item.youtubeCode}`} target='_blank'>
                                                    <div
                                                        className={styles.sec1_image}
                                                        style={{ backgroundImage: `url(${'/img/symbol_1.png'})` }}
                                                    ></div>
                                                </a>
                                                
                                            ) : (
                                                <>
                                                    <div className={styles.sec1_image}>
                                                        <iframe
                                                            className={styles.sec1_iframe}
                                                            src={`https://www.youtube.com/embed/${item.youtubeCode}`}
                                                            title={item.title}
                                                            frameBorder="0"
                                                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                            allowFullScreen>
                                                        </iframe>
                                                    </div>
                                                </>
                                                
                                            )}
                                            
                                            <a href={`https://www.youtube.com/watch?v=${item.youtubeCode}`} target='_blank'>
                                                <div className={styles.sec1_text_wrap}>
                                                    <div className={styles.sec1_title}>Youtube</div>
                                                    <div className={styles.sec1_content}>{item.title}</div>
                                                    <div className={styles.sec1_date}>{item.createTime}</div>
                                                </div>
                                            </a>
                                        </div>
                                    ) : (
                                        <a 
                                            href={`noticedetail?id=${item.idx}`} 
                                            className={styles.sec1_box} 
                                            key={index} 
                                            target="_self"
                                        >
                                            <div
                                                className={styles.sec1_image}
                                                style={{ backgroundImage: `url(${item.thumbnail || '/img/symbol_1.png'})` }}
                                            ></div>
                                            <div className={styles.sec1_text_wrap}>
                                                <div className={styles.sec1_title}>{item.documentCategoryName}</div>
                                                <div className={styles.sec1_content}>{item.title}</div>
                                                <div className={styles.sec1_date}>{item.createTime}</div>
                                            </div>
                                        </a>
                                    )
                                ))}
                            </>
                        ) : (
                            <>
                                <Swiper
                                    spaceBetween={50}
                                    slidesPerView={1}
                                    loop={true}
                                    modules={[EffectFade, Navigation, Pagination, Autoplay]}
                                    autoplay={{
                                        delay: 3000,
                                        disableOnInteraction: false, // 사용자가 슬라이드를 조작해도 자동 슬라이드가 계속 작동하게 합니다.
                                    }}
                                >
                                    {sec1_data_pack_2.map((item, index) => (
                                        <SwiperSlide key={index}>
                                            <a
                                                href={`noticedetail?id=${item.idx}`}
                                                className={styles.sec1_box}
                                                key={index}
                                                target={item.isYoutube ? "_blank" : "_self"}
                                                rel={item.isYoutube ? "noopener noreferrer" : ""}
                                            >
                                                {item.isYoutube === "Y" ? (
                                                    <>
                                                    {item.thumbnail === 'N' ? (
                                                        <>
                                                        <a href={`https://www.youtube.com/watch?v=${item.youtubeCode}`} target='_blank'>
                                                            <div
                                                                className={styles.sec1_image}
                                                                style={{ backgroundImage: `url(${'/img/symbol_1.png'})` }}
                                                            ></div>
                                                        </a>
                                                        </>
                                                    ) : (
                                                        <>
                                                        <div className={styles.sec1_image}>
                                                            <iframe
                                                                className={styles.sec1_iframe}
                                                                src={`https://www.youtube.com/embed/${item.youtubeCode}`}
                                                                title={item.title}
                                                                frameBorder="0"
                                                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                                allowFullScreen>
                                                            </iframe>
                                                        </div>
                                                        </>
                                                    )}
                                                    
                                                    <a href={`https://www.youtube.com/watch?v=${item.youtubeCode}`} target='_blank'>
                                                        <div className={styles.sec1_text_wrap}>
                                                            <div className={styles.sec1_title}>{item.documentCategoryName}</div>
                                                            <div className={styles.sec1_content}>{item.title}</div>
                                                            <div className={styles.sec1_date}>{item.createTime}</div>
                                                        </div>
                                                    </a>
                                                    </>
                                                    
                                                ) : (
                                                    <a 
                                                        href={`noticedetail?id=${item.idx}`} 
                                                        className={styles.sec1_box} 
                                                        key={index} 
                                                        target="_self"
                                                    >
                                                        <div
                                                            className={styles.sec1_image}
                                                            style={{ backgroundImage: `url(${item.thumbnail || '/img/symbol_1.png'})` }}
                                                        ></div>
                                                        <div className={styles.sec1_text_wrap}>
                                                            <div className={styles.sec1_title}>{item.documentCategoryName}</div>
                                                            <div className={styles.sec1_content}>{item.title}</div>
                                                            <div className={styles.sec1_date}>{item.createTime}</div>
                                                        </div>
                                                    </a>
                                                )}
                                            </a>
                                        </SwiperSlide>
                                    ))}
                                </Swiper>
                            </>
                        )}
                    </div>

                    <div className={styles.main_more_btn_box}>
                        <a href="https://www.youtube.com/channel/UCgZHNqY0WjPDm4t7NuF5vCg" target="_blank" className={styles.main_more_btn}>more
                            <div className={styles.moreArrow}></div>
                        </a>
                    </div>
                </div>
            </div>
            <div className={styles.main_sec2}>
                <div className={styles.main_inner}>
                    <div className={styles.sec_title_box}>
                        <span className={styles.sec_title}>NAEK <span className={styles.color_b}>새 소식</span></span>
                        <span className={styles.sec_sub_title}>NAEK의 행사와 여러 활동, 회원 소식을 알려 드립니다.</span>
                    </div>
                    <div className={styles.sec2_top_grid}>
                        <div className={styles.sec2_left}>
                            <div className={styles.sec2_left_text_box}>
                                <span className={styles.sec2_left_title}>행사안내</span>
                                <span className={styles.sec2_left_content}>각 일정을 클릭하여<br />행사 정보를 자세하게<br />확인해보세요.</span>
                            </div>
                            <a href="/calendar?pageid=1" className={styles.main_more_btn}>more
                                <div className={styles.moreArrow}></div>
                            </a>
                        </div>
                        <div className={styles.sec2_right}>
                            {/* 768px 일때 more 표시 */}
                            {isMobileView2 && (
                                <div className={styles.main_more_btn_box}>
                                    <a href="/calendar?pageid=1" className={styles.main_more_btn}>more
                                        <div className={styles.moreArrow}></div>
                                    </a>
                                </div>
                            )}
                            {eventPack.map((event, index) => (
                                <a href={`/eventdetail?id=${event.idx}`} className={styles.event_card} key={index}>
                                    <div className={styles.event_date}>
                                        <div className={styles.event_month_year}>
                                            {/* 날짜 형식을 원본처럼 YYYY.MM으로 분리하여 표시 */}
                                            {`${event.date.split('-')[0]}.${event.date.split('-')[1]}.`} {/* 연도.월 */}
                                        </div>
                                        <div className={styles.event_day}>
                                            {event.date.split('-')[2]} {/* 일 */}
                                        </div>
                                    </div>
                                    <div className={styles.event_details}>
                                        <div className={styles.event_title}>{event.title}</div>
                                        <div className={styles.event_time}><span className={styles.font_bold}>시간</span> {event.time}</div>
                                        <div className={styles.event_location}><span className={styles.font_bold}>장소</span> {event.place}</div>
                                    </div>
                                </a>
                            ))}
                        </div>
                    </div>
                    <div className={styles.sec2_middle_grid}>
                        <div className={styles.sec2_tab_box}>
                            <div className={styles.sec2_tab_top_box}>
                                <div className={styles.sec2_tab_btn_box}>
                                    <button
                                        className={`${styles.tab_button} ${activeTab === 'notices' ? styles.active : ''}`}
                                        onClick={() => setActiveTab('notices')}
                                    >
                                        공지사항
                                    </button>
                                    <button
                                        className={`${styles.tab_button} ${activeTab === 'pressReleases' ? styles.active : ''}`}
                                        onClick={() => setActiveTab('pressReleases')}
                                    >
                                        보도자료
                                    </button>
                                </div>
                                <div className={`${styles.more_btn_box}`}>
                                    <a href="/notice" className={styles.main_more_btn}>more
                                        <div className={styles.moreArrow}></div>
                                    </a>
                                </div>
                            </div>
                            <div className={styles.tab_content}>
                                <div className={styles.notice_list}>
                                    {tabData.map((item, index) => (
                                        <div key={index} className={styles.notice_item}>
                                            <a href={`noticedetail?id=${item.idx}`} className={styles.notice_title}>{item.title}</a>
                                            <div className={styles.notice_date}>{item.createTime}</div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                        
                        <div className={styles.latest_publications_box}>
                            <div className={styles.latest_publications_row_1}>
                                <div className={styles.publication_title_box}>
                                    <span className={styles.publication_main_title}>최신 간행물</span>
                                    <a href="/en/report" className={`${styles.main_more_btn}`}>more
                                        <div className={styles.moreArrow}></div>
                                    </a>
                                </div>
                                <div className={styles.publication_items}>
                                    {/* 순서 변경: publicationPack[1], publicationPack[2], publicationPack[0] */}
                                    {publicationPack.slice(1, 3).map((publication, index) => (
                                        <a
                                            key={index}
                                            href={`noticedetail?id=${publication.idx}`}  
                                            className={styles.publication_item}
                                            style={{ backgroundImage: `url(${publication.thumbnail || '/img/symbol_1.png'})` }} 
                                            title={publication.title}
                                        >
                                            <span className={styles.publication_title}>{publication.title}</span>
                                        </a>
                                    ))}
                                    {/* 1024px 일때 최신 간행물 3개 모두 보이도록 */}
                                    {isMobileView && publicationPack[0] && (
                                        <a
                                            href={`noticedetail?id=${publicationPack[0].idx}`}  
                                            className={styles.publication_item}
                                            style={{ backgroundImage: `url(${publicationPack[0].thumbnail || '/img/symbol_1.png'})` }} 
                                            title={publicationPack[0].title}
                                        >
                                            <span className={styles.publication_title}>{publicationPack[0].title}</span>
                                        </a>
                                    )}
                                </div>
                            </div>
                            <div className={styles.latest_publications_row_2}>
                            {/* publicationPack[0]을 마지막으로 표시 */}
                            {publicationPack[0] && (
                                <a
                                    href={`noticedetail?id=${publicationPack[0].idx}`}
                                    className={styles.publication_item}
                                    style={{ backgroundImage: `url(${publicationPack[0].thumbnail || '/img/symbol_1.png'})` }}
                                    title={publicationPack[0].title}
                                >
                                    <span className={styles.publication_title}>{publicationPack[0].title}</span>
                                </a>
                            )}
                            </div>
                        </div>
                    </div>

                    <div className={styles.sec2_bottom_grid}>
                        <a href="http://www.naekyehs.org/" target='_blank' className={styles.banner_btn}>
                            <img src="/img/banner1.png" alt="" className={styles.banner_img} />
                        </a>
                        <a href="http://www.yipl.or.kr/home/" target='_blank' className={styles.banner_btn}>
                            <img src="/img/banner2.png" alt="" className={styles.banner_img} />
                        </a>
                        <a href="https://blog.naver.com/jrengineer" target='_blank' className={styles.banner_btn}>
                            <img src="/img/banner3.png" alt="" className={styles.banner_img} />
                        </a>
                        <a href="https://www.motie.go.kr/" target='_blank' className={styles.banner_btn}>
                            <img src="/img/banner4.jpg" alt="" className={styles.banner_img} />
                        </a>
                    </div>
                </div>
            </div>
            
        </>
    );
}

export default Main;
