
import React, { useState, useEffect, useMemo, useRef } from 'react';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import common from '../../../common';

import styles from './ProjectParti.module.scss';

const FingerprintJS = require('@fingerprintjs/fingerprintjs');

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

function ProjectParti() {
    const { useAlert,useConfirm, useLoading } = common();
    const { pAlert, AlertComponent } = useAlert();
    const { pConfirm, ConfirmComponent} = useConfirm();
    const { pLoadingOn, pLoadingOff, LoadingComponent } = useLoading();

    const navigate = useNavigate();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const idx = queryParams.get('id');
    
    const [detailData, setDetailData] = useState([]);
	const [formData, setFormData ] = useState({});
    const formDataRefs = useRef({});

    const colOrder = ["col1", "col2", "col3", "col4", "col5", "col6", "col7", "col8", "col9", "col10", "col11", "col12", "col13", "col14", "col15", "col16", "col17", "col18"];
    const fileOrder = ["file1", "file2"];
    
    const [requestReady, setRequestReady] = useState(false);
    const [dataReady, setDataReady] = useState(false);
    const [submitBreak, setSubmitBreak] = useState(false);

    const fetchData = async () => {
        try {
            pLoadingOn();
            const response = await axios.post(`${API_BASE_URL}/naek_project/out/get_detail_for_regist`, { target_idx:idx }, { withCredentials: true } );
            if (response.data.result === 't') {
                const data = response.data.data;
                setDetailData(data)
                setRequestReady(true);
            } else {
                pAlert(response.data.msg);;
            }
        } catch (error) {
            console.error('Fetch failed:', error);
            pAlert('실패함.');
        } finally {
            pLoadingOff();
        }
    };
    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        if(requestReady && detailData && Object.keys(detailData).length > 0){
            const newFormData = {};
            // colOrder.forEach((colKey) => {
            //     const value = detailData[colKey];
            //     if (value !== null) {
            //         newFormData[colKey] = '';
            //     }
            // });
            fileOrder.forEach((colKey) => {
                const value = detailData[colKey];
                if (value !== null) {
                    newFormData[colKey] = '';
                }
            });
            setFormData(newFormData);
            setDataReady(true);
        }
    }, [requestReady]);
    let allValid = true;
    const proSubmit = async () => {
        try {
            pLoadingOn();
            Object.keys(formDataRefs.current).forEach(key => {
                const tmpValue = formDataRefs.current[key].value;
                if (!tmpValue.trim()) {
                    pAlert(`${detailData[key]} 입력해주세요.`);
                    setSubmitBreak(false)
                    allValid = false;
                } else {
                    formData[key] = tmpValue;
                }
            });
            if (formData.file1 !== undefined && !formData.file1) {
                pAlert(`${detailData['file1']} 입력해주세요.`);
                allValid = false;
            }
    
            if (formData.file2 !== undefined && !formData.file2) {
                pAlert(`${detailData['file2']} 입력해주세요.`);
                allValid = false;
            }

            if(allValid){
                setSubmitBreak(true)
            }else{
                setSubmitBreak(false)
            }
            
        } catch (error) {
            console.error('Fetch failed:', error);
            pAlert('실패함.');
        } finally {
            pLoadingOff();
        }
    };
    
	useEffect(() => {
        if (submitBreak) {
            const submitData = async () => {
                try {
                    pConfirm(
                        '확인',
                        <>
                            <div>신청 하시겠습니까?</div>
                        </>,
                        'auto',
                        'auto',
                        async () => {
                            try {
                                pLoadingOn();
                                const fp = await FingerprintJS.load();
                                const result = await fp.get();
                                const visitor_id = result.visitorId;

                                const realFormData = new FormData();
                                realFormData.append('target_idx', idx);
                                realFormData.append('visitor_id', visitor_id);
                                Object.entries(formData).forEach(([key, value]) => {
                                    realFormData.append(key, value);
                                });
                                const response = await axios.post(`${API_BASE_URL}/naek_project/out/submit_pro`, realFormData, {
                                    withCredentials: true,
                                    headers: {
                                        'Content-Type': 'multipart/form-data'
                                    }
                                });
                                pAlert(response.data.msg);
                                if (response.data.result === 't') {
                                    const newFormData = Object.keys(formData).reduce((acc, key) => {
                                        acc[key] = '';  // 값을 초기화, 필요하면 다른 값으로 초기화 가능
                                        return acc;
                                    }, {});
                                    setFormData(newFormData);
                                    Object.keys(formDataRefs.current).forEach((key) => {
                                        if (formDataRefs.current[key]) {
                                            formDataRefs.current[key].value = '';  // 각 인풋 요소의 value를 빈 문자열로 설정
                                        }
                                    });
                                }
                            } catch (error) {
                                console.error('등록 실패:', error);
                            } finally {
                                pLoadingOff();
                            }
                        }
                    );
                } catch (error) {
                    console.error('Fetch failed:', error);
                    pAlert('실패함.');
                } finally {
                }
            };
            submitData();
            setSubmitBreak(false); // 상태가 업데이트 되었음을 초기화
        }
    }, [formData, submitBreak]);

    const onChg_file = (e) => {
		const newFile = e.target.files[0];
        setFormData((prevData) => ({
            ...prevData,
            [e.target.dataset.idx]:newFile,
        }))
    };

    return (
        <>
            <AlertComponent />
            <LoadingComponent />
            <ConfirmComponent />
            <div className="components_wrap">
                <div className="p_event_wrap">
                    <div className="event_info_box">
                        <span className="event_title">{detailData.title}</span>
                        <table className='event_table'>
                            <tbody>
                                <tr>
                                    <th>참여기간</th>
                                    <td>{detailData.date}</td>
                                </tr>
                            </tbody>
                        </table>
                        <div className={`p_i_row ${styles.projectregist_form_box}`}>
                            {/* {colOrder.map((colKey) => { */}
                            {dataReady && colOrder && colOrder.length > 0 && colOrder.map((colKey, index) => {
                                const value = detailData[colKey];
                                if (value !== null) {
                                    return (
                                        <div className={`p_i p_i_4 ${styles.pr_f_item}`} key={`${colKey}${index}`}>
                                            <span className={`p_i_tit f_small ${styles.pr_f_i_tit}`}>{value}</span>
                                            <div className={`p_i_con_box ${styles.pr_f_i_con_box}`}>
                                                <input type="text" className={`p_ip ${styles.pr_f_i_input}`} ref={(el) => (formDataRefs.current[colKey] = el)} />
                                            </div>
                                        </div>
                                    );
                                }
                                return null;
                            })}
                        </div>
                        <div className={` ${styles.projectregist_form_box} ${styles.projectregist_form_file_box}`}>
                            {/* {fileOrder.map((colKey) => { */}
                            {dataReady && fileOrder && fileOrder.length > 0 && fileOrder.map((colKey) => {
                                const value = detailData[colKey];
                                if (value !== null) {
                                    return (
                                        <>
                                            <div className={`p_i ${styles.pr_f_item}`} key={`f_${colKey}`}>
                                                <span className={`p_i_tit f_small ${styles.pr_f_i_tit}`}>{value}</span>
                                                <div className={`p_i_con_box ${styles.pr_f_i_con_box}`}>
                                                    { !formData[colKey] || formData[colKey].length === 0 ? (
                                                        <>
                                                            <label htmlFor={`tmpFile_${colKey}`} className={styles.file_item}>
                                                                <input className='p_ip' type="text" readOnly value='선택된 파일 없음' style={{ flex: 1, pointerEvents: 'none' }} />
                                                                <button className={`p_btn f_small p_btn_blue ${styles.file_on}`} onClick={() => document.getElementById(`tmpFile_${colKey}`).click()}>파일선택</button>
                                                            </label>
                                                            <input
                                                                type="file"
                                                                data-idx={colKey}
                                                                id={`tmpFile_${colKey}`}
                                                                style={{ display: 'none' }}
                                                                onChange={onChg_file}
                                                            />
                                                        </>
                                                    ) : (
                                                        <>
                                                            <div className={styles.file_item}>
                                                                <input className='p_ip' type="text" readOnly
                                                                    value={formData[colKey]?.name || '파일 없음'} />
                                                                <button
                                                                    onClick={() => setFormData((prevData) => ({
                                                                        ...prevData,
                                                                        [colKey]: ''
                                                                    }))}
                                                                    className='p_btn f_small '>삭제</button>
                                                            </div>
                                                        </>
                                                    )}
                                                </div>
                                            </div>
                                        </>
                                    );
                                }
                                return null;
                            })}
                        </div>
                        <div className='p_i_sec'>
                            <div className='p_i'>
                                <div className='p_i_con_box'>
                                    <span className='f_normal before_required'>신청이 완료되면 내용을 수정할 수 없습니다.</span>
                                </div>
                            </div>
                        </div>
                        <div className='p_i_sec'>
                            <div className='p_i'>
                                <div className='p_i_con_box center'>
                                    <button onClick={() => proSubmit()} className='p_btn p_btn_normal mgl10'>신청</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ProjectParti;