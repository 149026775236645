// src/Recoil/TokenAtom.js

import { atom, selector } from "recoil";

// sessionStorage에서 저장된 토큰을 가져옵니다.
const storedToken = sessionStorage.getItem('accessToken');

export const TokenAtom = atom({
    key: 'Token',
    default: storedToken || undefined,
});

export const isLoginSelector = selector({
    key: 'isLoginSelector',  // 고유한 키를 사용하여 선택자를 정의합니다.
    get: ({ get }) => !!get(TokenAtom),
});