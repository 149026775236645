import React, { useState, useEffect } from 'react';
import axios from 'axios';
import styles from './Login.module.scss';
import { useSetRecoilState } from 'recoil';
import { TokenAtom } from '../../Recoil/TokenAtom';
import { FaUser, FaLock } from 'react-icons/fa';
import common from '../../common';
import { useLocation, useNavigate } from 'react-router-dom';

import { useDispatch } from 'react-redux';
import { setLoginInfo } from '../../store/userSlice';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
function Login() {
    const { pChkbox, useAlert } = common();
    const { pAlert, AlertComponent } = useAlert(); // 훅 사용

    const navigate = useNavigate();
    const location = useLocation();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [rememberEmail, setRememberEmail] = useState(false);
    const setAccessToken = useSetRecoilState(TokenAtom);

    const dispatch = useDispatch();

    useEffect(() => {
        // 로컬 스토리지에서 저장된 이메일을 불러옵니다.
        const savedEmail = localStorage.getItem('savedEmail');
        if (savedEmail) {
            setEmail(savedEmail);
            setRememberEmail(true);
        }
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(`${API_BASE_URL}/naek_login/out/login`, { email, password }, {
                withCredentials: true
            });
            if (response.data.result === 't') {
                setAccessToken(response.data.token);
                localStorage.setItem('accessToken', response.data.token); // localStorage에 저장
                // 이메일 저장 처리
                if (rememberEmail) {
                    localStorage.setItem('savedEmail', email);
                } else {
                    localStorage.removeItem('savedEmail');
                }




                dispatch(setLoginInfo(response.data.data));


                const redirectedFrom = location.state?.redirectedFrom?.pathname || '/';
                navigate(redirectedFrom, { replace: true });
            } else {
                pAlert(response.data.msg);
            }
        } catch (error) {
            console.error('Login failed:', error);
            pAlert('로그인에 실패했습니다. 다시 시도해주세요.');
        }
    };

    const handleRememberEmailChange = (e) => {
        const checked = e.target.checked;
        setRememberEmail(checked);
        if (checked) {
            localStorage.setItem('savedEmail', email);
        } else {
            localStorage.removeItem('savedEmail');
        }
    };

    const idPwButtonClick = () => {
        navigate('/findIdPw');
    };

    useEffect(() => {
        if (rememberEmail) {
            localStorage.setItem('savedEmail', email);
        }
    }, [email, rememberEmail]);

    return (
        <>
            <AlertComponent />
            <div className={`${styles.login_wrap} flex_center`}>
                <div className={styles.login_box}>
                    <div className={styles.login_inner_box}>
                        <div className={styles.lg_input_wrap}>
                            <form onSubmit={handleSubmit}>
                                <div className={(styles.lg_input_box)}>
                                    <FaUser className={styles.c_icon} />
                                    <input
                                        type="text"
                                        autoFocus
                                        placeholder='이메일'
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                    />
                                </div>
                                <div className={(styles.lg_input_box)}>
                                    <FaLock className={styles.c_icon} />
                                    <input
                                        type="password"
                                        placeholder='비밀번호'
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                    />
                                </div>
                                <div className="login_wrap p_chkbox_box">
                                    <label className="login_wrap p_chkbox">
                                        <input
                                            type="checkbox"
                                            checked={rememberEmail}
                                            onChange={handleRememberEmailChange}
                                            onClick={(e) => pChkbox(e.target.parentElement)} />
                                        <span>이메일 저장하기</span>
                                    </label>
                                </div>
                                <div className={styles.lg_btn_box}>
                                    <button type="submit" className={styles.login_input_btn}><span className='f_bigger f_400 f_white'>로그인</span></button>
                                </div>
                            </form>
                            <div className={styles.cont_txt_box}>
                                {/* <button className={`${styles.cont_txt} f_small`}>비밀번호 이메일전송</button> */}
                                <button className={`${styles.cont_txt} ${styles.idpw_cont_txt} `} onClick={idPwButtonClick}>ID / PW 찾기</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Login;