
import React, { useState, useEffect, useMemo } from 'react';
import axios from 'axios';
import common from '../../../common';

import { TableList } from "../../../components";
import styles from './Dept.module.scss';
import 'primeicons/primeicons.css';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

function ChairmanContact() {
    const { useAlert, useLoading } = common();
    const { pAlert, AlertComponent } = useAlert();
    const { pLoadingOn, pLoadingOff, LoadingComponent } = useLoading();
    const [userData, setUserData] = useState([]);//
    
    // const data = useMemo(() => [
    //     { member_name: '장형식', member_duty: '전문위원', member_organ: '회장실', email: 'hschang@naek.or.kr' },
    //     { member_name: '김효진', member_duty: '책임연구원', member_organ: '회장실', email: 'hyojin@naek.or.kr' },
    // ], []);
    
    const getMember = async () => {
        try {
            pLoadingOn();
            const response = await axios.post(`${API_BASE_URL}/naek_organization/out/get_list`,
                {
                    committee_category: '회장실',
                    committee_name: '전체'
                },
                { withCredentials: true }
            );
            if (response && response.data && response.data.result === 't') {
                // setGetData(response.data.data)
                setUserData(response.data.data)
            } else {
                pAlert(response.data.msg);
            }
        } catch (error) {
            console.error('실패:', error);
        } finally {
            setTimeout(() => {
                pLoadingOff();
            }, 300);
        }
    };
    useEffect(() => {
        getMember();
    }, []);


    const columns = useMemo(() => [
        { title: '', dataIndex: 'idx', className: 'display_none' },
        { title: '성명', dataIndex: 'name', className: '' },
        // { title: '직위', dataIndex: 'member_duty', className: '' },
        { title: '소속', dataIndex: 'organization', className: '' },
        { title: '이메일', dataIndex: 'email', className: '' },
    ], []);
    const pageSize = 10;
    const categoryOptions = useMemo(() => [], []);


    return (
        <>
            <div className="components_wrap">
                <div className={styles.contact_flexwrap}>
                <div className={`${styles.contact_half} ${styles.border_bottom_none}`}>
                    <div className={styles.contactIconWrap}>
                            <div className={styles.contactI3}></div>
                        </div>
                        <div className={styles.contactText}>
                            <span>이메일</span>
                            <p>hschang@naek.or.kr</p>
                        </div>
                    </div>
                    <div className={`${styles.contact_half} ${styles.border_bottom_none}`}>
                        <div className={styles.contactIconWrap}>
                            <div className={styles.contactI2}></div>
                        </div>
                        <div className={styles.contactText}>
                            <span>팩스</span>
                            <p>02-6009-4019</p>
                        </div>
                    </div>
                </div>
                <div className={styles.member_info_text}>직원 안내</div>
                <div className={styles.table_list_box}>
                    <TableList data={userData} columns={columns} pageSize={pageSize} categoryOptions={categoryOptions} type='organization' />
                </div>
            </div>
        </>
    );
}

export default ChairmanContact;








