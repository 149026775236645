import React, { useState, useRef, useEffect, useMemo } from 'react';
import axios from 'axios';
import common from '../../../common';
import { useNavigate } from 'react-router-dom';

import styles from './MembersSearch.module.scss';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Paginator } from 'primereact/paginator';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
const FingerprintJS = require('@fingerprintjs/fingerprintjs');

function MembersSearch({ isLogin }) {
    const { useAlert, useLoading } = common();
    const { pAlert, AlertComponent } = useAlert();
    const { pLoadingOn, pLoadingOff, LoadingComponent } = useLoading();
    const navigate = useNavigate();
    const [getData, setGetData] = useState([]);//
    const [getDataLen, setGetDataLen] = useState(0);//
    const [isPosibleSearch, setIsPosibleSearch] = useState(false);//

    const [formData, setFormData] = useState({
        memberNameAl: '',
        memberGradeName: '',
        letterDepart: '',
        keyword: '',
        keywordType: 'a',
        page: 1
    });

    const [first, setFirst] = useState(0);
    const [rows, setRows] = useState(10);

    const onPageChange = (event) => {
        setFirst(event.first);
        setRows(event.rows);
        setFormData(prevData => ({ ...prevData, page: event.page + 1 }));
    };
    useEffect(() => {
        if(isPosibleSearch){
            onSearch();
        }
    }, [formData.page]);
    
    const onSearchStart = (e) => {
        setIsPosibleSearch(true);
        onSearch();
    };
    const onSearch = async () => {
        const fp = await FingerprintJS.load();
        const result = await fp.get();
        const visitorId = result.visitorId;
        try {
            pLoadingOn();
            const response = await axios.post(`${API_BASE_URL}/naek_memberssearch/out/get_result`,
                {
                    il: isLogin,
                    v: visitorId,
                    a: formData.memberNameAl,
                    g: formData.memberGradeName,
                    ld: formData.letterDepart,
                    k: formData.keyword,
                    ktype: formData.keywordType,
                    p: formData.page,
                },
                { withCredentials: true }
            );
            if (response && response.data && response.data.result === 't') {
                setGetDataLen(response.data.data.total_count);
                setGetData(response.data.data.page_data);
            } else {
                pAlert(response.data.msg);
            }
        } catch (error) {
            console.error('실패:', error);
        } finally {
            setTimeout(() => {
                pLoadingOff();
            }, 300);
        }
    };
    const onRowSelect = async (e) => {
        const selectedRowId=e.value.idx;
        navigate(`/userdetail?id=${selectedRowId}`); // 원하는 경로로 이동
    };

    const checkboxesRef = useRef([]);
    const handleReset = () => {
        setFormData(prevData => ({
            memberNameAl: '',
            memberGradeName: '',
            letterDepart: '',
            keyword: '',
            keywordType: '',
            page: 1
        }));
        setGetDataLen(0);
        setGetData([]);
        setIsPosibleSearch(false);
        checkboxesRef.current.forEach(checkbox => {
            if (checkbox) checkbox.checked = false;
        });
    };
    const alphabets = ['ㄱ', 'ㄴ', 'ㄷ', 'ㄹ', 'ㅁ', 'ㅂ', 'ㅅ', 'ㅇ', 'ㅈ', 'ㅊ', 'ㅋ', 'ㅌ', 'ㅍ', 'ㅎ', 'ENGLISH'];
    // const memberTypes = ['정회원', '일반회원', '원로회원', '외국회원', '비회원', '기타'];
    const memberTypes = ['정회원', '일반회원', '원로회원', '외국회원'];
    const committees = ['전기전자공학분과', '기계공학분과', '건설환경공학분과', '화학생물공학분과', '재료자원공학분과', '기술경영정책분과', '컴퓨팅분과', '바이오메디컬분과'];

    return (
        <>
            <AlertComponent />
            <LoadingComponent />
            <div className="components_wrap">
                <div className={styles.search_wrap}>
                    <div className={`${styles.search_box} ${styles.refresh_btn_box}`}>
                        <button className={styles.refresh_btn} onClick={handleReset}>
                            초기화<i className={styles.f5}></i>
                        </button>
                    </div>
                    <div className={styles.search_box}>
                        <span className={styles.search_title}>자음</span>
                        <div className={styles.alphabet_wrap}>
                            {alphabets.map((alphabet, index) => (
                                <label className={`${styles.p_chkbox} ${styles.alphabet_label}`} key={index}>
                                    <input
                                        // type="checkbox"
                                        type="radio"
                                        name="alphabet"
                                        ref={el => (checkboxesRef.current[index] = el)}
                                        // onClick={() => handleAlphabetClick(alphabet)}
                                        onChange={(e) => setFormData((prevData) => ({ ...prevData, memberNameAl: alphabet }))}
                                    />
                                    <span>{alphabet}</span>
                                </label>
                            ))}
                        </div>
                    </div>
                    <div className={styles.search_box}>
                        <span className={styles.search_title}>회원구분</span>
                        <div className={styles.member_type_wrap}>
                            {memberTypes.map((type, index) => (
                                <label className="p_chkbox" key={index + alphabets.length}>
                                    <input
                                        // type="checkbox"
                                        type="radio"
                                        name="memberType"
                                        ref={el => (checkboxesRef.current[index + alphabets.length] = el)}
                                        // onClick={() => handleMemberTypeClick(type)}
                                        onChange={(e) => setFormData((prevData) => ({ ...prevData, memberGradeName: type }))}
                                        value={type}
                                    />
                                    <span>{type}</span>
                                </label>
                            ))}
                        </div>
                    </div>
                    <div className={styles.search_box}>
                        <span className={styles.search_title}>전문분과위원회</span>
                        <div className={styles.committee_wrap}>
                            {committees.map((committee, index) => (
                                <label className={`p_chkbox ${styles.committee_chkbox}`} key={index + alphabets.length + memberTypes.length}>
                                    <input
                                        // type="checkbox"
                                        type="radio"
                                        name="committee"
                                        ref={el => (checkboxesRef.current[index + alphabets.length + memberTypes.length] = el)}
                                        // onClick={() => handleCommitteeClick(committee)}
                                        onChange={(e) => setFormData((prevData) => ({ ...prevData, letterDepart: committee }))}
                                        value={committee}
                                    />
                                    <span>{committee}</span>
                                </label>
                            ))}
                        </div>
                    </div>
                </div>

                <div className={styles.member_search_table_input_wrap}>
                    <div className={styles.member_search_table_input_box}>
                        <select
                            className={styles.p_sel}
                            onChange={(e) => setFormData((prevData) => ({ ...prevData, keywordType: e.target.value }))}
                        >
                            <option value="a">전체</option>
                            <option value="n">성명</option>
                            <option value="o">소속기관</option>
                            <option value="s">학력</option>
                            <option value="ld">전문분야</option>
                        </select>
                        <input
                            className={styles.p_sel}
                            type="text"
                            value={formData.keyword || ''}
                            placeholder="검색어를 입력해주세요."
                            onChange={(e) => setFormData((prevData) => ({ ...prevData, keyword: e.target.value }))}
                        />
                        <button
                            className={styles.p_btn}
                            onClick={onSearchStart}
                        >
                        </button>
                    </div>
                </div>

                <div className={styles.member_search_table_wrap}>
                    <span className={styles.result_count}>
                        회원수: <span className={styles.color_blue}>{getDataLen}</span> 명
                    </span>
                    <div className='naek_table'>
                        <DataTable
                            value={getData}
                            selectionMode="single"
                            dataKey="idx"
                            onSelectionChange={onRowSelect}
                            emptyMessage="검색 조건과 일치하는 회원이 존재하지 않습니다." // 메시지 변경
                        >
                            <Column field="memberName" header="성명" bodyClassName="text-center"></Column>
                            <Column field="organization" header="소속" bodyClassName="text-center"></Column>
                            <Column field="position" header="직위" bodyClassName="text-center"></Column>
                        </DataTable>
                        <Paginator first={first} rows={rows} totalRecords={getDataLen} onPageChange={onPageChange} />
                    </div>
                </div>
            </div>
        </>
    );
}

export default MembersSearch;