import React, { useState } from 'react';

import axios from 'axios';
import common from '../../../common';

import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

import styles from './MyCertificate.module.scss';
import { useNavigate } from 'react-router-dom';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

function MyCertificate() {

    
    const navigate = useNavigate();

	const { useAlert, useLoading } = common();
	const { pAlert, AlertComponent } = useAlert();
	const { pLoadingOn, pLoadingOff,LoadingComponent} = useLoading();

    const [showCertificate, setShowCertificate] = useState(false);
    const [certificateData, setCertificateData] = useState(null);

    const downloadPDF = async () => {
        try {
            pLoadingOn();
            
			const response = await axios.post(`${API_BASE_URL}/naek_mycertificate/in/get_cerNum`,{ lantype: certificateData.lantype }, { withCredentials: true });
            if (response && response.data && response.data.result === 't') {
                setCertificateData((prevData) => ({ ...prevData, cerNum: response.data.data }));

                setTimeout(() => {
                    const element = document.getElementById('pdf-content');
                    // html2canvas(element).then((canvas) => {
                    html2canvas(element, { scale: 2 }).then((canvas) => {
                        const imgData = canvas.toDataURL('image/png');
                        const pdf = new jsPDF('p', 'mm', 'a4');
                        const imgWidth = 210;
                        const pageHeight = 295;
                        const imgHeight = (canvas.height * imgWidth) / canvas.width;
                        let heightLeft = imgHeight;
                        let position = 0;
    
                        // pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
                        pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight, undefined, 'FAST'); // FAST, MEDIUM

                        heightLeft -= pageHeight;
    
                        // while (heightLeft >= 0) { // 2번째 페이지가 나와버림
                        //     position = heightLeft - imgHeight;
                        //     pdf.addPage();
                        //     pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
                        //     heightLeft -= pageHeight;
                        // }
                        pdf.save('certificate_'+response.data.data+'.pdf');
                    });
                }, 300);
            } else {
                
                console.error(response.data);
				pAlert(response.data.msg);
            }
        } catch (error) {
            console.error('실패:', error);
        } finally {
            setTimeout(() => {
                pLoadingOff();
            }, 300);
        }



        
    };
    const getCertificate = async (lantype) => {
        try {
            pLoadingOn();
			const response = await axios.post(`${API_BASE_URL}/naek_mycertificate/in/get_detail`,{ lantype: lantype }, { withCredentials: true });

            if (response && response.data && response.data.result === 't') {
                setCertificateData(response.data.data);
                setCertificateData((prevData) => ({ ...prevData, lantype: lantype, cerNum: '[PDF 저장하기] 시 발급번호가 부여됩니다.' }));
                
                setShowCertificate(true);
            } else {
                navigate(`/login`);
                console.error(response.data);
				pAlert(response.data.msg);
            }
        } catch (error) {
            console.error('실패:', error);
        } finally {
            setTimeout(() => {
                pLoadingOff();
            }, 300);
        }
    };
    const getTodayDate = () => {
        const today = new Date();
        const year = today.getFullYear();
        const month = String(today.getMonth() + 1).padStart(2, '0');
        const day = String(today.getDate()).padStart(2, '0');
        return `${year}. ${month}. ${day}`;
    };
    return (
        <>
            <AlertComponent />
            <LoadingComponent />
            <div className="components_wrap">
                <button
                    className={`p_btn p_btn_normal ${styles.getCertificate_btn}`}
                    onClick={() => getCertificate('K')}>회원확인증(국문)</button>
                <button
                    className={`p_btn p_btn_normal mgl20 ${styles.getCertificate_btn}`}
                    onClick={() => getCertificate('E')}>회원확인증(영문)</button>
                
                {showCertificate && certificateData && (
                    <>
                    <div className={styles.result_tit_box}>
                        <span className='f_normal'>아래 내용을 확인하신 뒤 하단 PDF 저장하기를 누르면 발급번호가 부여됩니다.</span>
                    </div>
                        <div className={styles.result_wrap}>
                            <div className={styles.pdf_wrap}>
                                {certificateData.lantype === 'K' && (
                                    <div className={styles.pdf} id="pdf-content" >
                                        <div className={styles.li}>
                                            <div className={styles.cerTitle}>
                                                <span className={styles.cerNum}>발급번호 : {certificateData.cerNum}</span>
                                                <div className={styles.mark}></div>
                                                <h3>회원확인증</h3>
                                                <h4>Certificate of Membership</h4>
                                            </div>
                                            <div className={styles.cerWrap}>
                                                <h5>회원정보</h5>
                                                <table className={styles.certable}>
                                                    <tbody>
                                                        <tr>
                                                            <th>성 　　　　　 명</th>
                                                            <td>{certificateData.nameKR}</td>
                                                        </tr>
                                                        <tr>
                                                            <th>소 속　 및 　직 위</th>
                                                            <td>{certificateData.organizationKR} {certificateData.positionKR}</td>
                                                        </tr>
                                                        <tr>
                                                            <th>생　 년 　월 　일</th>
                                                            <td>{certificateData.birth}</td>
                                                        </tr>
                                                        <tr>
                                                            <th>회　 원　 번　 호</th>
                                                            <td>{certificateData.memberNumber}</td>
                                                        </tr>
                                                        <tr>
                                                            <th>회　 원　 구　 분</th>
                                                            <td>{certificateData.gradeNameKR}</td>
                                                        </tr>
                                                        <tr>
                                                            <th>전　 문　 분　 과</th>
                                                            <td>{certificateData.departNameKR}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        <div className={`${styles.cerWrap} ${styles.li}`}>
                                            <div className={styles.cerNote}>
                                                <p>위 사람이 한국공학한림원 {certificateData.gradeNameKR}임을 확인함.</p>
                                                <p className={`${styles.mt10} ${styles.date}`}>{getTodayDate()}</p>
                                                <p className={`${styles.mt20} ${styles.last}`}>한국공학한림원 회장 김기남<span className={styles.sign}></span></p>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                
                                {certificateData.lantype === 'E' && (
                                    <div className={`${styles.pdf} ${styles.en}`} id="pdf-content" >
                                        <div className={styles.li}>
                                            <div className={styles.cerTitle}>
                                                <span className={styles.cerNum}>No : [PDF 저장하기] 시 발급번호가 부여됩니다.</span>
                                                <div className={styles.mark}></div>
                                                <h3>Certificate of Membership</h3>
                                            </div>
                                            <div className={styles.cerWrap}>
                                                <h5>Member Information</h5>
                                                <table className={styles.certable}>
                                                    <tbody>
                                                        <tr>
                                                            <th>Name</th>
                                                            <td>{certificateData.nameEN}</td>
                                                        </tr>
                                                        <tr>
                                                            <th>Affiliation &amp; Position</th>
                                                            <td>{certificateData.organizationEN} {certificateData.positionEN}</td>
                                                        </tr>
                                                        <tr>
                                                            <th>Date of Birth</th>
                                                            <td>{certificateData.birth}</td>
                                                        </tr>
                                                        <tr>
                                                            <th>Member ID</th>
                                                            <td>{certificateData.memberNumber}</td>
                                                        </tr>
                                                        <tr>
                                                            <th>Membership</th>
                                                            <td>{certificateData.gradeNameEN}</td>
                                                        </tr>
                                                        <tr>
                                                            <th>Divisions</th>
                                                            <td>{certificateData.departNameEN}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        <div className={`${styles.cerWrap} ${styles.li}`}>
                                            <div className={`${styles.cerNote} ${styles.eng}`}>
                                                <p>This is to certify that <span></span> has been granted a {certificateData.gradeNameEN} of The National Academy of Engineering of Korea.</p>
                                                <p className={`${styles.mt10} ${styles.date}`}>{getTodayDate()}</p>
                                                <p className={`${styles.mt20} ${styles.last}`}>President.<br/><span>Dr. Kinam Kim</span><span className={styles.sign}></span></p>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div className={styles.downloadPDF_box}>
                                <button className='p_btn p_btn_normal p_btn_blue' onClick={downloadPDF}>PDF 저장하기</button>
                            </div>
                        </div>
                    </>
                )}
            </div>
        </>
    );
}

export default MyCertificate;