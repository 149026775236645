//사업안내>국제교류 및 협력
import React, { useState, useEffect, useMemo, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { TableList } from "../../../components";
import styles from './InternationalExchange.module.scss';
import common from '../../../common';

import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

function InternationalExchange() {
    const { useAlert,useConfirm, useLoading } = common();
    const { pAlert, AlertComponent } = useAlert();
    const { pConfirm, ConfirmComponent} = useConfirm();
    const { pLoadingOn, pLoadingOff, LoadingComponent } = useLoading();



    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const pageid = queryParams.get('pageid');

    const [selectedData, setSelectedData] = useState([]);


    const [data1 , setData1] = useState([]);
    const [data2 , setData2] = useState([]);
    const [data3 , setData3] = useState([]);
    const [data4 , setData4] = useState([]);
    const [data5 , setData5] = useState([]);
    const [data6 , setData6] = useState([]);
    const [data7 , setData7] = useState([]);


    const categoryData = ['27','28','145','146','263','264','265'];

    const fetchData = async () => {
        try {
            pLoadingOn();
            const response = await axios.post(`${API_BASE_URL}/naek_document/out/get_table`, {categoryData:categoryData}, {
                withCredentials: true
            });


            if (response.data.result === 't') {
                const allData = response.data.data;

                const newData1 = [];
                const newData2 = [];
                const newData3 = [];
                const newData4 = [];
                const newData5 = [];
                const newData6 = [];
                const newData7 = [];
    
                allData.forEach(item => {
                    switch (item.documentCategoryIDX) {
                        case 27:
                            newData1.push(item);
                            break;
                        case 28:
                            newData2.push(item);
                            break;
                        case 145:
                            newData3.push(item);
                            break;
                        case 146:
                            newData4.push(item);
                            break;
                        case 263:
                            newData5.push(item);
                            break;
                        case 264:
                            newData6.push(item);
                            break;
                        case 265:
                            newData7.push(item);
                            break;
                        default:
                            console.warn(`Unexpected category: ${item.documentCategoryIDX}`);
                    }
                });
    
                setData1(newData1);
                setData2(newData2);
                setData3(newData3);
                setData4(newData4);
                setData5(newData5);
                setData6(newData6);
                setData7(newData7);
                
            } else {
                pAlert(response.data.msg);;
            }
        } catch (error) {
            console.error('Fetch failed:', error);
            pAlert('실패함.');
        } finally {
            pLoadingOff();
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const columns = useMemo(() => [
        { title: '제목', dataIndex: 'title', className: 'p_td_title p_td_w_85' },
        { title: '날짜', dataIndex: 'createTime', className: 'p_td_date' },
    ], []);
    const pageSize = 10;
    const categoryOptions = useMemo(() => [
        { label: '전체', value: '전체' },
        { label: '제목', value: 'title' },
        { label: '내용', value: 'contents' },
    ], []);

    const [returnTableList, setReturnTableList] = useState(null);

    useEffect(() => {
        let selectedData = [];
        switch (pageid) {
            case '1':
                selectedData = data1;
                break;
            case '2':
                selectedData = data2;
                break;
            case '3':
                selectedData = data3;
                break;
            case '4':
                selectedData = data4;
                break;
            case '5':
                selectedData = data5;
                break;
            case '6':
                selectedData = data6;
                break;
            case '7':
                selectedData = data7;
                break;
            default:
                selectedData = data1;
        }
        setSelectedData(selectedData);
        setReturnTableList(
            <TableList
                key={pageid}
                data={selectedData}
                columns={columns}
                pageSize={pageSize}
                categoryOptions={categoryOptions}
                type="Editor"
            />
        );
    }, [pageid, columns, data1, data2, data3, data4, data5, data6, data7, pageSize, categoryOptions]);

    // =================================

    const overviewRef = useRef(null);
    const forumHistoryRef = useRef(null);

    const handleButtonClick = (section) => {
        if (section === 'overview' && overviewRef.current) {
            window.scrollTo({
                top: overviewRef.current.offsetTop - 150,
                behavior: 'smooth',
            });
        } else if (section === 'forumHistory' && forumHistoryRef.current) {
            window.scrollTo({
                top: forumHistoryRef.current.offsetTop - 150,
                behavior: 'smooth',
            });
        }
    };

    return (
        <>
            <div className="components_wrap">
                <div className={styles.tab_btn_box} ref={overviewRef}>
                    <button
                        className={`${styles.tab_btn} ${styles.active}`}
                        onClick={() => handleButtonClick('overview')}
                    >
                        개요
                    </button>
                    <button
                        className={`${styles.tab_btn}`}
                        onClick={() => handleButtonClick('forumHistory')}
                    >
                        역대 사업 소개
                    </button>
                </div>

                {/* ==================== 목적 ==================== */}

                <div className={styles.cont_wrap}>
                    <div className={styles.cont_tit_box}>
                        <span>목적</span>
                    </div>
                    <div className={styles.cont_box}>
                        <ul className={styles.bul_list}>
                        {pageid === '1' &&
                            <>
                                <li>세계공학한림원 회원국들과 각국의 산업/공학기술 아젠다에 대한 협의 및 글로벌 공학기술 리더십 확보</li>
                            </>
                        }
                        {pageid === '2' &&
                            <>
                                <li>한중일 모두 경제 성장이 둔화되는 가운데 국가 경쟁력 향상과 경제적 기회 활용을 위해 3국간의 기술 동향을 공유하고 지속적인 협력 체계를 구축</li>
                                <li>한중일 유망기술 발굴 및 공동 대응책 마련을 위한 한중일 협력사업 추진</li>
                            </>
                        }
                        {pageid === '3' &&
                            <>
                                <li>한·호 에너지 분야의 기술혁신 동향 파악, 연구 및 산업 협력 분야 발굴</li>
                                <span className={`${styles.noteYellow} ${styles.m_t_20}`}>에너지강국인 호주와 첨단 산업 제조국인 한국의 공통 관심사이자 다양한 기술 교류가 가능한 에너지 분야, 특히 수소 산업을 중심으로 기술 현황 및 주요 이슈를 검토하고, 향후 정책 및 전략 수립에 반영</span>
                            </>
                        }
                        {pageid === '4' &&
                            <>
                                <li>한국과 아세안 공학한림원의 인적네트워크를 활용한 장기적 협력관계 구축</li>
                                <li>한-아세안 고위급 산업계 및 공학기술인 협의체제 구축·운영 및 산업교류 활성화</li>
                            </>
                        }
                        {pageid === '5' &&
                            <>
                                <li>한국과 중국의 공통 관심 분야에 대한 기술현황과 전략 공유를 통한 한-중 공학기술계 지도자들 간 협력 네트워크 강화</li>
                            </>
                        }
                        {pageid === '6' &&
                            <>
                                <li>한국과 영국 간 핵심 산업분야를 중심으로 정책, 기술, 비즈니스 등 다각적인 교류를 통해 실질적인 산업 발전 및 시장 성장 도모</li>
                            </>
                        }
                        {pageid === '7' &&
                            <>
                                <li>한·인도 기술혁신 동향 파악 및 연구 및 산업 협력 분야 발굴</li>
                            </>
                        }
                        </ul>
                    </div>
                </div>

                {/* ==================== 효과 ==================== */}

                <div className={styles.cont_wrap}>
                    <div className={styles.cont_tit_box}>
                        <span>효과</span>
                    </div>
                    <div className={styles.cont_box}>
                        <ul className={styles.bul_list_2}>
                        {pageid === '1' &&
                            <>
                                <li>세계공학한림원과의 네트워크 구축 및 공학한림원의 정체성 제고</li>
                                <li>공학분야의 범국가적 안건 및 각국의 기술현황, 도전과제 등 토의</li>
                            </>
                        }
                        {pageid === '2' &&
                            <>
                                <li>동북아 지역 내 지속적인 민간차원 지식 네트워크를 운영하여 미래 산업기술 경쟁력 제고에 기여</li>
                            </>
                        }
                        {pageid === '3' &&
                            <>
                                <li>기술보유국인 한국과 자원보유국인 호주 간의 협력을 통해 에너지 신산업 분야의 시너지 창출을 위하여 각국의 기술 현황 공유</li>
                                <li>한국-호주의 기업, 대학, 연구소의 기술개발 및 사업화 사례를 공유하고 新시장을 창출하는 기회 모색</li>
                                <li>호주와의 협력 플랫폼 역할로 에너지 생산, 수송, 활용 등의 분야에서 비즈니스 모델 도출</li>
                            </>
                        }
                        {pageid === '4' &&
                            <>
                                <li>한-아세안 고위급 전문가들 간 기술협력 및 정책교류를 통한 공동 R&D 프로젝트 발굴 및 정책제언 기능 강화</li>
                                <li>한-아세안 기업 간 기술 및 비즈니스 교류를 통해 한국 기업의 아세안 시장 진출 및 글로벌 네트워크 구축에 기여</li>
                            </>
                        }
                        {pageid === '5' &&
                            <>
                                <li>주요 공학기술 분야에 대한 중국의 기술수준 및 연구개발 현황 파악을 통해 세계적인 기술 강국으로 도약하기 위한 전략과 인사이트 도출</li>
                                <li>한-중 기업 간 기술 및 비즈니스 교류를 통한 산업계 협력 강화</li>
                            </>
                        }
                        {pageid === '6' &&
                            <>
                                <li>핵심 분야의 정책, 기술, 비즈니스 교류를 통한 양국 간 호혜적인 비즈니스 파트너십 및 가치 사슬 구축</li>
                            </>
                        }
                        {pageid === '7' &&
                            <>
                                <li>양국의 관심사이자 다양한 기술 교류가 가능한 첨단재료 등 핵심 분야를 중심으로 기술 현황 및 주요 이슈를 검토하고, 향후 정책 및 전략 수립에 반영</li>
                                <li>첨단재료 및 소재 기술, 통신, 의생명과학, 항공우주공학, 방위, 환경공학 등 분야와 융합을 시도</li>
                            </>
                        }
                        </ul>
                    </div>
                </div>

                {/* ==================== 활동 내용 ==================== */}

                <div className={styles.cont_wrap}>
                    <div className={styles.cont_tit_box}>
                        <span>활동 내용</span>
                    </div>
                    <div className={styles.cont_box}>
                        <table className={styles.cont_table}>
                            <tbody>
                                {pageid === '1' &&
                                    <>
                                        <tr>
                                            <th>심포지엄</th>
                                            <td>
                                                <span>31개 회원국이 로테이션으로 개최(2020년 한국 개최)· ‘더 나은 세상을 위한 공학’이라는 대주제 아래 매년 새로운 의제를 선정· 심포지엄 결과를 반영한 성명서 발행</span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>총회</th>
                                            <td>
                                                <span>31개 전회원국 임원진 참석 · CAETS 운영국의 전년도 재무감사보고서 승인 및 현 재무상태 검토 · 차년도 주최국 및 의제 선정· 이사회 구성원 지정</span> 
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>CAETS 에너지위원회</th>
                                            <td>
                                                <span className={styles.p_b_10}>CAETS 워킹그룹 중 가장 지속적이고 활발히 운영됨. 20개국 이상이 참여하여 각국 에너지 정책 및 현황 비교 보고서를 2년 주기로 발행함.</span> 
                                                <span>2022년에는 호스트인 프랑스공학한림원의 주도로 약 530페이지 분량의 보고서를 발행</span> 
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>Working Group</th>
                                            <td>
                                                <span className={styles.p_b_10}>CAETS 참석 회원들이 심포지엄 개최 전 원하는 주제의 소그룹에 참여</span> 
                                                <span>* Communications, Energy, Sustainable Development Goal, Diversity, Education</span> 
                                            </td>
                                        </tr>
                                    </>
                                }
                                {pageid === '2' &&
                                    <>
                                        <tr>
                                            <th>심포지엄</th>
                                            <td>
                                                <span>한-중-일 로테이션으로 개최하며 주제 관련 기술동향 공유</span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>라운드테이블미팅</th>
                                            <td>
                                                <span>3상태 검토 · 차년도 주최국 및 의제 선정· 이사회 구성원 지정</span> 
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>산업시찰</th>
                                            <td>
                                                <span>심포지엄과 연계한 기업·연구기관·대학 방문</span> 
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>설문조사</th>
                                            <td>
                                                <span>한중일 기술협력 인식조사로 한중일 주요 현안에 대한 인식 공유 및 공동 대응책 마련</span> 
                                            </td>
                                        </tr>
                                    </>
                                }
                                {pageid === '3' &&
                                    <>
                                        <tr>
                                            <th>심포지엄</th>
                                            <td>
                                                <span className={styles.p_b_10}>- 로테이션으로 개최하며 주제 관련 기술동향 공유</span>
                                                <span>- 한국 공학기술 발전과정을 공유하고, 기술교류, 투자진흥을 통해 동반성장, 지속발전 가능한 오픈이노베이션 협력활동 추진</span>
                                            </td>
                                        </tr>
                                    </>
                                }
                                {pageid === '4' &&
                                    <>
                                        <tr>
                                            <th>한-아세안 엔지니어링 포럼</th>
                                            <td>
                                                <span className={styles.p_b_10}>- 아세안공학한림원과 연례 포럼 로테이션 개최 중(아세안 4회, 한국 1회)</span>
                                                <span>- 아세안공학한림원(ASEAN Academy of Engineering and Technology): 아세안 10개 국가 대표 엔지니어 250여명으로 구성된 전문가 단체</span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>한-아세안 수처리 워킹그룹</th>
                                            <td>
                                                <span className={styles.p_b_10}>- 한-아세안 각국의 수처리 기술, 연구활동 및 정책 현황을 공유하고 상호협력 가능성을 모색하기 위해 2023년에 구성된 전문가 그룹</span>
                                                <span>- 연 2회 세미나 개최, 7개국 20여 명의 전문가 참여 중</span>
                                            </td>
                                        </tr>
                                    </>
                                }
                                {pageid === '5' &&
                                    <>
                                        <tr>
                                            <th>한-중 공학기술발전포럼</th>
                                            <td>
                                                <span className={styles.p_b_10}>- 2023년부터 매년 로테이션으로 개최하기로 함</span>
                                                <span>- 제1회 한-중 공학기술발전포럼은 ‘AI Technology and Application’을 주제로 중국 항저우에서 개최됨(기간: 2023. 7. 25 ~ 27)</span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>현지 산업체 방문</th>
                                            <td>
                                                <span>- 현지 핵심 산업체 방문 및 교류를 통한 기술 및 산업협력 기회 모색</span>
                                            </td>
                                        </tr>
                                    </>
                                }
                                {pageid === '6' &&
                                    <>
                                        <tr>
                                            <th>Korea-UK Policy and Technology Forum on Net Zero</th>
                                            <td>
                                                <span>- 양국 간 청정에너지(수소, 원자력 등) 분야의 협력을 강화하기 위해 매년 정책기술 포럼을 개최하기로 함</span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>전문가 네트워크 구축을 위한 비즈니스 미팅 추진</th>
                                            <td>
                                                <span>- 포럼 참석자들을 중심으로 관련 기업 및 기관과의 미팅 추진을 통해 장기적인 협력 및 시장 진출 지원</span>
                                            </td>
                                        </tr>
                                    </>
                                }
                                {pageid === '7' &&
                                    <>
                                        <tr>
                                            <th>워크숍</th>
                                            <td>
                                                <span className={styles.p_b_10}>- 국제협력위원회를 통해 세부주제 및 일정 협의</span>
                                                <span className={styles.p_b_10}>- 인도공학한림원과 추진일정 협의 및 조율</span>
                                                <span>- 워크숍 개최 후 한-인도 공학한림원 공동성명서 작성 및 발표</span>
                                            </td>
                                        </tr>
                                        
                                    </>
                                }
                            </tbody>
                        </table>
                    </div>
                </div>

                <div className={`${styles.homepage_btn_box} ${styles.m_b_40}`}>
                    {pageid === '1' &&
                        <>
                            <a href="https://www.newcaets.org/" target='_blank' className={styles.homepage_btn} rel="noreferrer">
                                <span>세계공학한림원평의회(CAETS)</span>
                                <span>홈페이지 바로가기</span>
                            </a>
                        </>
                    }
                    {pageid === '2' &&
                        <>
                            <a href="https://en.cae.cn/cae/html/en/index.html" target='_blank' className={styles.homepage_btn} rel="noreferrer">
                                <span>중국공정원(CAE)</span>
                                <span>홈페이지 바로가기</span>
                            </a>
                            <a href="https://www.eaj.or.jp/" target='_blank' className={styles.homepage_btn} rel="noreferrer">
                                <span>일본공학한림원(EAJ)</span>
                                <span>홈페이지 바로가기</span>
                            </a>
                        </>
                    }
                    {pageid === '3' &&
                        <>
                            <a href="https://www.atse.org.au/" target='_blank' className={styles.homepage_btn} rel="noreferrer">
                                <span>호주공학한림원(ATSE)</span>
                                <span>홈페이지 바로가기</span>
                            </a>
                        </>
                    }
                    {pageid === '4' &&
                        <>
                            <a href="https://aaet-asean.org/" target='_blank' className={styles.homepage_btn} rel="noreferrer">
                                <span>아세안공학한림원(AAET)</span>
                                <span>홈페이지 바로가기</span>
                            </a>
                        </>
                    }
                    {pageid === '5' &&
                        <>
                            <a href="https://en.cae.cn/cae/html/en/index.html" target='_blank' className={styles.homepage_btn} rel="noreferrer">
                                <span>중국공정원(CAE)</span>
                                <span>홈페이지 바로가기</span>
                            </a>
                        </>
                    }
                    {pageid === '6' &&
                        <>
                            <a href="https://raeng.org.uk/" target='_blank' className={styles.homepage_btn} rel="noreferrer">
                                <span>영국공학한림원(RAEng)</span>
                                <span>홈페이지 바로가기</span>
                            </a>
                        </>
                    }
                    {pageid === '7' &&
                        <>
                            <a href="https://www.inae.in/" target='_blank' className={styles.homepage_btn} rel="noreferrer">
                                <span>인도공학한림원(INAE)</span>
                                <span>홈페이지 바로가기</span>
                            </a>
                        </>
                    }
                </div>
                <div className={`${styles.tab_btn_box} ${styles.m_t_b_100}`} ref={forumHistoryRef}>
                    <button
                        className={`${styles.tab_btn}`}
                        onClick={() => handleButtonClick('overview')}
                    >
                        개요
                    </button>
                    <button
                        className={`${styles.tab_btn} ${styles.active}`}
                        onClick={() => handleButtonClick('forumHistory')}
                    >
                        역대 사업 소개
                    </button>
                </div>
                {returnTableList}
            </div>
        </>
    );
}

export default InternationalExchange;