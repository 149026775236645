import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './OrderNumCheck.module.scss';
import common from '../../../../common';
import { FaUser, FaLock } from 'react-icons/fa';
import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

function OrderNumCheck() {
    const { useModal, useAlert } = common();
    const { pModal, ModalComponent } = useModal();
    const { pAlert, AlertComponent } = useAlert();
    const navigate = useNavigate();

    const [orderId, setOrderId] = useState('');

    const handleNewClick = () => {
        navigate('candidateList/?type=new');
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(`${API_BASE_URL}/naek_toss_payments/out/my_payments_detail`, {
                orderId,
            }, {
                withCredentials: true
            });
            if (response.data.result === 't') {
                navigate('/paymenthistory', { state: response.data.data });
            } else {
                pAlert(response.data.msg);
            }
        } catch (error) {
            console.error('Login failed:', error);
            pAlert('데이터 불러오기가 실패 하였습니다. 관리자에게 문의 부탁드립니다.');
        }
    };

    return (
        <>
            <ModalComponent />
            <AlertComponent />
            <form onSubmit={handleSubmit}>
                <div className={styles.lg_input_box}>
                    <input
                        type="text"
                        autoFocus
                        placeholder="주문번호"
                        value={orderId}
                        onChange={(e) => setOrderId(e.target.value)}
                    />
                </div>
                <div className={styles.lg_btn_box}>
                    <button type="submit" className={styles.login_input_btn}>
                        <span className="f_bigger f_400 f_white">내역확인</span>
                    </button>
                </div>
            </form>
            <span className={styles.noteMini}>
                이메일로 전송받은 주문번호를 입력해 주세요.
            </span>
        </>
    );
}

export default OrderNumCheck;
