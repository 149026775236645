import React, { useState, useEffect, useRef  } from 'react';
import { useLocation } from 'react-router-dom';
import './Detail.scss';
import common from '../../common'; 
import { FaDownload } from 'react-icons/fa';

import { useSelector , useDispatch } from 'react-redux';

import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

function Detail({ isLogin }) {
    const loginInfo = useSelector((state) => state.user.loginInfo);
    const loginName = loginInfo.name;

    const { useAlert, useConfirm, useModal, useLoading } = common();
    const { pAlert, AlertComponent } = useAlert();
    const { pModal, ModalComponent } = useModal();
    const { pConfirm, ConfirmComponent} = useConfirm();
    const { pLoadingOn, pLoadingOff, LoadingComponent } = useLoading();
    
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const targetIdx = queryParams.get('id');

    const [data , setData] = useState([]);
    const [files, setFiles] = useState([]);

    const [memberDepartChk, setMemberDepartChk] = useState(false);
    
    const [cateIdx, setCateIdx] = useState(0);

    const [commentPack, setCommentPack] = useState([]);

    const fetchData = async () => {
        try {
            pLoadingOn();
            const response = await axios.post(`${API_BASE_URL}/naek_document/out/get_detail`, { targetIdx:targetIdx }, {
                withCredentials: true
            });
            if (response.data.result === 't') {
                setData(response.data.data);
                setFiles(response.data.data.file);
                if(response.data.data.memberDepart===true){
                    setMemberDepartChk(true);
                }
                setCateIdx(response.data.data.cateIdx);
                
                if(response.data.data.commentPack){
                    setCommentPack(response.data.data.commentPack);
                }

                const titEl = document.querySelector('.top_only_big_tit');
                if (titEl) {
                    titEl.textContent = response.data.data.documentCategoryName;
                }
                const titEl2 = document.querySelector('.mo_hd_page_tit');
                if (titEl2) {
                    titEl2.textContent = response.data.data.documentCategoryName;
                }

            } else {
                pAlert(response.data.msg);
            }
        } catch (error) {
            console.error('Fetch failed:', error);
            pAlert('실패함.');
        } finally {
            pLoadingOff();
        }
    };








    // 회원 전문분과위원회 --

    const [memberDepartEditStat , setMemberDepartEditStat] = useState(false);
    const memberDepartEditChk = async () => {
        try {
            pLoadingOn();
            const response = await axios.post(`${API_BASE_URL}/naek_member_depart/in/chk_edit_possible`,
                { doc:targetIdx },
                { withCredentials: true }
            );
            if (response.data.result === 't' && response.data.msg===true) {
                setMemberDepartEditStat(true);
            } else {
                setMemberDepartEditStat(false);
            }
        } catch (error) {
            console.error('Fetch failed:', error);
            pAlert('실패함.');
        } finally {
            pLoadingOff();
        }
    };
    
    const gogoMdList = async () => {
        window.location.href = '/memberDepart?pageid='+cateIdx.toString();
    };
    const gogopage = async () => {
        try {
            pLoadingOn();
            const response = await axios.post(`${API_BASE_URL}/naek_member_depart/in/chk_edit_possible`,
                { doc:targetIdx },
                { withCredentials: true }
            );
            if (response.data.result === 't' && response.data.msg===true) {
                window.location.href = response.data.data;
            }
        } catch (error) {
            console.error('Fetch failed:', error);
            pAlert('실패함.');
        } finally {
            pLoadingOff();
        }
    };

    const [MdCommentWriteStat , setMdCommentWriteStat] = useState(false);
    const memberDepartCommentWriteChk = async () => {
        try {
            pLoadingOn();
            const response = await axios.post(`${API_BASE_URL}/naek_member_depart/in/chk_write_possible`,
                { pageid:cateIdx.toString() },
                { withCredentials: true }
            );

            if (response.data.result === 't' && response.data.msg===true) {
                setMdCommentWriteStat(true);
            } else {
                setMdCommentWriteStat(false);
            }
        } catch (error) {
            console.error('Fetch failed:', error);
            pAlert('실패함.');
        } finally {
            pLoadingOff();
        }
    };

    useEffect(() => {
        if(isLogin && memberDepartChk){
            memberDepartEditChk();
            memberDepartCommentWriteChk();
        }
    }, [memberDepartChk]);
    
    
    const [taVal, setTaVal] = useState("");
    const taRef = useRef(null);
    const taChange = (e) => {
        setTaVal(e.target.value);
        taRef.current.style.height = "auto";
        taRef.current.style.height = `${taRef.current.scrollHeight}px`;
    };

    const gogoComment = (e) => {
        pConfirm(
            '댓글 등록',
            <>
                <div>댓글을 등록 하시겠습니까?</div>
            </>,
            '400px',
            'auto',
            async () => {
                try {
                    pLoadingOn();
                    const response = await axios.post(`${API_BASE_URL}/naek_document/in/comment_insert`,
                        { targetIdx:targetIdx, contents:taVal }, {
                        withCredentials: true
                    });

                    if (response.data.result === 't') {
                        fetchData();
                        pAlert('댓글 등록이 완료되었습니다.');
                        setTaVal('');
                    } else {
                        pAlert(response.data.msg);
                    }
                } catch (error) {
                    console.error('Data fetching failed:', error);
                } finally {
                    pLoadingOff();
                }
            }
        );
    };
    const gogoCommentDel = (coIdx) => {
        pConfirm(
            '댓글 삭제',
            <>
                <div>댓글을 삭제 하시겠습니까?</div>
            </>,
            '400px',
            'auto',
            async () => {
                try {
                    pLoadingOn();
                    const response = await axios.post(`${API_BASE_URL}/naek_document/in/comment_status_update`,
                        { targetIdx:coIdx }, {
                        withCredentials: true
                    });

                    if (response.data.result === 't') {
                        // fetchData();
                        setCommentPack((prevComments) =>
                            prevComments.filter((comment) => comment.idx !== coIdx)
                        );
                        pAlert('댓글이 삭제되었습니다.');
                        setTaVal('');
                    } else {
                        pAlert(response.data.msg);
                    }
                } catch (error) {
                    console.error('Data fetching failed:', error);
                } finally {
                    pLoadingOff();
                }
            }
        );
    };
    
    // 회원 전문분과위원회 --

    




    









    useEffect(() => {
        fetchData();
    }, []);

    const handleFileDownload = async (file) => {
		if (file.isLocal) {
            pLoadingOn();
			const link = document.createElement('a');
			link.href = file.serverName;
			link.download = file.name;
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
            pLoadingOff();
		} else {
			try {
				pLoadingOn();
				const response = await axios.post(`${API_BASE_URL}/naek_document/out/get_file`, {
                    idx: [file.idx],
                    serverName: file.serverName,
                    name: file.name
                }, {
                    withCredentials: true,
                    responseType: 'blob'  // 응답 타입을 blob으로 설정
                });

                if (response.status === 200) { // HTTP 응답 상태 코드가 200인 경우
                    const blob = response.data;
                    const downloadUrl = window.URL.createObjectURL(blob);

                    // Blob을 사용하여 파일 다운로드
                    const link = document.createElement('a');
                    link.href = downloadUrl;
                    link.download = file.name;
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);

                    // Blob URL을 해제
                    window.URL.revokeObjectURL(downloadUrl);
                } else {
                    pAlert('파일 다운로드에 실패했습니다.');
                }

			} catch (error) {
				pAlert(`파일다운에 실패했습니다. 다시 시도해 주세요.`);
			} finally {
				pLoadingOff();
			}
		}
	};

    function fileContent (){
        return (
            <div>
                {files.map((file, index) => (
                    <div key={index}>
                        <button
                            onClick={() => handleFileDownload(file)}
                            className='p_detail_file_txt'
                        >{file.name} <FaDownload /></button>
                    </div>
                ))}
            </div>
        );
    }
    

    return (
        <>
            <AlertComponent />
            <ConfirmComponent/>
            <LoadingComponent />
            <ModalComponent />
            <div className="components_wrap p_document_detail_wrap">
                <div className='p_detail_wrap'>
                    <div className='p_detail_header'>
                        <h1>{data.title}</h1>
                        <div className='p_detail_meta'>
                            {data.adminName && (
                                <span className='adminName'>{data.adminName}</span>
                            )}
                            <span className='date'>{data.createTime}</span>
                            <span className='views'><img src="/img/views.png" alt="views" />{data.view}</span>
                        </div>
                    </div>
                    {Array.isArray(data.file) && data.file.length > 0 ? (
                        <div className='p_detail_file_box'>
                            <button className='p_detail_file_down_btn' onClick={() => pModal('',fileContent,'auto','auto')}>
                                첨부파일({data.file.length})<i className='download_icon'></i>
                            </button>
                        </div>
                    ) : (
                        <div className='p_detail_file_box'>
                            {/* <span>해당 게시글은 첨부파일이 없습니다.</span> */}
                        </div>
                    )}
                        {/* 기타 내용 */}
                    <div className='p_detail_content' dangerouslySetInnerHTML={{ __html: data.contents }} />
                    
                    {memberDepartChk && (
                        <>
                            <div className='docu_comment_wrap'>
                                <span className='f_normal docu_comment_count'>댓글 <font className='f_bold'>{commentPack.length}</font></span>
                                {commentPack.length > 0 ? (
                                    <>
                                        
                                        <div className="docu_comment_list">
                                            {commentPack.map((comment) => (
                                                <div key={comment.idx} className="docu_comment_item">
                                                    <span className='f_small f_bold docu_c_i_name'>{comment.memberName} ({comment.organizationKR})</span>
                                                    <span className='f_small docu_c_i_con' dangerouslySetInnerHTML={{ __html: comment.contents.replace(/\n/g, '<br />') }}></span>
                                                    <span className='docu_c_i_reg'>{comment.createTime}</span>
                                                    {comment.my === 'my' && (
                                                        <button className={`p_btn  docu_c_i_del`} onClick={() => gogoCommentDel(comment.idx)} >삭제</button>
                                                    )}
                                                </div>
                                            ))}
                                        </div>
                                    </>
                                ) : (
                                    <div>
                                        {/* 등록된 댓글이 없습니다 */}
                                    </div>
                                )}

                                        
                                {MdCommentWriteStat && (
                                    <div className='docu_comment_write_wrap'>
                                        <span className='f_small docu_comment_write_myname'>{loginName}</span>
                                        <textarea
                                            className='p_txtarea'
                                            placeholder='댓글을 남겨보세요'
                                            ref={taRef}
                                            value={taVal}
                                            onChange={taChange}
                                        ></textarea>
                                        <button className={`p_btn p_btn_grey`} onClick={gogoComment}>등록</button>
                                    </div>
                                )}

                            </div>
                        </>
                    )}
                    
                    
                    {memberDepartChk && (
                        
                        <>
                            <div className='md_foot_right_btn_box'>
                                <button className={`p_btn p_btn_normal p_btn_grey md_foot_btn`} onClick={gogoMdList}>목록</button>
                                {memberDepartEditStat && (
                                    <>
                                        <button className={`p_btn p_btn_normal md_foot_btn`} onClick={gogopage}>수정</button>
                                    </>
                                )}
                            </div>
                        </>
                    )}
                </div>

            </div>
            
        </>
    );
}

export default Detail;
