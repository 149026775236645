// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Suggest-module__event_register_btn_box___PVL6S{display:block;padding:35px 0px;text-align:center}.Suggest-module__event_register_btn_box___PVL6S .Suggest-module__event_register_btn___kVUU3{font-size:24px;padding:16px 36px;height:auto;background:#327ecb;color:#fff;border:1px solid #327ecb;transition:.3s ease-out,-webkit- .3s ease-out}.Suggest-module__event_register_btn_box___PVL6S .Suggest-module__event_register_btn___kVUU3:hover{background:#1f5285;border:1px solid #1f5285}@media screen and (max-width: 768px){.Suggest-module__p_event_wrap___woCRR .Suggest-module__event_register_btn_box___PVL6S .Suggest-module__event_register_btn___kVUU3{width:100%;margin-top:8px;font-size:18px;padding:16px}}`, "",{"version":3,"sources":["webpack://./src/pages/Participation/Suggest/Suggest.module.scss"],"names":[],"mappings":"AAAA,gDACI,aAAA,CACA,gBAAA,CACA,iBAAA,CACA,4FACI,cAAA,CACA,iBAAA,CACA,WAAA,CACA,kBAAA,CACA,UAAA,CACA,wBAAA,CACA,6CAAA,CAGJ,kGACI,kBAAA,CACA,wBAAA,CAKR,qCAGY,kIACI,UAAA,CACA,cAAA,CACA,cAAA,CACA,YAAA,CAAA","sourcesContent":[".event_register_btn_box{\r\n    display: block;\r\n    padding: 35px 0px;\r\n    text-align: center;\r\n    .event_register_btn{\r\n        font-size: 24px;\r\n        padding: 16px 36px;\r\n        height: auto;\r\n        background: #327ECB;\r\n        color: #fff;\r\n        border: 1px solid #327ECB;\r\n        transition: .3s ease-out, -webkit- .3s ease-out;\r\n    }\r\n\r\n    .event_register_btn:hover{\r\n        background: #1f5285;\r\n        border: 1px solid #1f5285;\r\n    }\r\n}\r\n\r\n\r\n@media screen and (max-width: 768px) {\r\n    .p_event_wrap{\r\n        .event_register_btn_box{\r\n            .event_register_btn{\r\n                width: 100%;\r\n                margin-top: 8px;\r\n                font-size: 18px;\r\n                padding: 16px;\r\n            }\r\n        }\r\n    }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"event_register_btn_box": `Suggest-module__event_register_btn_box___PVL6S`,
	"event_register_btn": `Suggest-module__event_register_btn___kVUU3`,
	"p_event_wrap": `Suggest-module__p_event_wrap___woCRR`
};
export default ___CSS_LOADER_EXPORT___;
