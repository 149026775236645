//사업안내>공학기술진흥
import React, { useState, useEffect, useMemo, useRef } from 'react';
import styles from './Forum.module.scss';

function Forum() {
    return (
        <>
            <div className="components_wrap">


                <div className={styles.cont_wrap}>
                    <div className={styles.cont_box}>
                        <ul className={styles.bul_list_none}>
                            <li>The National Academy of Engineering of Korea holds various forums, seminars, and debates throughout the year on contemporary engineering and technological issues, government policy, economy, education, and other societal concerns. The Fourth Industrial Revolution, digitalization, and corporate strategy are among the most pressing challenges for sustainable growth and future prosperity in Korea.</li>
                        </ul>
                    </div>
                </div>


                <div className={styles.cont_wrap}>
                    <div className={styles.cont_tit_box}>
                        <span>NAEK Forum</span>
                    </div>
                    <div className={styles.cont_box}>
                        <ul className={styles.bul_list_none}>
                            <li>The NAEK Forum invites a keynote speaker to discuss his or her insights with NAEK members. The themes are pressing contemporary issues that cover multiple disciplines: engineering technology, industrial environment, education and government policy.</li>
                        </ul>
                    </div>
                </div>


                <div className={styles.cont_wrap}>
                    <div className={styles.cont_tit_box}>
                        <span>Korea Leaders Forum</span>
                    </div>
                    <div className={styles.cont_box}>
                        <ul className={styles.bul_list_none}>
                            <li>Prominent opinion leaders in Korea join the forum to discuss pressing national issues and propose optimal and practical policies for the government, as well as influential organizations and companies in the country. This forum will discuss how to overcome emerging crisis and create a turning point for the new normal.</li>
                        </ul>
                    </div>
                </div>


                <div className={styles.cont_wrap}>
                    <div className={styles.cont_tit_box}>
                        <span>CEO Breakfast Meeting</span>
                    </div>
                    <div className={styles.cont_box}>
                        <ul className={styles.bul_list_none}>
                            <li>The CEO Breakfast Meeting is exclusive to the members of the Business Executive Council and aims to assist industrial development by sharing knowledge on management skills, technology innovation, and market trends. It helps industries build networks to share contemporary issues in domestic and international markets. The meeting also strengthens ties with distinguished overseas institutions and elevates the status of Korean engineers and scientists.</li>
                        </ul>
                    </div>
                </div>

                <div className={styles.cont_wrap}>
                    <div className={styles.cont_tit_box}>
                        <span>IP Strategy Forum</span>
                    </div>
                    <div className={styles.cont_box}>
                        <ul className={styles.bul_list_none}>
                            <li>The IP Strategy Forum aims to promote patent-friendly strategies and policies of the government through in-depth discussions with opinion leaders, raise awareness of the significance of intellectual property policy, and strengthen the competitiveness of Korea’s intellectual property.</li>
                        </ul>
                    </div>
                </div>

                <div className={styles.cont_wrap}>
                    <div className={styles.cont_tit_box}>
                        <span>Future Land Forum</span>
                    </div>
                    <div className={styles.cont_box}>
                        <ul className={styles.bul_list_none}>
                            <li>The forum examines strategic and cooperative land planning for the reunification of the Korean peninsula. It covers land development related to infrastructure, industrial cooperation, education and employment, taking into account the economic, demographic, and social issues of North Korea and the differences between North and South. Under the theme of science and technology for land development on the Korean peninsula, it explores new land development strategies, policies, and national visions.</li>
                        </ul>
                    </div>
                </div>


            </div>
        </>
    );
}

export default Forum;