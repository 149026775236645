
import React, { useState, useEffect, useMemo, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { TableCard } from "../../../../components";
import styles from './Report.module.scss';
import common from '../../../../common';

import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

function Report() {
    const { useAlert,useConfirm, useLoading } = common();
    const { pAlert, AlertComponent } = useAlert();
    const { pConfirm, ConfirmComponent} = useConfirm();
    const { pLoadingOn, pLoadingOff, LoadingComponent } = useLoading();


    const [selectedData, setSelectedData] = useState([]);


    const [data , setData] = useState([]);


    const categoryData = ['152'];

    const fetchData = async () => {
        try {
            pLoadingOn();
            const response = await axios.post(`${API_BASE_URL}/naek_document/out/get_table`, {categoryData:categoryData}, {
                withCredentials: true
            });


            if (response.data.result === 't') {
                const data = response.data.data;

                setData(data);
            } else {
                pAlert(response.data.msg);;
            }
        } catch (error) {
            console.error('Fetch failed:', error);
            pAlert('실패함.');
        } finally {
            pLoadingOff();
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    // const data = useMemo(() => [
    //     { idx: '1', title: '2023 Annual Report', date: '2022.02.17', thumbnail: '/img/thumbnail_1.jpg', views: 121 },
    //     { idx: '2', title: '2022 Activity Report', date: '2022.12.10', thumbnail: '/img/thumbnail_2.jpg', views: 25 },
    //     { idx: '3', title: '2021 Activity Report', date: '2022.09.29', thumbnail: '/img/thumbnail_3.jpg', views: 524 },
    //     { idx: '4', title: '2020 Activity Report', date: '2022.09.29', thumbnail: '/img/thumbnail_4.jpg', views: 199999 },
    //     // ...other cards
    // ], []);
    
    const pageSize = 10;
    const categoryOptions = useMemo(() => [
        { label: 'Entire', value: '전체' },
        { label: 'Title', value: 'title' },
        { label: 'Content', value: '' },
    ], []);


    return (
        <>
            <div className="components_wrap">
                <div className={styles.tab_btn_box}>
                    <button
                        className={`${styles.tab_btn} ${styles.active}`}
                    >
                        Activity Report
                    </button>
                </div>
               
                <TableCard data={data} pageSize={pageSize} categoryOptions={categoryOptions} type="Report"  />
            </div>
        </>
    );
}

export default Report;