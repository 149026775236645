// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Notice-module__top_content_wrap___O867c{padding:20px 0px}.Notice-module__tab_box___lXhl6{display:flex;justify-content:center;font-size:18px;border-bottom:1px solid #cbcbcb;margin-bottom:50px}.Notice-module__tab_btn___l8Lxh{position:relative;padding:10px 18px}.Notice-module__tab_btn_active___wj9r1{color:#0c9ecc;font-weight:700}.Notice-module__tab_btn_active___wj9r1::after{content:"";display:block;width:100%;position:absolute;left:0;bottom:-2px;border-bottom:4px solid #02a4c8}@media screen and (max-width: 768px){.Notice-module__tab_box___lXhl6{font-size:16px !important;justify-content:space-around;text-align:center}.Notice-module__tab_btn___l8Lxh{width:50%}}`, "",{"version":3,"sources":["webpack://./src/pages/News/Notice/Notice.module.scss"],"names":[],"mappings":"AAAA,yCACI,gBAAA,CAGJ,gCACI,YAAA,CACA,sBAAA,CACA,cAAA,CACA,+BAAA,CACA,kBAAA,CAGJ,gCACI,iBAAA,CACA,iBAAA,CAGJ,uCACI,aAAA,CACA,eAAA,CAGJ,8CACI,UAAA,CACA,aAAA,CACA,UAAA,CACA,iBAAA,CACA,MAAA,CACA,WAAA,CACA,+BAAA,CAKJ,qCACI,gCACI,yBAAA,CACA,4BAAA,CACA,iBAAA,CAMJ,gCACI,SAAA,CAAA","sourcesContent":[".top_content_wrap{\r\n    padding: 20px 0px;\r\n}\r\n\r\n.tab_box{\r\n    display: flex;\r\n    justify-content: center;\r\n    font-size: 18px;\r\n    border-bottom: 1px solid #cbcbcb;\r\n    margin-bottom: 50px;\r\n}\r\n\r\n.tab_btn{\r\n    position: relative;\r\n    padding: 10px 18px;\r\n}\r\n\r\n.tab_btn_active{\r\n    color: #0C9ECC;\r\n    font-weight: 700;\r\n}\r\n\r\n.tab_btn_active::after{\r\n    content: '';\r\n    display: block;\r\n    width: 100%;\r\n    position: absolute;\r\n    left: 0;\r\n    bottom: -2px;\r\n    border-bottom: 4px solid #02A4C8;\r\n}\r\n\r\n\r\n\r\n@media screen and (max-width: 768px) {\r\n    .tab_box{\r\n        font-size: 16px !important;\r\n        justify-content: space-around;\r\n        text-align: center;\r\n    }\r\n\r\n    \r\n    \r\n    //임시\r\n    .tab_btn{\r\n        width: 50%;\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"top_content_wrap": `Notice-module__top_content_wrap___O867c`,
	"tab_box": `Notice-module__tab_box___lXhl6`,
	"tab_btn": `Notice-module__tab_btn___l8Lxh`,
	"tab_btn_active": `Notice-module__tab_btn_active___wj9r1`
};
export default ___CSS_LOADER_EXPORT___;
