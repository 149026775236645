import React, { useState, useEffect,useRef } from 'react';
import axios from 'axios';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useSetRecoilState, useRecoilValue } from 'recoil';
import { TokenAtom, isLoginSelector } from './Recoil/TokenAtom';

import common from './common';

import { useSelector , useDispatch } from 'react-redux';
import { clearLoginInfo } from './store/userSlice';



const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

function Header({ openLoginModal, menuSet }) {
    
	const { useAlert, useLoading } = common();
	const { pAlert, AlertComponent } = useAlert();
	const { pLoadingOn, pLoadingOff,LoadingComponent} = useLoading();

    const navigate = useNavigate();
    const setAccessToken = useSetRecoilState(TokenAtom);
    const isLogin = useRecoilValue(isLoginSelector); // 로그인 상태 가져오기
    const [activeMenu, setActiveMenu] = useState(null);
    const [isMypageVisible, setIsMypageVisible] = useState(false);

    const dispatch = useDispatch();

    const location = useLocation();
    const url = new URL(window.location.href);
    const _URLSearchParams = new URLSearchParams(url.search);

    const pageid = _URLSearchParams.get('pageid');
    const basePath = url.pathname;
    const currentPath = pageid ? `${basePath}?pageid=${pageid}` : basePath;

    const loginInfo = useSelector((state) => state.user.loginInfo);
    const loginName = loginInfo.name;

    const [HdSearchValue, setHdSearchValue] = useState('');
    const HdSearchInputRef = useRef(null);

    const hdSearchClear = () => {
        setHdSearchValue(''); // State 초기화
        HdSearchInputRef.current.value = ''; // 직접 입력 필드 초기화 (선택 사항)
        HdSearchInputRef.current.focus(); // 입력 필드에 포커스 (선택 사항)
    };
    const onHdSearch = () => {
        const searchValue = HdSearchInputRef.current.value.trim(); // 입력값의 앞뒤 공백 제거
        if (searchValue.length < 2) {
            pAlert('검색어는 최소 2글자 이상이어야 합니다.');
            return;
        }
        navigate(`/integrationsearch?t=${searchValue}`);
    };
    
    const [MoHdSearchValue, setMoHdSearchValue] = useState('');
    const MoHdSearchInputRef = useRef(null);
    const onMoHdSearch = () => {
        const searchValue = MoHdSearchInputRef.current.value.trim(); // 입력값의 앞뒤 공백 제거
        if (searchValue.length < 2) {
            pAlert('검색어는 최소 2글자 이상이어야 합니다.');
            return;
        }
        toggleMoSearch();
        navigate(`/integrationsearch?t=${searchValue}`);
    };

    const findMenuItem = (path, menu) => {
        let midTitStatus = false;
        const result = (function search(menu) {
            for (let item of menu) {
                if (item.subMenu) {
                    for (let subItem of item.subMenu) {
                        if (subItem.subMenu) {
                            for (let subSubItem of subItem.subMenu) {
                                if (subSubItem.link === path) {


                                    if (subSubItem.midTitboxImg) {
                                        midTitStatus = true;
                                    }
                                    return { grandparent: item, parent: subItem, item: subSubItem };
                                }
                            }
                        }
                        if (subItem.link === path) {
                            if (subItem.midTitboxImg) {
                                midTitStatus = true;
                            }
                            return { grandparent: item, parent: null, item: subItem };
                        }
                    }
                }
                if (item.link === path) {
                    return { grandparent: null, parent: null, item: item };
                }
            }
            return null;
        })(menu);

        return { midTitStatus, result };
    };
    const { midTitStatus, result: menuItem } = findMenuItem(currentPath, menuSet);
    // if (midTitStatus === true) {
    //     //
    // }
    

    const toggleMypage = () => {
        setIsMypageVisible(!isMypageVisible);
    };

    const handleLogout = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(`${API_BASE_URL}/naek_login/out/logout`, { }, {
                withCredentials: true
            });
            setAccessToken(undefined);
            localStorage.removeItem('accessToken'); // sessionStorage 토큰 제거
            navigate('/');
            dispatch(clearLoginInfo());
            toggleMoGnbClose();
            // if (response.data.result === 't') {
            if (response && response.data && response.data.result === 't') {
            } else {
                pAlert(response.data.msg);
            }
        } catch (error) {
            console.error('Login failed:', error);
            pAlert('오류가 발생하였습니다. 새로고침 후 다시 시도해주세요.');
        }
    };

    const handleMouseEnter = (menu) => {
        // setActiveMenu(menu);
        const moNavWrapSlide = document.querySelector('.mo_nav_wrap_slide');
        if (moNavWrapSlide && moNavWrapSlide.classList.contains('open')) {
            //
        } else {
            setActiveMenu(menu);
        }
    };

    const handleMouseLeave = () => {
        // setActiveMenu(null);
        const moNavWrapSlide = document.querySelector('.mo_nav_wrap_slide');
        if (moNavWrapSlide && moNavWrapSlide.classList.contains('open')) {
            //
        } else {
            setActiveMenu(null);
        }
    };

    const handleLinkClick = () => {
        setActiveMenu(null);
        toggleMoGnbClose();
        toggleMoLnbClose();
        const moSearchWrap = document.querySelector('.mo_search_wrap');
        const searchBg = document.querySelector('.mo_search_bg');
        const searchBtn = document.querySelector('.mo_search_btn');
        if (moSearchWrap && moSearchWrap.classList.contains('open')) {
            moSearchWrap.classList.remove('open');
            searchBg.classList.remove('open');
            searchBtn.classList.remove('open');
        }
    };

    const toggleMoSearch = () => {
        const moSearchWrap = document.querySelector('.mo_search_wrap');
        const searchBg = document.querySelector('.mo_search_bg');
        const searchBtn = document.querySelector('.mo_search_btn');

        if (moSearchWrap.classList.contains('open')) {
            moSearchWrap.classList.remove('open');
            searchBg.classList.remove('open');
            searchBtn.classList.remove('open');
        } else {
            moSearchWrap.classList.add('open');
            searchBg.classList.add('open');
            searchBtn.classList.add('open');
        }
    };

    const handleMoNavArrowClick = (menu) => {
        if (activeMenu === menu) {
            const subMenu = document.querySelector('.submenu');
            if (subMenu && subMenu.classList.contains('open')) {
                subMenu.classList.remove('open');
            }
            const timer = setTimeout(() => {
                setActiveMenu(null);
            }, 0); // 1초 딜레이
            return () => clearTimeout(timer); // 컴포넌트 언마운트 시 타이머 클리어
        } else {
            setActiveMenu(menu);
        }
    };
    useEffect(() => {
        if (activeMenu !== null) {
            const timer = setTimeout(() => {
                const subMenu = document.querySelector('.submenu');
                if (subMenu) {
                    subMenu.classList.add('open');
                }
            }, 0); // 1초 딜레이
            return () => clearTimeout(timer); // 컴포넌트 언마운트 시 타이머 클리어
        }
    }, [activeMenu]);
    const toggleMoGnb = () => {
        const moNavWrapSlide = document.querySelector('.mo_nav_wrap_slide');
        const navBg = document.querySelector('.mo_nav_bg');

        if (moNavWrapSlide.classList.contains('open')) {
            toggleMoGnbClose();
        } else {
            moNavWrapSlide.classList.add('open');
            navBg.classList.add('open');
        }
    };
    const toggleMoGnbClose = () => {
        const moNavWrapSlide = document.querySelector('.mo_nav_wrap_slide');
        if (moNavWrapSlide && moNavWrapSlide.classList.contains('open')) {
            moNavWrapSlide.classList.remove('open');
            const navBg = document.querySelector('.mo_nav_bg');
            if (navBg && navBg.classList.contains('open')) {
                navBg.classList.remove('open');
            }
            const subMenu = document.querySelector('.submenu');
            if (subMenu && subMenu.classList.contains('open')) {
                subMenu.classList.remove('open');
                setActiveMenu(null);
            }
        }
    };

    const toggleMoLnb = () => {
        const moLnbWarp = document.querySelector('.top_lnb_wrap');
        const moLnbI = document.querySelector('.mo_lnb_icon');

        if (moLnbWarp.classList.contains('open')) {
            toggleMoLnbClose();
        } else {
            moLnbWarp.classList.add('open');
            moLnbI.classList.add('open');
        }
    };
    const toggleMoLnbClose = () => {
        const moLnbWarp = document.querySelector('.top_lnb_wrap');
        if (moLnbWarp && moLnbWarp.classList.contains('open')) {
            moLnbWarp.classList.remove('open');
            const moLnbI = document.querySelector('.mo_lnb_icon');
            if (moLnbI && moLnbI.classList.contains('open')) {
                moLnbI.classList.remove('open');
            }
        }
    };



    const isPathEnglish = location.pathname.startsWith('/en');

    return (
        <>
            <AlertComponent />
            <LoadingComponent />
            <header className="header_wrap">
                <div className="header_top_box flex_between">
                    <div className="logo_box">
                        {isPathEnglish ? (
                            <>
                                <Link className='logo_eng' to={'/en'}></Link>
                            </>
                        ):(
                            <>
                                <Link className='logo' to={'/'}></Link>
                            </>
                        )}
                        
                    </div>
                    <ul className="hd_top_right ">
                        <div className="hd_top_info_wrap display_flex">
                            {isPathEnglish ? (
                                <li>
                                    <button
                                        className="korean_btn"
                                        onClick={() => {
                                            navigate('/');
                                        }}
                                    >
                                        한국어
                                    </button>
                                </li>
                            ) : (
                                <>
                                {isLogin ? (
                                    <li className="islogin_wrap">
                                        <ul className='islogin_box display_flex'>
                                            <li className='login_li'>
                                                <button onClick={toggleMypage}>마이페이지</button>
                                                {isMypageVisible && (
                                                    <ul className='mypage_wrap'>
                                                        <li className='mypage_li'>
                                                            <Link to={'/mypage'} onClick={() => setIsMypageVisible(false)}>내 정보</Link>
                                                        </li>
                                                        <li className='mypage_li'>
                                                            <Link to={'/calendar?pageid=2'} onClick={() => setIsMypageVisible(false)}>행사 참석</Link>
                                                        </li>
                                                        <li className='mypage_li'>
                                                            <Link to={'/mycertificate'} onClick={() => setIsMypageVisible(false)}>회원확인증</Link>
                                                        </li>
                                                    </ul>
                                                )}
                                            </li>
                                            <li><button onClick={handleLogout} className="logout_btn">로그아웃</button></li>
                                        </ul>
                                    </li>
                                ) : (
                                    <button onClick={openLoginModal} className="login_btn">로그인</button>
                                )}
                                <li>
                                    <button
                                        className="login_btn"
                                        onClick={() => {
                                            navigate('/payment');
                                        }}
                                    >회비결제</button>
                                </li>
                                <li>
                                    <button
                                        className="english_btn"
                                        onClick={() => {
                                            navigate('/en');
                                        }}
                                    >ENGLISH</button>
                                </li>
                                </>
                            )}
                        </div>
                        {/* <div className="hd_top_info_wrap_m">
                    </div> */}
                    </ul>
                    <div className='header_top_mo_center_wrap'>
                        {menuItem && menuItem.item && menuItem.item.name && (
                            <span className='mo_hd_page_tit'>{menuItem.item.name}</span>
                        )}
                    </div>
                    <div className='header_top_mo_right_wrap'>

                        {!isPathEnglish ? (
                            <button className='mo_search_btn' onClick={toggleMoSearch}></button>
                        ):null}
                        <button className='mo_nav_btn' onClick={toggleMoGnb}>
                            <span></span>
                            <span></span>
                            <span></span>
                        </button>
                    </div>
                </div>
                <div className='mo_nav_bg' onClick={toggleMoGnb}></div>
                <div className='header_bot_box mo_nav_wrap_slide'>
                    <div className='mo_nav_top_box'>
                        <div className='mo_nav_top_info_box'>
                            {isLogin ? (
                                <>
                                    <span className='mo_nav_top_info'>{loginName}님 환영합니다.</span>
                                    {/* <span className='mo_nav_top_info'>환영합니다.</span> */}
                                </>
                            ) : (
                                <>
                                    <Link className='mo_nav_top_info_btn' onClick={openLoginModal}>로그인해주세요.</Link>
                                </>
                            )}
                            <button className='mo_nav_close_btn' onClick={toggleMoGnb}></button>
                        </div>
                    </div>
                    <ul className='hd_nav_box mo_nav_wrap' onMouseLeave={handleMouseLeave}>
                        {
                            isLogin && (
                                <li className='mo_nav_li_info nav_1_li' key='moNavInfo'>
                                    <button className={`nav_1_btn_arrow ${activeMenu === 'moNavInfo' ? 'open' : ''}`} onClick={() => handleMoNavArrowClick('moNavInfo')}></button>
                                    <Link className={`nav_1_btn ${activeMenu === 'moNavInfo' ? 'open' : ''}`} onClick={() => handleMoNavArrowClick('moNavInfo')}>마이페이지</Link>
                                    {activeMenu === 'moNavInfo' && (
                                        <ul className="submenu">


                                            <li className="nav_2_li">
                                                <Link className='nav_2_btn' to='/mypage' onClick={handleLinkClick}>내 정보</Link>
                                            </li>
                                            <li className="nav_2_li">
                                                <Link className='nav_2_btn' to='/calendar?pageid=2' onClick={handleLinkClick}>행사 참석</Link>
                                            </li>
                                            <li className="nav_2_li">
                                                <Link className='nav_2_btn' to='/payment' onClick={handleLinkClick}>회비 납부</Link>
                                            </li>
                                            <li className="nav_2_li">
                                                <Link className='nav_2_btn' to='/' onClick={handleLogout}>로그아웃</Link>
                                            </li>
                                        </ul>

                                    )}
                                </li>
                            )
                        }
                        {
                            menuSet.map((item) => (
                                (item.gnbView !== false) && (
                                    <li className='nav_1_li' key={item.idTxt}>
                                        <button className={`nav_1_btn_arrow ${activeMenu === item.idTxt ? 'open' : ''}`} onClick={() => handleMoNavArrowClick(item.idTxt)}></button>
                                        <Link className='nav_1_btn' to={item.link} onMouseEnter={() => handleMouseEnter(item.idTxt)} onClick={handleLinkClick}>{item.name}</Link>
                                        {activeMenu === item.idTxt && item.subMenu && (
                                            <ul className="submenu">
                                                {item.subMenu.map((subItem) => (
                                                    (subItem.gnbView !== false) && (
                                                        <li className="nav_2_li" key={subItem.id}>
                                                            <Link className='nav_2_btn' to={subItem.link} onClick={handleLinkClick}>{subItem.name}</Link>
                                                            {subItem.subMenu && (
                                                                <ul className="sub_2_menu">
                                                                    {subItem.subMenu.map((subItem3cha) => (
                                                                        (subItem3cha.gnbView !== false) && (
                                                                            <li className="nav_3_li" key={subItem3cha.id}>
                                                                                <Link className='nav_3_btn' to={subItem3cha.link} onClick={handleLinkClick}>{subItem3cha.name}</Link>
                                                                            </li>
                                                                        )
                                                                    ))}
                                                                </ul>
                                                            )}
                                                        </li>
                                                    )
                                                ))}
                                            </ul>
                                        )}
                                    </li>
                                )
                            ))
                        }
                    </ul>

                    {!isPathEnglish ? (
                        <div className="hd_sh_box">
                            <div className="hd_search">
                                <input
                                    type="text"
                                    placeholder="검색어를 입력하세요."
                                    value={HdSearchValue}
                                    onChange={(e) => setHdSearchValue(e.target.value)}
                                    ref={HdSearchInputRef}
                                />
                                <Link className='hd_search_clear' onClick={hdSearchClear}></Link>
                                <button onClick={onHdSearch} style={{ backgroundImage: 'url(/img/i-search.png)' }}></button>
                            </div>
                        </div>
                    ):null}
                </div>

                <div className='mo_search_bg' onClick={toggleMoSearch}></div>
                <div className="mo_search_wrap">
                    <div className="hd_search">
                        <input
                            type="text"
                            placeholder="검색어를 입력하세요."
                            value={MoHdSearchValue}
                            onChange={(e) => setMoHdSearchValue(e.target.value)}
                            ref={MoHdSearchInputRef}
                        />
                        {/* <Link className='hd_search_clear' onClick='#'></Link> */}
                        <button onClick={onMoHdSearch} style={{ backgroundImage: 'url(/img/i-search.png)' }}></button>
                    </div>
                </div>
            </header>
            <div>
                {
                    menuItem ? (
                        <>
                            <div className="breadcrumb_wrap">
                                <div className='pc_breadcrumb_wrap'>
                                    {menuItem.grandparent && (
                                        <>
                                            <span className='bc_home'><Link className='bc_home_btn' to='/'></Link></span>
                                            <span className='bc_gp'>{menuItem.grandparent.name}</span>
                                            {menuItem.parent && (
                                                <span className='bc_pa'>{menuItem.parent.name}</span>
                                            )}
                                            <span className='bc_currents'>{menuItem.item.name}</span>
                                        </>
                                    )}
                                    {!menuItem.grandparent && !menuItem.parent && (
                                        // 1차만 있는 경우. 이런건 없겠지
                                        // <span>{menuItem.item.name}</span>
                                        ''
                                    )}
                                </div>
                            </div>
                            <div className='top_tit_wrap'>
                                {
                                    menuItem.item.name != '조직도' && (
                                        !midTitStatus ? (
                                            <>
                                                <span className='top_only_big_tit'>{menuItem.item.name}</span>
                                            </>
                                        ) : (
                                            <>
                                                <div className='top_tit_subbox_wrap' style={{ backgroundImage: `url('../../img/${menuItem.item.midTitboxImg}')` }}>
                                                    <span className='t_t_sb_big_tit'>{menuItem.item.name}</span>
                                                    <span className='t_t_sb_description' dangerouslySetInnerHTML={{ __html: menuItem.item.midTitSub }} />
                                                </div>
                                            </>
                                        )
                                    )
                                }
                            </div>
                            {
                                midTitStatus && menuItem.parent && menuItem.parent.subMenu && (
                                    <>
                                        <div className='mo_lnb_tit_box' onClick={toggleMoLnb}>
                                            <button className='mo_lnb_tit_btn'>{menuItem.item.name}</button>
                                            <span className='mo_lnb_icon'></span>
                                        </div>
                                        <div className='top_lnb_wrap'>
                                            <ul className='top_lnb_ul'>
                                                {menuItem.parent.subMenu.map(subItem => (
                                                    (subItem.lnbView !== false) && (
                                                        <li key={subItem.id} className={`top_lnb_item ${currentPath === subItem.link ? 'active' : ''}`} onClick={handleLinkClick}>
                                                            <Link className='top_lnb_item_btn' to={subItem.link}>{subItem.name}</Link>
                                                        </li>
                                                    )
                                                ))}
                                            </ul>
                                        </div>
                                    </>
                                )
                            }
                        </>
                    ) : (
                        <>
                            {/* 홈이라는 뜻인가 */}
                        </>
                    )
                }
            </div>
        </>
    );
}

export default Header;
