import styles from './Direction.module.scss';
import { Map, MapMarker, CustomOverlayMap } from "react-kakao-maps-sdk";

function Direction() {
    const center = { lat: 37.5032976, lng: 127.0436475 };
    return (
        <div className="components_wrap">
            <Map
                center={center}
                style={{ width: "100%", height: "760px" }}
            >
                <MapMarker position={center} />
                <CustomOverlayMap position={center}>
                    <div className={styles.overlay}>
                        <div className={styles.overlay_content}>
                            <h4>한국공학한림원</h4>
                            <p>서울특별시 강남구 테헤란로 305 <br />한국기술센터 15층</p>
                            <a href="https://map.kakao.com/link/to/한국공학한림원,37.5032976,127.0436475" target="_blank" rel="noopener noreferrer">
                                길찾기
                            </a>
                        </div>
                    </div>
                </CustomOverlayMap>
            </Map>
        </div>
    );
}

export default Direction;