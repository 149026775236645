//사업안내>공학기술진흥
import React, { useState, useEffect, useMemo, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { TableCard } from "../../../components";
import styles from './PromotionEngineering.module.scss';
import common from '../../../common';

import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

function PromotionEngineering() {
    const { useAlert, useConfirm, useLoading } = common();
    const { pAlert, AlertComponent } = useAlert();
    const { pConfirm, ConfirmComponent } = useConfirm();
    const { pLoadingOn, pLoadingOff, LoadingComponent } = useLoading();


    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const pageid = queryParams.get('pageid');




    // ########################  20241031 주석 ########################
    // const categoryData = ['24'];

    // const fetchData = async () => {
    //     try {
    //         pLoadingOn();
    //         const response = await axios.post(`${API_BASE_URL}/naek_document/out/get_table`, { categoryData: categoryData }, {
    //             withCredentials: true
    //         });

    //         if (response.data.result === 't') {
    //             const allData = response.data.data;
    //             setData(allData);
    //         } else {
    //             pAlert(response.data.msg);;
    //         }
    //     } catch (error) {
    //         console.error('Fetch failed:', error);
    //         pAlert('실패함.');
    //     } finally {
    //         pLoadingOff();
    //     }
    // };

    // useEffect(() => {
    //     fetchData();
    // }, []);

    // const [returnTableCard, setReturnTableCard] = useState(null);

    // useEffect(() => {
    //     setReturnTableCard(
    //         <TableCard data={data} pageSize={pageSize} categoryOptions={categoryOptions} type="Editor" />
    //     );


    // }, [pageid, data, pageSize, categoryOptions]);
    // ########################  20241031 주석 ########################


    const [selectedData, setSelectedData] = useState([]);

    const [data1 , setData1] = useState([]);
    const [data5 , setData5] = useState([]);


    const categoryData = ['24','25'];

    const fetchData = async () => {
        try {
            pLoadingOn();
            const response = await axios.post(`${API_BASE_URL}/naek_document/out/get_table`, {categoryData:categoryData}, {
                withCredentials: true
            });


            if (response.data.result === 't') {
                const allData = response.data.data;

                const newData1 = [];
                const newData5 = [];

                allData.forEach(item => {
                    switch (item.documentCategoryIDX) {
                        case 24:
                            newData1.push(item);
                            break;
                        case 25:
                            newData5.push(item);
                            break;
                        default:
                            console.warn(`Unexpected category: ${item.documentCategoryIDX}`);
                    }
                });
    
                setData1(newData1);
                setData5(newData5);
                
            } else {
                pAlert(response.data.msg);;
            }
        } catch (error) {
            console.error('Fetch failed:', error);
            pAlert('실패함.');
        } finally {
            pLoadingOff();
        }
    };

    useEffect(() => {
        fetchData();
    }, []);


    const pageSize = 10;
    const categoryOptions = useMemo(() => [
        { label: '전체', value: '전체' },
        { label: '제목', value: 'title' },
        { label: '내용', value: 'contents' },
    ], []);
    

    const [returnTableCard, setReturnTableCard] = useState(null);

    useEffect(() => {
        let selectedData = [];

        switch (pageid) {
            case '1':
                selectedData = data1;
                break;
            case '5':
                selectedData = data5;
                break;
            default:
                selectedData = data1;
        }

        setSelectedData(selectedData);
        setReturnTableCard(
            <TableCard
                key={pageid}
                data={selectedData}
                pageSize={pageSize}
                categoryOptions={categoryOptions}
                type="Editor"
            />
        );


    }, [pageid, data1, data5, pageSize, categoryOptions]);





    // =========================================

    const overviewRef = useRef(null);
    const forumHistoryRef = useRef(null);

    const handleButtonClick = (section) => {
        if (section === 'overview' && overviewRef.current) {
            window.scrollTo({
                top: overviewRef.current.offsetTop - 150,
                behavior: 'smooth',
            });
        } else if (section === 'forumHistory' && forumHistoryRef.current) {
            window.scrollTo({
                top: forumHistoryRef.current.offsetTop - 150,
                behavior: 'smooth',
            });
        }
    };

    

    // =========================================

    const bookSeries = [
        {
            thumbnail: "/img/bookt-1.jpg",
            title: "반가워요 공학자 시리즈",
            titleCount: "15",
            content: "초등학생 고학년을 대상으로 통신공학부터 조선공학까지 공학의 거의 모든 전 분야를 동화를 통해 재미있게 설명하여, 초등학생 독자도 공학기술에 쉽게 다가갈 수 있도록 구성된 초등학생을 위한 공학한림원의 대표 공학기술도서 시리즈"
        },
        {
            thumbnail: "/img/bookt-2.jpg",
            title: "어린이공학자 시리즈",
            titleCount: "10",
            content: "초등학생 저학년을 대상으로 어렵지 않은 표현과 풍부한 이미지를 통해 공학을 처음 접할 초등학교 저학년 아동이 이해하기 쉽도록 쓰여진 맞춤형 공학기술도서 시리즈"
        },
        {
            thumbnail: "/img/bookt-3.jpg",
            title: "공학과의 새로운 만남 시리즈 (전 78권)",
            titleCount: "78",
            content: "중고등학생부터 성인까지 즐길 수 있는 공학기술도서 시리즈. 공학관련 진로를 탐색하는 학생부터, 첨단기술, 공학의 역사 등에 대해 알고 싶어하는 일반인까지 아우르는 공학기술도서 시리즈. 총 2002년부터 78권(일부절판) 발행되었으며, 매년 6~7권 새로운 공학도서 발간 중"
        }
    ];

    // =========================================
    return (
        <>
            <div className="components_wrap">
                {(pageid === '1' || pageid === '5') && (
                    <>
                        <div className={styles.tab_btn_box} ref={overviewRef}>
                            <button
                                className={`${styles.tab_btn} ${styles.active}`}
                                onClick={() => handleButtonClick('overview')}
                            >
                                개요
                            </button>
                            <button
                                className={`${styles.tab_btn}`}
                                onClick={() => handleButtonClick('forumHistory')}
                            >
                                {pageid === '1' ? '역대 도서 소개' : '참여 후기'}
                            </button>
                        </div>
                    </>
                )}

                {/* ============================ 목적 ============================ */}

                <div className={styles.cont_wrap}>
                    <div className={styles.cont_tit_box}>
                        <span>목적</span>
                    </div>
                    <div className={styles.cont_box}>
                        <ul className={styles.bul_list}>
                            {pageid === '1' &&
                                <>
                                    <li>공학기술교양도서의 지속적인 발간 및 지원을 통해 공학기술의 중요성에 대한 대중의 인식을 제고하고, 공학관련 도서 출판 생태계를 육성하기 위함.</li>
                                </>
                            }
                            {pageid === '2' &&
                                <>
                                    <li>초등학생 공학 소양 증진과 관심 유발을 통해 미래 공학기술자에 대한 꿈과 희망을 심어주고, 이공계 진로선택에 긍정적 영향 제공
                                    </li>
                                </>
                            }
                            {pageid === '3' &&
                                <>
                                    <li>공학 기술 및 사회 여러 분야에서 리더십을 갖추고 이를 바탕으로 국가와 국제 사회에 기여할 수 있는 차세대 글로벌 공학 기술 지도자 양성</li>
                                    <li>공학도로서 각 분야에서 탁월한 공학적 능력을 기르고, 다양한 분야의 지식과 경험을 학습하고 공유하도록 함</li>
                                </>
                            }
                            {pageid === '4' &&
                                <>
                                    <li>지식재산 및 인문, 사회, 경영, 법률 등 다양한 분야에 대한 소양을 쌓을 수 있도록 기회를 제공하여 국가와 국제 사회에 기여하는 차세대 글로벌 지식재산 리더로 육성</li>
                                </>
                            }
                            {pageid === '5' &&
                                <>
                                    <li>한국공학한림원 회원들의 지식과 노하우가 다음 세대로 유익하게 이어질 수 있는 장을 마련하여 국가 인재 양성 및 산업 경쟁력 확보에 기여</li>
                                </>
                            }
                        </ul>
                    </div>
                </div>

                {/* ============================ 내용 ============================ */}

                {(pageid === '1' || pageid === '2' || pageid === '5') && (
                    <>
                        <div className={styles.cont_wrap}>
                            <div className={styles.cont_tit_box}>
                                <span>내용</span>
                            </div>
                            <div className={styles.cont_box}>
                                <ul className={styles.bul_list_2}>
                                    {pageid === '1' &&
                                        <>
                                            <li>공학기술 관련 문화 컨텐츠의 다양성을 확보하고 전문 필자, 전문 출판사의 발굴과 독서 시장의 확대를 위하여 기획된 공학기술도서사업은 첨단기술에 대한 일반인들의 이해와 관심 제고를 위해 지속적으로 전개 예정.</li>
                                            <li>청소년 과학기술도서 독후감대회, Engineering Book 보내기 운동 등을 운영하여 공학한림원에서 발간된 공학기술도서 보급을 확대하고, 미래 한국의 주역이 될 청소년들의 공학기술에 소양을 높임.</li>
                                        </>
                                    }
                                    {pageid === '2' &&
                                        <>
                                            <li>초등학생들에게 주요 기업 전문연구원들의 교육으로 공학 소양 증진과 관심 유발을 통해 미래 공학기술자에 대한 꿈과 희망을 심어주고, 신산업 관련 진로선택에 긍정적 영향 제공</li>
                                        </>
                                    }
                                    {pageid === '5' &&
                                        <>
                                            <li>대학생 멘티를 대상으로 석학 멘토들의 직업현장 및 기술개발 등의 경험과 역량, 비전 등을 공유</li>
                                            <li>멘티의 관심 분야에 따라 멘토-멘티를 연결 및 미팅 진행 (다양한 전문기술 분야 뿐만 아니라 기업 경영 노하우 및 창업 관련 포함)</li>
                                            <span className={`${styles.noteYellow} ${styles.m_t_20}`}>멘토 : 한국공학한림원 회원 <br /> 멘티 : 국내 대학(원) 공대생</span>
                                        </>
                                    }
                                </ul>
                            </div>
                        </div>
                    </>
                )}

                {/* ============================ NAEK 공학기술도서 시리즈 ============================ */}

                {(pageid === '1') && (
                    <>
                        <div className={styles.cont_wrap}>
                            <div className={styles.cont_tit_box}>
                                <span>NAEK 공학기술도서 시리즈</span>
                            </div>
                            <div className={styles.cont_box}>
                                {bookSeries.map((series, index) => (
                                    <div key={index} className={styles.series_item}>
                                        <div className={styles.thumbnail_img_box}>
                                            <img src={series.thumbnail} alt={series.title} className={styles.thumbnail} />
                                        </div>
                                        <div className={styles.series_txt_box}>
                                            <span className={styles.title}>{index + 1}. {series.title}<span className={styles.f_14}>(전 {series.titleCount}권)</span></span>
                                            <span className={styles.content}>{series.content}</span>
                                        </div>

                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className={`${styles.tab_btn_box} ${styles.m_t_b_100}`} ref={forumHistoryRef}>
                            <button
                                className={`${styles.tab_btn}`}
                                onClick={() => handleButtonClick('overview')}
                            >
                                개요
                            </button>
                            <button
                                className={`${styles.tab_btn} ${styles.active}`}
                                onClick={() => handleButtonClick('forumHistory')}
                            >
                                역대 도서 소개
                            </button>
                        </div>
                        {returnTableCard}
                    </>
                )}

                {(pageid === '2') && (
                    <>
                        <div className={styles.cont_wrap}>
                            <div className={styles.cont_tit_box}>
                                <span>프로그램</span>
                            </div>
                            <div className={styles.cont_box}>
                                <table className={styles.cont_table}>
                                    <tbody>
                                        <tr>
                                            <th>기업 참여 주니어공학기술교실</th>
                                            <td>
                                                <span>
                                                    기업 임직원이 교사 연수 수료 후, 각 사업장 인근 초등학교 및 지역아동센터 등을 방문하여 교구·교재를 활용하여 일일 공학수업 진행
                                                    <span className={`${styles.noteYellow} ${styles.m_t_20}`}>참여기관 : 삼성전자, 현대자동차, SK텔레콤, POSCO 등 기업(2005년부터 시행되어 50여개 기업 참여), 연구소, 대학 등</span></span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>돌봄교실</th>
                                            <td>
                                                <span>초등돌봄교실, 다함께 돌봄센터, 지역아동센터 등 방과 후에 돌봄이 필요한 초등학생들이 영상으로 이론을 학습하고 공학키트를 직접 제작함으로써 공학적 소양을 키움</span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>홈스쿨링</th>
                                            <td>
                                                <span>공학적 원리가 적용된 키트를 부모님과 함께 제작할 수 있도록 온라인 프로그램 진행</span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>소외계층 어린이대상 체험캠프</th>
                                            <td>
                                                <span>과학문화의 혜택을 받지 못하는 사회적 배려계층 청소년들에게 과학을 공학기술과 연계시켜 체험할 수 있는 기회를 제공함으로써 미래 공학자의 꿈을 키울 수 있도록 발판을 마련함</span>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className={`${styles.homepage_btn_box} ${styles.m_b_40}`}>
                            <a href="https://blog.naver.com/jrengineer" target='_blank' className={styles.homepage_btn} rel="noreferrer">
                                <span>주니어공학기술교실</span>
                                <span>홈스쿨링 블로그 바로가기</span>
                            </a>
                            <a href="https://www.youtube.com/playlist?list=PLL1yNnNN1ur2ydUTQJZkNygfqxcsjgWVd" target='_blank' className={styles.homepage_btn} rel="noreferrer">
                                <span>주니어공학기술교실</span>
                                <span>유튜브 바로가기</span>
                            </a>
                        </div>
                    </>
                )}

                {(pageid === '3') && (
                    <>
                        <div className={styles.cont_wrap}>
                            <div className={styles.cont_tit_box}>
                                <span>효과</span>
                            </div>
                            <div className={styles.cont_box}>
                                <ul className={styles.bul_list_2}>
                                    <li>국내 최고 엔지니어들이 모인 포럼에 공대 재학생들이 함께 참여함으로써 공학을 공부하는 학생으로서 자긍심을 높이고 리더십을 개발함</li>
                                    <li>산업, 기술, 정책과 관련된 전문적인 지식과 정보를 제공하고 학습하도록 하여 차세대 리더로서의 자질을 함양함</li>
                                </ul>
                            </div>
                        </div>
                        <div className={styles.cont_wrap}>
                            <div className={styles.cont_tit_box}>
                                <span>차세대 공학 리더 초청 규모 및 자격</span>
                            </div>
                            <div className={styles.cont_box}>
                                <table className={styles.cont_table}>
                                    <tbody>
                                        <tr>
                                            <th>초청 규모</th>
                                            <td>
                                                <span>2004년 3월부터 매회 10명 내외 학생을 NAEK Forum에 초청 (연간 100명 내외 초청, 총 1,500여 명 규모)</span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>초청자격</th>
                                            <td>
                                                <span className={styles.p_b_10}>초청대상 선정 : 각 대학 총장 및 학장이 선정</span>
                                                <span className={styles.p_b_10}>자격 : 성적이 우수한 공과대학 재학생으로, 리더십이 탁월하고 학내외 활동이 우수하여 차세대 리더로서의 소양을 갖춘 장래성 있는 학생</span>
                                                <span>NAEK Forum에 참석했던 각 대학 차세대 리더들이 자체적으로 모임을 결성하여 활동 중</span>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className={styles.cont_wrap}>
                            <div className={styles.cont_tit_box}>
                                <span>차세대 공학 리더 주요 활동</span>
                            </div>
                            <span className={styles.cont_sub_title}>학술활동</span>
                            <div className={styles.cont_box}>
                                <table className={styles.cont_table}>
                                    <tbody>
                                        <tr>
                                            <th>YEHS 세미나</th>
                                            <td>
                                                <span className={styles.p_b_10}>- 각 전공의 기술 및 산업 동향에 대한 정보를 교환하고 공학도들에게 필요한 다양한 지식을 공유</span>
                                                <span>- 공학 전공 주제 및 인문/교양 주제로 총 100여 회 개최</span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>리더십 포럼</th>
                                            <td>
                                                <span>- 다양한 분야에서 활동하는 리더의 강연을 통해 공대생에게 부족한 기본 교양을 배양하고 차세대 리더로서 역량 증진</span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>산업체 방문</th>
                                            <td>
                                                <span className={styles.p_b_10}>- 기업 및 연구소를 방문하여 전공지식이 실제로 적용되는 산업현장을 참관</span>
                                                <span>- LG화학, 삼성종합기술원, 현대건설기술개발원, 국방과학연구소, POSCO, 현대자동차 등 총 20여 곳 견학</span>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <span className={`${styles.cont_sub_title} ${styles.m_t_50}`}>사회공헌활동</span>
                            <div className={styles.cont_box}>
                                <table className={styles.cont_table}>
                                    <tbody>
                                        <tr>
                                            <th>고교 전공설명회</th>
                                            <td>
                                                <span>
                                                    - 진로 선택에 고민하고 있는고교생들을 방문하여 공과대학의 각 전공을 설명</span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>공학교육 봉사활동</th>
                                            <td>
                                                <span>- 지방 분교 초등학교와 또는 사회적 배려 계층을 방문하여 일일 공학교사로 활동</span>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <span className={`${styles.cont_sub_title} ${styles.m_t_50}`}>정기총회, 회원 소식지 발행 등 회원 네트워크 활동</span>
                        </div>
                        <div className={`${styles.homepage_btn_box} ${styles.m_b_40}`}>
                            <a href="http://www.yehs.or.kr/" target='_blank' className={styles.homepage_btn} rel="noreferrer">
                                <span>차세대공학리더</span>
                                <span>홈페이지 바로가기</span>
                            </a>
                            <a href="https://www.facebook.com/yehs.naek" target='_blank' className={styles.homepage_btn} rel="noreferrer">
                                <span>차세대공학리더</span>
                                <span>페이스북 바로가기</span>
                            </a>
                        </div>
                    </>
                )}

                {(pageid === '4') && (
                    <>
                        <div className={styles.cont_wrap}>
                            <div className={styles.cont_tit_box}>
                                <span>효과</span>
                            </div>
                            <div className={styles.cont_box}>
                                <ul className={styles.bul_list_2}>
                                    <li>학술교류 및 사회공헌 활동을 통해 회원 간 교류를 활성화하여 전국적인 지식재산 리더들의 네트워크 구축</li>
                                    <li>미래를 이끌어갈 잠재적 리더로 구성된 네트워크 구축으로 국가 지식재산 경쟁력 강화의 기틀을 마련</li>
                                </ul>
                            </div>
                        </div>
                        <div className={styles.cont_wrap}>
                            <div className={styles.cont_tit_box}>
                                <span>선발 자격 및 규모</span>
                            </div>
                            <div className={styles.cont_box}>
                                <table className={styles.cont_table}>
                                    <tbody>
                                        <tr>
                                            <th>선발 자격</th>
                                            <td>
                                                <span>캠퍼스 특허 유니버시아드(CPU) 대회 수상자로서 대학교 학부과정에 재/휴학 중이거나, 석사 또는 박사과정 재학생으로 차세대 지식재산 리더로서의 소양을 갖춘 학생</span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>선발 규모</th>
                                            <td>
                                                <span>매년 캠퍼스 특허전략 유니버시아드 대회 수상자 150인 내외 선발</span>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className={styles.cont_wrap}>
                            <div className={styles.cont_tit_box}>
                                <span>주요 활동</span>
                            </div>
                            <div className={styles.cont_box}>
                                <table className={styles.cont_table}>
                                    <tbody>
                                        <tr>
                                            <th>YIPL 세미나</th>
                                            <td>
                                                <span>지식재산을 포함한, 경제, 경영, 인문, 사회 분야 및 특허 관련 지식 공유를 위한 세미나 개최</span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>리더십 포럼</th>
                                            <td>
                                                <span>학계 및 산업계 전반의 명사를 초청하여 기술, 산업, 지식재산 등 다양한 분야의 포럼 개최 및 최신 트렌드 공유</span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>산업체 방문</th>
                                            <td>
                                                <span>지식재산 관련 기관 및 후원기업 등 산업체 방문 견학</span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>취·창업 멘토링</th>
                                            <td>
                                                <span>YIPL 시니어 회원과 주니어 회원 간 진학·취업·창업 멘토링 및 공학한림원회원 초청 멘토링 프로그램 운영</span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>지역네트워크</th>
                                            <td>
                                                <span>주니어 공학교실, 낙후지역 발명교육 실시 등 사회공헌 활동과 연계한 지역 네트워크 프로그램 운영</span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>기타</th>
                                            <td>
                                                <span>네트워킹 활성화를 위한 각종 소모임 활동</span>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className={`${styles.homepage_btn_box} ${styles.m_b_40}`}>
                            <a href="http://www.yipl.or.kr/" target='_blank' className={styles.homepage_btn} rel="noreferrer">
                                <span>차세대지식재산리더</span>
                                <span>홈페이지 바로가기</span>
                            </a>
                            <a href="https://www.instagram.com/yipl_yplus/" target='_blank' className={styles.homepage_btn} rel="noreferrer">
                                <span>차세대지식재산리더</span>
                                <span>인스타그램 바로가기</span>
                            </a>
                        </div>
                    </>
                )}



                {(pageid === '5') && (
                    <>
                        <div className={`${styles.tab_btn_box} ${styles.m_t_b_100}`} ref={forumHistoryRef}>
                            <button
                                className={`${styles.tab_btn}`}
                                onClick={() => handleButtonClick('overview')}
                            >
                                개요
                            </button>
                            <button
                                className={`${styles.tab_btn} ${styles.active}`}
                                onClick={() => handleButtonClick('forumHistory')}
                            >
                                참여 후기
                            </button>
                        </div>
                        {returnTableCard}
                    </>
                )}





            </div>
        </>
    );
}

export default PromotionEngineering;