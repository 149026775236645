// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.RelatedOrganization-module__top_content_wrap___nLRBK{padding:20px 0px}.RelatedOrganization-module__top_content_wrap___nLRBK .card_box{display:none}.RelatedOrganization-module__top_content_wrap___nLRBK .ant-pagination{display:none}.RelatedOrganization-module__top_content_wrap___nLRBK .search_container_wrap{display:none}`, "",{"version":3,"sources":["webpack://./src/pages/English/Link/RelatedOrganization/RelatedOrganization.module.scss"],"names":[],"mappings":"AAAA,sDACI,gBAAA,CACA,gEACI,YAAA,CAEJ,sEACI,YAAA,CAEJ,6EACI,YAAA","sourcesContent":[".top_content_wrap{\r\n    padding: 20px 0px;\r\n    :global(.card_box){\r\n        display: none;\r\n    }\r\n    :global(.ant-pagination){\r\n        display: none;\r\n    }\r\n    :global(.search_container_wrap){\r\n        display: none;\r\n    }\r\n\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"top_content_wrap": `RelatedOrganization-module__top_content_wrap___nLRBK`
};
export default ___CSS_LOADER_EXPORT___;
