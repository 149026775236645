
import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import common from '../../../common';

import { useLocation } from 'react-router-dom';
import {TableList} from "../../../components";


import styles from './MemberDepart.module.scss';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

function MemberDepart(isLogin) {

    const { useAlert,useConfirm, useLoading } = common();
    const { pAlert, AlertComponent } = useAlert();
    const { pConfirm, ConfirmComponent} = useConfirm();
    const { pLoadingOn, pLoadingOff, LoadingComponent } = useLoading();

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const pageid = queryParams.get('pageid');

    const [selectedData, setSelectedData] = useState([]);
    const [data , setData] = useState([]);

    const [data1 , setData1] = useState([]);
    const [data2 , setData2] = useState([]);
    const [data3 , setData3] = useState([]);
    const [data4 , setData4] = useState([]);
    const [data5 , setData5] = useState([]);
    const [data6 , setData6] = useState([]);
    const [data7 , setData7] = useState([]);
    const [data8 , setData8] = useState([]);

    const categoryData = ['60','61','62','63','64','65','66','67'];

    const fetchData = async () => {
        try {
            pLoadingOn();
            const response = await axios.post(`${API_BASE_URL}/naek_document/out/get_table`, {categoryData:categoryData}, {
                withCredentials: true
            });

            if (response.data.result === 't') {
                const allData = response.data.data;

                const newData1 = [];
                const newData2 = [];
                const newData3 = [];
                const newData4 = [];
                const newData5 = [];
                const newData6 = [];
                const newData7 = [];
                const newData8 = [];
    
                allData.forEach(item => {
                    switch (item.documentCategoryIDX) {
                        case 60:
                            newData1.push(item);
                            break;
                        case 61:
                            newData2.push(item);
                            break;
                        case 62:
                            newData3.push(item);
                            break;
                        case 63:
                            newData4.push(item);
                            break;
                        case 64:
                            newData5.push(item);
                            break;
                        case 65:
                            newData6.push(item);
                            break;
                        case 66:
                            newData7.push(item);
                            break;
                        case 67:
                            newData8.push(item);
                            break;
                        default:
                            console.warn(`Unexpected category: ${item.documentCategoryIDX}`);
                    }
                });
    
                setData1(newData1);
                setData2(newData2);
                setData3(newData3);
                setData4(newData4);
                setData5(newData5);
                setData6(newData6);
                setData7(newData7);
                setData8(newData8);

            } else {
                pAlert(response.data.msg);;
            }
        } catch (error) {
            console.error('Fetch failed:', error);
            pAlert('실패함.');
        } finally {
            pLoadingOff();
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const [returnTableList, setReturnTableList] = useState(null);

    const columns = [
        { title: '분류', dataIndex: 'documentCategoryName', className: 'p_td_w_20' },
        { title: '제목', dataIndex: 'title', className: 'p_td_title p_td_w_60' },
        { title: '날짜', dataIndex: 'createTime', className: 'p_td_date' },
    ];

    const pageSize = 10;

    const categoryOptions = [
        { label: '전체', value: '전체' },
        { label: '제목', value: 'title' },
        { label: '내용', value: 'contents' },
    ];

    useEffect(() => {
        let selectedData = [];
    
        switch (pageid) {
            case '60':
                selectedData = data1;
                break;
            case '61':
                selectedData = data2;
                break;
            case '62':
                selectedData = data3;
                break;
            case '63':
                selectedData = data4;
                break;
            case '64':
                selectedData = data5;
                break;
            case '65':
                selectedData = data6;
                break;
            case '66':
                selectedData = data7;
                break;
            case '67':
                selectedData = data8;
                break;
            default:
                selectedData = data1;
        }
    
        setSelectedData(selectedData);
        setReturnTableList(
            <TableList
                key={pageid}
                data={selectedData}
                columns={columns}
                pageSize={pageSize}
                categoryOptions={categoryOptions}
                type="Editor"
            />
        );
    
    }, [pageid, data1, data2, data3, data4, data5, data6, data7, data8]);

    // =================================

    const overviewRef = useRef(null);
    const forumHistoryRef = useRef(null);

    const handleButtonClick = (section) => {
        if (section === 'overview' && overviewRef.current) {
            window.scrollTo({
                top: overviewRef.current.offsetTop - 150,
                behavior: 'smooth',
            });
        } else if (section === 'forumHistory' && forumHistoryRef.current) {
            window.scrollTo({
                top: forumHistoryRef.current.offsetTop - 150,
                behavior: 'smooth',
            });
        }
    };

    const [writeStat , setWriteStat] = useState(false);
    const writeStatChk = async () => {
        try {
            pLoadingOn();
            const response = await axios.post(`${API_BASE_URL}/naek_member_depart/in/chk_write_possible`,
                { pageid:pageid },
                { withCredentials: true }
            );

            if (response.data.result === 't' && response.data.msg===true) {
                setWriteStat(true);
            } else {
                setWriteStat(false);
            }
        } catch (error) {
            console.error('Fetch failed:', error);
            pAlert('실패함.');
        } finally {
            pLoadingOff();
        }
    };
    useEffect(() => {
        if(isLogin){
            writeStatChk();
        }
    }, [pageid]);
    
    const gogopage = async () => {
        try {
            pLoadingOn();
            const response = await axios.post(`${API_BASE_URL}/naek_member_depart/in/chk_write_possible`,
                { pageid:pageid },
                { withCredentials: true }
            );

            if (response.data.result === 't' && response.data.msg===true) {
                setWriteStat(true);
                window.location.href = response.data.data;
            }
        } catch (error) {
            console.error('Fetch failed:', error);
            pAlert('실패함.');
        } finally {
            pLoadingOff();
        }
    };
    return (
        <>
            <AlertComponent />
            <LoadingComponent />
            <div className="components_wrap">
                <div className={styles.tab_btn_box} ref={overviewRef}>
                    <button
                        className={`${styles.tab_btn} ${styles.active}`}
                        onClick={() => handleButtonClick('overview')}
                    >개요</button>
                    <button
                        className={`${styles.tab_btn}`}
                        onClick={() => handleButtonClick('forumHistory')}
                    >분과 행사</button>
                </div>

                <div className={styles.cont_wrap}>
                    <div className={styles.cont_box}>
                        <ul className={styles.bul_list}>
                            <div className={styles.depart_img_box}>
                                <img src="/img/member_depart.png" alt="" />
                            </div>
                        </ul>
                    </div>
                </div>

                <div className={`${styles.tab_btn_box} ${styles.m_t_b_100}`} ref={forumHistoryRef}>
                    <button
                        className={`${styles.tab_btn}`}
                        onClick={() => handleButtonClick('overview')}
                    >
                        개요
                    </button>
                    <button
                        className={`${styles.tab_btn} ${styles.active}`}
                        onClick={() => handleButtonClick('forumHistory')}
                    >
                        분과 행사
                    </button>
                </div>
                {writeStat && (
                    <div className={`${styles.md_write_btn_box}`}>
                        <button className={`p_btn p_btn_normal ${styles.md_write_btn}`} onClick={gogopage}>글작성</button>
                    </div>
                )}
                {returnTableList}
                {/* <TableList data={data} columns={columns} pageSize={pageSize} categoryOptions={categoryOptions} type="Editor" /> */}
            </div>
        </>
    );
}

export default MemberDepart;