import React, { useState, useRef, useEffect, useMemo } from 'react';
import axios from 'axios';
import common from '../../../common';
import { useLocation, useNavigate } from 'react-router-dom';

import styles from './IntegrationSearch.module.scss';
import { DataView } from 'primereact/dataview';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;


function IntegrationSearch() {

    const { useAlert,useConfirm, useLoading } = common();
    const { pAlert, AlertComponent } = useAlert();
    const { pConfirm, ConfirmComponent} = useConfirm();
    const { pLoadingOn, pLoadingOff, LoadingComponent } = useLoading();
    const navigate = useNavigate();

    const [searchedData, setSearchedData] = useState([]);
    const [itsCount, setItsCount] = useState(0);
    
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const searchKeyword = queryParams.get('t');

    // naek_document/out/search_docmuent 파라미터 keyword 

    const fetchData = async () => {
        try {
            pLoadingOn();
            const response = await axios.post(`${API_BASE_URL}/naek_document/out/search_docmuent`, 
                {
                    keyword:searchKeyword
                }, {
                    withCredentials: true
                });

            if (response.data.result === 't') {
                const data = response.data.data;
                setSearchedData(data);
                setItsCount(data.length);
            } else {
                pAlert(response.data.msg);;
            }
        } catch (error) {
            console.error('Fetch failed:', error);
            pAlert('실패함.');
        } finally {
            pLoadingOff();
        }
    };

    useEffect(() => {
        fetchData();
    }, [searchKeyword]);

    const moveDocu = async (docuIdx) => {
        navigate(`/noticedetail?id=${docuIdx}`); // 원하는 경로로 이동
    };
    
    const itemTemplate = (item, index) => {
        return (
            <div className={styles.its_item} key={item.idx}>
                <span className={`f_smaller ${styles.its_i_cate}`}>{item.categoryName}</span>
                <a onClick={() => moveDocu(item.idx)} className={styles.its_i_tit}>
                    <span className='f_big'>{item.title}</span>
                </a>
                <span className={`f_small ${styles.its_i_con}`}>{item.contents}</span>
            </div>
        );
    };

    const listTemplate = (items) => {
        if (!items || items.length === 0) return (
            <div className={styles.its_item}>
                <a className={`${styles.its_i_tit} ${styles.its_i_tit_none}`}>
                    <span className='f_big'>"{searchKeyword}" 검색결과가 없습니다.</span>
                </a>
            </div>
        );

        let list = items.map((searchedData, index) => {
            return itemTemplate(searchedData, index);
        });

        return <div className="grid grid-nogutter">{list}</div>;
    };

    return (
        <>
            <AlertComponent />
            <LoadingComponent />
            <div className={`components_wrap ${styles.its_components_wrap}`}>
                <div className={styles.its_wrap}>
                    <span className={`f_smaller ${styles.its_count}`}>{itsCount}건의 검색 결과</span>
                    <DataView value={searchedData} listTemplate={listTemplate} paginator rows={10} />
                </div>
            </div>
        </>
    );
}

export default IntegrationSearch;