import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import styles from './Payment.module.scss';
import common from '../../../common';
import axios from 'axios';
import 'primeicons/primeicons.css';
import { loadTossPayments } from '@tosspayments/payment-sdk';


import OrderNumCheck from './OrderNumCheck/OrderNumCheck';
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;


const contactPrefixes = [
    { value: '010', label: '010' },
    { value: '011', label: '011' },
    { value: '016', label: '016' },
    { value: '017', label: '017' },
    { value: '018', label: '018' },
    { value: '019', label: '019' }
];

const emailDomains = [
    { value: '', label: '직접입력' },
    { value: 'naver.com', label: 'naver.com' },
    { value: 'nate.com', label: 'nate.com' },
    { value: 'hanmail.net', label: 'hanmail.net' },
    { value: 'gmail.com', label: 'gmail.com' },
    { value: 'hotmail.com', label: 'hotmail.com' },
    { value: 'outlook.com', label: 'outlook.com' },
    { value: 'empal.com', label: 'empal.com' },
    { value: 'dreamwiz.com', label: 'dreamwiz.com' },
    { value: 'lycos.co.kr', label: 'lycos.co.kr' },
    { value: 'yahoo.co.kr', label: 'yahoo.co.kr' },
    { value: 'korea.com', label: 'korea.com' },
    { value: 'paran.com', label: 'paran.com' }
];

const paymentMethods = [
    { value: 'CARD', label: '카드' },
    // { value: 'TRANSFER', label: '계좌이체' },
    // { value: 'FOREIGN_EASY_PAY', label: '해외간편결제' },
    // { value: 'MOBILE_PHONE', label: '휴대폰' },
    // { value: 'VIRTUAL_ACCOUNT', label: '가상계좌' },
    // { value: 'CULTURE_GIFT_CERTIFICATE', label: '문화상품권' },
    // { value: 'PAYPAL', label: '페이팔' },
];

const Payment = () => {
    const { useAlert, useLoading, useConfirm, useModal, pToast, pClipboard } = common();
    const { pAlert, AlertComponent } = useAlert();
    const { pLoadingOn, pLoadingOff, LoadingComponent } = useLoading();
    const { pConfirm, ConfirmComponent } = useConfirm();
    const { pModal, closeModal, ModalComponent } = useModal();

    const [memberTypes, setMemberTypes] = useState([]);
    const [amounts, setAmounts] = useState({});
    // const [departments, setDepartments] = useState([]);
    const [formData, setFormData] = useState({
        memberGradeNameIDX: '', // 회원구분
        amount: '', // 금액
        customerName: '', // 이름
        organization: '', // 소속
        position: '', // 직위
        // letterDepartIDX: '', // 분과
        phonePrefix: contactPrefixes[0].value, // 연락처 앞자리
        phoneNumber1: '', // 연락처 첫 번째 뒷자리
        phoneNumber2: '', // 연락처 두 번째 뒷자리
        emailUsername: '', // 이메일 아이디
        emailDomain: emailDomains[0].value, // 이메일 도메인
        emailDomainInput: '', // 직접 입력 이메일 도메인
        memo: '', // 메모
        method: 'CARD', // 결제방법
    });

    // useEffect(() => {
    //     window.scrollTo(0, document.body.scrollHeight);
    // }, []);

    useEffect(() => {
        pAlert(`회원님 안녕하세요.<br />
    회비 카드 납부 방법을 안내해 드립니다.<br /><br />
    회비 결제 항목을 정확하게 채워주세요.<br />
    <b>금액은 이메일로 개별 발송된 공문 금액과 일치</b>하도록 선택해 주세요.<br />
    회비는 <b>환불이 불가능</b>함을 양해 바랍니다.<br />
    납부 방법의 변경은 결제일로부터 28일 내에 가능합니다. (당해 연도 내)<br />
    원하실 경우, 사무처로 연락 주시기 바랍니다.<br /><br />
    감사합니다.`, '500px');
    }, []);



    useEffect(() => {
        const fetchData = async () => {
            pLoadingOn();  // 로딩 시작
            try {
                const response = await axios.post(`${API_BASE_URL}/naek_toss_payments/out/get_toss_payments_fee`, {}, {
                    withCredentials: true
                });

                if (response.data.result === 't') {
                    const { memberTypes, amounts, departments } = response.data.data;

                    // console.log('Original memberTypes:', memberTypes);
                    // console.log('Original amounts:', amounts);
                    // console.log('Original departments:', departments);

                    setMemberTypes(memberTypes);
                    setAmounts(amounts);
                    // setDepartments(departments);

                    // console.log('Updated memberTypes:', memberTypes);
                    // console.log('Updated amounts:', amounts);

                    pLoadingOff(); // 로딩 종료
                } else {
                    pLoadingOff();
                    // pAlert(response.data.msg);
                }
            } catch (error) {
                pLoadingOff();
                // pAlert('데이터를 가져오는 중 오류가 발생했습니다. 관리자에게 문의하세요.');
            }
        };

        fetchData();
    }, []);

    const handleChange = (field, value) => {
        setFormData((prevData) => {
            if (field === 'memberGradeNameIDX') {
                pAlert('금액은 이메일로 개별 발송된 공문 금액과 일치하도록 선택해 주세요.');
                return {
                    ...prevData,
                    memberGradeNameIDX: value,
                    amount: null // 회원종류 변경 시 금액 초기화
                };
            }
            return {
                ...prevData,
                [field]: value
            };
        });
    };

    const handlePhoneNumber1Change = (event) => {
        const value = event.target.value.replace(/\D/g, '').slice(0, 4); // 숫자만 허용하고 최대 4자리로 제한
        handleChange('phoneNumber1', value);
        if (value.length === 4) {
            document.getElementById('phoneNumber2').focus(); // 4자리 입력되면 phoneNumber2로 포커스 이동
        }
    };

    const handlePhoneNumber2Change = (event) => {
        const value = event.target.value.replace(/\D/g, '').slice(0, 4); // 숫자만 허용하고 최대 4자리로 제한
        handleChange('phoneNumber2', value);
    };


    const handleFormSubmit = async (event) => {
        event.preventDefault();

        if (!formData.memberGradeNameIDX) {
            pAlert('회원종류를 선택해 주세요.');
            return;
        }
        if (!formData.amount) {
            pAlert('금액을 선택해 주세요.');
            return;
        }
        if (!formData.customerName) {
            pAlert('이름 (회원명)을 입력해 주세요.');
            return;
        }
        if (!formData.organization) {
            pAlert('소속기관을 입력해 주세요.');
            return;
        }
        if (!formData.position) {
            pAlert('직위를 입력해 주세요.');
            return;
        }
        // if (!formData.letterDepartIDX) {
        //     pAlert('분과를 선택해 주세요.');
        //     return;
        // }

        const phoneRegex = /^\d{4}$/;
        if (!phoneRegex.test(formData.phoneNumber1) || !phoneRegex.test(formData.phoneNumber2)) {
            pAlert('유효한 전화번호를 입력해 주세요.');
            return;
        }

        if (!formData.emailUsername) {
            pAlert('이메일을 입력해 주세요.');
            return;
        }

        const emailDomain = formData.emailDomain === '' ? formData.emailDomainInput : formData.emailDomain;
        const email = `${formData.emailUsername}@${emailDomain}`;
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        if (!emailRegex.test(email)) {
            pAlert('유효한 이메일 주소를 입력해 주세요.');
            return;
        }

        if (!formData.method) {
            pAlert('결제 방법을 선택해 주세요.');
            return;
        }

        const completePhone = `${formData.phonePrefix}${formData.phoneNumber1}${formData.phoneNumber2}`;

        const { phoneNumber1, phoneNumber2, emailDomain: _, emailDomainInput, emailUsername, ...restFormData } = formData;

        const finalFormData = {
            ...restFormData,
            customerMobilePhone: completePhone,
            customerEmail: email,
            currency: 'KRW',
            orderName: '회비',
        };
        pLoadingOn();
        try {
            const response = await axios.post(`${API_BASE_URL}/naek_toss_payments/out/request_payment`, finalFormData, {
                withCredentials: true
            });

            if (response.data.result === 't') {
                const { orderId } = response.data.data;
                // 토스 결제 창 호출
                const tossPayments = await loadTossPayments(process.env.REACT_APP_TOSS_CLIENT_KEY);
                tossPayments.requestPayment(formData.method, {
                    amount: formData.amount,
                    orderId: orderId,
                    orderName: finalFormData.orderName,
                    successUrl: `${window.location.origin}/paymentresult?status=success`,
                    failUrl: `${window.location.origin}/paymentresult?status=fail`,
                });
                pLoadingOff();

            } else {
                pLoadingOff();
                pAlert(response.data.msg);
            }
        } catch (error) {
            // console.error('Payment request failed:', error);
            pLoadingOff();
            pAlert('결제 요청에 실패했습니다. 관리자에게 문의하세요.');
        }
    };

    const ListConfrim = () => {
        pModal('', <OrderNumCheck />, '400px', 'auto');
    };


    return (
        <>
            <AlertComponent />
            <LoadingComponent />
            <ConfirmComponent />
            <ModalComponent />
            <div className={`components_wrap ${styles.payment_wrap}`}>
                {/* <div className={styles.cont_tit_box}>
                    <p>회비 결제</p>
                </div> */}
                <div className={styles.cont_box}>
                    <div className={styles.cont_sel_wrap}>
                        <label>회원종류</label>
                        <div className={styles.cont_sel_box}>
                            <Select
                                value={memberTypes?.find(option => option.value === formData.memberGradeNameIDX)}
                                onChange={(option) => handleChange('memberGradeNameIDX', option.value)}
                                options={memberTypes || []}
                            />

                        </div>
                    </div>
                    <div className={styles.cont_sel_wrap}>
                        <label>금액</label>
                        <div className={styles.cont_sel_box}>
                            <Select
                                value={formData.amount ? amounts[formData.memberGradeNameIDX]?.find(option => option.value === formData.amount) : null}
                                onChange={(option) => handleChange('amount', option.value)}
                                options={formData.memberGradeNameIDX ? amounts[formData.memberGradeNameIDX] || [] : []}
                                isDisabled={!formData.memberGradeNameIDX}
                            />
                        </div>
                    </div>
                    <div className={styles.cont_sel_wrap}>
                        <label>이름 (회원명)</label>
                        <div className={styles.cont_sel_box}>
                            <input
                                className={styles.p_input}
                                type="text"
                                value={formData.customerName}
                                onChange={(e) => handleChange('customerName', e.target.value)}
                            />
                        </div>
                    </div>
                    <div className={styles.cont_sel_wrap}>
                        <label>소속기관</label>
                        <div className={styles.cont_sel_box}>
                            <input
                                className={styles.p_input}
                                type="text"
                                value={formData.organization}
                                onChange={(e) => handleChange('organization', e.target.value)}
                            />
                        </div>
                    </div>
                    <div className={styles.cont_sel_wrap}>
                        <label>직위</label>
                        <div className={styles.cont_sel_box}>
                            <input
                                className={styles.p_input}
                                type="text"
                                value={formData.position}
                                onChange={(e) => handleChange('position', e.target.value)}
                            />
                        </div>
                    </div>
                    {/* <div className={styles.cont_sel_wrap}>
                        <label>분과</label>
                        <div className={styles.cont_sel_box}>
                            <Select
                                value={departments.find(option => option.value === formData.letterDepartIDX)}
                                onChange={(option) => handleChange('letterDepartIDX', option.value)}
                                options={departments}
                            />
                        </div>
                    </div> */}
                    <div className={styles.cont_sel_wrap}>
                        <label>연락처</label>
                        <div className={styles.cont_sel_box}>
                            <div className={styles.cont_sel_ph_box}>
                                <div className={styles.cont_sel_ph_sel_box}>
                                    <Select
                                        value={contactPrefixes.find(option => option.value === formData.phonePrefix)}
                                        onChange={(option) => handleChange('phonePrefix', option.value)}
                                        options={contactPrefixes}
                                        className={styles.contactPrefix}
                                    />
                                </div>
                                <div className={styles.cont_sel_ph_input_box}>
                                    <input
                                        type="text"
                                        value={formData.phoneNumber1}
                                        onChange={handlePhoneNumber1Change}
                                        className={styles.p_input}
                                    />
                                    <span>-</span>
                                    <input
                                        type="text"
                                        value={formData.phoneNumber2}
                                        onChange={handlePhoneNumber2Change}
                                        className={styles.p_input}
                                        id="phoneNumber2"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={styles.cont_sel_wrap}>
                        <label>이메일</label>
                        <div className={styles.cont_sel_box}>
                            <div className={styles.cont_sel_mail_box}>
                                <div className={styles.cont_sel_mail_input_box}>
                                    <input
                                        type="text"
                                        value={formData.emailUsername}
                                        onChange={(e) => handleChange('emailUsername', e.target.value)}
                                        className={styles.p_input}
                                    />
                                    <span>@</span>
                                    <input
                                        type="text"
                                        value={formData.emailDomain === '' ? formData.emailDomainInput : formData.emailDomain}
                                        onChange={(e) => handleChange('emailDomainInput', e.target.value)}
                                        className={styles.p_input}
                                        disabled={formData.emailDomain !== ''}
                                    />
                                </div>
                                <div className={styles.cont_sel_mail_sel_box}>
                                    <Select
                                        value={emailDomains.find(option => option.value === formData.emailDomain)}
                                        onChange={(option) => handleChange('emailDomain', option.value)}
                                        options={emailDomains}
                                        className={styles.emailDomain}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={styles.cont_sel_wrap}>
                        <label>메모</label>
                        <div className={styles.cont_sel_box}>
                            <input
                                className={styles.p_input}
                                type="text"
                                value={formData.memo}
                                onChange={(e) => handleChange('memo', e.target.value)}
                            />
                        </div>
                    </div>
                    <div className={styles.cont_sel_wrap}>
                        <label>결제 방법</label>
                        <div className={styles.cont_sel_box}>
                            <Select
                                value={paymentMethods.find(option => option.value === formData.method)}
                                onChange={(option) => handleChange('method', option.value)}
                                options={paymentMethods}
                            />
                        </div>
                    </div>
                </div>
                <div className={styles.cont_tit_box}>
                    <p>회비제도</p>
                </div>
                <div className={styles.cont_box}>
                    <div className={styles.cont_txt_box}>
                        <p>한국공학한림원은 정관 및 회원에 관한 규정에 의거하여 회비제도를 운영하고 있습니다.</p>
                    </div>
                    <div className={styles.cont_txt_box}>
                        <p className={styles.cont_txt_color}>관련근거</p>
                    </div>
                    <div className={styles.cont_txt_box}>
                        <p className={styles.cont_txt_ul}>정관 제8조 (회원의 임기 등)</p>
                        <p className={styles.cont_txt_3_li}>③ 회원은 회원에 관한 규정에서 정한 연회비를 납부한다.</p>
                        <p className={styles.cont_txt_ul}>회원에 관한 규정 제5조 (회원의 의무)</p>
                        <p className={styles.cont_txt_3_li}>① 회원은 [별표1]에 나와 있는 바와 같이 연회비를 납부한다.</p>
                        <p className={styles.cont_txt_ul}>[별표1 회원의 연회비]</p>
                        <p className={styles.cont_txt_li}>① 정회원 : 연 300,000원</p>
                        <p className={styles.cont_txt_li}>② 일반회원 : 연 200,000원</p>
                        <p className={styles.cont_txt_li}>③ 외국회원 : 연 300,000원 (국내에 거주하는 외국회원은 정회원에 준하는 회비를 납부한다.)</p>
                    </div>
                </div>
                <div className={styles.cont_tit_box}>
                    <p>문의 및 회비 관련 안내</p>
                </div>
                <div className={styles.cont_box}>
                    <p>사무처  (02-6009-4013 / nahui@naek.or.kr)</p>
                </div>
                <div className={styles.cont_tit_box}>
                    <p>연회비 환불규정</p>
                </div>
                <div className={styles.cont_box}>
                    <table className={styles.info_table}>
                        <tbody>
                            <tr>
                                <td>연회비 환불 요청 시</td>
                                <td>환불 불가</td>
                            </tr>
                            <tr>
                                <td>납부방법 변경 요청 시</td>
                                <td>전액환불 후 재결제 진행</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className={styles.pm_btn_wrap}>
                    {/* <button onClick={handleFormSubmit} className={styles.submit_btn}>결제하기</button> */}
                    {/* <button onClick={ListConfrim}>내역확인</button> */}
                    <button onClick={handleFormSubmit} className='p_btn p_btn_normal'>결제하기</button>
                    <button onClick={ListConfrim} className='p_btn p_btn_normal'>내역확인</button>
                </div>
            </div>
        </>
    );
}

export default Payment;