import './RegularDebate.module.scss';
import React, { useState, useEffect, useMemo, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { TableList } from "../../../components";
import styles from './RegularDebate.module.scss';
import common from '../../../common';

import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

function RegularDebate() {
    const { useAlert,useConfirm, useLoading } = common();
    const { pAlert, AlertComponent } = useAlert();
    const { pConfirm, ConfirmComponent} = useConfirm();
    const { pLoadingOn, pLoadingOff, LoadingComponent } = useLoading();

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const pageid = queryParams.get('pageid');


    const [selectedData, setSelectedData] = useState([]);


    const [data1 , setData1] = useState([]);
    const [data2 , setData2] = useState([]);
    const [data3 , setData3] = useState([]);
    const [data4 , setData4] = useState([]);
    const [data5 , setData5] = useState([]);


    const categoryData = ['3','4','5','7','188'];

    const fetchData = async () => {
        try {
            pLoadingOn();
            const response = await axios.post(`${API_BASE_URL}/naek_document/out/get_table`, {categoryData:categoryData}, {
                withCredentials: true
            });


            if (response.data.result === 't') {
                const allData = response.data.data;

                const newData1 = [];
                const newData2 = [];
                const newData3 = [];
                const newData4 = [];
                const newData5 = [];
    
                allData.forEach(item => {
                    switch (item.documentCategoryIDX) {
                        case 3:
                            newData1.push(item);
                            break;
                        case 4:
                            newData2.push(item);
                            break;
                        case 5:
                            newData3.push(item);
                            break;
                        case 7:
                            newData4.push(item);
                            break;
                        case 188:
                            newData5.push(item);
                            break;
                        default:
                            console.warn(`Unexpected category: ${item.documentCategoryIDX}`);
                    }
                });
    
                setData1(newData1);
                setData2(newData2);
                setData3(newData3);
                setData4(newData4);
                setData5(newData5);
            } else {
                pAlert(response.data.msg);;
            }
        } catch (error) {
            console.error('Fetch failed:', error);
            pAlert('실패함.');
        } finally {
            pLoadingOff();
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const columns = useMemo(() => [
        { title: '제목', dataIndex: 'title', className: 'p_td_title p_td_w_85' },
        { title: '날짜', dataIndex: 'createTime', className: 'p_td_date' },
    ], []);
    const pageSize = 10;
    const categoryOptions = useMemo(() => [
        { label: '전체', value: '전체' },
        { label: '제목', value: 'title' },
        { label: '내용', value: 'contents' },
    ], []);

    const [returnTableList, setReturnTableList] = useState(null);

    useEffect(() => {
        let selectedData = [];

        switch (pageid) {
            case '1':
                selectedData = data1;
                break;
            case '2':
                selectedData = data2;
                break;
            case '3':
                selectedData = data3;
                break;
            case '4':
                selectedData = data4;
                break;
            case '5':
                selectedData = data5;
                break;
            default:
                selectedData = data1;
        }

        setSelectedData(selectedData);
        setReturnTableList(
            <TableList
                key={pageid}
                data={selectedData}
                columns={columns}
                pageSize={pageSize}
                categoryOptions={categoryOptions}
                type="Editor"
            />
        );
    }, [pageid, columns, data1, data2, data3, data4, data5, pageSize, categoryOptions]);

    // =================================

    const overviewRef = useRef(null);
    const forumHistoryRef = useRef(null);

    const handleButtonClick = (section) => {
        if (section === 'overview' && overviewRef.current) {
            window.scrollTo({
                top: overviewRef.current.offsetTop - 150,
                behavior: 'smooth',
            });
        } else if (section === 'forumHistory' && forumHistoryRef.current) {
            window.scrollTo({
                top: forumHistoryRef.current.offsetTop - 150,
                behavior: 'smooth',
            });
        }
    };

    return (
        <>
            <div className="components_wrap">
                <div className={styles.tab_btn_box} ref={overviewRef}>
                    <button
                        className={`${styles.tab_btn} ${styles.active}`}
                        onClick={() => handleButtonClick('overview')}
                    >
                        개요
                    </button>
                    <button
                        className={`${styles.tab_btn}`}
                        onClick={() => handleButtonClick('forumHistory')}
                    >
                        역대 포럼 소개
                    </button>
                </div>

                {/* ==================== 목적 ==================== */}

                <div className={styles.cont_wrap}>
                    <div className={styles.cont_tit_box}>
                        <span>목적</span>
                    </div>
                    <div className={styles.cont_box}>
                        <ul className={styles.bul_list}>
                        {pageid === '1' &&
                            <>
                                <li>정책입안자, 산업계 최고경영인 및 학계 석학들의 집단지성으로 올바른 정책을 개발하고 건의함으로써 기업과 국가의 경쟁력 제고</li>
                                <li>정책입안자, 최고경영인 및 학계 석학들의 의견 교환을 통한 올바른 정책 개발 및 건의</li>
                                <li>국내외 최신 산업기술, 공학교육, R&D 전략 등에 대한 정보의 공유</li>
                            </>
                        }
                        {pageid === '2' &&
                            <>
                                <li>국가적인 주요 현안에 대한 여론 형성 및 국론 결집</li>
                                <li>참여 인사들 간 국내외 산업기술, 공학교육, R&D 전략 등에 대한 최신 정보의 공유</li>
                            </>
                        }
                        {pageid === '3' &&
                            <>
                                <li>한국공학한림원 CEO/CTO회원 상호간에 전문 경영정보를 공유하고 경영 노하우를 벤치마킹 함으로써 기업의 경쟁력 강화</li>
                                <li>지식 경영의 궁극적 목적인 기업과 국가의 경쟁력 제고에 기여</li>
                            </>
                        }
                        {pageid === '4' &&
                            <>
                                <li>국토발전 전략을 모색하고 나아가 미래지향적 비전과 정책 방향 제시</li>
                            </>
                        }
                        {pageid === '5' &&
                            <>
                                <li>지식재산 정책·이슈에 대한 오피니언 리더들의 심도 있는 토론을 통해 특허 친화적인 국가발전 전략 및 정책 제시</li>
                                <li>지식재산 정책에 대한 사회 전반의 인식제고 및 대한민국 지식 재산 경쟁력 강화</li>
                            </>
                        }
                        </ul>
                    </div>
                </div>

                {/* ==================== 내용 ==================== */}

                <div className={styles.cont_wrap}>
                    <div className={styles.cont_tit_box}>
                        <span>내용</span>
                    </div>
                    <div className={styles.cont_box}>
                        <ul className={styles.bul_list}>
                        {pageid === '1' &&
                            <>
                                <li>산업, 기술, 공학, 정책 분야의 Top Issue나 Major Agenda 로 주제 선정</li>
                                <li>선정된 주제의 최고 전문가를 연사/패널로 초청하여 최신정보 공유 및 의견 토론</li>
                                <li>문제에 대한 해법을 모색하고 실질적인 공학정책으로 이어지도록 전략 제시</li>
                                <li>한국공학한림원 회원을 비롯한 산업기술 유관 기관장 등 지도급자 인사 초청</li>
                            </>
                        }
                        {pageid === '2' &&
                            <>
                                <li>정치, 경제, 사회 분야의 국가적 Top Isuue와 Global Agenda로 선정</li>
                                <li>선정된 주제의 최고 전문가를 연사/패널로 초청하여 최신정보 공유 및 의견 토론</li>
                                <li>국가 최고의 지도자 그룹과 글로벌 석학들의 심도 있는 토론을 통해 국가 전략 제시</li>
                                <li>국가 정책에 영향을 줄 수 있는 각계 지도자급 인사 초청
                                <br/>
                                (국회의원, 기업 CEO 및 CTO, 대학 총장 및 교수, 유관기관장 및 단체장, 정부부처 인사 등)
                                </li>
                            </>
                        }
                        {pageid === '3' &&
                            <>
                                <li>한국공학한림원의 사회적 가치 실현을 위해 대중소기업간 상생, 지식 경영 전략 등의 주제로 최신 정보 공유 및 네트워킹</li>
                                <li>한국공학한림원 산업계 CEO/CTO 회원 초청</li>
                            </>
                        }
                        {pageid === '4' &&
                            <>
                                <li>국토교통, 건설 생산, 재해·재난, 인구·환경 위기 등 분야의 Top Issue나 Major Agenda 로 주제 선정</li>
                                <li>선정된 주제의 최고 전문가를 연사/패널로 초청하여 최신정보 공유 및 의견 토론</li>
                                <li>국토 균형발전 방향 모색과 지속 가능한 통합 솔루션 제시</li>
                                <li>한국공학한림원 회원을 비롯한 건설환경공학분야 지도급자 인사 초청
                                <br/>
                                (국회의원, 기업 CEO 및 CTO, 대학 총장 및 교수, 연구원장, 유관기관장 및 단체장, 정부부처 인사 등)
                                </li>
                            </>
                        }
                        {pageid === '5' &&
                            <>
                                <li>지식재산과 관련된 국내외 이슈, 연구개발, 비즈니스, 법 ∙ 제도 등 다양하고 시의성 있는 주제 선정</li>
                                <li>선정된 주제의 최고 전문가를 연사/패널로 초청하여 최신정보 공유 및 의견 토론</li>
                                <li>한국공학한림원 회원을 비롯한 국가지식재산 정책에 영향을 미칠 수 있는 각계 지도자급 인사
                                <br/>
                                (국회의원, 기업 CEO 및 CTO, 유관기관장 및 단체장, 정부부처 인사, 변호사 및 변리사 등)
                                </li>
                            </>
                        }
                        </ul>
                    </div>
                </div>

                {/* ==================== 일시 ==================== */}

                {(pageid === '1') && (
                    <>
                        <div className={styles.cont_wrap}>
                            <div className={styles.cont_tit_box}>
                                <span>일시</span>
                            </div>
                            <div className={styles.cont_box}>
                                <ul className={styles.bul_list_2}>
                                    <li>연 6회 개최(홀수달 마지막 월요일 17시)</li>
                                </ul>
                            </div>
                        </div>
                    </>
                )}

                {/* ==================== 초청대상 ==================== */}

                {(pageid === '1') && (
                    <>
                        <div className={styles.cont_wrap}>
                            <div className={styles.cont_tit_box}>
                                <span>초청대상</span>
                            </div>
                            <div className={styles.cont_box}>
                                <ul className={styles.bul_list_2}>
                                    <li>한국공학한림원 회원을 비롯한 산업기술 관련 기관장 등 지도급자 인사</li>
                                </ul>
                            </div>
                        </div>
                    </>
                )}

                {/* ==================== 포럼형식 ==================== */}

                {(pageid === '1') && (
                    <>
                        <div className={styles.cont_wrap}>
                            <div className={styles.cont_tit_box}>
                                <span>포럼형식</span>
                                <span className={styles.con_tit_sub_txt}>(오프라인 진행 시)</span>
                            </div>
                            <div className={styles.cont_box}>
                                <ul className={styles.bul_list_2}>
                                    <li>오프닝(17:00~17:05)</li>
                                    <li>연사발표(17:05~17:35)</li>
                                    <li>토론(17:35~19:00)</li>
                                </ul>
                            </div>
                        </div>
                    </>
                )}

                {/* ==================== 공동의장 ==================== */}

                {(pageid === '4') && (
                    <>
                        <div className={styles.cont_wrap}>
                            <div className={styles.cont_tit_box}>
                                <span>공동의장</span>
                            </div>
                            <div className={styles.cont_box}>
                                <ul className={styles.bul_list_2}>
                                    <li>김기남 한국공학한림원 회장</li>
                                    <li>강호인 국토교통부 前 장관</li>
                                    <li>이현수 서울대학교 명예교수</li>
                                    <li>윤영구 한국건설기술인협회 회장</li>
                                </ul>
                            </div>
                        </div>
                    </>
                )}


                {(pageid === '1' || pageid === '4' || pageid === '5') && (
                    <>
                        {pageid === '1' &&
                            <span className={styles.noteBlue}>2015년 The CEO포럼(150회 개최)과 한경-토론마당(65회 개최)을 NAEK포럼으로 통합하였음</span>
                        }
                        {pageid === '4' &&
                            <span className={styles.noteBlue}>후원 : 국토교통과학기술진흥원</span>
                        }
                        {pageid === '5' &&
                            <span className={styles.noteBlue}>후원 : 특허청</span>
                        }
                    </>
                )}

                <div className={`${styles.tab_btn_box} ${styles.m_t_b_100}`} ref={forumHistoryRef}>
                    <button
                        className={`${styles.tab_btn}`}
                        onClick={() => handleButtonClick('overview')}
                    >
                        개요
                    </button>
                    <button
                        className={`${styles.tab_btn} ${styles.active}`}
                        onClick={() => handleButtonClick('forumHistory')}
                    >
                        역대 포럼 소개
                    </button>
                </div>
                {returnTableList}
            </div>
        </>
    );
}

export default RegularDebate;
