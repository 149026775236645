import React, { useState } from 'react';
import styles from './PasswordUri.module.scss';
import common from '../../common';
import axios from 'axios';
import { useLocation } from 'react-router-dom';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

function PasswordUri() {

    const { useAlert, useLoading } = common();
    const { pAlert, AlertComponent } = useAlert();
    const { pLoadingOn, pLoadingOff, LoadingComponent } = useLoading();



    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const getData = queryParams.get('data');

    if(!getData){
        window.location.href = `/block`;
    }


    const [password, setPassword] = useState('');
    const [passwordConfirm, setPasswordConfirm] = useState('');

    
    const findPw = async () => {
        // formData 객체 생성
        const formData = {
            uri: getData, // URI 파라미터
            password: password, // 입력된 비밀번호
            password_confirm: passwordConfirm // 입력된 비밀번호 확인
        };

        if(password === ''){
            pAlert('비밀번호를 입력해주세요.');
            return false;
        }

        if(passwordConfirm === ''){
            pAlert('비밀번호 확인을 입력해주세요.');
            return false;
        }

        if(password !== passwordConfirm){
            pAlert('비밀번호와 비밀번호 확인이 맞지 않습니다. 다시 입력해주세요.');
            return false;
        }

        try {
            pLoadingOn();
            const response = await axios.post(`${API_BASE_URL}/naek_login/out/password_remake`, formData, {
                withCredentials: true
            });
    
            if (response.data.result === 't') {
                pAlert('비밀번호 변경이 완료되었습니다. 로그인을 해주세요.');
            } else {
                pAlert(response.data.msg);
            }
        } catch (error) {
            console.error('비밀번호 변경 실패:', error);
            pAlert('비밀번호 변경에 실패했습니다.');
        } finally {
            pLoadingOff();
        }
    };


   

    return (
        <>
            <AlertComponent />
            <LoadingComponent />
            <div className={styles.components_wrap}>
                <div className={styles.idpw_title}>비밀번호 변경</div>
                <form className={styles.form_wrap}>
                    <input type="text" name="username" autoComplete="username" style={{ display: 'none' }} />
                    <div className={styles.form_box}>
                        <span>비밀번호</span>
                        <input
                            type="password"
                            placeholder="비밀번호"
                            className={styles.input}
                            value={password}
                            autoComplete="password"
                            onChange={(e) => setPassword(e.target.value)}
                        />
                    </div>
                    <div className={styles.form_box}>
                        <span>비밀번호 확인</span>
                        <input
                            type="password"
                            placeholder="비밀번호 확인"
                            className={styles.input}
                            value={passwordConfirm}
                            autoComplete="new-password"
                            onChange={(e) => setPasswordConfirm(e.target.value)}
                        />
                    </div>
                </form>
                
                <button onClick={findPw} className={styles.button}>비밀번호 변경</button>
            </div>
        </>
        
    );
}

export default PasswordUri;
