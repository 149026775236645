
import styles from'./MembersProcess.module.scss';

function MembersProcess() {
    return (
        <>
            <div className="components_wrap">
                <div className={styles.cont_box}>
                    <ul className={styles.m_i_ul_2}>
                        <li>회원의 임기는 심사 이듬해 1월 1일부터 시작됩니다.</li>
                        <li>정회원은 현 일반회원 중에서 정회원의 추천을 받아, 약 1년간의 심사를 거쳐 선정되고 있습니다.</li>
                    </ul>

                    <ul className={styles.step_wrap}>
                        <li className={styles.step_wrap_li}>
                            <div className={styles.step_area}>
                                <div className={styles.step}>
                                    <strong>STEP 1</strong>
                                    <span>5월</span>
                                </div>
                            </div>
                            <div className={styles.step_box}>
                                <strong>신입 회원 추천서 접수</strong>
                                <span className={styles.bul}>現 정회원만 추천권 보유</span>
                                <div className={`${styles.bn_wrap} ${styles.step_list}`}>
                                    <ul className={styles.bul_list}>
                                        <li>정회원대상으로 신입 정, 일반, 외국 회원 추천서 요청 및 접수</li>
                                        <li>연임, 원로 회원 심사 대상자 안내 공지</li>
                                    </ul>
                                </div>
                                <span className={styles.bg_m}></span>
                            </div>
                        </li>
                        <li className={styles.step_wrap_li}>
                            <div className={styles.step_area}>
                                <div className={styles.step}>
                                    <strong>STEP 2</strong>
                                    <span>~7월 말</span>
                                </div>
                            </div>
                            <div className={styles.step_box}>
                                <strong>후보자 ‘지명추천서’ 접수</strong>
                                <span className={styles.bul}>후보자 업적 자료</span>
                                <div className={`${styles.bn_wrap} ${styles.step_list}`}>
                                    <ul className={styles.bul_list}>
                                        <li>지명추천서 : 회원 후보의 공적 및 신상자료, 회원심사의 기초자료</li>
                                    </ul>
                                </div>
                                <span className={styles.bg_m}></span>
                            </div>
                        </li>
                        <li className={styles.step_wrap_li}>
                            <div className={styles.step_area}>
                                <div className={styles.step}>
                                    <strong>STEP 3</strong>
                                    <span>8~9월 중</span>
                                </div>
                            </div>
                            <div className={styles.step_box}>
                                <strong>분과별 회원심사위원 위촉 및 심사위원 전제 회의 개최</strong>
                                <span className={styles.bul}>각 분과별 심사위원 위촉</span>
                                <div className={`${styles.bn_wrap} ${styles.step_list}`}>
                                    <ul className={styles.bul_list}>
                                        <li>분과 회원 심사 방향, 방법, 심사 일정 협의, 심사권고안 공유</li>
                                        <li>정회원 중에서 분야, 대학, 산학연 등 고른 배분, 연임 등도 고려하여 구성</li>
                                    </ul>
                                </div>
                                <span className={styles.bg_m}></span>
                            </div>
                        </li>
                        <li className={styles.step_wrap_li}>
                            <div className={styles.step_area}>
                                <div className={styles.step}>
                                    <strong>STEP 4</strong>
                                    <span>9월 말</span>
                                </div>
                            </div>
                            <div className={styles.step_box}>
                                <strong>심사위원 평가소견서 접수</strong>
                                <span className={styles.bul}>분과 회원심사 위원 개별 평가</span>
                                <div className={`${styles.bn_wrap} ${styles.step_list}`}>
                                    <ul className={styles.bul_list}>
                                        <li>8개 분과 각 심사위원의 분과 신입회원 후보자 개별 평가</li>
                                    </ul>
                                </div>
                                <span className={styles.bg_m}></span>
                            </div>
                        </li>
                        <li className={styles.step_wrap_li}>
                            <div className={styles.step_area}>
                                <div className={styles.step}>
                                    <strong>STEP 5</strong>
                                    <span>10월</span>
                                </div>
                            </div>
                            <div className={styles.step_box}>
                                <strong>분과별 회원심사위원회 개최</strong>
                                <span className={styles.bul}>6개 분과 개별 심사 회의 개최</span>
                                <div className={`${styles.bn_wrap} ${styles.step_list}`}>
                                    <ul className={styles.bul_list}>
                                        <li>분과별 회원심사위원회 개최</li>
                                        <li>전체회원위원회에 상정할 분과 후보 순위 결정</li>
                                        <li>연심심사, 원로회원 후보자 심사 등</li>
                                    </ul>
                                </div>
                                <span className={styles.bg_m}></span>
                            </div>
                        </li>
                        <li className={styles.step_wrap_li}>
                            <div className={styles.step_area}>
                                <div className={styles.step}>
                                    <strong>STEP 6</strong>
                                    <span>11월</span>
                                </div>
                            </div>
                            <div className={styles.step_box}>
                                <strong>회원위원회 개최</strong>
                                <span className={styles.bul}>회원위원회 위원 투표</span>
                                <div className={`${styles.bn_wrap} ${styles.step_list}`}>
                                    <ul className={styles.bul_list}>
                                        <li>이사회에 상정할 신입회원 후보자 선정, 회의 전 후보자 지명추천서, 활동내역 등 자료 제공</li>
                                        <li>회원위원회 위원 투표진행, 분과별 5인으로 구성</li>
                                        <li>무기명 비밀 투표로 진행, 참석 위원 2/3이상 득표 후보자 선정</li>
                                    </ul>
                                </div>
                                <span className={styles.bg_m}></span>
                            </div>
                        </li>
                        <li className={styles.step_wrap_li}>
                            <div className={styles.step_area}>
                                <div className={styles.step}>
                                    <strong>STEP 7</strong>
                                    <span>11월 말</span>
                                </div>
                            </div>
                            <div className={styles.step_box}>
                                <strong>이사회</strong>
                                <span className={styles.bul}>일반회원 승인</span>
                                <div className={`${styles.bn_wrap} ${styles.step_list}`}>
                                    <ul className={styles.bul_list}>
                                        <li>정회원, 외국회원, 원로회원 후보 의결</li>
                                    </ul>
                                </div>
                                <span className={styles.bg_m}></span>
                            </div>
                        </li>
                        <li className={styles.step_wrap_li}>
                            <div className={styles.step_area}>
                                <div className={styles.step}>
                                    <strong>STEP 8</strong>
                                    <span>12월</span>
                                </div>
                            </div>
                            <div className={styles.step_box}>
                                <strong>총회 서면결의</strong>
                                <span className={styles.bul}>現 정회원을 대상으로 한 서면투표 진행</span>
                                <div className={`${styles.bn_wrap} ${styles.step_list}`}>
                                    <ul className={styles.bul_list}>
                                        <li>정회원, 외국회원, 원로회원 후보자 대상 서면 투표</li>
                                    </ul>
                                </div>
                                <span className={styles.bg_m}></span>
                            </div>
                        </li>
                        <li className={styles.step_wrap_li}>
                            <div className={styles.step_area}>
                                <div className={styles.step}>
                                    <strong>STEP 9</strong>
                                    <span>차년도 1월</span>
                                </div>
                            </div>
                            <div className={styles.step_box}>
                                <strong>신입 정회원 환영식 </strong>
                                <span className={styles.bul}>신입 정회원 인증패/인증서 수여</span>
                                <div className={`${styles.bn_wrap} ${styles.step_list}`}>
                                    <ul className={styles.bul_list}>
                                        <li>신입 일반회원은 총회 개최시 인증서 수여</li>
                                    </ul>
                                </div>
                                <span className={styles.bg_m}></span>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </>
    );
}

export default MembersProcess;