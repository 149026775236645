import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styles from './PaymentRefund.module.scss';
import common from '../../../common';

const PaymentRefund = () => {
    const { useAlert, useLoading, useConfirm, useModal, pToast, pClipboard } = common();
    const { pAlert, AlertComponent } = useAlert();
    const { pLoadingOn, pLoadingOff, LoadingComponent } = useLoading();
    const { pConfirm, ConfirmComponent } = useConfirm();
    const { pModal, closeModal, ModalComponent } = useModal();
    const navigate = useNavigate();
    const location = useLocation();

    // useLocation을 통해 state를 받아옵니다
    const { state } = location;
    const [data, setData] = useState(null);

    useEffect(() => {
        if (state && state.data) {
            // console.log('Received state:', state);
            setData(state.data); // 받아온 데이터를 state에 저장
        } else {
            // console.log('No data received');
        }
    }, [state]);

    // useEffect(() => {
    //     if (data) {
    //         console.log('Updated data:', data);
    //         console.log('Updated result:', data.result);
    //     }
    // }, [data]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const handleBackClick = () => {
        window.scrollTo(0, 0);
        navigate('/payment', { replace: true });
    };

    const formatNumber = (num) => {
        if (!num) return '';
        return Number(num).toLocaleString();
    };

    return (
        <>
            <AlertComponent />
            <LoadingComponent />
            <ConfirmComponent />
            <ModalComponent />
            <div className={styles.payment_wrap}>
                <div className={styles.cont_tit_box}>
                    {data && (
                        <>
                            {data.result === 't' ? (
                                <>
                                    <i className='pi pi-check-circle'></i>
                                    <p>결제 취소가 성공적으로 완료되었습니다.</p>
                                </>
                            ) : (
                                <>
                                    <i className={`pi pi-exclamation-circle ${styles.check_btn}`}></i>
                                    <p>결제가 실패되었습니다.</p>
                                </>
                            )}
                        </>
                    )}
                </div>
                <div className={styles.cont_box}>
                    {data ? (
                        data.result === 't' ? (
                            <>
                                <table className={styles.info_table}>
                                    <tbody>
                                        <tr>
                                            <td>결제 상태</td>
                                            <td>{data?.data?.statusName}</td>
                                        </tr>
                                        <tr>
                                            <td>주문 번호</td>
                                            <td>{data?.data?.orderId}</td>
                                        </tr>
                                        <tr>
                                            <td>결제 금액</td>
                                            <td>{formatNumber(data?.data?.amount)} 원</td>
                                        </tr>
                                        <tr>
                                            <td>취소 일시</td>
                                            <td>{data?.data?.cancelRequestTime}</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <p>* 결제 이후 주문 번호 확인은 입력하신 이메일을 통해 확인하실 수 있습니다.</p>
                            </>
                        ) : (
                            <>
                                <table className={styles.info_table}>
                                    <tbody>
                                        <tr>
                                            <td>오류 코드</td>
                                            <td>{data?.data?.code}</td>
                                        </tr>
                                        <tr>
                                            <td>실패 사유</td>
                                            <td>{data?.data?.msg}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </>
                        )
                    ) : (
                        <p></p>
                    )}
                </div>
                <div className={styles.pm_btn_wrap}>
                    <button className={styles.submit_btn} onClick={handleBackClick}>이전으로</button>
                </div>
            </div>
        </>
    );
}

export default PaymentRefund;