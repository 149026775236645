import React from 'react';

function Footer() {


    return (
        <>
            <footer className='footer_wrap'>
                <div className="footer_box">
                    <div className="flex_between f_top_content">
                        <div className="info_box">
                            
                        </div>
                        <div className="flex_between icon_box">
                            <a href="https://www.facebook.com/naek1995" target='_blank' rel="noopener noreferrer">
                                <div className="f_icon icon_facebook"></div>
                            </a>
                            <a href="https://blog.naver.com/naek1995" target='_blank' rel="noopener noreferrer">
                                <div className="f_icon icon_blog"></div>
                            </a>
                            <a href="https://www.youtube.com/channel/UCgZHNqY0WjPDm4t7NuF5vCg" target='_blank' rel="noopener noreferrer">
                                <div className="f_icon icon_youtube"></div>
                            </a>
                        </div>
                    </div>
                    <div className="f_bottom_content">
                        <div className="b_content_box">
                            <span className='b_content_bold'>Address</span>
                            <span className='b_content_txt'>15F, Korea Technology Center, 305 Teheran-ro, Gangnam-gu, Seoul, Republic of Korea</span>
                        </div>
                        <div className="b_content_box">
                            <span className='b_content_bold'>Tel.</span>
                            <span className='b_content_txt'>+82-2-6009-4000</span>
                        </div>
                        <div className="b_content_box">
                            <span className='b_content_bold'>Email</span>
                            <span className='b_content_txt'>naek@naek.or.kr</span>
                        </div>
                        <div className="b_content_copyright">
                            <span>© Copyright - The National Academy of Engineering of Korea</span>
                        </div>
                    </div>
                    <div className="footer_logo_box">
                        <div className="footer_logo_eng"></div>    
                    </div>
                </div>
            </footer>
        </>
    );
}

export default Footer;

